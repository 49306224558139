import { FC } from 'react'
import { Box } from '@material-ui/core'
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import { Typography, Button } from '@olaisaac/design-system'

import type { HelperButtontProps } from './types'

const HelperButton: FC<HelperButtontProps> = ({ link, onClick }) => {
  function handleOnClick() {
    if (onClick) onClick()
    window.open(encodeURI(link), '_blank')
  }

  return (
    <Button variation="ghost" onClick={handleOnClick}>
      <Box display="flex" alignItems="center">
        <HelpOutlineOutlinedIcon />
        <Typography
          variation="buttonLarge"
          color="accent"
          style={{ textTransform: 'capitalize', marginLeft: 8 }}
        >
          Ajuda
        </Typography>
      </Box>
    </Button>
  )
}

export default HelperButton
