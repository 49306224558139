import styled from 'styled-components'

import { MAX_STEP_WIDTH } from '../../constants'

export const Wrapper = styled.div`
  max-width: ${MAX_STEP_WIDTH}px;
  margin: ${({ theme }) => theme.spacing(5)}px auto 100px;
`

export const CardSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`
