import { Route, Switch } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import SchoolRoutes from '@/escolas/router/[schoolSlug]/router'
import { ContractProvider } from '@/escolas/contexts/contractContext'
import { BreadcrumbsProvider, Breadcrumb } from '@/escolas/contexts/BreadcrumbsContext'
import { SnackbarProvider } from '@/shared/contexts/snackbarContext'
import { ReceivablesCheckoutProvider } from '@/escolas/contexts/checkoutContext'
import { PosProvider } from '@/escolas/contexts/posContext'
import { AgreementProvider } from '@/escolas/contexts/agreementContext/agreementContext'
import { CheckoutCartProvider } from '@/modules/guardians/contexts/CheckoutCartContext'
import { InstallmentDrawerProvider } from '@/modules/guardians/contexts/InstallmentDrawerContext'

import { PageWrapper } from '../components/PageWrapper'

/**
 * TODO:
 * - Move providers to another file (shared/contexts/index [AppProvider])
 */

export type PageRouteProps = {
  breadcrumbs?: Breadcrumb[]
  path: string
}

const SchoolRouter = () => {
  return (
    <Switch>
      <PageWrapper>
        <SchoolRoutes />
      </PageWrapper>
    </Switch>
  )
}

export default function Router() {
  return (
    <BreadcrumbsProvider>
      <SnackbarProvider>
        <ContractProvider>
          {/* Remover ReceivablesCheckoutProvider após descontinuar o useCheckout; usar somente o CheckoutCartProvider */}
          <ReceivablesCheckoutProvider>
            <CheckoutCartProvider>
              <PosProvider>
                <AgreementProvider>
                  <InstallmentDrawerProvider>
                    <QueryParamProvider ReactRouterRoute={Route}>
                      <SchoolRouter />
                    </QueryParamProvider>
                  </InstallmentDrawerProvider>
                </AgreementProvider>
              </PosProvider>
            </CheckoutCartProvider>
          </ReceivablesCheckoutProvider>
        </ContractProvider>
      </SnackbarProvider>
    </BreadcrumbsProvider>
  )
}
