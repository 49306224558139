import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { SIDE_MENU_WIDTH } from '@/modules/app/components/Layout/styles'

export const Container = styled(Box)`
  width: calc(100vw - ${SIDE_MENU_WIDTH});
  display: flex;
  flex-direction: column;
  padding: 34px 0;
  padding-right: 0;
  margin: 24px -64px 0 -64px;
  background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[1]};

  height: 232px;
  transition: height ${({ theme }) => theme.transitions.duration.standard}ms
    ${({ theme }) => theme.transitions.easing.easeInOut} 0ms;

  &.compacted {
    height: 92px;
    flex-direction: row;
    align-items: center;
    padding: 0;
    padding-left: 64px;
    overflow: hidden;
  }
`

export const StudentCardPlaceholder = styled(Skeleton)`
  transform: none;
  width: 256px;
  height: 48px;
  padding: 10px 12px;
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
