import { FormControl, Box, Typography as MuiTypography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Grid, Table, TableColumns, TextField, Typography } from '@olaisaac/design-system'
import { useContext } from 'react'
import { Product } from 'src/shared/interfaces'
import { formatCentsToReal } from 'src/shared/utils'
import { useSnackbar } from '@/shared/hooks'

import { CampaignContext } from '../../context'
import { ActionType } from '../../types'

const SelectNextYearProduct = () => {
  const { state, dispatch } = useContext(CampaignContext)
  const { products, nextYearProductId, nextYearProduct } = state
  const { setIsOpen: setSnackbarIsOpen } = useSnackbar()

  const handleSelectNextYearProduct = productId => {
    setSnackbarIsOpen(false)

    dispatch({
      type: ActionType.SELECT_NEXT_YEAR_PRODUCT,
      payload: {
        nextYearProductId: productId,
      },
    })
  }

  const columns: TableColumns = [
    { headerName: 'Nome', field: 'name' },
    {
      headerName: 'Série',
      field: 'educational_substage',
      valueFormatter: value => value?.name,
    },
    {
      headerName: 'Turno',
      field: 'product_program',
      valueFormatter: value => value?.name,
    },
    {
      headerName: 'Categoria',
      field: 'product_category',
      valueFormatter: value => value?.name,
    },
    {
      headerName: 'Anuidade',
      field: 'totalTuitionAmount',
      valueFormatter: value => formatCentsToReal(value),
    },
  ]

  return (
    <Grid item xs={4}>
      <Typography>Selecione o produto que gostaria de rematricular</Typography>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          data-testid="next-year-product-select"
          id="next-year-product-select"
          options={products}
          getOptionLabel={product => product.name || ''}
          noOptionsText="Nenhum produto encontrado"
          disableClearable
          forcePopupIcon={false}
          onChange={(_, data) => {
            const product = data as Product

            handleSelectNextYearProduct(product.id)
          }}
          renderOption={product => <MuiTypography>{product.name}</MuiTypography>}
          renderInput={params => (
            <TextField
              {...params}
              label="Selecione o produto que gostaria de rematricular"
              placeholder="Selecione o produto que gostaria de rematricular"
            />
          )}
        />
      </FormControl>

      {nextYearProduct && (
        <Box paddingTop="20px">
          <Table<Product>
            columns={columns}
            rows={Array.of({ ...nextYearProduct, key: nextYearProductId })}
            sorted={false}
          />
        </Box>
      )}
    </Grid>
  )
}

export default SelectNextYearProduct
