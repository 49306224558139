import React, { FC } from 'react'
import styled from 'styled-components'
import { Button, DialogTitle, DialogContent, DialogActions } from '@olaisaac/design-system'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import booksAndApple from 'src/escolas/assets/booksAndApple.png'
import meditating from 'src/escolas/assets/meditating.png'
import { useApi } from 'src/shared/hooks'

const DEFAULT_MESSAGE = 'Por favor, tente novamente ou entre em contato com a equipe responsável.'

const StyledImage = styled.img`
  text-align: center;
  width: 50%;
  padding: ${({ theme }) => theme.spacing(4)}px 0px;
`

const StyledPaddingBox = styled(Box)`
  padding-top: 20px;
`

const TextAndImageContent: FC<{
  imageSrc: string
  message: string
  secondaryMessage?: React.ReactNode
}> = ({ imageSrc, message, secondaryMessage }) => (
  <>
    <Box display="flex" justifyContent="center" textAlign="center">
      <Typography variant="body2">{message}</Typography>
    </Box>
    {secondaryMessage ? (
      <StyledPaddingBox display="flex" justifyContent="center" textAlign="center">
        {secondaryMessage}
      </StyledPaddingBox>
    ) : null}
    <Box display="flex" justifyContent="center">
      <StyledImage src={imageSrc} alt="" />
    </Box>
  </>
)

export const FailureFeedbackContent: FC<{ message?: string }> = ({ message = DEFAULT_MESSAGE }) => {
  const { correlationId } = useApi()
  const secondaryMessage = correlationId ? (
    <Typography variant="body2">
      Se o erro persistir, informe este código ao suporte:{' '}
      <strong>#{correlationId.split('-')[0]}</strong>
    </Typography>
  ) : null
  return (
    <TextAndImageContent
      message={message}
      imageSrc={booksAndApple}
      secondaryMessage={secondaryMessage}
    />
  )
}

export type ConfirmationDialogProps = {
  backButtonLabel?: string
  buttonLabel?: string
  centered?: boolean
  closeIcon?: boolean
  hideTitle?: boolean
  isConfirmDisabled?: () => boolean
  isVisible: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  onClose: () => void
  shortId?: string
  submitHandler: () => void
  title?: string
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  buttonLabel = 'Ok, entendi!',
  backButtonLabel,
  centered,
  children,
  closeIcon,
  isVisible,
  onClose,
  submitHandler,
  title,
  isConfirmDisabled,
  hideTitle,
  maxWidth,
}) => (
  <Dialog
    data-testid="feedback-dialog"
    open={isVisible}
    onClose={onClose}
    maxWidth={maxWidth || 'xs'}
    fullWidth
  >
    {!hideTitle && (
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {title}
          {closeIcon && (
            <IconButton onClick={onClose} data-testid="close-feedback-button">
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
    )}
    <DialogContent>{children}</DialogContent>
    <DialogActions className={`${centered ? 'centered' : ''}`}>
      {backButtonLabel && (
        <Button variation="ghost" onClick={onClose}>
          {backButtonLabel}
        </Button>
      )}
      <Button variation="primary" onClick={submitHandler} disabled={isConfirmDisabled?.()}>
        {buttonLabel}
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmationDialog

export const failureFeedbackTitle = 'Aconteceu um erro inesperado'

export const FailureFeedbackDialog: FC<ConfirmationDialogProps> = ({
  title = failureFeedbackTitle,
  ...rest
}) => (
  <ConfirmationDialog title={title} {...rest}>
    {rest.children ?? <FailureFeedbackContent />}
  </ConfirmationDialog>
)

export const SuccessFeedbackContent: FC<{ message?: string }> = ({
  message = 'Ação realizada com sucesso.',
}) => <TextAndImageContent message={message} imageSrc={meditating} />

export const successFeedbackTitle = 'Sucesso!'

export const SuccessFeedbackDialog: FC<ConfirmationDialogProps> = ({
  title = successFeedbackTitle,
  ...rest
}) => (
  <ConfirmationDialog title={title} {...rest}>
    {rest.children ?? <SuccessFeedbackContent />}
  </ConfirmationDialog>
)
