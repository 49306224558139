export enum GuardianDetailsTabs {
  CONTRATOS = 'contratos',
  PARCELAS = 'parcelas',
  RESUMO = 'resumo',
}

export enum InstallmentStatus {
  AGGLUTINATED = 'AGGLUTINATED',
  CANCELED = 'CANCELED',
  DUE_TODAY = 'DUE_TODAY',
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  PAID_AND_CANCELED = 'PAID_AND_CANCELED',
  PENDING = 'PENDING',
  RENEGOTIATED = 'RENEGOTIATED',
}

export enum InstallmentRowType {
  ENROLLMENT_INSTALLMENT = 'ENROLLMENT_INSTALLMENT',
  INSTALLMENT = 'INSTALLMENT',
  NEGOTIATION = 'NEGOTIATION',
  NEGOTIATION_START = 'NEGOTIATION_START',
}

export enum InstallmentStatusDescription {
  NEGOTIATION_EXPIRED = 'NEGOTIATION_EXPIRED',
  OVERDUE = 'OVERDUE',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED',
  PENDING_DOWNPAYMENT = 'PENDING_DOWNPAYMNENT',
  PENDING_ENROLL_PROCESSING = 'PENDING_ENROLL_PROCESSING',
  PENDING_INVOICE_GENERATION = 'PENDING_INVOICE_GENERATION',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
}

export enum ReceivableType {
  ENROLLMENT = 'ENROLLMENT',
  NEGOTIATION = 'NEGOTIATION',
  TUITION = 'TUITION',
}

export enum InstallmentType {
  DOWN_PAYMENT = 'DOWN_PAYMENT',
  ENROLLMENT = 'ENROLLMENT',
  INSTALLMENT = 'INSTALLMENT',
  ISAAC_PAY = 'ISAAC_PAY',
  NEGOTIATION = 'NEGOTIATION',
  RECEIVABLE = 'RECEIVABLE',
  TUITION = 'TUITION',
}

export enum InvoiceErrorDescriptionEnum {
  CEP = 'ZIP_CODE',
  CPF = 'CPF',
  DEFAULT = 'DEFAULT',
  DUE_DATE = 'DUE_DATE',
  EARLY_PAYMENT_DISCOUNT = 'EARLY_PAYMENT_DISCOUNT',
}

export const MIN_RECEIVABLES_ALLOWED = 2

export const DEFAULT_QUERY_STALE_TIME = 1000 * 60 * 5
export const DEFAULT_QUERY_RETRY_COUNT = 2
export const NO_QUERY_CACHE = 0

export const guardianDetailsQueries = [
  'guardian-details-installments',
  'guardian-details-contracts',
]

export const MAX_DOWN_PAYMENT_INSTALLMENTS_IN_CART = 1
export const MIN_CART_ITEMS = 1

export const invoicesErrorsPriority: InvoiceErrorDescriptionEnum[] = [
  InvoiceErrorDescriptionEnum.CEP,
  InvoiceErrorDescriptionEnum.CPF,
  InvoiceErrorDescriptionEnum.DUE_DATE,
]

export enum NegotiationType {
  AGGLUTINATION = 'AGGLUTINATION',
  NEGOTIATION_API = 'NEGOTIATION_API',
  NEGOTIATION_API_V2 = 'NEGOTIATION_API_V2',
  RENEGOTIATION = 'RENEGOTIATION',
}

export enum GuardianFinanceSummaryStatus {
  IN_NEGOTIATION = 'IN_NEGOTIATION',
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
}
