import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { propEq } from 'ramda'
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'
import { Box, ListItemIcon, Menu, MenuItem, Tooltip } from '@material-ui/core'
import { IconButton, Button, ButtonDocker } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { InfoOutlined, MoreVert as MoreVertIcon } from '@material-ui/icons'

import CopyInvoiceLinkButton from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/CopyInvoiceLinkButton'
import PrintReceiptButton from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/PrintReceiptButton'
import { useJWT } from '@/shared/hooks'
import { useContract, useNavigation } from '@/escolas/hooks'
import { DrawerState } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/constants'
import {
  InstallmentType,
  LiquidationSource,
  PaymentMethod,
  Receivable,
  ReceivableStatuses,
} from '@/shared/interfaces'
import { date2PTFormat, isDayBeforeToday } from '@/shared/utils'
import { FeatureFlags, useFeatureFlag } from '@/shared/hooks/useFeatureFlag'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

import useManualLiquidationNavigation from './hooks/useManualLiquidationNavigation'
import { useSendManualLiquidationEvent } from '@/modules/guardians/ManualLiquidation/hooks/manualLiquidation/useSendManualLiquidationEvents'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { useIsaacPayOutsourcedPaymentProvider } from '@/modules/isaacpay/hooks/useIsaacPayOutsourcedPaymentProvider'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import ChangePaymentDialog from '@/escolas/components/modal/ChangePaymentMethodDialog'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/escolas/components/modal/ConfirmationDialog'
import styled, { useTheme } from 'styled-components'
import { useGuardianNavigation } from '../../hooks/useGuardianNavigation'

const MenuItemIcon = styled(ListItemIcon)`
  max-width: 16px;
  min-width: 16px;
  margin-left: 8px;
`
type PaymentSourceKey = PaymentMethod | LiquidationSource

type InstallmentDrawerActionsProps = {
  isPayableByCreditCard?: boolean
  removeOptionManualLiquidation?: boolean
  replaceReceivables: (receivables: Array<Receivable>) => void
  selectedInstallmentHasError: boolean
  selectedReceivableId: uuid
  setDrawerState: Dispatch<SetStateAction<DrawerState>>
  setShowInvoicesNotGeneratedDialog: (value: boolean) => void
  urn?: string
}

export const InstallmentDrawerActions: FC<InstallmentDrawerActionsProps> = ({
  selectedReceivableId,
  setDrawerState,
  removeOptionManualLiquidation,
  selectedInstallmentHasError,
  setShowInvoicesNotGeneratedDialog,
  isPayableByCreditCard,
  urn,
  replaceReceivables,
}) => {
  const { isAdmin } = useJWT()
  const { schoolId } = useNavigation()
  const { contract } = useContract()

  const sendManualLiquidationEvent = useSendManualLiquidationEvent()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { sendHotjarEvent } = useHotjar()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { school } = useSelectedSchool()
  const isOutsourcedProvider = useIsaacPayOutsourcedPaymentProvider(school)
  const [showChangePaymentModal, setShowChangePaymentModal] = useState(false)
  const [showFailedDialog, setShowFailedDialog] = useState<boolean>(false)
  const { setPageToReturn } = useGuardianNavigation()

  const {
    value: manualLiquidationFeatureFlagValue,
    config: manualLiquidationConfig,
  } = useFeatureFlag(FeatureFlags.MANUAL_LIQUIDATION_PILOT_SCHOOLS_FEATURE_FLAG)
  const { search: currentPageQueryParams } = useLocation()

  const IsManualLiquidationFeatureFlagEnabled =
    !isAdmin && manualLiquidationFeatureFlagValue && manualLiquidationConfig.includes(schoolId)

  const installment = contract?.installments.find(installment =>
    installment?.receivables.some(propEq('id', selectedReceivableId))
  )

  const { goToManualLiquidation, goToEditManualLiquidation } = useManualLiquidationNavigation({
    urn,
    callbackParams: currentPageQueryParams,
  })
  const theme = useTheme() as any

  const receivables = installment?.receivables
  const receivable = receivables?.find(propEq('id', selectedReceivableId))
  const open = Boolean(anchorEl)
  const isOpen = receivable?.status === 'OPEN'
  const isManualLiquidation = [
    LiquidationSource.ISAAC_ACCOUNT,
    LiquidationSource.SCHOOL_ACCOUNT,
  ].includes(receivable?.payment_source)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const hasAgglutination = receivables?.some(
    receivable => receivable?.status === ReceivableStatuses.AGGLUTINATED
  )
  const isOverdue = isDayBeforeToday(receivable?.due_date)
  const PAYMENT_SOURCE_MAP: Partial<Record<PaymentSourceKey, string>> = {
    [PaymentMethod.BANK_SLIP_PAYMENT_METHOD]: 'Pagamento por boleto',
    [PaymentMethod.CREDIT_CARD_PAYMENT_METHOD]: 'Pagamento por cartão de crédito',
    [PaymentMethod.DEBIT_CARD_PAYMENT_METHOD]: 'Pagamento por cartão de débito',
    [LiquidationSource.ISAAC_ACCOUNT]: 'Recebimento manual na conta do isaac',
    [PaymentMethod.PIX_PAYMENT_METHOD]: 'Pagamento por PIX',
    [LiquidationSource.SCHOOL_ACCOUNT]: 'Recebimento manual na conta da escola',
    [PaymentMethod.SCHOOL_PORTFOLIO_REPURCHASE]: 'Baixa registrada por recompra de carteira',
  }

  let info = ''
  if (receivable?.status === ReceivableStatuses.PAID) {
    const date = dayjs(receivable?.paid_date).utc().format()
    let payment = PAYMENT_SOURCE_MAP[receivable.payment_source]
    if (!payment || receivable?.payment_method === PaymentMethod.SCHOOL_PORTFOLIO_REPURCHASE) {
      payment = PAYMENT_SOURCE_MAP[receivable.payment_method]
    }
    info = `${payment} em ${date2PTFormat(date)}`
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isManualLiquidationBlockedForSchool = useUnleashFlag(
    UnleashFlags.B2CB_984_DISABLE_MANUAL_LIQUIDATION
  )
  const isRenegotiateAndAgglutinateButtonEnabled = useUnleashFlag(
    UnleashFlags.REG_2042_ENABLE_RENEGOTIATE_AND_AGGLUTINATE_BUTTONS
  )

  const isEditable =
    isManualLiquidation && !hasAgglutination && !(isManualLiquidationBlockedForSchool && !isAdmin)

  const canEditManualLiquidation = IsManualLiquidationFeatureFlagEnabled
    ? isEditable && receivable?.payment_source === LiquidationSource.ISAAC_ACCOUNT
    : isEditable

  const isEnrollmentReceivable = receivable?.installment.type === InstallmentType.ENROLLMENT
  const isIsaacPayReceivable = receivable?.installment.type === InstallmentType.ISAACPAY
  const isEditEnrollmentDueDateEnabled = useUnleashFlag(
    UnleashFlags.PAS_734_EDIT_ENROLLMENT_DUEDATE
  )
  const canEditDueDate =
    isEditEnrollmentDueDateEnabled &&
    (isEnrollmentReceivable || isIsaacPayReceivable) &&
    !hasAgglutination &&
    (receivable?.status === ReceivableStatuses.OPEN ||
      receivable?.status === ReceivableStatuses.OVERDUE)

  const handleGoToManualLiquidation = () => {
    if (selectedInstallmentHasError) {
      setShowInvoicesNotGeneratedDialog(true)
    } else {
      sendManualLiquidationEvent(
        EventDispatcherEvents.BUTTON_CLICKED,
        EventDispatcherEntities.MANUAL_LIQUIDATION,
        undefined,
        {
          name: 'Registrar recebimento',
          receivable_id: selectedReceivableId,
          selected_date: dayjs().format(),
          contract_id: contract.id,
        }
      )
      sendHotjarEvent(HotjarEvents.MANUAL_LIQUIDATION)
      setPageToReturn()
      goToManualLiquidation()
    }
  }

  const handleClickRenegotiation = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.INSTALLMENT_DRAWER,
        entity: EventDispatcherEntities.RENEGOTIATE,
        action: 'click',
        customProperties: {
          $name: 'Renegociar',
          $receivable_id: selectedReceivableId,
        },
      })
    if (selectedInstallmentHasError) {
      setShowInvoicesNotGeneratedDialog(true)
    } else {
      sendHotjarEvent(HotjarEvents.RENEGOTIATE_INSTALLMENT)
      setDrawerState(DrawerState.RENEGOTIATION)
    }
  }

  const handleClickAddDiscounts = () => {
    sendHotjarEvent(HotjarEvents.ADD_INSTALLMENT_DISCOUNT)
    setDrawerState(DrawerState.ADD_DISCOUNTS)
  }

  const handleClickEditEnrollmentDueDate = () => {
    if (selectedInstallmentHasError) {
      setShowInvoicesNotGeneratedDialog(true)
    } else {
      sendHotjarEvent(HotjarEvents.EDIT_ENROLLMENT_DUEDATE)
      setDrawerState(DrawerState.EDIT_ENROLLMENT_DUEDATE)
    }
  }
  const changePaymentMethodTitle = isPayableByCreditCard
    ? 'Alterar para pagamento em boleto'
    : 'Alterar para pagamento em cartão'

  return (
    <>
      <FailureFeedbackDialog
        buttonLabel="Voltar"
        isVisible={showFailedDialog}
        onClose={() => setShowFailedDialog(false)}
        submitHandler={() => setShowFailedDialog(false)}
        centered
      >
        <FailureFeedbackContent />
      </FailureFeedbackDialog>
      <ChangePaymentDialog
        isVisible={showChangePaymentModal}
        buttonLabel="Confirmar alteração"
        onClose={() => setShowChangePaymentModal(false)}
        submitHandler={() => setShowChangePaymentModal(false)}
        closeIcon
        backButtonLabel="Voltar"
        maxWidth="sm"
        title={changePaymentMethodTitle}
        receivableId={receivable?.id}
        setShowChangePaymentModal={setShowChangePaymentModal}
        isPayableByCreditCard={isPayableByCreditCard}
        replaceReceivables={replaceReceivables}
        setShowFailedDialog={setShowFailedDialog}
      />
      {isOpen ? (
        <ButtonDocker>
          <CopyInvoiceLinkButton
            openReceivable={receivable}
            selectedInstallmentHasError={selectedInstallmentHasError}
            setShowInvoicesNotGeneratedDialog={setShowInvoicesNotGeneratedDialog}
          />
          <IconButton
            variation="ghost"
            aria-label="mais ações"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            data-testid="more-actions"
          >
            <MoreVertIcon />
          </IconButton>
        </ButtonDocker>
      ) : (
        <ButtonDocker description={info}>
          <PrintReceiptButton receivableId={selectedReceivableId} />
          {canEditManualLiquidation && (
            <Button
              fullWidth
              variation="ghost"
              onClick={() => {
                sendHotjarEvent(HotjarEvents.EDIT_MANUAL_LIQUIDATION)
                setPageToReturn()
                goToEditManualLiquidation()
              }}
            >
              Editar recebimento
            </Button>
          )}
        </ButtonDocker>
      )}

      {isOpen && (
        <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
          {!removeOptionManualLiquidation && (
            <MenuItem onClick={handleGoToManualLiquidation}>Registrar recebimento</MenuItem>
          )}
          <>
            {isAdmin && isRenegotiateAndAgglutinateButtonEnabled && (
              <MenuItem
                disabled={isEnrollmentReceivable}
                onClick={() => handleClickRenegotiation()}
              >
                Renegociar
              </MenuItem>
            )}
            {isAdmin && (
              <MenuItem onClick={() => handleClickAddDiscounts()}>Editar descontos</MenuItem>
            )}
            {canEditDueDate && (
              <MenuItem onClick={() => handleClickEditEnrollmentDueDate()}>
                Editar vencimento
              </MenuItem>
            )}
            {isOutsourcedProvider && !isOverdue && (
              <MenuItem onClick={() => setShowChangePaymentModal(true)}>
                {changePaymentMethodTitle}
              </MenuItem>
            )}
            {isOutsourcedProvider && isOverdue && (
              <Tooltip title="Não é possível alterar a forma de pagamento de uma parcela vencida">
                <Box>
                  <MenuItem disabled onClick={() => setShowChangePaymentModal(true)}>
                    {changePaymentMethodTitle}
                    <MenuItemIcon>
                      <InfoOutlined style={{ color: theme.primitiveTokens.colors.gray[80] }} />
                    </MenuItemIcon>
                  </MenuItem>
                </Box>
              </Tooltip>
            )}
          </>
        </Menu>
      )}
    </>
  )
}
