import { useContext } from 'react'
import { useUnleashFlag, UnleashFlags } from '@/shared/hooks'
import { useLocation } from 'react-router-dom'
import { GuardianDetailsTabs } from '@/modules/guardians/GuardianDetails/constants'
import { GuardianNavigationContext } from '../contexts/GuardianNavigationContext'

export const useGuardianNavigation = () => {
  const isGuardianSummaryPageEnabled = useUnleashFlag(
    UnleashFlags.REG_2202_ENABLE_GUARDIAN_SUMMARY_PAGE
  )
  const location = useLocation()
  const context = useContext(GuardianNavigationContext)

  const { pageToReturn, setPageToReturn } = context
  const defaultGuardianEntryPage = isGuardianSummaryPageEnabled
    ? GuardianDetailsTabs.RESUMO
    : GuardianDetailsTabs.PARCELAS

  const handleReturnPage = () => {
    const fullPathWithQuery = `${location.pathname}${location.search}`
    setPageToReturn(fullPathWithQuery)
  }

  return {
    defaultGuardianEntryPage,
    pageToReturn,
    setPageToReturn: handleReturnPage,
  }
}
