import { Tooltip } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'

import { InstallmentRowType } from '@/modules/guardians/GuardianDetails/constants'

import { Row } from '../GuardianDetailsInstallmentsTable/types'
import { formatter } from '../GuardianDetailsInstallmentsTable/utils/formatter'

export const InstallmentInfo = (value: Row['installment_info']) =>
  [InstallmentRowType.NEGOTIATION, InstallmentRowType.NEGOTIATION_START].includes(
    value.row_type
  ) ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '155px',
        ...(!value.checkable && { marginLeft: '32px' }),
      }}
    >
      <Tooltip title={value.negotiation_id}>
        <Typography variation="bodyLarge">
          {formatter.negotiation_id(value.negotiation_id)}
        </Typography>
      </Tooltip>
      <Tooltip title={formatter.competence_date(value.competence_date)}>
        <Typography
          variation="caption"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {formatter.competence_date(value.competence_date)}
        </Typography>
      </Tooltip>
    </div>
  ) : (
    <div
      style={{
        ...(!value.checkable && { marginLeft: '32px' }),
      }}
    >
      <Typography variation="bodyLarge">
        {formatter.competence_date(value.competence_date)}
      </Typography>
    </div>
  )
