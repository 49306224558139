import { Dispatch, SetStateAction } from 'react'
import dayjs from 'dayjs'

import {
  Box,
  Typography,
  Table as TableMU,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { Button, Checkbox, Notification } from '@olaisaac/design-system'

import { formatCentsToReal } from '@/shared/utils'
import { ContractNeverPaidDetailContract, ContractStatus } from '@/shared/interfaces'

import { SelectedRowsProps } from '../../types'

type TableProps = {
  contract: ContractNeverPaidDetailContract
  onManualLiquidationClick: (value) => void
  selectedRows: SelectedRowsProps | null
  setSelectedRows: Dispatch<SetStateAction<SelectedRowsProps>>
}

export function Table({
  contract,
  onManualLiquidationClick,
  setSelectedRows,
  selectedRows,
}: TableProps) {
  const formatedRows = contract?.installments?.map(row => ({
    ...row,
    id: row.installment_id,
    key: row.installment_id,
    contract_id: contract.contract_id,
    contractCreatedAt: contract.created_at,
    installment_due_date: dayjs(row.installment_due_date).format('MMMM') || '',
    receivable_due_date: dayjs(row.receivable_due_date).format('DD/MM/YYYY') || '',
    receivable_amount: formatCentsToReal(row.receivable_amount || 0),
    installment_order_reference: row.installment_order_reference.replace('/', ' de '),
  }))

  const selectedRow = selectedRows?.[contract.contract_id] || []

  return (
    <Box marginTop="4rem" flex="1" display="flex" flexDirection="column">
      <Box marginBottom="1rem">
        <Typography>{contract.product_name}</Typography>
      </Box>

      {contract.status === ContractStatus.CANCELED && (
        <Box marginBottom="1rem">
          <Notification
            description="O contrato foi cancelado, porém ainda possui parcelas a serem validadas."
            variation="warning"
          />
        </Box>
      )}

      <TableMU>
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                title="Selecionar todas"
                checked={selectedRow.length === formatedRows.length}
                style={{ padding: '0.75rem 0' }}
                onChange={() => {
                  if (selectedRow.length === formatedRows.length) {
                    setSelectedRows({
                      ...selectedRows,
                      [contract.contract_id]: [],
                    })
                  } else {
                    setSelectedRows({
                      ...selectedRows,
                      [contract.contract_id]: formatedRows,
                    })
                  }
                }}
              />
              Parcela
            </TableCell>
            <TableCell>Competência</TableCell>
            <TableCell>Vencimento</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {formatedRows.map(row => {
            const checked = selectedRow?.filter(item => item.id === row.id).length > 0

            return (
              <TableRow key={row.id}>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      checked={checked}
                      onChange={() => {
                        if (selectedRow.filter(item => item.id === row.id).length > 0) {
                          setSelectedRows({
                            ...selectedRows,
                            [contract.contract_id]: selectedRow.filter(item => item.id !== row.id),
                          })
                        } else {
                          setSelectedRows({
                            ...selectedRows,
                            [contract.contract_id]: [...selectedRow, row],
                          })
                        }
                      }}
                    />

                    <Box marginRight="1rem">{row.installment_order_reference}</Box>
                  </Box>
                </TableCell>
                <TableCell>{row.installment_due_date}</TableCell>
                <TableCell>{row.receivable_due_date}</TableCell>
                <TableCell>{row.receivable_amount}</TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" py="0.5rem">
                    <Button
                      variation="ghost"
                      style={{
                        fontSize: '0.875rem',
                        padding: '0.5rem 1rem',
                        height: '2rem',
                      }}
                      onClick={() => {
                        onManualLiquidationClick(row)
                      }}
                    >
                      Registrar recebimento
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </TableMU>
    </Box>
  )
}
