import { Popover, withStyles } from '@material-ui/core'
import theme from '@/shared/theme'

const { spacing, colors } = theme.primitiveTokens

export const MUIMenu = withStyles({
  root: {
    pointerEvents: 'none',
  },
  paper: {
    padding: `${spacing[2]} 0`,
    width: '240px',
    border: `1px solid ${colors.gray[10]}`,
    borderRadius: '8px',
    variant: 'outlined',
  },
})(Popover)
