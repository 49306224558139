import { InstallmentRowType } from '../GuardianDetails/constants'
import { FilterOptionsType } from '../types'

export enum GuardiansInstallmentsQueryParamsNameEnum {
  COMPETENCE_DATES = 'installment_competence_dates',
  CONTRACT_STATUS = 'contract_status',
  DEBT_STATUSES = 'debt_statuses',
  PRODUCT_IDS = 'product_ids',
  STATUSES = 'installment_statuses',
  STUDENT_IDS = 'student_ids',
  TYPES = 'installment_types',
  YEARS = 'reference_years',
}

export const SHOW_MODAL_AGAIN_IN_HOURS = 6

export const INSTALLMENT_STATUSES: FilterOptionsType[] = [
  {
    label: 'Aguardando',
    value: 'PENDING',
  },
  {
    label: 'A vencer',
    value: 'OPEN',
  },
  {
    label: 'Cancelada',
    value: 'CANCELED',
  },
  {
    label: 'Paga',
    value: 'PAID',
  },
  {
    label: 'Paga e cancelada',
    value: 'PAID_AND_CANCELED',
  },
  {
    label: 'Vence hoje',
    value: 'DUE_TODAY',
  },
  {
    label: 'Vencida',
    value: 'OVERDUE',
  },
]

export const INSTALLMENT_TYPES: FilterOptionsType[] = [
  {
    label: 'Pré-matrícula',
    value: 'ENROLLMENT',
  },
  {
    label: 'Mensalidade',
    value: 'TUITION',
  },
  {
    label: 'Negociação',
    value: 'NEGOTIATION',
  },
]

export const INSTALLMENT_CONTRACT_STATUSES: FilterOptionsType[] = [
  {
    label: 'Contrato ativo',
    value: 'OPEN',
  },
  {
    label: 'Contrato inativo',
    value: 'CANCELED',
  },
]

export const INSTALLMENT_ROW_TYPES = {
  [InstallmentRowType.ENROLLMENT_INSTALLMENT]: 'Pré-matrícula',
  [InstallmentRowType.INSTALLMENT]: 'Mensalidade',
  [InstallmentRowType.NEGOTIATION]: 'Negociação',
  [InstallmentRowType.NEGOTIATION_START]: 'Entrada da negociação',
}

export const NEGOTIATION_CHANNEL = {
  SAMUCA: 'Analista isaac',
  'PI-ESCOLA': 'Plataforma isaac (feito pela escola)',
}
