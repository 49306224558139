import { useEffect, useState } from 'react'
import { useFlagsStatus } from '@unleash/proxy-client-react'
import { captureException } from '@sentry/react'

const UNLEASH_CHECK_COUNT = 5
const UNLEASH_CHECK_TIMEOUT = 2000

/**
 * Custom hook to health check the connection between application and unleash.
 *
 * @returns Values indicating flags and unleash conection status
 */
export const useCheckUnleash = () => {
  const { flagsReady, flagsError } = useFlagsStatus()

  const [isUnleashChecked, setIsUnleashChecked] = useState(false)
  const [isUnleashError, setIsUnleashError] = useState(false)
  const [unleashCount, setUnleashCount] = useState(1)

  // Validate the connection with Unleash within the maximum number of attempts
  useEffect(() => {
    if (flagsReady || flagsError) {
      setIsUnleashChecked(true)
      return
    }

    const timeoutId = setTimeout(() => {
      if (unleashCount === UNLEASH_CHECK_COUNT) {
        setIsUnleashChecked(true)
        return
      }

      setUnleashCount(count => count + 1)
    }, UNLEASH_CHECK_TIMEOUT)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [flagsError, flagsReady, unleashCount])

  // Check if there was an error connecting to Unleash within the maximum number of attempts
  useEffect(() => {
    if (!isUnleashChecked) {
      return
    }

    const isUnleashWithouResponse = !flagsReady && flagsError === null

    if (flagsError || isUnleashWithouResponse) {
      setIsUnleashError(true)
    }

    if (isUnleashWithouResponse) {
      // Send a custom unleash exception to Sentry
      captureException(Error('Unleash: unable to connect to Unleash Edge'))
    }
  }, [flagsError, flagsReady, isUnleashChecked])

  return {
    flagsReady,
    flagsError,
    isUnleashChecked,
    isUnleashError,
  }
}
