import { InstallmentType } from '@/shared/interfaces'
import { formatCentsToReal } from '@/shared/utils'
import { calculateCommomAmount, calculateTotalDiscount } from '../utils'
import { Installment } from '@/escolas/services/enrollment/types'
import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

const formatTotalDetail = (installments: Installment[]) => {
  const commomAmount = calculateCommomAmount(installments)

  let text = `em ${installments.length}x`

  if (commomAmount > 0) {
    text += ` de ${formatCentsToReal(commomAmount)}`
  }

  return text
}

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: end;
`

type TotalItemProps = {
  amount: number
  detail: string
  title: string
}

/**
 * @deprecated This component will be deprecated after the EEMAT-727-ENABLE_UNIFIED_CONTRACT_EDITING rollout
 */
const TotalItem = ({ title, amount, detail }: TotalItemProps) => {
  return (
    <ItemContainer role="group" aria-label={title}>
      <Typography variation="subtitleDesktopSmall" color="secondary">
        {title}
      </Typography>
      <Typography variation="headlineDesktopXsmall">{formatCentsToReal(amount)}</Typography>
      <Typography variation="caption" color="secondary">
        {detail}
      </Typography>
    </ItemContainer>
  )
}

const TableFooterContainer = styled.div`
  display: flex;
  gap: 32px;
  justify-content: end;
`

type TableTotalsProps = {
  installments: Installment[]
}

export const TableTotals = ({ installments }: TableTotalsProps) => {
  const allDiscounts = installments.reduce(
    (allDiscounts, installment) => [...allDiscounts, ...installment.discounts],
    []
  )

  const enrollmentInstallments = installments.filter(i => i.type === InstallmentType.ENROLLMENT)
  const tuitionInstallments = installments.filter(i => i.type === InstallmentType.TUITION)

  return (
    <TableFooterContainer>
      <TotalItem
        title="Descontos aplicados"
        amount={calculateTotalDiscount(allDiscounts)}
        detail=""
      />
      <TotalItem
        title="Valor total da pré-matrícula"
        amount={enrollmentInstallments.reduce((a, b) => a + b.amount, 0)}
        detail={formatTotalDetail(enrollmentInstallments)}
      />
      <TotalItem
        title="Valor total das mensalidades"
        amount={tuitionInstallments.reduce((a, b) => a + b.amount, 0)}
        detail={formatTotalDetail(tuitionInstallments)}
      />
    </TableFooterContainer>
  )
}
