import { debounce } from 'throttle-debounce'
import { Box } from '@material-ui/core'
import { Button, Grid, Search } from '@olaisaac/design-system'
import { SortRounded } from '@material-ui/icons'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { FilterReportButton } from '@/escolas/components/report/FilterReportDrawer'
import { ContextDropdown } from '@/shared/components/ContextDropdown'
import { PayoutReportActiveFilters } from '@/modules/report/components/PayoutReportActiveFilters'

import { useQuery } from '@/escolas/hooks'
import { useOrdering } from '@/shared/hooks/useOrdering'
import { useEnrollmentPayoutReportFilter } from '@/modules/report/hooks/useEnrollmentPayoutReportFilter'

import { REPORT_PAGE_NAMES } from '@/modules/report/constants/REPORT_PAGE_NAMES'

import type { SortOrder } from '@/shared/hooks/useOrdering/types'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

export type ReportPageTableHeaderProps = {
  disableActions: boolean
  filterOptions: Record<string, Array<{ label: string; value: string }>>
  isFilterReportDrawerOpen: boolean
  onRequestFilterDrawerOpen: () => void
  reportType: 'enrollment' | 'tuition' | 'isaac-pay'
  shouldShowFilterButton?: boolean
  shouldShowOrderingButton?: boolean
}

export const ReportPageTableHeader = ({
  disableActions,
  filterOptions,
  reportType,
  isFilterReportDrawerOpen,
  shouldShowFilterButton = true,
  shouldShowOrderingButton = true,
  onRequestFilterDrawerOpen,
}: ReportPageTableHeaderProps) => {
  const { setOnQueryParam } = useQuery()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { filterCount } = useEnrollmentPayoutReportFilter()
  const { updateOrdering } = useOrdering<
    'student_name' | 'reference_month' | 'last_receivable_paid_date'
  >()

  const mapReportTypeToPageName = {
    tuition: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
    enrollment: REPORT_PAGE_NAMES.ENROLLMENT_PAYOUT_REPORT,
    'isaac-pay': REPORT_PAGE_NAMES.ISAAC_PAY_PAYOUT_REPORT,
  }

  const pageName = mapReportTypeToPageName[reportType]

  const mapReportTypeToScopeName = {
    tuition: EventDispatcherEventScopes.PAYOUT_REPORT,
    enrollment: EventDispatcherEventScopes.ENROLLMENT_REPORT,
    'isaac-pay': EventDispatcherEventScopes.ISAAC_PAY_REPORT,
  }
  const scope = mapReportTypeToScopeName[reportType]

  const searchByName = (value: string) => {
    setOnQueryParam(value, 'name')
    setOnQueryParam('1', 'page')

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: scope,
        name:
          value !== '' ? EventDispatcherEvents.APPLY_FILTER : EventDispatcherEvents.RESET_FILTER,
        action: 'type',
        customProperties: {
          $page_name: pageName,
        },
      })
  }

  const handleApplyOrdering = (
    sortBy: 'student_name' | 'reference_month' | 'last_receivable_paid_date',
    sortOrder: SortOrder
  ) => {
    updateOrdering({ sortBy, sortOrder })

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: scope,
        name: EventDispatcherEvents.TABLE_ORDERING,
        action: 'click',
        customProperties: {
          $page_name: pageName,
          $sort_by: sortBy,
          $sort_order: sortOrder,
        },
      })
  }

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item sm={4}>
          <Search
            disabled={disableActions}
            initialValue=""
            placeholder="Pesquisar por aluno"
            onChange={debounce<(value: string) => void>(500, value => searchByName(value))}
            onClear={() => searchByName('')}
          />
        </Grid>

        <Grid item sm={8}>
          <Box display="flex" justifyContent="flex-end">
            <Box mr="1rem">
              {shouldShowOrderingButton && (
                <ContextDropdown
                  button={
                    <Button variation="ghost" startIcon={<SortRounded />} disabled={disableActions}>
                      <span>Ordenar por</span>
                    </Button>
                  }
                  options={[
                    {
                      label: 'Mais novos',
                      name: 'newer',
                      onClick: () =>
                        handleApplyOrdering(
                          reportType !== 'enrollment'
                            ? 'reference_month'
                            : 'last_receivable_paid_date',
                          'DESC'
                        ),
                    },
                    {
                      label: 'Mais antigos',
                      name: 'older',
                      onClick: () =>
                        handleApplyOrdering(
                          reportType !== 'enrollment'
                            ? 'reference_month'
                            : 'last_receivable_paid_date',
                          'ASC'
                        ),
                    },
                    {
                      label: 'A-Z',
                      name: 'a-z',
                      onClick: () => handleApplyOrdering('student_name', 'ASC'),
                    },
                    {
                      label: 'Z-A',
                      name: 'z-a',
                      onClick: () => handleApplyOrdering('student_name', 'DESC'),
                    },
                  ]}
                />
              )}
            </Box>
            {shouldShowFilterButton && (
              <FilterReportButton
                selectedFilters={filterCount}
                disabled={disableActions}
                onClick={onRequestFilterDrawerOpen}
                showFilterDrawer={isFilterReportDrawerOpen}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      <Box>
        <PayoutReportActiveFilters reportType={reportType} filterOptions={filterOptions} />
      </Box>
    </>
  )
}
