export enum EventDispatcherEvents {
  /**
   * Acesso a página de responsáveis
   */
  ACCESS_GUARDIAN_PAGE = 'ACCESS_GUARDIAN_PAGE',
  /**
   * Acesso a listagem de responsáveis
   */
  ACCESS_GUARDIANS_LIST = 'ACCESS_GUARDIANS_LIST',
  /**
   * Evento genérico para a ação de visualizar algum alerta
   */
  ALERT_VIEWED = 'ALERT_VIEWED',
  /**
   * Evento genérico para a ação de aplicar filtro em listagens
   */
  APPLY_FILTER = 'APPLY_FILTER',
  /**
   * Clique em algum botão
   */
  BUTTON_CLICK = 'BUTTON_CLICK',
  /**
   * Evento de clique no botão
   */
  BUTTON_CLICKED = 'BUTTON_CLICKED',
  /**
   * Evento de clique em cards
   */
  CARD_CLICKED = 'CARD_CLICKED',
  /**
   * Clique no botão de redirecionamento para a página de alunos a partir do card do aluno na página do RF
   */
  CARD_VIEW_STUDENT_PAGE = 'CARD_VIEW_STUDENT_PAGE',
  /**
   * Clique no checkbox da tabela de resumo financeiro do RF
   */
  CHECKBOX_CLICKED = 'CHECKBOX_CLICKED',
  /**
   * Clique na caixa de checkbox dentro do modal de informação sobre baixa manual para RF com parcelas de entrada
   */
  CHECKBOX_SELECTED = 'CHECKBOX_SELECTED',

  /**
   * Clique no botão "Pagar" na página do RF
   */
  CHECKOUT_PROCESS_CLICK = 'CHECKOUT_PROCESS_CLICK',

  /**
   * Clique em algum item de chip
   */
  CHIP_CLICKED = 'CHIP_CLICKED',

  /**
   * Clique no link para copiar o link da fatura
   */
  CLICKED_GET_CHARGE_LINK_NEGOTIATION_2_ENTRY = 'CLICKED-GET-CHARGE-LINK-NEGOTIATION-2.0-ENTRY',

  /**
   * Clique na opção de cartão de crédito na tela de pagamento POS
   */
  CLICKED_PAY_NEGOTIATION_2_ENTRY_CREDIT = 'CLICKED-PAY-NEGOTIATION-2.0-ENTRY-CREDIT',

  /**
   * Clique na opção de cartão de débito na tela de pagamento POS
   */
  CLICKED_PAY_NEGOTIATION_2_ENTRY_DEBIT = 'CLICKED-PAY-NEGOTIATION-2.0-ENTRY-DEBIT',

  /**
   * Criação de contrato a partir da página do RF
   */
  CREATE_CONTRACT_GUARDIAN = 'CREATE_CONTRACT_GUARDIAN',

  /**
   * Clique em um datepicker
   */
  DATEPICKER_CLICK = 'DATEPICKER_CLICK',

  /**
   * Ação para baixar arquivo
   */
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',

  /**
   * Interação com uma gaveta
   */
  DRAWER_VIEW = 'DRAWER_VIEW',

  /**
   * Evento de clique em um dropdown
   */
  DROPDOWN_CLICKED = 'DROPDOWN_CLICKED',

  /**
   * Interação com datepicker interno repasse de matrículas
   */
  ENROLLMENT_PAYOUT_DATE_CHANGE = 'ENROLLMENT_PAYOUT_DATE_CHANGE',
  /**
   * Alteração de data de repasse de matrícula no dashboard de repasses (datepicker)
   */
  ENROLLMENT_PAYOUT_DATE_CHANGE_ON_REPORT_DASHBOARD = 'ENROLLMENT_PAYOUT_DATE_CHANGE_ON_REPORT_DASHBOARD',
  /**
   * Baixar planilha no repasse de matrícula
   */
  ENROLLMENT_PAYOUT_DOWNLOAD_CSV = 'ENROLLMENT_PAYOUT_DOWNLOAD_CSV',
  /**
   * Baixar resumo (PDF) no repasse de matrícula
   */
  ENROLLMENT_PAYOUT_DOWNLOAD_SUMMARY = 'ENROLLMENT_PAYOUT_DOWNLOAD_SUMMARY',
  /**
   * Uso dos filtros no repasse de matrícula (aplicar filtro)
   */
  ENROLLMENT_PAYOUT_FILTER_APPLIED = 'ENROLLMENT_PAYOUT_FILTER_APPLIED',
  /**
   * Ajuda no repasse de matrícula
   */
  ENROLLMENT_PAYOUT_HELP = 'ENROLLMENT_PAYOUT_HELP',
  /**
   * Interação com a linha da tabela para ver mais detalhes no repasse de matrícula
   */
  ENROLLMENT_PAYOUT_REPORT_DETAILS = 'ENROLLMENT_PAYOUT_REPORT_DETAILS',
  /**
   * Ver resumo do repasse de matrículas
   */
  ENROLLMENT_PAYOUT_SUMMARY = 'ENROLLMENT_PAYOUT_SUMMARY',
  /**
   * Interação "ir para contrato" no repasse de matrícula
   */
  ENROLLMENT_PAYOUT_VIEW_CONTRACT = 'ENROLLMENT_PAYOUT_VIEW_CONTRACT',
  /**
   * Resposta de erro ao tentar carregar o relatório do FIDC
   */
  ERROR_LOADING_FIDC_REPORT = 'ERROR_LOADING_FIDC_REPORT',
  /**
   * Acesso a tela de "Dúvidas frequentes" do menu no header
   */
  FAQ_ACCESS = 'FAQ_ACCESS',
  /**
   * Clique em "Ver parcelas" em um contrato da tabela de contratos na página do RF
   */
  GUARDIAN_CONTRACT_INSTALLMENTS_LIST = 'GUARDIAN_CONTRACT_INSTALLMENTS_LIST',
  /**
   * Seleção da aba "Contratos" da página do RF
   */
  GUARDIAN_CONTRACT_LIST = 'GUARDIAN_CONTRACT_LIST',
  /**
   * Seleção da aba "Parcelas" da página do RF
   */
  GUARDIAN_INSTALLMENTS_LIST = 'GUARDIAN_INSTALLMENTS_LIST',
  /**
   * Filtro da listagem de responsáveis pela presença de débitos
   */
  GUARDIANS_WITH_FINANCIAL_DEBT_FILTER = 'GUARDIANS_WITH_FINANCIAL_DEBT_FILTER',
  /**
   * Filtro da listagem de responsáveis pela ausência de débitos
   */
  GUARDIANS_WITHOUT_FINANCIAL_DEBT_FILTER = 'GUARDIANS_WITHOUT_FINANCIAL_DEBT_FILTER',
  /**
   * Navegação para a página de repasse de parcelas do tipo isaac pay
   */
  ISAAC_PAY_NAVIGATION_TO_PAYOUT_REPORT = 'ISAAC_PAY_NAVIGATION_TO_PAYOUT_REPORT',
  /**
   * Interação com datepicker interno repasse de parcelas do tipo isaac pay
   */
  ISAAC_PAY_PAYOUT_DATE_CHANGE = 'ISAAC_PAY_PAYOUT_DATE_CHANGE',
  /**
   * Alteração de data de repasse de isaac pay no dashboard de repasses (weekpicker)
   */
  ISAAC_PAY_PAYOUT_DATE_CHANGE_ON_REPORT_DASHBOARD = 'ISAAC_PAY_PAYOUT_DATE_CHANGE_ON_REPORT_DASHBOARD',
  /**
   * Baixar planilha no repasse de parcelas do tipo isaac pay
   */
  ISAAC_PAY_PAYOUT_DOWNLOAD_CSV = 'ISAAC_PAY_PAYOUT_DOWNLOAD_CSV',
  /**
   * Ajuda no repasse de parcelas do tipo isaac pay
   */
  ISAAC_PAY_PAYOUT_HELP = 'ISAAC_PAY_PAYOUT_HELP',
  /**
   * Interação com a linha da tabela para ver mais detalhes no repasse de parcelas do tipo isaac pay
   */
  ISAAC_PAY_PAYOUT_REPORT_DETAILS = 'ISAAC_PAY_PAYOUT_REPORT_DETAILS',
  /**
   * Ver resumo do repasse de parcelas do tipo isaac pay
   */
  ISAAC_PAY_PAYOUT_SUMMARY = 'ISAAC_PAY_PAYOUT_SUMMARY',
  /**
   * Clique em um link
   */
  LINK_CLICK = 'LINK_CLICK',
  /**
   * Erro de carregamento
   */
  LOADING_ERROR = 'LOADING_ERROR',
  /**
   * Clique na opção "Sair" do menu no header
   */
  LOGOUT_CLICK = 'LOGOUT_CLICK',
  /**
   * Fechar uma modal
   */
  MODAL_CLOSE = 'MODAL_CLOSE',
  /**
   * Modal visualizado
   */
  MODAL_VIEWED = 'MODAL_VIEWED',
  /**
   * Navegação para a página de repasse de matrículas
   */
  NAVIGATION_TO_ENROLLMENT_REPORT = 'NAVIGATION_TO_ENROLLMENT_REPORT',
  /**
   * Navegação para a página de relatório do FIDC
   */
  NAVIGATION_TO_FIDC_REPORT = 'NAVIGATION_TO_FIDC_REPORT',
  /**
   * Navegação para a página de repasse de mensalidades
   */
  NAVIGATION_TO_PAYOUT_REPORT = 'NAVIGATION_TO_PAYOUT_REPORT',
  /**
   * Navegação para a página de dashboard de repasses
   */
  NAVIGATION_TO_REPORT_DASHBOARD = 'NAVIGATION_TO_REPORT_DASHBOARD',
  /**
   * Clique no botão "Cancelar contrato" dentro da tela de detalhes de "Never paids"
   */
  NEVER_PAID_CANCEL_CONTRACT_CLICK = 'NEVER_PAID_CANCEL_CONTRACT_CLICK',
  /**
   * Cancelamento de contrato na tela de detalhes de "Never paids"
   */
  NEVER_PAID_CANCEL_CONTRACT_CONFIRM = 'NEVER_PAID_CANCEL_CONTRACT_CONFIRM',
  /**
   * Clique no botão de "Manter cobrança(s)" na tela de "Never paids"
   */
  NEVER_PAID_CONFIRM_CLICK = 'NEVER_PAID_CONFIRM_CLICK',
  /**
   * Confirmação das cobranças na tela de "Never paids"
   */
  NEVER_PAID_CONFIRM_FINISH = 'NEVER_PAID_CONFIRM_FINISH',
  /**
   * Acesso a tela de detalhes de "Never paids"
   */
  NEVER_PAID_DETAILS_ACCESS = 'NEVER_PAID_DETAILS_ACCESS',
  /**
   * Clique no botão "Registrar recebimento" dentro da tela de detalhes de "Never paids"
   */
  NEVER_PAID_PAYMENT_REGISTER_CLICK = 'NEVER_PAID_PAYMENT_REGISTER_CLICK',
  /**
   * Confirmação da baixa através da visão de "Never paids"
   */
  NEVER_PAID_PAYMENT_REGISTER_CONFIRM = 'NEVER_PAID_PAYMENT_REGISTER_CONFIRM',
  /**
   * Clique no botão "Revisar cobranças do aluno" na tela inicial de "Never paids"
   */
  NEVER_PAID_REVIEW_INSTALLMENTS_CLICK = 'NEVER_PAID_REVIEW_INSTALLMENTS_CLICK',
  /**
   * Evento genérico para seleção de opção
   */
  OPTION_SELECTED = 'OPTION_SELECTED',
  /**
   * Acesso a uma página
   */
  PAGE_VIEWED = 'PAGE_VIEWED',
  /**
   * Alteração de página de uma tabela
   */
  PAGINATION_CHANGE_PAGE = 'PAGINATION_CHANGE_PAGE',
  /**
   * Alteração de tamanho de página de uma tabela
   */
  PAGINATION_CHANGE_PAGE_SIZE = 'PAGINATION_CHANGE_PAGE_SIZE',
  /**
   * Clique no botão "Editar cadastro" na tela do RF
   */
  PERSONAL_DATA_CHANGE = 'PERSONAL_DATA_CHANGE',
  /**
   * Evento genérico para a ação de limpar filtro em listagens
   */
  RESET_FILTER = 'RESET_FILTER',

  /**
   * Clique em algum Select
   */
  SELECT_TRIGGER_CLICKED = 'SELECT_TRIGGER_CLICKED',

  /**
   * Ordenação de uma tabela
   */
  TABLE_ORDERING = 'TABLE_ORDERING',
  /**
   * Clique em uma das linhas de uma tabela
   */
  TABLE_ROW_CLICK = 'TABLE_ROW_CLICK',
  TABLE_ROW_CLICKED = 'TABLE_ROW_CLICKED',
  /**
   * Acesso ao pdf de termos e condições de uso isaac
   */
  TERMS_CONDITIONS_PDF_ACCESS = 'TERMS_CONDITIONS_PDF_ACCESS',
  /**
   * Interação com datepicker interno repasse de mensalidades
   */
  TUITION_PAYOUT_DATE_CHANGE = 'TUITION_PAYOUT_DATE_CHANGE',
  /**
   * Alteração de data de repasse de mensalidade no dashboard de repasses (weekpicker)
   */
  TUITION_PAYOUT_DATE_CHANGE_ON_REPORT_DASHBOARD = 'TUITION_PAYOUT_DATE_CHANGE_ON_REPORT_DASHBOARD',
  /**
   * Baixar planilha no repasse de mensalidade
   */
  TUITION_PAYOUT_DOWNLOAD_CSV = 'TUITION_PAYOUT_DOWNLOAD_CSV',
  /**
   * Baixar resumo (PDF) no repasse de mensalidade
   */
  TUITION_PAYOUT_DOWNLOAD_SUMMARY = 'TUITION_PAYOUT_DOWNLOAD_SUMMARY',
  /**
   * Uso dos filtros no repasse de mensalidade (aplicar filtro)
   */
  TUITION_PAYOUT_FILTER_APPLIED = 'TUITION_PAYOUT_FILTER_APPLIED',
  /**
   * Ajuda no repasse de matrícula
   */
  TUITION_PAYOUT_HELP = 'TUITION_PAYOUT_HELP',
  /**
   * Interação com a linha da tabela para ver mais detalhes no repasse de mensalidade
   */
  TUITION_PAYOUT_REPORT_DETAILS = 'TUITION_PAYOUT_REPORT_DETAILS',
  /**
   * Ver resumo do repasse de mensalidade
   */
  TUITION_PAYOUT_SUMMARY = 'TUITION_PAYOUT_SUMMARY',
  /**
   * Interação "ir para contrato" no repasse de mensalidade
   */
  TUITION_PAYOUT_VIEW_CONTRACT = 'TUITION_PAYOUT_VIEW_CONTRACT',
  /**
   * Visualização da página de relatório do FIDC
   */
  VIEW_FIDC_REPORT = 'VIEW_FIDC_REPORT',
  /**
   * Clique no botão "Ver situação de matrícula" a partir da página do RF (tabela de contratos)
   */
  VIEW_STUDENT_PAGE = 'VIEW_STUDENT_PAGE',
  /**
   * Visualização de alerta
   */
  WARNING_VIEWED = 'WARNING_VIEWED',
}
