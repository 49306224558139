import styled, { css } from 'styled-components'

type ContainerProps = {
  backgroundColor: string
  borderColor?: string
}

type BorderProps = {
  color: string
}

// TODO: Use DD token
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${props => props.backgroundColor};
  border-radius: 0.5rem;
  overflow: hidden;

  ${props =>
    !!props.borderColor &&
    css`
      border: 1px solid ${props.borderColor};
    `}
`

export const Border = styled.div<BorderProps>`
  height: 0.5rem;

  ${props =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
`
