import { Divider, Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

export const SubSectionWrapper = styled.div`
  margin: 12px 0 24px;
`

export const SummaryItemWrapper = styled.div`
  display: flex;
  align-items: end;
  margin: 12px 0;
`

export const LabelWrapper = styled.div`
  flex: 1;
  padding-right: 16px;
  min-width: 64px;
`

export const SummaryItemLabel = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledDivider = styled(Divider)`
  margin: 24px -24px;
`
