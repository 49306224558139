import { useEffect, useState } from 'react'
import { useGetContract } from '@/escolas/hooks/useGetContract'
import { useContract, useQuery } from '@/escolas/hooks'
import { AgglutinationMetaData } from '@/escolas/components/contract/ContractDetails'
import { PagedDrawerProvider } from '@/escolas/contexts/pagedDrawerContext'
import { InstallmentsProvider } from '@/escolas/contexts/installmentsContext'
import InstallmentDrawer from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer'
import { useJWT, useSnackbar } from '@/shared/hooks'
import { InvoicesNotGeneratedDialog } from '@/escolas/components/contract/InvoicesNotGeneratedDialog/InvoicesNotGeneratedDialog'
import AgglutinationDrawer from '@/modules/guardians/InstallmentsDrawerContainer/AgglutinationDrawer/AgglutinationDrawer'
import { formOfPaymentOptions } from '@/modules/guardians/InstallmentsDrawerContainer/AgglutinationDrawer/AgglutinationPayment'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { Installment } from '../GuardianDetails/types'
import { NotificationProps } from '@olaisaac/design-system'
import { formatter } from '../GuardianDetailsInstallmentsTab/components/GuardianDetailsInstallmentsTable/utils/formatter'
import { InstallmentStatusDescription } from '../GuardianDetails/constants'
import { ReceivablesAgglutinationProvider } from '@/escolas/contexts/receivablesAgglutinationContext'
import {
  TrackDrawerClickEvents,
  useGuardiansInstallmentsTabEvents,
} from '../GuardianDetailsInstallmentsTab/hooks/useGuardiansInstallmentsTabEvents'

type InstallmentsDrawerContainerProps = {
  onClose: () => void
  selectedInstallment: Partial<Installment>
  selectedInstallmentHasError: boolean
  showAgglutinationDrawer: boolean
}

export const InstallmentsDrawerContainer = ({
  selectedInstallment,
  selectedInstallmentHasError,
  showAgglutinationDrawer,
  onClose,
}: InstallmentsDrawerContainerProps) => {
  const { isAdmin } = useJWT()
  const { setOnQueryParam } = useQuery()
  const [contractId, setContractId] = useState<string>(selectedInstallment?.contract_id)
  const { contract, refetch: refetchContract } = useGetContract(contractId)
  const { setContract } = useContract()
  const [orderReference, setOrderReference] = useState<string>(null)
  const [selectedReceivableId, setSelectedReceivableId] = useState<string>(null)
  const [showInvoicesNotGeneratedDialog, setShowInvoicesNotGeneratedDialog] = useState<boolean>(
    false
  )
  const [isProcessingAgglutination, setProcessingAgglutination] = useState<boolean>(false)
  const { trackDrawerClick } = useGuardiansInstallmentsTabEvents()

  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()

  const showSnackbar = (variation: NotificationProps['variation'], message: string) => {
    setSnackbarVariation(variation)
    setSnackbarMessage(message)
    setSnackbarIsOpen(true)
  }

  const closeAllDrawers = () => {
    trackDrawerClick(selectedInstallment?.urn, TrackDrawerClickEvents.CLOSE)
    setSelectedReceivableId(null)
    setContractId(null)
    onClose()
  }

  useEffect(() => {
    if (selectedInstallment?.contract_id) {
      setContractId(selectedInstallment.contract_id)
    }
  }, [selectedInstallment])

  // --- Aglutinação
  const agglutinationForm = useForm<AgglutinationMetaData>({
    mode: 'onChange',
  })

  const handleAgglutinationClose = () => {
    agglutinationForm.reset({
      due_date: dayjs().add(1, 'day').toISOString(),
      payment_method: formOfPaymentOptions.filter(option => option.key === 'PIX_BANKSLIP')[0].value,
      discount: 0,
    })
    closeAllDrawers()
  }

  const callbackAgglutination = () => {
    showSnackbar('success', 'Aglutinação realizada com sucesso')
    setOnQueryParam('true', 'refetch')
    closeAllDrawers()
  }
  // ---

  // Passa o contrato p/ contexto do useContract e busca os dados da installment selecionada
  useEffect(() => {
    if (contract) {
      setContract(contract)

      const receivableId = selectedInstallment?.receivable_id

      setOrderReference(formatter.order_reference(selectedInstallment?.order_reference))
      setSelectedReceivableId(receivableId)
    }
  }, [contract])

  const installmentsDrawerCallback = () => {
    setOnQueryParam('true', 'refetch')
    refetchContract()
    closeAllDrawers()
  }

  return (
    <InstallmentsProvider>
      <PagedDrawerProvider>
        <ReceivablesAgglutinationProvider>
          {/* Drawer de Parcelas */}
          {selectedReceivableId && (
            <InstallmentDrawer
              isOpen={Boolean(selectedReceivableId)}
              onClose={closeAllDrawers}
              selectedReceivableId={selectedReceivableId}
              orderReference={orderReference}
              setSelectedReceivableId={setSelectedReceivableId}
              removeIsaacOption={!isAdmin}
              isCheckout
              callbackCheckout={installmentsDrawerCallback}
              // Cashier
              selectedInstallmentHasError={selectedInstallmentHasError}
              setShowInvoicesNotGeneratedDialog={setShowInvoicesNotGeneratedDialog}
              urn={selectedInstallment?.urn}
              isBrokenPromise={
                selectedInstallment?.status_description ===
                InstallmentStatusDescription.NEGOTIATION_EXPIRED
              }
            />
          )}

          {/* Drawer de Aglutinação de Parcelas */}
          <AgglutinationDrawer
            isOpen={showAgglutinationDrawer}
            onClose={handleAgglutinationClose}
            form={agglutinationForm}
            isProcessingAgglutination={isProcessingAgglutination}
            disableChecks={() => setProcessingAgglutination(true)}
            enableChecks={() => setProcessingAgglutination(false)}
            callbackAgglutination={callbackAgglutination}
          />

          {/* Modal Cashier */}
          <InvoicesNotGeneratedDialog
            isOpen={showInvoicesNotGeneratedDialog}
            setIsOpen={setShowInvoicesNotGeneratedDialog}
          />
        </ReceivablesAgglutinationProvider>
      </PagedDrawerProvider>
    </InstallmentsProvider>
  )
}
