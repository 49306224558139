import { Dispatch, FC, SetStateAction, useState } from 'react'
import {
  DatePicker,
  Typography,
  TextField,
  Notification,
  DialogContent,
  ButtonDocker,
  Button,
} from '@olaisaac/design-system'
import { Receivable } from 'src/shared/interfaces'
import { Controller, useForm } from 'react-hook-form'
import { Box, CircularProgress, FormControl } from '@material-ui/core'
import { date2PTFormat, validateEditReason } from 'src/shared/utils'
import dayjs from 'dayjs'
import { omit } from 'ramda'
import { useApi, useSnackbar } from '@/shared/hooks'
import styled from 'styled-components'

const StyledBodyLargeTypography = styled(Typography).attrs({
  variation: 'bodyLarge',
  withoutMargin: true,
})``

const StyledOverlay = styled.div`
  opacity: 0.87;
  position: absolute;
  background: #fff;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
`

export type ContractEditEnrollmentDueDayDrawerProps = {
  callbackCheckout?: Dispatch<SetStateAction<boolean>>
  goBackToDefaultContent: () => void
  receivables: Receivable[]
  selectedReceivableId: string
}

export type EditContractEnrollmentForm = {
  change_due_month: string
  due_day: number
  reason: string
}

enum EditFormSteps {
  Form = 0,
  Review = 1,
}

const ContractEditEnrollmentDueDayDrawer: FC<ContractEditEnrollmentDueDayDrawerProps> = ({
  selectedReceivableId,
  callbackCheckout,
  goBackToDefaultContent,
  receivables = [],
}) => {
  const form = useForm<EditContractEnrollmentForm>({
    mode: 'all',
    defaultValues: {
      reason: '',
    },
  })
  const [currentStep, setCurrentStep] = useState<EditFormSteps>(EditFormSteps.Form)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { api } = useApi()
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    formState: { isSubmitting, isValid, isDirty },
  } = form
  const { setMessage: setSnackbarMessage, setIsOpen: setSnackbarIsOpen } = useSnackbar()

  const selectedReceivable = receivables.find(receivable => receivable.id === selectedReceivableId)

  const {
    installment: { order_reference: orderReference = '', due_date: dueDate = '' } = {},
  } = selectedReceivable

  const { due_day: newDueDate, reason } = getValues()
  const maxDateAvailable = new Date(new Date().getFullYear() + 1, 11, 31)
  const originalDueDateToString = date2PTFormat(dayjs(dueDate).toISOString())

  const renderInstallmentOrderReference = (orderReference: string) =>
    orderReference.replace('/', ' de ')
  const goToReview = () => {
    setCurrentStep(EditFormSteps.Review)
  }
  const onSubmit = async () => {
    setIsLoading(true)

    const installmentId = receivables[0].installment_id

    return api.installments
      .changeDueDayEnrollment(installmentId, {
        reason,
        due_date: dayjs.utc(+newDueDate).toISOString(),
      })
      .then(() => {
        setSnackbarMessage('Vencimento alterado com sucesso')
        setSnackbarIsOpen(true, { variation: 'success' })
        callbackCheckout(true)
        goBackToDefaultContent()
      })
      .catch(() => {
        setSnackbarMessage('Erro ao alterar vencimento')
        setSnackbarIsOpen(true, { variation: 'error' })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  watch(['due_day', 'reason'])

  return (
    <>
      {isLoading && (
        <StyledOverlay>
          <CircularProgress />
        </StyledOverlay>
      )}

      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          {currentStep === EditFormSteps.Form && (
            <>
              <Typography variation="bodyLarge">
                A edição do vencimento será feita na parcela{' '}
                {renderInstallmentOrderReference(orderReference)}.
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Typography color="secondary">Vencimento original</Typography>
                <Typography color="secondary">{originalDueDateToString}</Typography>
              </Box>
              <Box mb={4}>
                <FormControl variant="outlined" fullWidth>
                  <Controller
                    name="due_day"
                    rules={{ required: true, validate: v => dayjs(v).isAfter(dayjs(), 'date') }}
                    control={control}
                    render={({ formState, field }) => (
                      <DatePicker
                        {...omit(['ref'], field)}
                        views={['date']}
                        id="enrollment_new_due_date"
                        label="Nova data"
                        placeholder="DD/MM/YYYY"
                        format="DD/MM/YYYY"
                        disablePast
                        error={Boolean(formState.errors.due_day)}
                        helperText={
                          formState.errors.due_day
                            ? 'Selecione uma data de vencimento válida e após o dia de hoje'
                            : ''
                        }
                        maxDate={maxDateAvailable}
                        minDateMessage="A data não pode ser anterior a hoje"
                        invalidDateMessage="Formato de data inválido"
                      />
                    )}
                  />
                </FormControl>
              </Box>

              <Box mb={3}>
                <Typography variation="subtitleDesktopLarge">
                  Qual é o motivo da edição de vencimento da parcela?
                </Typography>
                <FormControl fullWidth variant="outlined">
                  <Controller
                    rules={{
                      required: true,
                      maxLength: 100,
                      minLength: 5,
                      validate: validateEditReason,
                    }}
                    name="reason"
                    control={control}
                    render={({ field: { value, ...rest }, fieldState: { error } }) => (
                      <TextField
                        {...rest}
                        label="Motivo"
                        id="reason-input"
                        value={value}
                        error={Boolean(error)}
                        helperText={
                          error
                            ? 'Deve ter entre 5 e 100 caracteres (somente letras e números)'
                            : ''
                        }
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </>
          )}

          {currentStep === EditFormSteps.Review && (
            <Box>
              <Box mt={3}>
                <Typography variation="subtitleDesktopLarge">
                  Confirme a alteração de vencimento:
                </Typography>
                <Typography variation="subtitleDesktopLarge" withoutMargin>
                  Motivo
                </Typography>
                <StyledBodyLargeTypography color="secondary">{reason}</StyledBodyLargeTypography>
              </Box>
              <Box mt={3} display="flex" justifyContent="space-between">
                <StyledBodyLargeTypography variation="subtitleDesktopLarge" withoutMargin>
                  Parcela
                </StyledBodyLargeTypography>
                <StyledBodyLargeTypography>
                  {renderInstallmentOrderReference(orderReference)}
                </StyledBodyLargeTypography>
              </Box>
              <Box mt={1.5} display="flex" justifyContent="space-between">
                <StyledBodyLargeTypography color="secondary">
                  Vencimento original
                </StyledBodyLargeTypography>
                <StyledBodyLargeTypography color="secondary" withoutMargin>
                  {originalDueDateToString}
                </StyledBodyLargeTypography>
              </Box>
              <Box mb={3} display="flex" justifyContent="space-between">
                <StyledBodyLargeTypography color="secondary">
                  Novo vencimento
                </StyledBodyLargeTypography>
                <StyledBodyLargeTypography color="secondary">
                  {date2PTFormat(dayjs(newDueDate).toISOString())}
                </StyledBodyLargeTypography>
              </Box>

              <Notification
                variation="information"
                description="Lembre-se que, caso essa parcela possua descontos, as suas regras serão atualizadas para a
          nova data"
              />
            </Box>
          )}
        </form>
      </DialogContent>

      <ButtonDocker>
        <Button
          fullWidth
          variation="primary"
          disabled={isSubmitting || !isValid || !isDirty}
          onClick={currentStep === EditFormSteps.Form ? goToReview : handleSubmit(onSubmit)}
        >
          {currentStep === EditFormSteps.Form ? 'Próximo' : 'Confirmar'}
        </Button>
      </ButtonDocker>
    </>
  )
}

export default ContractEditEnrollmentDueDayDrawer
