import { FC, useEffect, useState } from 'react'
import {
  ActionDrawerHeader,
  Button,
  ButtonDocker,
  Checkbox,
  DialogContent,
  Drawer,
  FormControlLabel,
  Notification,
  Typography,
} from '@olaisaac/design-system'
import { Contract, Guardian, Student } from 'src/shared/interfaces'
import CollapseStudent from './CollapseStudent'
import CollapseGuardian from './CollapseGuardian'
import CollapseAddress from './CollapseAddress'
import useHelperEditRegistration from './useHelperEditRegistration'
import ConfirmationEditDialog from './ConfirmationEditDialog'
import { Controller } from 'react-hook-form'
import { Box, CircularProgress } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import styled from 'styled-components'
import ConfirmationDialog from '../../modal/ConfirmationDialog'
import { EditGuardianFormSection } from '@/escolas/router/responsavel/[guardianId]/contratos/constants'

export type EditRegistrationDrawerProps = {
  contracts: Contract[]
  guardian: Guardian
  isOpen: boolean
  onClose: () => void
  refreshData: () => void
}

export const CollapsesMap = new Map<string, EditGuardianFormSection>([
  ['student', EditGuardianFormSection.STUDENT_SECTION],
  ['guardian', EditGuardianFormSection.GUARDIAN_SECTION],
  ['address', EditGuardianFormSection.ADDRESS_SECTION],
])

export const clearCollapses = (searchParams: URLSearchParams) => {
  CollapsesMap.forEach((_, key, map) => {
    searchParams.delete(map.get(key))
  })
}
const TypographyDialog = styled(Typography)`
  margin-bottom: 24px;
`

enum MomentsOfAlert {
  END_EDIT = 'END_EDIT',
  START_EDIT = 'START_EDIT',
}

const EditRegistrationDrawer: FC<EditRegistrationDrawerProps> = ({
  isOpen,
  contracts,
  guardian,
  refreshData,
}) => {
  const isNewEditRfNameEnabled = useUnleashFlag(UnleashFlags.GC_973_B2BCORE_122_EDIT_RF)
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [openAlertRfEdit, setOpenAlertRfEdit] = useState<MomentsOfAlert>(null)
  const history = useHistory()
  const location = useLocation()
  const { search } = location
  const searchParams = new URLSearchParams(search)

  const studentsInContracts: Array<Pick<Student, 'name' | 'id'>> = contracts?.map(contract => ({
    name: contract.student.name,
    id: contract.student.id,
  }))

  const studentsIds = studentsInContracts?.map(student => student.id)

  const {
    form,
    handleMenuItemClick,
    isZipValid,
    OnZipFieldFilled,
    isFirstRender,
    submitHandler,
    isFeedbackDialogOpen,
    handleCloseFeedbackDialog,
    isStudentsFetched,
  } = useHelperEditRegistration(guardian.id, studentsIds, contracts)

  useEffect(() => {
    if (isStudentsFetched) {
      handleMenuItemClick()
      setOpenAlertRfEdit(MomentsOfAlert.START_EDIT)
    }
  }, [isStudentsFetched])

  const handleOpenCollapse = value => {
    const selectedCollapse = CollapsesMap.get(value)
    if (searchParams.get(selectedCollapse)) {
      searchParams.delete(selectedCollapse)
    } else {
      clearCollapses(searchParams)
      searchParams.append(selectedCollapse, 'true')
    }
    history.push({ search: searchParams.toString() })
  }

  const handleOnClose = () => {
    clearCollapses(searchParams)
    searchParams.delete('abrirCadastro')
    history.push({ search: searchParams.toString() })
  }

  const handleStartRfEditAlert = () => {
    setOpenAlertRfEdit(null)
    if (!searchParams.get('ativarEdicao')) {
      clearCollapses(searchParams)
      searchParams.append(EditGuardianFormSection.GUARDIAN_SECTION, 'true')
      searchParams.append('ativarEdicao', 'true')
      history.push({ search: searchParams.toString() })
    }
  }

  const handleConfirmRfEditButton = () => {
    setOpenAlertRfEdit(MomentsOfAlert.END_EDIT)
  }

  const handleCancelRfEditButton = () => {
    searchParams.delete('ativarEdicao')
    history.push({ search: searchParams.toString() })
    handleOnClose()
  }

  const handleConfirmRfEditAlert = () => {
    setOpenAlertRfEdit(null)
    form.handleSubmit(submitHandler)()
  }

  const closeModal = () => {
    setIsVisible(false)
  }

  const handleConfirmationDialog = () => {
    setIsVisible(true)
  }

  const {
    control,
    formState: { isValid, isDirty },
  } = form

  const isEdit = !isNewEditRfNameEnabled || Boolean(searchParams.get('ativarEdicao'))
  const isStartEdit = openAlertRfEdit === MomentsOfAlert.START_EDIT

  const newEditNotification = () => {
    if (isEdit) {
      return (
        <Notification
          description="Essas informações são essenciais para garantirmos o contato com o responsável financeiro. As alterações irão refletir em todos os contratos desse responsável."
          title="Mantenha os dados atualizados"
          variation="information"
        />
      )
    }

    return (
      <Notification
        description="Essas informações são essenciais para garantirmos o contato com o responsável financeiro"
        title="Mantenha os dados atualizados"
        variation="information"
      />
    )
  }

  const newEditRegistrationFooter = () => {
    if (isEdit) {
      return (
        <>
          <Button
            variation="primary"
            disabled={!(isValid && isDirty)}
            fullWidth
            onClick={handleConfirmRfEditButton}
          >
            Concluir edição
          </Button>
          <Button variation="ghost" fullWidth onClick={handleCancelRfEditButton}>
            Cancelar
          </Button>
        </>
      )
    }
  }

  return (
    <Drawer open={isOpen}>
      {isStudentsFetched ? (
        <>
          <ConfirmationEditDialog
            form={form}
            submitHandler={submitHandler}
            isVisible={isVisible}
            handleClose={closeModal}
            isFeedbackDialogOpen={isFeedbackDialogOpen}
            handleCloseFeedbackDialog={handleCloseFeedbackDialog}
            refreshData={refreshData}
          />
          <ActionDrawerHeader
            onClose={handleOnClose}
            title={isNewEditRfNameEnabled ? 'Dados cadastrais' : 'Editar Cadastro'}
          />
          {isNewEditRfNameEnabled ? (
            newEditNotification()
          ) : (
            <Notification
              description="Essas informações são essenciais para garantirmos o contato com o responsável financeiro. As alterações irão refletir em todos os contratos desse responsável."
              title="Cadastre os dados corretamente."
              variation="warning"
            />
          )}
          <DialogContent style={{ padding: '0' }}>
            <CollapseStudent
              edit={isEdit}
              handleOpenCollapse={handleOpenCollapse}
              openCollapse={searchParams.get(CollapsesMap.get('student')) && 'student'}
              form={form}
            />
            <CollapseGuardian
              edit={isEdit}
              handleOpenCollapse={handleOpenCollapse}
              openCollapse={searchParams.get(CollapsesMap.get('guardian')) && 'guardian'}
              guardian={guardian}
              form={form}
            />
            <CollapseAddress
              edit={isEdit}
              handleOpenCollapse={handleOpenCollapse}
              openCollapse={searchParams.get(CollapsesMap.get('address')) && 'address'}
              form={form}
              isZipValid={isZipValid}
              isFirstRender={isFirstRender}
              onZipFieldFilled={OnZipFieldFilled}
            />
            {!isNewEditRfNameEnabled && (
              <Controller
                defaultValue={false}
                name="recreate_invoices"
                control={control}
                render={({ field }) => (
                  <Box paddingX={3} paddingBottom={2.8}>
                    <Box display="flex">
                      <FormControlLabel control={<Checkbox {...field} />} label="" />
                      <Typography variation="bodyLarge" color="primary">
                        Atualizar próximos boletos
                      </Typography>
                    </Box>
                    <Typography variation="bodySmall" color="secondary" withoutMargin>
                      Ao marcar a opção, novos boletos vão ser emitidos, cancelando aqueles com
                      dados desatualizados. Os boletos com datas anteriores à atualização dos dados
                      não vão sofrer alterações.
                    </Typography>
                  </Box>
                )}
              />
            )}
          </DialogContent>
          <ButtonDocker>
            {isNewEditRfNameEnabled ? (
              newEditRegistrationFooter()
            ) : (
              <Button
                disabled={!isValid || !isDirty}
                variation="primary"
                fullWidth
                onClick={handleConfirmationDialog}
              >
                Salvar
              </Button>
            )}
          </ButtonDocker>
          <ConfirmationDialog
            isVisible={Boolean(openAlertRfEdit)}
            onClose={() => setOpenAlertRfEdit(null)}
            submitHandler={isStartEdit ? handleStartRfEditAlert : handleConfirmRfEditAlert}
            title={`Novas faturas podem ser geradas, você quer ${
              isStartEdit ? 'continuar' : 'concluir'
            } a edição?`}
            backButtonLabel={isStartEdit ? 'Apenas visualizar' : 'Voltar'}
            buttonLabel="Continuar"
          >
            <TypographyDialog>
              Antes de você continuar, saiba que ao editar nome e/ou endereço, faturas anteriores
              serão reemitidas e enviadas novas faturas para o e-mail do responsável financeiro.
            </TypographyDialog>
            <TypographyDialog>
              A alteração de e-mail e/ou telefone não irá atualizar faturas.{' '}
            </TypographyDialog>
            <Typography>Você deseja continuar?</Typography>
          </ConfirmationDialog>
        </>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="80vh"
        >
          <CircularProgress style={{ marginTop: '24px' }} />
        </Box>
      )}
    </Drawer>
  )
}

export default EditRegistrationDrawer
