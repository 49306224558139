import { BigNumber } from '@/modules/guardians/GuardianDetailsFinancialSummaryTab/components/BigNumber'
import { SummaryTable } from '@/modules/guardians/GuardianDetailsFinancialSummaryTab/components/SummaryTable'
import { CURRENT_MONTH_TEXT } from '@/escolas/pages/Home/constants'
import * as Styled from '@/modules/guardians/GuardianDetailsFinancialSummaryTab/styles'
import { useGuardianDetailsFinancialSummaryTab } from './hooks/useGuardianDetailsFinancialSummaryTab'
import noPending from '@/modules/guardians/assets/noPending.svg'
import { Text } from '@gravity/text'
import { Link } from '@gravity/link'
import { GuardianDetailsTabs } from '@/modules/guardians/GuardianDetails/constants'
import { InstallmentsDrawerContainerV2 } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentsDrawerContainerV2'

type GuardianDetailsFinancialSummaryTabProps = {
  changeActiveTab: (tab: GuardianDetailsTabs) => void
}
export const GuardianDetailsFinancialSummaryTab = ({
  changeActiveTab,
}: GuardianDetailsFinancialSummaryTabProps) => {
  const {
    guardianSummary,
    installments,
    isLoadingGuardianSummaryData,
    isSuccessGuardianSummaryData,
    isEmpty,
    handleRowClick,
    selectedInstallment,
    guardianId,
    filtersKey,
    showInstallmentsDetailsDrawer,
    handleCloseDrawer,
  } = useGuardianDetailsFinancialSummaryTab()

  if (isSuccessGuardianSummaryData && isEmpty)
    return (
      <Styled.NoPendingWrapper>
        <Styled.Image src={noPending} alt="sem pendências" />
        <Text variant="body-1-medium">Este responsável já quitou todas as pendências</Text>
        <Text>Se novas pendências surgirem, elas ficarão disponível aqui.</Text>
        <Link
          onClick={() => changeActiveTab(GuardianDetailsTabs.PARCELAS)}
          underline="none"
          weight="semibold"
        >
          {'Ver parcelas pagas >'}
        </Link>
      </Styled.NoPendingWrapper>
    )
  return (
    <Styled.SummaryPageWrapper>
      <Styled.Text>{`Pendências de ${CURRENT_MONTH_TEXT}`}</Styled.Text>
      <Styled.BigNumberWrapper>
        {guardianSummary.map((key, idx) => (
          <BigNumber
            key={idx}
            quantity={key?.quantity}
            status={key?.status}
            totalAmount={key?.total_amount}
            isLoading={isLoadingGuardianSummaryData}
          />
        ))}
      </Styled.BigNumberWrapper>

      <SummaryTable
        installments={installments}
        isLoading={isLoadingGuardianSummaryData}
        onRowClick={handleRowClick}
        key={filtersKey}
      />
      {showInstallmentsDetailsDrawer && (
        <InstallmentsDrawerContainerV2
          onClose={handleCloseDrawer}
          guardianId={guardianId}
          selectedInstallment={selectedInstallment}
        />
      )}
    </Styled.SummaryPageWrapper>
  )
}
