import { TableFilterParam } from '@/escolas/components/TableFilter'
import { InstallmentType } from './installment'
import type { FinancialOperations } from '@/modules/report/models/FinancialOperations'
import type { PayoutTransactionEvents } from '@/modules/report/models/PayoutTransactionEvents'
import { FetchPayoutDetailsResponseDTO } from '@/escolas/services/report/types'

export type ReportFilters = {
  name: TableFilterParam<string>
  page?: TableFilterParam<string>
  per_page: TableFilterParam<string>
}

export enum Report {
  CHARGES = 'charges',
  ENROLLMENT = 'enrollment',
  PAYMENT_STATUS = 'payment_status',
  TICKET = 'ticket',
}

export enum TransactionEventType {
  AGGLUTINATION_DISCOUNT = 'AGGLUTINATION_DISCOUNT',
  APPLIED_DISCOUNT = 'APPLIED_DISCOUNT',
  BALANCE_TRANSFER = 'BALANCE_TRANSFER',
  CANCEL = 'CANCEL',
  CORRECTION = 'CORRECTION',
  EXISTING_CONTRACT = 'EXISTING_CONTRACT',
  LIQUIDATION_EDITION = 'LIQUIDATION_EDITION',
  LOST_DISCOUNT = 'LOST_DISCOUNT',
  NEW_CONTRACT = 'NEW_CONTRACT',
  PAYMENT_SCHEDULE = 'PAYMENT_SCHEDULE',
  SCHOOL_LIQUIDATION = 'SCHOOL_LIQUIDATION',
  STONE_TRANSACTION = 'STONE_TRANSACTION',

  U_PAYMENT_CHANGED = 'U_PAYMENT_CHANGED',
  U_PAYMENT_MANUAL_CANCELED = 'U_PAYMENT_MANUAL_CANCELED',
  U_PAYMENT_REGISTERED = 'U_PAYMENT_REGISTERED',
}

export enum PayoutStatus {
  OPEN = 'OPEN',
  PAID = 'PAID',
}
export enum PayoutVersion {
  INITIAL_PV = 1,
  DASHBOARD_PV = 2,
}

export interface TransactionEvents {
  [TransactionEventType.NEW_CONTRACT]?: number
  [TransactionEventType.SCHOOL_LIQUIDATION]?: number
  [TransactionEventType.CANCEL]?: number
  [TransactionEventType.APPLIED_DISCOUNT]?: number
  [TransactionEventType.EXISTING_CONTRACT]?: number
  [TransactionEventType.BALANCE_TRANSFER]?: number
}

export type ReportAggregations = {
  financial_details: FinancialDetails
  percentage_from_expected?: number
  platform_transaction_count?: number
  unique_students?: number
}

export interface Payout {
  due_date: datestring
  status: PayoutStatus
  version: PayoutVersion
}

export type UnguaranteedList = {
  agregations: ReportAggregations
  ended_at: Date
  payout: Payout
  payout_id: string
  started_at: Date
}

export interface ReferralInfo {
  contract_id: uuid
  contract_reference_year?: string
  guardian_id: string
  installment_id: uuid
  installment_order_reference?: string
  installment_type: InstallmentType
  last_receivable_paid_date?: datestring
  payment_schedule_description: string
  payment_schedule_id: uuid
  product_id: string
  product_name: string
  student_id: string
  student_name: string
}

export type EventDetail = {
  event: PayoutTransactionEvents
  reference_date: Date
  total: number
}

export type EventItems = Partial<
  Record<PayoutTransactionEvents, { reference_date: Date; total: number }>
>

export interface FinancialDetails {
  deductions?: FinancialOperations
  events?: TransactionEvents
  events_details?: EventDetail[]
  income?: FinancialOperations
  total: number
  total_base_amount: number
  total_expected_base_amount: number
  total_fee: number
}

export interface BankTransfer {
  amount: number
  date: datestring
}

export interface FinancialTransactionProps {
  detail_transactions: DetailTransactions[]
  financial_detail: FinancialDetails
  id: uuid
  key: string
  last_modified_at: datestring
  reference_month: datestring
  referrals: ReferralInfo
}

export interface DetailTransactions {
  BaseAmount: number
  created_at: datestring
  Fee: number
  transaction_event: TransactionEvents
}

export interface FinancialTransactionMainInfo {
  financial_detail: FinancialDetails
  id: uuid
  key: string
  referrals: ReferralInfo
}

export interface FinancialTransactionSubInfo {
  due_date: datestring
  installment_type: InstallmentType
  key: string
  last_modified_at: datestring
}

export type FinancialTransaction = FinancialTransactionMainInfo &
  FinancialDetails &
  FinancialTransactionSubInfo & {
    children?: Array<FinancialTransaction>
  }

export type ReportDates = {
  endDate: Date
  startDate: Date
}

export type ReportDateProps = {
  isOutsourcedProvider: boolean
  payout: FetchPayoutDetailsResponseDTO<'isaac-pay'> | undefined
  schoolReportingPeriod: string
  selectedDate: Date
}
