import { useEffect, useMemo, useState } from 'react'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Box } from '@material-ui/core'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useFetchProductsByReferenceYear } from '@/modules/enrollment/hooks/queries/useFetchProductsByReferenceYear'
import { SelectAutocomplete } from './components/SelectAutocomplete'
import { ProductItem } from '@/modules/enrollment/services/campaigns/types'
import * as Styled from './styles'
import { Chip, Pagination } from '@olaisaac/design-system'
import Icon from '@mdi/react'
import { mdiInformationSlabCircleOutline } from '@mdi/js'
import { ToggleButton } from './components/ToggleButton'
import { StepFooter } from '@/modules/enrollment/components/Stepper/components/StepFooter'
import { useForm } from 'react-hook-form'
import { FormProps } from './types'
import { useCampaignContext } from '@/modules/enrollment/contexts/CampaignContext'
import { useFetchStudentsByProduct } from '@/modules/enrollment/hooks/queries/useFetchStudentsByProduct'
import { StudentsTable } from './components/StudentsTable'
import { usePagination } from '@/shared/hooks/usePagination'
import { RowActionTypes } from './components/StudentsTable/types'
import { StepPageProps } from '@/modules/enrollment/components/Stepper/types'
import { sortProducts } from '@/modules/enrollment/utils/sortProducts'

export const StudentsStep = ({ onFinish, enrollmentCycle }: StepPageProps) => {
  const { school } = useSelectedSchool()
  const {
    conditions,
    chosenProducts,
    updateChosenProducts,
    nonParticipatingStudentsIds,
    updateNonParticipatingStudentsIds,
    updateTotalParticipatingStudents,
  } = useCampaignContext()

  const previousReferenceYear = enrollmentCycle.reference_year - 1

  const { data: response } = useFetchProductsByReferenceYear({
    referenceYears: [previousReferenceYear],
    schoolId: school.id,
  })

  const sortedProducts = sortProducts(!response?.data ? [] : response.data)

  const [selectedProduct, setSelectedProduct] = useState<ProductItem>()

  const currentProductList = useMemo(() => {
    if (!response?.data) return []

    const selectedProductsSet = new Set(chosenProducts)
    return sortedProducts.filter(product => !selectedProductsSet.has(product))
  }, [response, chosenProducts])

  const handleSelectProduct = (product: ProductItem) => {
    setSelectedProduct(product)
  }

  const handleAddProduct = () => {
    if (selectedProduct && !chosenProducts.includes(selectedProduct)) {
      updateChosenProducts([...chosenProducts, selectedProduct])
      setSelectedProduct(undefined)
    }
  }

  const handleRemoveProduct = (productId: string) => {
    const product = chosenProducts.find(p => p.id === productId)
    if (!product) return

    updateChosenProducts(chosenProducts.filter(p => p.id !== productId))
    setSelectedProduct(undefined)
  }

  const stepDescription = `Agora, escolha os alunos para a sua campanha. Você pode adicioná-los a partir dos produtos em que estão matriculados em ${previousReferenceYear}.`
  const alertMessage = `Alunos não rematriculáveis podem demonstrar interesse na campanha e visualizar as condições de matrícula, porém precisam regularizar suas dívidas para terem seus boletos de pré-matrícula liberados. Além disso, alunos que já estejam participando de outra campanha para o mesmo produto em ${enrollmentCycle.reference_year} não serão incluídos na campanha.`

  const [showParticipatingStudents, setShowParticipatingStudents] = useState(true)

  const { pagination, updatePaginationValue, updatePagination } = usePagination(1, 10)
  const resetPagination = () => {
    updatePagination({ page: 1, itemsPerPage: 10 })
  }

  const handleUpdateTotalParticipatingStudents = (
    totalParticipating: number,
    totalNonParticipating: number
  ) => {
    if (showParticipatingStudents) {
      const total = totalParticipating - totalNonParticipating
      updateTotalParticipatingStudents(totalParticipating > 0 ? total : 0)
    }
  }

  const fetchStudentsParams = {
    inProductIds: chosenProducts.map(product => product.id),
    isAbleCampaign: showParticipatingStudents,
    notProductId: conditions?.product?.id,
    referenceYear: enrollmentCycle.reference_year,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    },
  }
  const { data, isSuccess, isLoading, refetch } = useFetchStudentsByProduct(fetchStudentsParams)
  const students = data?.data?.students ?? []
  const totalStudents = data?.pagination?.total ?? 0

  const tableRows = useMemo(() => {
    return students.map(student => ({
      columns: [
        student.student_name,
        student.main_guardian?.name,
        student.product_name,
        student.student_status,
      ],
      key: student.student_id,
    }))
  }, [students])

  useEffect(() => {
    handleUpdateTotalParticipatingStudents(totalStudents, nonParticipatingStudentsIds.length)
  }, [totalStudents, nonParticipatingStudentsIds])

  const handleShowParticipatingStudentsChange = (value: string) => {
    setShowParticipatingStudents(value === 'included')
    resetPagination()
  }

  useEffect(() => {
    if (chosenProducts.length > 0) {
      refetch()
    }
  }, [showParticipatingStudents, chosenProducts])

  const handleSubmit = () => {
    onFinish()
  }

  const handleActionRow = (studentId: string, action: string) => {
    if (action === RowActionTypes.REMOVE) {
      updateNonParticipatingStudentsIds([...nonParticipatingStudentsIds, studentId])
    } else if (action === RowActionTypes.UNDO) {
      updateNonParticipatingStudentsIds(nonParticipatingStudentsIds.filter(id => id !== studentId))
    }
  }

  const form = useForm<FormProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      option_id: '',
      option_name: '',
    },
  })

  return (
    <Styled.Form onSubmit={form.handleSubmit(handleSubmit)}>
      <Heading variant="heading-h3-medium">Alunos</Heading>
      <Box marginTop={2} marginBottom={4}>
        <Text variant="body-1-regular">{stepDescription}</Text>
        <Box marginTop={2} />
        <Styled.AlertBox icon={<Icon path={mdiInformationSlabCircleOutline} />}>
          {alertMessage}
        </Styled.AlertBox>
        <Box marginTop={4}>
          <Text variant="subtitle-medium">Quais produtos de origem?</Text>
          <SelectAutocomplete
            form={form}
            referenceYear={previousReferenceYear}
            options={currentProductList}
            onOptionChange={handleSelectProduct}
            onOptionAction={handleAddProduct}
          />
        </Box>
        <Styled.ChipsWrapper>
          {chosenProducts?.map((product, index) => {
            return (
              <Styled.ChipContainer key={index}>
                <Chip
                  label={product.name}
                  onDelete={() => handleRemoveProduct(product.id)}
                  variation="blue"
                />
              </Styled.ChipContainer>
            )
          })}
        </Styled.ChipsWrapper>
        {chosenProducts.length > 0 && (
          <Box>
            <Box marginTop={4}>
              <ToggleButton onChange={value => handleShowParticipatingStudentsChange(value)} />
            </Box>
            <Box marginTop={4}>
              <StudentsTable
                rows={isSuccess ? tableRows : []}
                isLoading={isLoading}
                disabledRows={nonParticipatingStudentsIds}
                disableActions={!showParticipatingStudents}
                onActionRow={(key, action) => handleActionRow(key, action)}
              />
              <Box my={2} px={2} data-testid="students-table-pagination">
                <Pagination
                  currentPage={pagination.page}
                  itensPerPage={pagination.itemsPerPage}
                  itensPerPageOptions={[10, 15, 25, 50]}
                  totalItens={(isSuccess && totalStudents) ?? 0}
                  onPageChange={newPage => {
                    updatePaginationValue('page', newPage)
                  }}
                  onItensPerChangeChange={newItensPerPage => {
                    updatePaginationValue('itemsPerPage', newItensPerPage)
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <StepFooter disabled={!chosenProducts.length} />
    </Styled.Form>
  )
}
