import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { Typography } from '@olaisaac/design-system'
import { Typography as MuiTypography, Box } from '@material-ui/core'
import { useForm } from 'react-hook-form'

import { ContractDetailsDrawer } from '@/escolas/components/contract/ContractDetailsDrawer/ContractDetailsDrawer'
import { ContractDetailsDrawerState } from '@/escolas/components/contract/ContractDetailsDrawer/constants'
import { useGetContract } from '@/escolas/hooks/useGetContract'
import { useContract, useNavigation } from '@/escolas/hooks'
import { NewContractFormDefaults } from '@/escolas/components/contract/create/types'
import { useEditDiscountsInfo } from '@/escolas/hooks/useEditDiscountsInfo'
import { UnleashFlags, useApi, useJWT, useSnackbar, useUnleashFlag } from '@/shared/hooks'
import envConfig from '@/config'
import { useChangeDueDayInfo } from '@/escolas/hooks/useChangeDueDayInfo'
import {
  Contract,
  ContractCancellationReason,
  Installment,
  InstallmentType,
  PathParams,
  PreContractStatuses,
  ReceivableStatuses,
} from '@/shared/interfaces'
import ConfirmationDialog from '@/escolas/components/modal/ConfirmationDialog'
import { useGetInvoicesByIds } from '@/escolas/hooks/useGetInvoicesByIds'
import { InvoicesNotGeneratedDialog } from '@/escolas/components/contract/InvoicesNotGeneratedDialog/InvoicesNotGeneratedDialog'
import {
  ContractEditDiscountsDrawer,
  ContractEditDueDayDrawer,
} from '@/escolas/components/contract/ContractEditDrawer'
import { PagedDrawerProvider } from '@/escolas/contexts/pagedDrawerContext'
import CancellationDrawer from '@/escolas/components/contract/CancellationDrawer'
import { processInstallments } from '@/escolas/components/contract/ContractDetails'
import useInstallments from '@/escolas/hooks/useInstallments'
import { ContractCancellationForm } from '@/escolas/components/contract/CancellationDrawer/CancellationDrawerDefaultContent'
import { ModalTypography } from './styles'
import ConfirmationDialogCtrl from '@/shared/components/ConfirmationDialogCtrl'

import { getEditDueDayTooltipContent } from './utils/getEditDueDayTooltipContent'
import { getInvoicesIds } from '../utils/getInvoicesIds'

type ContractsDrawerContainerProps = {
  contractId: uuid
  onClose: () => void
  refetchGuardianContracts: () => void
}

enum DialogContentsKeys {
  CANCELATION_PRE_CONTRACT_CONFIRM = 'CANCELATION_PRE_CONTRACT_CONFIRM',
  CANCELATION_PRE_CONTRACT_CONFIRM_RECEIVABLE_PAID = 'CANCELATION_PRE_CONTRACT_CONFIRM_RECEIVABLE_PAID',
}

const checkReceivablesPaidInContract = (contract: Contract) =>
  contract.installments.some(i => i.receivables.some(r => r.status === ReceivableStatuses.PAID))

export const ContractsDrawerContainer = ({
  contractId,
  refetchGuardianContracts,
  onClose,
}: ContractsDrawerContainerProps) => {
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState<boolean>(false)
  const [cancelQueryLoaded, setCancelQueryLoaded] = useState<boolean>(false)
  const [installmentsToCancelContract, setInstallmentsToCancelContract] = useState<
    Array<Installment>
  >([])
  const [drawerState, setDrawerState] = useState<ContractDetailsDrawerState>(
    ContractDetailsDrawerState.DEFAULT
  )
  const [showInvoicesNotGeneratedDialog, setShowInvoicesNotGeneratedDialog] = useState<boolean>(
    false
  )
  const [showContractEditDiscountsDrawer, setShowContractEditDiscountsDrawer] = useState<boolean>(
    false
  )
  const [showCancellationErrorMessage, setShowCancellationErrorMessage] = useState<boolean>(false)
  const [showConfirmCancellationMessage, setShowConfirmCancellationMessage] = useState<boolean>(
    false
  )
  const [showEditContractDueDayDrawer, setShowEditContractDueDayDrawer] = useState<boolean>(false)
  const [showCancellationDrawer, setShowCancellationDrawer] = useState<boolean>(false)
  const [isOnTheFirstPage, setIsOnTheFirstPage] = useState<boolean>(true)
  const [contentDialog, setContentDialog] = useState<DialogContentsKeys | null>(null)
  const [
    showChangeContractOwnershipDialog,
    setShowChangeContractOwnershipDialog,
  ] = useState<boolean>(false)
  const { getNavigationUrl, schoolId } = useNavigation()
  const { schoolSlug } = useParams<PathParams>()
  const { isAdmin } = useJWT()
  const { api } = useApi()
  const history = useHistory<NewContractFormDefaults>()

  const { setContract } = useContract()
  const { contract, isFetched, refetch } = useGetContract(contractId, schoolId)
  const [invoiceIds, setInvoiceIds] = useState<uuid[]>([])
  const { invoicesWithError } = useGetInvoicesByIds(invoiceIds, schoolId, false)
  const { processedInstallments, setProcessedInstallments } = useInstallments()

  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()

  const closeAllDrawers = () => {
    setShowContractEditDiscountsDrawer(false)
    setShowEditContractDueDayDrawer(false)
    setShowCancellationDrawer(false)
  }

  // --- Rematrícula -->  Usado em: ContractActionsCheckout_old.tsx |
  const reEnrollStudent = () => {
    const path = getNavigationUrl({
      path: `contratos`,
    })
    const params = new URLSearchParams()

    params.append('guardianId', contract.guardian_id)
    params.append('studentId', contract.student_id)

    history.push({
      pathname: `${path}/novo`,
      search: params.toString(),
    })
  }
  // ---

  // --- Registrar Assinatura --> Usado em: ManualSignDrawer.tsx
  const refetchContract = async () => {
    await refetch()
  }
  // ---

  // --- Gerar Demonstrativo de Pgto --> Usado em: ContractTable_old.tsx
  const showStatement = () => {
    window.open(`/${schoolSlug}/contrato/${contract?.id}/demonstrativo-pagos`)
    setIsConfirmationDialogVisible(false)
  }
  // ---

  // --- Troca de Titularidade --> Usado em: ContractActionsCheckout_old.tsx
  const changeContractOwnership = () => {
    setShowChangeContractOwnershipDialog(true)
  }

  const redirectToChangeContractOwnership = () => {
    setShowChangeContractOwnershipDialog(false)
    history.push(getNavigationUrl({ path: `/contratos/${contract?.id}/alterar-titularidade` }))
  }
  // ---

  const firstInstallmentID = contract?.installments[0]?.id || ''

  const handleContractDrawerClose = () => {
    onClose()
  }

  // --- Edição de vencimento --> Usado em ContractEditDueDayDrawer.tsx
  const isNewContractEditRulesEnabled =
    useUnleashFlag(UnleashFlags.ENABLE_NEW_RULES_FOR_SCHOOL_CONTRACT_EDITS) && !isAdmin

  const maxDaysToChangeContractDueDay = Number(envConfig.MAX_DAYS_TO_CHANGE_CONTRACT_DUE_DAY)
  const maxDaysToChangeContractDueDayIsEnabled = maxDaysToChangeContractDueDay >= 0

  const getDueDayToChangeDueDayInfo = (contract: Contract): number => {
    const tuitionInstallments = contract?.installments.filter(
      inst => inst.type === InstallmentType.TUITION
    )

    const isaacPayInstallments = contract?.installments.filter(
      inst => inst.type === InstallmentType.ISAACPAY
    )

    const enrollmentInstallments = contract?.installments.filter(
      inst => inst.type === InstallmentType.ENROLLMENT
    )

    const tuitionDueDay = tuitionInstallments?.length
      ? Number(dayjs(tuitionInstallments[0].due_date).format('DD'))
      : 0

    const isaacPayDueDay = isaacPayInstallments?.length
      ? Number(dayjs(isaacPayInstallments[0].due_date).format('DD'))
      : 0

    const enrollmentDueDay = enrollmentInstallments?.length
      ? Number(dayjs(enrollmentInstallments[0].due_date).format('DD'))
      : 0

    return tuitionDueDay || isaacPayDueDay || enrollmentDueDay
  }

  const getDaysSinceContractCreation = () => {
    const contractCreationDate = dayjs(contract?.created_at).utc().format()
    const today = dayjs().utc().format()

    return Number(dayjs(today).diff(contractCreationDate, 'day'))
  }

  const {
    installmentsToChangeDueDay,
    data: changeDueDayDateData,
    isInstallmentsToChangeDueDayLoading,
  } = useChangeDueDayInfo({
    contractId: contract?.id,
    change_due_month: false,
    due_day: getDueDayToChangeDueDayInfo(contract),
    installment_id: firstInstallmentID,
    start_month: dayjs().hour(0).minute(0).second(0).millisecond(0).utc().hour(0).format(),
  })

  const handleEditContractDueDayDrawerClose = () => {
    setShowEditContractDueDayDrawer(false)
  }

  const handleEditContractDueDayDrawerClick = () => {
    closeAllDrawers()
    setShowEditContractDueDayDrawer(true)
  }

  const hasInstallmentsToChangeDueDay = installmentsToChangeDueDay.length > 0
  const canEditDueDay = () => {
    if (isAdmin || !maxDaysToChangeContractDueDayIsEnabled) {
      return hasInstallmentsToChangeDueDay
    }

    return (
      hasInstallmentsToChangeDueDay &&
      getDaysSinceContractCreation() <= maxDaysToChangeContractDueDay
    )
  }

  const editDueDayTooltipContent = getEditDueDayTooltipContent(
    hasInstallmentsToChangeDueDay,
    maxDaysToChangeContractDueDay
  )
  // ---

  // --- Edição de descontos --> Usado em ContractEditDiscountsDrawer.tsx
  const {
    installmentsToEditDiscounts,
    isLoading: isInstallmentsToEditDiscountsLoading,
    data: editDiscountsData,
  } = useEditDiscountsInfo({
    contractId: contract?.id,
    installment_id: firstInstallmentID,
    get_current_amount: true,
    discounts: [],
  })

  const handleContractEditDiscountsDrawerClick = () => {
    closeAllDrawers()
    setShowContractEditDiscountsDrawer(true)
  }
  const handleContractEditDiscountsDrawerClose = () => {
    setShowContractEditDiscountsDrawer(false)
  }
  // ---

  // --- Cancelamento de Contratos --> Usado em CancellationDrawer.tsx

  const cancelContractForm = useForm<ContractCancellationForm>({
    mode: 'all',
    defaultValues: {
      installment_id: '',
      cancellation_description: '',
    },
  })

  const { handleSubmit, reset } = cancelContractForm

  const isRevokedCancelContract = useUnleashFlag(UnleashFlags.B2BCOR_193_BLOCK_CONTRACT_REVOKE)

  const cancelPreContractFeatureFlagValue = useUnleashFlag(
    UnleashFlags.ENGAJAMENTO_PAS392_CANCELLATION_PRECONTRACT
  )

  const isCancelContractEnabled = !isAdmin && !isRevokedCancelContract

  const isCancelPreContractFeatureFlagEnabled = !isAdmin && cancelPreContractFeatureFlagValue

  const isPreContract = contract?.pre_contract_status === PreContractStatuses.CREATED_PCS

  const hasInstallmentsToCancelContract = installmentsToCancelContract?.length > 0

  const canCancelContract =
    !isPreContract && isCancelContractEnabled && hasInstallmentsToCancelContract
  const canCancelPreContract = isPreContract && isCancelPreContractFeatureFlagEnabled

  const handleCancellationDrawerOpen = () => {
    closeAllDrawers()
    isAdmin || canCancelContract || canCancelPreContract
      ? setShowCancellationDrawer(true)
      : setShowCancellationErrorMessage(true)
  }

  const handleCancellationDrawerClose = () => {
    setShowCancellationDrawer(false)
  }

  const onCancellationSuccess = () => {
    refetchGuardianContracts()
    refetchContract()
    setShowConfirmCancellationMessage(false)
    handleCancellationDrawerClose()
  }

  const cancelContractSubmitHandler = (form: ContractCancellationForm) => {
    setContentDialog(null)
    setShowConfirmCancellationMessage(false)
    const { cancellation_reason, installment_id, cancellation_description } = form

    const snackbarErrorMessage = isPreContract
      ? 'Ocorreu um erro ao cancelar o contrato'
      : 'Antes de cancelar, é necessário renegociar as parcelas vencidas deste contrato.'

    return api.contracts
      .revoke(
        contract?.id,
        {
          cancellation_reason:
            isCancelContractEnabled && !isPreContract
              ? ContractCancellationReason.OTHER
              : cancellation_reason,
          ...(!isPreContract && { installment_id }),
          cancellation_description,
        },
        schoolId,
        contract
      )
      .then(() => {
        setSnackbarVariation('success')
        setSnackbarMessage('Contrato cancelado com sucesso.')
        setSnackbarIsOpen(true)
        onCancellationSuccess()
        setIsOnTheFirstPage(true)
        reset()
      })
      .catch(() => {
        setSnackbarVariation('error')
        setSnackbarMessage(snackbarErrorMessage)
        setSnackbarIsOpen(true, {
          title: 'Esse contrato não pode ser cancelado',
        })
        handleCancellationDrawerClose()
        setShowConfirmCancellationMessage(false)
        setIsOnTheFirstPage(true)
        reset()
      })
  }

  const dialogContents = {
    [DialogContentsKeys.CANCELATION_PRE_CONTRACT_CONFIRM_RECEIVABLE_PAID]: {
      title: 'Atenção',
      content: (
        <>
          <ModalTypography>
            Este contrato possui uma parcela paga. Caso queira seguir com o cancelamento, o estorno
            do pagamento precisará ser feito diretamente da escola para o responsável.
          </ModalTypography>
          <ModalTypography>
            O cancelamento de contrato é irreversível. Caso necessário, um novo contrato precisará
            ser criado.
          </ModalTypography>
        </>
      ),
      backButtonLabel: 'Voltar',
      buttonLabel: 'Cancelar contrato',
      submitHandler: handleSubmit(cancelContractSubmitHandler),
    },
    [DialogContentsKeys.CANCELATION_PRE_CONTRACT_CONFIRM]: {
      title: 'Atenção',
      content: (
        <ModalTypography>
          O cancelamento de contrato é irreversível. Caso necessário, um novo contrato precisará ser
          criado.
        </ModalTypography>
      ),
      backButtonLabel: 'Voltar',
      buttonLabel: 'Cancelar contrato',
      submitHandler: handleSubmit(cancelContractSubmitHandler),
    },
  }

  const getCancellationContractDialogMessage = () => {
    const hasPaidReceivables = checkReceivablesPaidInContract(contract)
    if (hasPaidReceivables) {
      setContentDialog(DialogContentsKeys.CANCELATION_PRE_CONTRACT_CONFIRM_RECEIVABLE_PAID)
    } else {
      setContentDialog(DialogContentsKeys.CANCELATION_PRE_CONTRACT_CONFIRM)
    }
  }
  // ---

  const hasInvoicesWithError = invoicesWithError?.length > 0

  const getCancelContractInstallments = () => {
    if (!contract) return
    api.contracts.cancelContractInfo(contract.id).then(data => {
      setCancelQueryLoaded(true)
      setInstallmentsToCancelContract(data)
    })
  }
  // ---

  useEffect(() => {
    if (contract) {
      setContract(contract)
      setInvoiceIds(getInvoicesIds(contract))

      if (contract?.installments?.length === 0) return
      const processedInstallments = processInstallments(contract)
      setProcessedInstallments(processedInstallments)
      getCancelContractInstallments()
    }
  }, [contract])

  return (
    <PagedDrawerProvider>
      {/* Default Drawer */}
      <ContractDetailsDrawer
        // Dados e refetch
        contract={contract}
        getContract={refetchContract}
        // Controle do Drawer
        isOpen={isFetched}
        onClose={handleContractDrawerClose}
        drawerState={drawerState}
        setDrawerState={setDrawerState}
        // Rematrícula
        isReenrollable={contract?.isReenrollable}
        addContract={() => reEnrollStudent()}
        // Edição de Descontos
        canEditDiscounts={installmentsToEditDiscounts?.length > 0}
        openDiscountsEdition={handleContractEditDiscountsDrawerClick}
        isInstallmentsToEditDiscountsLoading={isInstallmentsToEditDiscountsLoading}
        // Cancelamento de Contrato
        cancelContractQueryLoading={cancelQueryLoaded}
        openCancellationDrawer={handleCancellationDrawerOpen}
        // Edição de Vencimento
        isInstallmentsToChangeDueDayLoading={isInstallmentsToChangeDueDayLoading}
        openDueDayEdition={handleEditContractDueDayDrawerClick}
        canEditDueDay={
          isNewContractEditRulesEnabled ? () => hasInstallmentsToChangeDueDay : canEditDueDay
        }
        tooltip={
          isNewContractEditRulesEnabled
            ? 'Edição disponível somente para parcelas com vencimento no próximo mês e não renegociadas'
            : editDueDayTooltipContent
        }
        // Cashier
        hasInvoicesWithError={hasInvoicesWithError}
        setShowInvoicesNotGeneratedDialog={setShowInvoicesNotGeneratedDialog}
        // Troca de titularidade
        changeContractOwnership={changeContractOwnership}
        // Gerar Demonstrativo
        setIsConfirmationDialogVisible={setIsConfirmationDialogVisible}
        // Categoria da escola
        isCheckoutSchool
      />

      {/* Drawer Edição de Descontos */}
      <ContractEditDiscountsDrawer
        isOpen={showContractEditDiscountsDrawer}
        isContractInFlexiblePeriod={editDiscountsData?.is_contract_in_flexible_period}
        onClose={handleContractEditDiscountsDrawerClose}
        availableInstallments={installmentsToEditDiscounts}
        callbackEdits={refetchGuardianContracts}
      />

      {/* Drawer Edição de Vencimento */}
      <ContractEditDueDayDrawer
        isOpen={showEditContractDueDayDrawer}
        isContractInFlexiblePeriod={changeDueDayDateData?.is_contract_in_flexible_period}
        flexibleMaxDueDate={changeDueDayDateData?.flexible_maximum_due_date}
        onClose={handleEditContractDueDayDrawerClose}
        availableInstallments={installmentsToChangeDueDay}
        callbackEdits={refetchGuardianContracts}
      />

      {/* Drawer Cancelamento de Contrato */}
      <CancellationDrawer
        isOpen={showCancellationDrawer}
        onClose={handleCancellationDrawerClose}
        onConfirm={getCancellationContractDialogMessage}
        processedInstallments={processedInstallments}
        isOnTheFirstPage={isOnTheFirstPage}
        setIsOnTheFirstPage={setIsOnTheFirstPage}
        form={cancelContractForm}
        setShowConfirmCancellationMessage={setShowConfirmCancellationMessage}
        availableInstallments={installmentsToCancelContract}
      />

      {/* Modal Gerar Demonstrativo */}
      <ConfirmationDialog
        isVisible={isConfirmationDialogVisible}
        onClose={() => setIsConfirmationDialogVisible(false)}
        submitHandler={showStatement}
        buttonLabel="Ok, gerar demonstrativo"
        title="Aviso"
      >
        <Typography>
          Este demonstrativo só apresenta valores pagos que são de competência do isaac, ou seja,
          valores anteriores a parceria não constam no demonstrativo.
        </Typography>
      </ConfirmationDialog>

      {/* Modal Cashier */}
      <InvoicesNotGeneratedDialog
        isOpen={showInvoicesNotGeneratedDialog}
        setIsOpen={setShowInvoicesNotGeneratedDialog}
      />

      {/* Modal Troca de Titularidade */}
      <ConfirmationDialog
        isVisible={showChangeContractOwnershipDialog}
        closeIcon
        maxWidth="sm"
        onClose={() => setShowChangeContractOwnershipDialog(false)}
        submitHandler={redirectToChangeContractOwnership}
        backButtonLabel="Voltar"
        buttonLabel="Continuar"
        title="O contrato anterior será cancelado a partir das parcelas a vencer:"
      >
        <MuiTypography>
          Antes de você continuar, saiba que ao realizar uma alteração de titularidade{' '}
          <b>o contrato atual será cancelado a partir das parcelas a vencer</b> e ficará inativo
          (mas não se preocupe, você ainda poderá visualizá-lo).
        </MuiTypography>
        <Box my={2}>
          <MuiTypography>
            Um <b>novo contrato será criado</b> com os dados do novo responsável financeiro;
          </MuiTypography>
        </Box>
        <Box my={2}>
          <MuiTypography>
            <b>
              Parcelas com vencimento para o dia de hoje, ficarão vinculadas ao contrato anterior.
              Novos boletos não serão gerados.
            </b>
          </MuiTypography>
        </Box>
        <MuiTypography>Você deseja continuar?</MuiTypography>
      </ConfirmationDialog>

      {/* Modais Cancelamento de Contrato */}

      {isCancelContractEnabled && (
        <ConfirmationDialog
          isVisible={showCancellationErrorMessage}
          onClose={() => setShowCancellationErrorMessage(false)}
          submitHandler={() => setShowCancellationErrorMessage(false)}
          title="Esse contrato não pode ser cancelado"
        >
          <MuiTypography>
            Contratos com parcelas vencidas ou renegociadas não podem ser cancelados. Entre em
            contato com o atendimento.
          </MuiTypography>
        </ConfirmationDialog>
      )}

      <ConfirmationDialog
        isVisible={showConfirmCancellationMessage}
        onClose={() => setShowConfirmCancellationMessage(false)}
        submitHandler={handleSubmit(cancelContractSubmitHandler)}
        backButtonLabel="Voltar"
        buttonLabel="Cancelar contrato"
        title="Atenção"
      >
        <MuiTypography>
          O cancelamento de contrato é irreversível e altera os próximos repasses.
        </MuiTypography>
      </ConfirmationDialog>

      {Boolean(contentDialog) && (
        <ConfirmationDialogCtrl
          dialogContents={dialogContents}
          isVisible={Boolean(contentDialog)}
          onClose={() => setContentDialog(null)}
          selectedContentKey={contentDialog}
        />
      )}
    </PagedDrawerProvider>
  )
}
