import { FC } from 'react'
import { useController } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import useTheme from '@material-ui/core/styles/useTheme'
import DiscountFormFields from '@/shared/components/DiscountsListForm/components/DiscountFormFields'
import DiscountPreviewCard from '@/shared/components/DiscountsListForm/components/DiscountPreviewCard'
import { leftZeroPadding, formatNumberToTwoFractionDigits } from '@/shared/utils'
import {
  SaveButton,
  DiscountPaper,
  CaptionScondaryTypography,
  DeleteButton,
  EditButton,
  EditBox,
  DeleteBox,
} from './style'
import { getBeforeDueDateDescription } from './utils/getBeforeDueDateDescription'
import { DiscountFormProps } from './types'

const DiscountForm: FC<DiscountFormProps> = ({
  form,
  totalAmount,
  index,
  removeDiscount,
  fieldNames,
}) => {
  const { control, watch, trigger } = form
  watch()
  const theme = useTheme()

  const description = useController({
    name: fieldNames.description,
    control: control,
    rules: { required: true },
  })

  const amount = useController({
    name: fieldNames.amount,
    control,
    rules: { required: true, validate: v => v > 0 && v < totalAmount },
  })

  const daysBeforeDueDateLabel = useController({
    name: fieldNames.daysBeforeDueDateLabel,
    control,
    rules: { required: true },
  })

  const daysBeforeDueDate = useController({
    name: fieldNames.daysBeforeDueDate,
    control,
    rules: { required: true },
  })

  const isFinishedController = useController({
    name: fieldNames.isFinished,
    control,
    rules: { required: true },
  })

  const isFinished = isFinishedController.field.value
  const changeIsFinished = isFinishedController.field.onChange

  const percentage = formatNumberToTwoFractionDigits((amount.field.value / totalAmount) * 100)

  const daysBeforeDueDateOptionDescription = getBeforeDueDateDescription(
    daysBeforeDueDate.field.value,
    daysBeforeDueDateLabel.field.value
  )

  const isFinishButtonDisabled =
    amount.fieldState.invalid ||
    daysBeforeDueDate.fieldState.invalid ||
    description.fieldState.invalid

  return (
    <DiscountPaper theme={theme as any} data-testid={`add-discount-${index}-content`}>
      <Box mb={2}>
        <CaptionScondaryTypography>
          Desconto nº {leftZeroPadding(index + 1, 2)}
        </CaptionScondaryTypography>
        <DeleteBox>
          <DeleteButton
            aria-label="remover desconto"
            onClick={() => {
              removeDiscount(index)
            }}
          >
            <DeleteIcon />
          </DeleteButton>
        </DeleteBox>
        {isFinished && (
          <EditBox>
            <EditButton aria-label="editar desconto" onClick={() => changeIsFinished(!isFinished)}>
              <EditIcon />
            </EditButton>
          </EditBox>
        )}
      </Box>
      {isFinished ? (
        <DiscountPreviewCard
          {...{
            amount: amount.field.value,
            description: description.field.value,
            percentage,
            daysBeforeDueDate: daysBeforeDueDate.field.value,
            daysBeforeDueDateOptionDescription,
            index,
          }}
        />
      ) : (
        <DiscountFormFields
          totalAmountPaid={totalAmount}
          fields={{
            description,
            amount,
            daysBeforeDueDateLabel,
            daysBeforeDueDate,
          }}
        />
      )}

      {!isFinished && (
        <SaveButton
          onClick={() => {
            changeIsFinished(!isFinished)
            trigger()
          }}
          disabled={isFinishButtonDisabled}
          data-testid="save-discount-button"
        >
          Salvar desconto
        </SaveButton>
      )}
    </DiscountPaper>
  )
}

export default DiscountForm
