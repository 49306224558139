import { useEffect, useReducer, useState } from 'react'
import { Box, MenuItem } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Button, Chip, Grid, Pagination, Typography } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Button as GravityButton } from '@gravity/button'
import { mdiArchiveOutline, mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'

import useAddContractEnabled from '@/escolas/hooks/useAddContractEnabled'
import { useIsFirstRender } from '@/shared/hooks/useIsFirstRender'
import { FilterReportButton } from '@/escolas/components/report/FilterReportDrawer'
import { ErrorDialog } from '@/modules/enrollment/components/ErrorDialog'
import { StudentTable } from '@/modules/enrollment/components/StudentTable'
import {
  useNavigation,
  useQuery,
  useScrollToElement,
  useStudentsEnrollmentsFilter,
} from '@/escolas/hooks'
import { useLayout } from '@/shared/hooks/useLayout'
import { useEnrollmentCycle } from '@/modules/enrollment/hooks/queries/useEnrollmentCycle'
import { useGetStatusSummary } from '@/modules/enrollment/hooks/queries/enrollment'
import { usePagination } from '@/shared/hooks/usePagination'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { UserGuidingGuidesIds, useUserGuidingTrigger } from '@/shared/hooks/useUserGuidingTrigger'
import { PageWrapper } from '@/escolas/components/PageWrapper'
import { SchoolEnrollmentCycleTransition } from '@/modules/enrollment/components/SchoolEnrollmentCycleTransition'
import { SearchWithDebounce } from '@/modules/enrollment/components/SearchWithDebounce'
import { StatusHelpButton } from './components/StatusSummary/StatusHelpButton'

import FilterDrawer from './components/FilterDrawer'
import StatusDrawer from './components/StatusDrawer'
import { StatusSummary } from './components/StatusSummary'
import { useSelectYear } from './hooks/useSelectYear'
import * as Styled from './styles'

export const SchoolEnrollmentCycle = () => {
  const currentYear = new Date().getFullYear()

  const isFirstRender = useIsFirstRender()
  const [name, setName] = useState<string>('')
  const { schoolId, navigateTo } = useNavigation()
  const { pagination, updatePaginationValue } = usePagination(1, 25)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const {
    studentsEnrollmentsFilter,
    studentsEnrollmentsFilterCount,
  } = useStudentsEnrollmentsFilter()
  const addContractEnabled = useAddContractEnabled()

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)
  const isSelectCyclesEnabled = useUnleashFlag(UnleashFlags.ENABLE_MATRICULAS_SELECT_CYCLES)
  const isCampaignFFEnabled = useUnleashFlag(UnleashFlags.ENABLE_MATRICULAS_CAMPAIGN)

  const { handleChangeSelectedYear, years, selectedYear: selectedCycle } = useSelectYear()

  const selectedYear = isSelectCyclesEnabled ? selectedCycle : currentYear
  const isCampaignButtonEnabled = isCampaignFFEnabled && selectedYear >= 2025

  useSetPageTitle(`Matrículas ${selectedYear} - isaac`)

  const { setOnQueryParam } = useQuery()

  const { canUseAutoTrigger } = useUserGuidingTrigger()

  const { data, isLoading, isError, isSuccess } = useEnrollmentCycle({
    referenceYears: [selectedYear, selectedYear - 1].map(String),
    schoolId,
    name,
    enrollmentStatus: studentsEnrollmentsFilter.enrollmentStatus,
    enrollmentSubStatus: studentsEnrollmentsFilter.enrollmentSubStatus,
    pagination: {
      page: pagination.page,
      per_page: pagination.itemsPerPage,
    },
  })

  const {
    data: dataSummary,
    isLoading: isLoadingSummary,
    isError: isErrorSummary,
  } = useGetStatusSummary({
    schoolId,
    referenceYear: String(selectedYear),
  })

  // TODO set isChangingCycles to true when change cycle
  const [isChangingCycles, setIsChangingCycles] = useState(false)

  const [isStatusDrawerOpen, toggleIsStatusDrawerOpen] = useReducer(state => {
    return !state
  }, false)

  const [isFilterDrawerOpen, toggleIsFilterDrawerOpen] = useReducer(state => {
    return !state
  }, false)

  const goToCampaignsPage = () => {
    navigateTo({ path: `campanhas/${selectedYear}` })
  }

  useEffect(() => {
    if (name) {
      updatePaginationValue('page', 1)
    }
  }, [name])

  useEffect(() => {
    if (isFirstRender) return

    updatePaginationValue('page', 1)
  }, [studentsEnrollmentsFilter])

  const guideId = isIntegratedSchool
    ? UserGuidingGuidesIds.ENROLLMENT_PAGE_INTEGRATED_SCHOOL
    : UserGuidingGuidesIds.ENROLLMENT_PAGE

  useEffect(() => {
    if (canUseAutoTrigger) {
      setOnQueryParam(guideId, 'guide')
    }
  }, [isIntegratedSchool])

  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Matrículas' })

  useScrollToElement({
    ready: !isLoading && !isLoadingSummary && !isErrorSummary && !isError,
  })

  return isChangingCycles ? (
    <SchoolEnrollmentCycleTransition onComplete={() => setIsChangingCycles(false)} />
  ) : (
    <PageWrapper>
      <Styled.PageContainer>
        <ErrorDialog isError={isError || isErrorSummary} />
        {isSelectCyclesEnabled ? (
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item sm={6}>
              <Box flexDirection="column" order="1">
                <Box display="flex" flexDirection="row">
                  <Styled.SelectYearTitle>Ano letivo:</Styled.SelectYearTitle>
                  <Styled.SelectYear
                    value={selectedYear}
                    onChange={evt => handleChangeSelectedYear(Number(evt.target.value))}
                  >
                    {years.map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                        <Box flex={1} />
                        {year > currentYear && (
                          <Box display="inline-block" ml={1}>
                            <Chip label="Novo" variation="blue" />
                          </Box>
                        )}
                      </MenuItem>
                    ))}
                  </Styled.SelectYear>
                </Box>

                {!isLoadingSummary && !isErrorSummary && (
                  <Typography variation="subtitleDesktopLarge">{`Total de alunos: ${dataSummary.total}`}</Typography>
                )}
              </Box>
            </Grid>

            <Styled.HeaderButtonsWrapper item sm={6}>
              {isCampaignButtonEnabled && (
                <GravityButton
                  variant="outline"
                  iconStart={<Icon path={mdiArchiveOutline} />}
                  onClick={goToCampaignsPage}
                >
                  Gerenciar campanhas
                </GravityButton>
              )}

              {addContractEnabled && (
                <GravityButton
                  variant="solid"
                  iconStart={<Icon path={mdiPlus} />}
                  onClick={() => {
                    navigateTo({ path: `/contratos/${selectedYear}/novo` })
                    isInitialized &&
                      eventDispatcherClient.sendEvent({
                        scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
                        name: EnrollmentEventDispatcherEvents.CLICKED,
                        action: 'click',
                        customProperties: { $Button_name: 'ADICIONAR_NOVO_ALUNO' },
                      })
                  }}
                >
                  Adicionar novo aluno
                </GravityButton>
              )}
            </Styled.HeaderButtonsWrapper>
          </Grid>
        ) : (
          <Styled.HeaderContainer>
            <div style={{ flex: 1 }}>
              <Typography variation="headlineDesktopSmall" style={{ paddingBottom: 4 }}>
                Visão geral
              </Typography>
              {!isLoadingSummary && !isErrorSummary && (
                <Typography variation="subtitleDesktopLarge">{`${dataSummary.total} alunos`}</Typography>
              )}
            </div>
            <StatusHelpButton
              onClick={() => {
                toggleIsStatusDrawerOpen()
                isInitialized &&
                  eventDispatcherClient.sendEvent({
                    scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
                    name: EnrollmentEventDispatcherEvents.CLICKED,
                    action: 'click',
                    customProperties: { $Button_name: 'ENTENDA_A_SITUAÇÃO_DE_MATRÍCULA' },
                  })
              }}
            />
            {addContractEnabled && (
              <Button
                variation="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  navigateTo({ path: `/contratos/${selectedYear}/novo` })
                  isInitialized &&
                    eventDispatcherClient.sendEvent({
                      scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
                      name: EnrollmentEventDispatcherEvents.CLICKED,
                      action: 'click',
                      customProperties: { $Button_name: 'ADICIONAR_NOVO_ALUNO' },
                    })
                }}
              >
                Adicionar novo aluno
              </Button>
            )}
          </Styled.HeaderContainer>
        )}
        <StatusSummary data={dataSummary} isLoading={isLoadingSummary} isError={isErrorSummary} />
        {isSelectCyclesEnabled ? (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box order="1">
              <Typography id="students-list" variation="headlineDesktopSmall">
                Lista de alunos
              </Typography>
            </Box>
            <Box order="2">
              <StatusHelpButton
                onClick={() => {
                  toggleIsStatusDrawerOpen()
                  isInitialized &&
                    eventDispatcherClient.sendEvent({
                      scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
                      name: EnrollmentEventDispatcherEvents.CLICKED,
                      action: 'click',
                      customProperties: { $Button_name: 'ENTENDA_A_SITUAÇÃO_DE_MATRÍCULA' },
                    })
                }}
              />
            </Box>
          </Box>
        ) : (
          <Typography id="students-list" variation="headlineDesktopSmall">
            Lista de alunos
          </Typography>
        )}
        <Grid container alignItems="center">
          <Grid item sm={8}>
            <Styled.SearchContainer>
              <SearchWithDebounce
                value={name}
                setValue={setName}
                placeholder="Pesquisar por nome do aluno ou responsável"
              />
            </Styled.SearchContainer>
          </Grid>
          <Grid item sm={4} style={{ textAlign: 'right' }}>
            <FilterReportButton
              selectedFilters={studentsEnrollmentsFilterCount}
              showFilterDrawer={false}
              onClick={() => {
                toggleIsFilterDrawerOpen()
                isInitialized &&
                  eventDispatcherClient.sendEvent({
                    scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
                    name: EnrollmentEventDispatcherEvents.CLICKED,
                    action: 'click',
                    customProperties: { $Button_name: 'BOTÃO_FILTRAR_DA_TABELA_DE_ALUNOS' },
                  })
              }}
            />
          </Grid>
        </Grid>
        <StudentTable
          enrollmentYear={selectedYear}
          schoolId={schoolId}
          data={isSuccess ? data.data : []}
          isLoading={isLoading}
        />
        <Box my={2} px={2}>
          <Pagination
            currentPage={pagination.page}
            itensPerPage={pagination.itemsPerPage}
            itensPerPageOptions={[10, 15, 25, 50]}
            totalItens={isSuccess ? data.pagination.total : 0}
            onPageChange={newPage => {
              updatePaginationValue('page', newPage)
              isInitialized &&
                eventDispatcherClient.sendEvent({
                  scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
                  name: EnrollmentEventDispatcherEvents.CLICKED,
                  action: 'click',
                  customProperties: {
                    $Button_name: 'NEXT_PAGE_BUTTON',
                    $table_name: 'LISTA_DE_ALUNOS',
                  },
                })
            }}
            onItensPerChangeChange={newItensPerPage => {
              updatePaginationValue('itemsPerPage', newItensPerPage)
            }}
          />
        </Box>

        <StatusDrawer open={isStatusDrawerOpen} onClose={toggleIsStatusDrawerOpen} />
        <FilterDrawer
          selectedYear={selectedYear}
          open={isFilterDrawerOpen}
          onClose={toggleIsFilterDrawerOpen}
        />
      </Styled.PageContainer>
    </PageWrapper>
  )
}
