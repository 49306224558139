import { Typography } from '@olaisaac/design-system'
import { CardsContainer, CompactedHeader } from './styles'
import { StudentsCarouselCompactedProps } from './types'
import { StudentCard } from '../StudentCard'
import { MAX_COMPACTED_CARDS } from '../../constants'

export const StudentsCarouselCompacted = ({
  students,
  enrollmentYear,
}: StudentsCarouselCompactedProps) => {
  const compactedStudentList = students?.slice(0, MAX_COMPACTED_CARDS)

  return (
    <CompactedHeader>
      <Typography variation="subtitleDesktopMedium">
        {`${students?.length || 0} aluno(s) vinculado(s)`}
      </Typography>
      <CardsContainer className="compacted">
        {compactedStudentList?.map(student => (
          <StudentCard
            student={student}
            key={student.student_id}
            isExpanded={false}
            enrollmentYear={enrollmentYear}
          />
        ))}
      </CardsContainer>
    </CompactedHeader>
  )
}
