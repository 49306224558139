import { Box } from '@material-ui/core'
import { Breadcrumbs } from '@olaisaac/design-system'
import { FC } from 'react'
import {
  ContractEditInfoAlert,
  EditContractTypography,
  GuardianNameTypography,
  HeaderBox,
  SchoolNameTypography,
  StudentNameTypography,
  MarginHeaderBox,
} from './style'
import { ComponentExampleProps } from './types'

const PreContractEditHeader: FC<ComponentExampleProps> = ({
  guardianName,
  studentName,
  schoolName,
  hasDocusign,
}) => {
  return (
    <>
      <Box>
        <SchoolNameTypography>{schoolName}</SchoolNameTypography>
        <HeaderBox>
          <Breadcrumbs>
            <GuardianNameTypography>{guardianName}</GuardianNameTypography>
            <StudentNameTypography>{studentName}</StudentNameTypography>
          </Breadcrumbs>
        </HeaderBox>
      </Box>
      <MarginHeaderBox>
        <EditContractTypography>Edição de contrato</EditContractTypography>
      </MarginHeaderBox>
      {hasDocusign && (
        <ContractEditInfoAlert>
          Alterações farão com que o contrato enviado para o responsável via Docusign seja
          desconsiderado. Um novo contrato será enviado para assinatura com os novos termos.
        </ContractEditInfoAlert>
      )}
    </>
  )
}

export default PreContractEditHeader
