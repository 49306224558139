import ContractsCheckout from 'src/escolas/components/contract/ContractsYear/contractsYearCheckout'
import { assoc } from 'ramda'
import { FC, useState } from 'react'
import { useNavigation } from 'src/escolas/hooks'
import { ContractStatus, ContractStatusLabel } from 'src/shared/interfaces'
import { PageRouteProps } from 'src/escolas/router'
import { buildRoute } from 'src/shared/router/utils'
import { ContractFilters } from '..'
import { useJWT } from 'src/shared/hooks'
import { StatusSearch } from 'src/escolas/hooks/useSearchByStudentOrGuardian'
import useRouterReferenceYear from 'src/escolas/hooks/useRouterReferenceYear'
import { GuardiansInstallmentsQueryParamsNameEnum } from '@/escolas/pages/responsaveis/GuardianDetailsInstallmentsTab/constants'

const Page: FC = () => {
  const { newTabNavigateTo } = useNavigation()
  const { isAdmin } = useJWT()
  const referenceYear = useRouterReferenceYear()

  const [name, setName] = useState<string>('')
  const [filters, setFilters] = useState<ContractFilters>({
    name: { value: name },
    status: isAdmin ? null : { label: ContractStatusLabel.OPEN, value: ContractStatus.OPEN },
  })

  const handleRowClick = (obj: any, isGuardian = false) => {
    const searchParams = new URLSearchParams()
    searchParams.append(GuardiansInstallmentsQueryParamsNameEnum.YEARS, referenceYear)

    if (isGuardian) {
      newTabNavigateTo({
        path: `/responsaveis/${obj?.guardianId}/contratos?${searchParams.toString()}`,
      })
    } else {
      searchParams.append(GuardiansInstallmentsQueryParamsNameEnum.STUDENT_IDS, obj?.studentId)
      searchParams.append(GuardiansInstallmentsQueryParamsNameEnum.PRODUCT_IDS, obj?.productId)
      newTabNavigateTo({
        path: `/responsaveis/${obj?.guardianId}/parcelas?${searchParams.toString()}`,
      })
    }
  }

  const search = (statusSearch?: StatusSearch) => {
    if (name === filters.name.value && statusSearch.isGuardian === filters.name?.isGuardian) return

    setFilters(
      assoc(
        'name',
        { value: name, isCpf: statusSearch.isCPF, isGuardian: statusSearch.isGuardian },
        filters
      )
    )
  }

  return (
    <ContractsCheckout
      handleRowClick={handleRowClick}
      filters={filters}
      name={name}
      setName={setName}
      search={search}
    />
  )
}

const getPathname = ({ path }: PageRouteProps) => `${path}`
const buildBreadcrumbs = ({ breadcrumbs }: PageRouteProps) => breadcrumbs
const PageRoute = buildRoute(Page, getPathname, buildBreadcrumbs)

export default PageRoute
