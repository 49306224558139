import {
  Box,
  Collapse,
  Divider,
  FormControl,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
} from '@material-ui/core'
import { TextField, Typography } from '@olaisaac/design-system'
import { FC, useEffect } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { defaultAddress } from '../create/constants'
import { UFs } from 'src/shared/utils'
import * as S from './EditRegistrationDrawer.styles'
import NumberFormat from 'react-number-format'

export type CollapseAddressProps = {
  edit?: boolean
  form: UseFormReturn<any>
  handleOpenCollapse: (value: string) => void
  isFirstRender: boolean
  isZipValid: boolean
  onZipFieldFilled: (zip: cep) => void
  openCollapse: string
}

const CollapseAddress: FC<CollapseAddressProps> = ({
  edit = false,
  openCollapse,
  handleOpenCollapse,
  form,
  isZipValid,
  isFirstRender,
  onZipFieldFilled,
}) => {
  const isOpen = openCollapse === 'address'
  const { control, reset, getValues, setError, clearErrors, trigger } = form
  const formValues = getValues()
  const defaultValue = ''
  const clearAddressErrors = fields => {
    clearErrors([...fields])
  }

  useEffect(() => {
    if (isOpen) {
      trigger()
    }
  }, [openCollapse])

  const resetZipCode = () => {
    reset(
      {
        ...formValues,
        address: { ...defaultAddress },
      },
      { keepErrors: true }
    )

    setError('address.zip', {
      type: 'required',
    })

    clearAddressErrors(['address.street', 'address.number', 'address.city', 'address.state_code'])
  }

  return (
    <Box>
      <ListItem
        button
        style={{ justifyContent: 'space-between', paddingLeft: '22px' }}
        onClick={() => handleOpenCollapse('address')}
      >
        <Typography variation="subtitleDesktopLarge">Endereço</Typography>
        {isOpen ? <S.ExpandLess /> : <S.ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <S.StyledBox>
          <FormControl fullWidth variant="outlined" margin="normal">
            <Controller
              rules={{
                minLength: 8,
                maxLength: 8,
                validate: () => isZipValid,
              }}
              name="address.zip"
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error, invalid } }) => (
                <NumberFormat
                  {...rest}
                  label="CEP"
                  id="edit-guardian-zip-input"
                  type="tel"
                  format="#####-###"
                  mask="_"
                  onValueChange={currentValue => {
                    if (!currentValue.value) {
                      return resetZipCode()
                    }

                    onChange(currentValue.value)

                    if (!isFirstRender) {
                      currentValue.value.length === 8 && onZipFieldFilled(currentValue.value)
                    }
                  }}
                  value={value}
                  customInput={TextField}
                  variant="outlined"
                  error={Boolean(error?.type || invalid)}
                  helperText={error?.type || invalid ? 'CEP inválido' : ''}
                  disabled={!edit}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined" margin="normal">
            <Controller
              rules={{ required: true }}
              name="address.street"
              control={control}
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  label="Rua"
                  id="edit-guardian-street-input"
                  fullWidth
                  value={value || defaultValue}
                  error={Boolean(error?.type && isZipValid)}
                  helperText={error?.type && isZipValid ? 'Rua inválida' : ''}
                  disabled={!edit}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined" margin="normal">
            <Controller
              rules={{
                required: true,
              }}
              name="address.number"
              control={control}
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  label="Número"
                  id="edit-guardian-number-input"
                  value={value || defaultValue}
                  error={Boolean(error?.type && isZipValid)}
                  helperText={error?.type && isZipValid ? 'Número inválido' : ''}
                  disabled={!edit}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined" margin="normal">
            <Controller
              rules={{
                required: false,
              }}
              name="address.additional_information"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <TextField
                  {...rest}
                  label="Complemento (opcional)"
                  id="edit-guardian-additional-information-input"
                  value={value || defaultValue}
                  disabled={!edit}
                />
              )}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined" margin="normal">
            <Controller
              rules={{ required: true }}
              name="address.city"
              control={control}
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  label="Cidade"
                  id="edit-guardian-city-input"
                  value={value || defaultValue}
                  error={Boolean(error?.type && isZipValid)}
                  helperText={error?.type && isZipValid ? 'Cidade inválida' : ''}
                  disabled={!edit}
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="edit-guardian-state">Estado</InputLabel>
            <Controller
              rules={{ required: true }}
              name="address.state_code"
              control={control}
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <Select
                  {...rest}
                  labelId="edit-guardian-state"
                  label="Estado"
                  value={value || defaultValue}
                  error={Boolean(error?.type && isZipValid)}
                  disabled={!edit}
                >
                  {UFs.map(state => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </S.StyledBox>
      </Collapse>
      <Box paddingRight={3} paddingLeft={2.8}>
        <Divider variant="middle" style={{ margin: '0' }} />
      </Box>
    </Box>
  )
}

export default CollapseAddress
