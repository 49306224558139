import {
  ActionDrawerHeader,
  ButtonDocker,
  DialogContent,
  Divider,
  Drawer,
  Typography,
} from '@olaisaac/design-system'
import { Box } from '@material-ui/core'
import { FC } from 'react'
import dayjs from 'dayjs'

import { formatCentsToReal, isDayBeforeToday } from 'src/shared/utils'
import { prettifyFullName } from 'src/shared/utils/namePrettifiers'
import { CheckoutCombinableReceivable } from '@/escolas/contexts/checkoutContext'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import type { AgreementDetailDrawerProps } from './types'
import { usePaymentCreditCard } from '@/escolas/hooks'

const AgreementDetailDrawer: FC<AgreementDetailDrawerProps> = ({ onClose, isOpen, paymentFee }) => {
  const { agreementSimulations, positionAgreement } = useAgreement()
  const { isCreditCardFlow } = usePaymentCreditCard()

  const agreement = agreementSimulations?.[positionAgreement]
  const studentName = agreement?.receivables?.[0]?.student ?? ''
  const productName = agreement?.receivables?.[0]?.product ?? ''

  const discounts = agreement
    ? agreement.due_payment_discount_applied +
      agreement.early_payment_discount_applied +
      agreement.perpetual_discount_applied
    : 0

  const finesAndInterest = agreement ? agreement.fine_applied + agreement.interest_applied : 0

  const isOverdue = isDayBeforeToday(agreement?.receivables?.[0]?.due_date)

  function getReceivableCurrentAmount(receivable: CheckoutCombinableReceivable) {
    return (
      receivable.amount -
      (receivable.current_perpetual_discount ?? 0) -
      (receivable.current_early_payment_discount ?? 0) -
      (receivable.current_due_payment_discount ?? 0)
    )
  }

  const totalAmount = isCreditCardFlow
    ? agreement?.total_amount + (agreement?.credit_card_fee ?? 0)
    : agreement?.total_amount

  return (
    <Drawer open={isOpen && !!agreement}>
      <ActionDrawerHeader title="Detalhes do pagamento" onClose={onClose} />
      <DialogContent>
        <Box mt={3}>
          <Typography
            withoutMargin
            variation="bodySmall"
            color="secondary"
            style={{ marginBottom: 10 }}
          >
            {prettifyFullName(studentName)}
          </Typography>
          <Typography variation="subtitleDesktopLarge">{productName}</Typography>

          <Divider style={{ marginBottom: 20 }} />

          {agreement?.receivables.map(receivable => (
            <Box key={receivable.id} pb={2} display="flex" justifyContent="space-between">
              <Typography withoutMargin variation="bodyLarge">
                {dayjs(receivable?.competenceDate).utc().format('MMMM')}
              </Typography>
              <Typography withoutMargin variation="bodyLarge">
                {formatCentsToReal(getReceivableCurrentAmount(receivable))}
              </Typography>
            </Box>
          ))}

          <Box pb={2} display="flex" justifyContent="space-between">
            <Typography withoutMargin variation="bodyLarge">
              Multa e Juros
            </Typography>

            <Typography
              withoutMargin
              variation="bodyLarge"
              data-testid="agreement-detail-drawer-fines-and-interest"
            >
              {formatCentsToReal(finesAndInterest)}
            </Typography>
          </Box>

          <Box pb={2} display="flex" justifyContent="space-between">
            <Typography withoutMargin variation="bodyLarge">
              {isOverdue ? 'Desconto de negociação' : 'Descontos'}
            </Typography>

            <Typography
              withoutMargin
              variation="bodyLarge"
              data-testid="agreement-detail-drawer-discounts"
            >
              -{formatCentsToReal(discounts)}
            </Typography>
          </Box>

          {isCreditCardFlow && (
            <Box pb={2} display="flex" justifyContent="space-between">
              <Typography withoutMargin variation="bodyLarge">
                Taxa do cartão
              </Typography>

              <Typography
                withoutMargin
                variation="bodyLarge"
                data-testid="agreement-detail-drawer-credit-card-fee"
              >
                {formatCentsToReal(paymentFee)}
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>

      <ButtonDocker>
        <Box pb={1} display="flex" justifyContent="space-between" width="100%">
          <Typography variation="bodyLarge" withoutMargin>
            Total
          </Typography>
          <Typography
            variation="bodyLarge"
            withoutMargin
            data-testid="agreement-detail-drawer-total"
          >
            {formatCentsToReal(totalAmount)}
          </Typography>
        </Box>
      </ButtonDocker>
    </Drawer>
  )
}

export default AgreementDetailDrawer
