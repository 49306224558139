import { Route, useRouteMatch, Redirect } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'

import ContractRoutes from '@/escolas/router/[schoolSlug]/contratos/router'
import { PendingCharges } from '@/escolas/pages/PendingCharges'
import { Home } from '@/escolas/pages/Home'
import { LoadingBox } from '@/shared/components/LoadingBox'

import { UnleashFlags, useUnleashFlag, useUnleashFlagsStatus } from '@/shared/hooks'
import { useNavigation } from '@/escolas/hooks'

import { ChangeGuardianRoutes } from './contratos/alterar-titularidade'
import { PendingChargesDetails } from '@/escolas/pages/PendingChargesDetails'
import { InstallmentsProvider } from '@/escolas/contexts/installmentsContext'
import { GuardianRoutes as GuardiansModuleRoutes } from '@/modules/guardians/routes'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export default () => {
  const { path } = useRouteMatch()
  const { schoolSlug, school } = useSelectedSchool()
  const { schoolId } = useNavigation()
  const { flagsReady } = useUnleashFlagsStatus()

  const isInadimplenciaPageEnabled = useUnleashFlag(UnleashFlags.INADIMPLENCIA_PAGE_ENABLED)

  const isReady = flagsReady && school && schoolId

  return isReady ? (
    <>
      <Route exact path={`${path}/`}>
        <Home />
      </Route>

      <InstallmentsProvider>
        {isInadimplenciaPageEnabled && (
          <Route exact path={`${path}/validacao-de-inadimplencia`}>
            <PendingCharges />
          </Route>
        )}

        {isInadimplenciaPageEnabled && (
          <Route exact path={`${path}/validacao-de-inadimplencia/:uniqueKey`}>
            <PendingChargesDetails />
          </Route>
        )}
      </InstallmentsProvider>

      <Route path={`${path}/contratos/:referenceYear`}>
        <ContractRoutes />
      </Route>

      <ChangeGuardianRoutes />

      <GuardiansModuleRoutes />

      <Route exact path={path}>
        <Redirect to={`/${schoolSlug}`} />
      </Route>
    </>
  ) : (
    <LoadingBox>
      <CircularProgress />
    </LoadingBox>
  )
}
