import { Text } from '@gravity/text'
import { fontSize, spacing } from '@gravity/tokens'
import styled from 'styled-components'

export const InputErrorText = styled(Text)`
  color: #ef1a0a; // TODO: use the DS color when it's available
`

export const InputWrapper = styled.div<{ hasError: boolean }>`
  > span {
    margin-bottom: ${spacing[2]};
    line-height: 24px;
  }

  ${({ hasError }) =>
    hasError &&
    `
    button, .MuiOutlinedInput-notchedOutline {
      --tw-ring-shadow: 0 0 #000 !important;
      border: 1px solid #ce2c31 !important; // TODO: use the DS color when it's available
    }
    span, .MuiFormHelperText-root {
      color: #903739;  // TODO: use the DS color when it's available
    }
    .MuiFormHelperText-root {
      margin-left: 0px;
      font-size: ${fontSize.sm}
    }
  `}
`
