import { FC } from 'react'
import {
  Button,
  ButtonGroup,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@olaisaac/design-system'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UseFormReturn } from 'react-hook-form'
import styled from 'styled-components'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
  SuccessFeedbackDialog,
  SuccessFeedbackContent,
} from 'src/escolas/components/modal/ConfirmationDialog'
import { omit } from 'ramda'
import { useApi } from 'src/shared/hooks'

type FormDialogProps = {
  disableFeedback?: boolean
  errorMessage?: string
  errorTitle?: string
  form: UseFormReturn<any>
  height?: string
  isVisible: boolean
  maxHeight?: string
  onClose: () => void
  onSubmit?: (form: any, e: React.BaseSyntheticEvent) => Promise<void>
  successMessage?: string
  successTitle?: string
  title: string
}

export const StyledOverlay = styled.div`
  opacity: 0.87;
  position: absolute;
  background: #fff;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledDialog = styled(props => (
  <Dialog {...(omit(['height', 'maxHeight'], props) as DialogProps)} />
))`
  .MuiDialog-paper {
    max-height: ${({ maxHeight }) => maxHeight ?? ''};
    height: ${({ height }) => height ?? ''};
  }
`

const FormDialog: FC<FormDialogProps> = ({
  children,
  disableFeedback,
  form,
  isVisible,
  successMessage,
  successTitle,
  errorMessage,
  errorTitle,
  onClose,
  onSubmit,
  title,
  height,
  maxHeight,
}) => {
  const { setCorrelationId } = useApi()
  if (!form) return null

  const {
    handleSubmit,
    reset,
    formState: { isSubmitted, isSubmitSuccessful, isSubmitting, isValid, isDirty },
  } = form

  const handleClose = () => {
    reset()
    onClose()
    setCorrelationId('')
  }

  const showSuccessFeedback = isSubmitted && isSubmitSuccessful && !isSubmitting && !disableFeedback
  const showFailureFeedback =
    isSubmitted && !isSubmitSuccessful && !isSubmitting && !disableFeedback
  if (showSuccessFeedback) {
    return (
      <SuccessFeedbackDialog
        isVisible={isVisible}
        onClose={handleClose}
        submitHandler={handleClose}
        title={successTitle}
        centered
      >
        <SuccessFeedbackContent message={successMessage} />
      </SuccessFeedbackDialog>
    )
  }
  if (showFailureFeedback) {
    return (
      <FailureFeedbackDialog
        buttonLabel="Voltar"
        isVisible={isVisible}
        onClose={handleClose}
        submitHandler={handleClose}
        title={errorTitle}
        centered
      >
        <FailureFeedbackContent message={errorMessage} />
      </FailureFeedbackDialog>
    )
  }

  return (
    <StyledDialog
      open={isVisible}
      onClose={isSubmitting ? null : handleClose}
      fullWidth
      maxWidth="xs"
      height={height}
      maxHeight={maxHeight}
    >
      {isSubmitting && (
        <StyledOverlay>
          <CircularProgress />
        </StyledOverlay>
      )}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
      </DialogContent>
      <DialogActions>
        <ButtonGroup>
          <Button variation="ghost" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            type="submit"
            variation="primary"
            disabled={isSubmitting || !isValid || !isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            Confirmar
          </Button>
        </ButtonGroup>
      </DialogActions>
    </StyledDialog>
  )
}

export default FormDialog
