import { useParams } from 'react-router-dom'
import { useNavigation } from '@/escolas/hooks'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { useApi } from '@/shared/hooks/useApi'
import { newContractAPI } from '@/modules/contract/services'

import { EditContract } from './EditContract'
import { EditContractV2 } from './EditContractV2'

export const EditContractPage = () => {
  const { contractId } = useParams<{ contractId: uuid }>()
  const { schoolId } = useNavigation()
  const enabledUnifiedContractEditingFF = useUnleashFlag(
    UnleashFlags.ENABLE_UNIFIED_CONTRACT_EDITING
  )
  const enableCancelFF = useUnleashFlag(UnleashFlags.FII_321_ENABLE_CANCEL_INSTALLMENTS)
  const enableCancelContractFF = useUnleashFlag(
    UnleashFlags.FII_394_ENABLE_CANCEL_CONTRACT_VIA_INSTALLMENTS
  )
  const schoolBlockedForCancel = useUnleashFlag(UnleashFlags.B2BCOR_193_BLOCK_CONTRACT_REVOKE)
  const enableEditInstallmentAmount = useUnleashFlag(
    UnleashFlags.FII_329_ENABLE_EDIT_INSTALLMENT_AMOUNT
  )

  const enableCancelEnrollmentContractFF = useUnleashFlag(
    UnleashFlags.EFI_41_ENABLE_EDIT_ENROLLMENT_INSTALLMENTS
  )

  const { apiClient } = useApi()
  const api = newContractAPI(apiClient.privateApi)

  if (enabledUnifiedContractEditingFF) {
    return (
      <EditContractV2
        api={api}
        contractId={contractId}
        schoolId={schoolId}
        enableCancelFF={enableCancelFF}
        enableEditAmountFF={enableEditInstallmentAmount}
        schoolBlockedForCancel={schoolBlockedForCancel}
        enableCancelContractFF={enableCancelContractFF}
        enableEnrolmmentCancelFF={enableCancelEnrollmentContractFF}
      />
    )
  }

  return <EditContract contractId={contractId} schoolId={schoolId} />
}
