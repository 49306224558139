import { Box, FormControl, Typography as MuiTypography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Grid, Table, TableColumns, TextField, Typography } from '@olaisaac/design-system'
import { useContext, useEffect, useState } from 'react'
import { useNavigation } from 'src/escolas/hooks'
import { useApi, useSnackbar } from 'src/shared/hooks'
import { HotjarEvents, useHotjar } from 'src/shared/hooks/useHotjar'
import { ContractStatus, Product } from 'src/shared/interfaces'
import formatListWithKeys from 'src/shared/utils/formatListWithKeys'
import { CampaignContext } from '../../context'
import CampaignDialog from '../../CampaignDialog'
import envConfig from 'src/config'
import { ActionType, CampaignContractWithKey, ContractInfoType } from '../../types'
import { getContractInfo } from '../../utils'
import ContractInfo from './ContractInfo'

const SelectContracts = () => {
  const { dispatch, state } = useContext(CampaignContext)
  const { products, contractList, selectedContractIdsList, nextYearProduct, currentYear } = state
  const [isContractLoading, setIsContractLoading] = useState(false)
  const [isChangeProductDialogOpen, setChangeProductDialogOpen] = useState(false)
  const [productIdToChange, setProductIdToChange] = useState('')
  const { schoolId } = useNavigation()
  const { api } = useApi()
  const label = `Selecione os alunos partir de um produto ${currentYear}`
  const { setIsOpen: setSnackbarIsOpen } = useSnackbar()
  const { sendHotjarEvent } = useHotjar()

  const renderContractInfo = (value: ContractInfoType) =>
    !value.ok && <ContractInfo chipText={value.chipText} tooltipText={value.tooltipText} />

  const tableColumns: TableColumns = [
    {
      headerName: 'Aluno',
      field: 'student',
      renderCell: value => value.name,
    },
    {
      headerName: 'Responsável',
      field: 'guardian',
      renderCell: value => value.name,
    },
    {
      headerName: `Produto ${currentYear}`,
      field: 'product',
      renderCell: value => value.name,
    },
    {
      headerName: '',
      field: 'info',
      renderCell: value => renderContractInfo(value),
    },
  ]

  const handleSelectContract = selectedContracts => {
    setSnackbarIsOpen(false)

    const selectedContractIdsList = selectedContracts.map(contract => contract.id)

    dispatch({
      type: ActionType.UPDATE_CONTRACT_IDS_LIST,
      payload: {
        selectedContractIdsList,
      },
    })
  }

  const selectCurrentYearProduct = (productId: string) => {
    setChangeProductDialogOpen(false)
    handleSelectContract([])

    dispatch({
      type: ActionType.SELECT_CURRENT_YEAR_PRODUCT,
      payload: {
        currentYearProductId: productId,
      },
    })

    setIsContractLoading(true)

    api.contracts
      .fetchByStudent({
        schoolId,
        referenceYear: currentYear,
        filter: {
          productId: [productId],
          contractStatus: [ContractStatus.OPEN],
        },
        pagination: {
          per_page: Number(envConfig.API.PER_PAGE_MAX),
        },
      })
      .then(({ data: contracts }) => {
        setIsContractLoading(false)

        const contractsWithInfo = contracts.map(contract => ({
          ...contract,
          info: getContractInfo(contract),
        }))

        dispatch({
          type: ActionType.UPDATE_CONTRACT_LIST,
          payload: {
            contractList: formatListWithKeys(contractsWithInfo, 'id'),
          },
        })
      })
  }

  const handleSelectCurrentYearProduct = productId => {
    if (selectedContractIdsList?.length > 0) {
      setChangeProductDialogOpen(true)
      setProductIdToChange(productId)
    } else {
      selectCurrentYearProduct(productId)
    }
  }

  const closeChangeProductDialog = () => {
    setChangeProductDialogOpen(false)
  }

  useEffect(() => {
    if (isChangeProductDialogOpen) {
      sendHotjarEvent(HotjarEvents.CAMPAIGN_CHANGE_PRODUCT_ALERT)
    }
  }, [isChangeProductDialogOpen])

  const handleConfirmChangeProduct = () => {
    selectCurrentYearProduct(productIdToChange)
  }

  return (
    <>
      <Grid item xs={4}>
        <Typography>{label}</Typography>
        <FormControl fullWidth variant="outlined">
          <Autocomplete
            data-testid="current-year-product_select"
            id="current-year-product_select"
            options={products}
            getOptionLabel={product => product.name || ''}
            noOptionsText="Nenhum produto encontrado"
            disableClearable
            forcePopupIcon={false}
            onChange={(_, data) => {
              const product = data as Product

              handleSelectCurrentYearProduct(product.id)
            }}
            renderOption={product => <MuiTypography>{product.name}</MuiTypography>}
            renderInput={params => <TextField {...params} label={label} placeholder={label} />}
          />
        </FormControl>
        <Box marginTop="20px">
          <Table<CampaignContractWithKey>
            columns={tableColumns}
            rows={contractList}
            checkable
            rowsSelected={selectedContractIdsList}
            setCheckedRows={handleSelectContract}
            rowDisabled={row => !row.is_reenrollable}
            isRowCheckable={row => row.is_reenrollable}
            isLoading={isContractLoading}
          />
        </Box>
      </Grid>
      <CampaignDialog
        open={isChangeProductDialogOpen}
        title="Tem certeza que seja continuar?"
        content={
          <>
            Você tem <strong>{selectedContractIdsList?.length} alunos</strong> selecionados do{' '}
            <strong>{nextYearProduct?.name}</strong> para serem rematrículados. Ao selecionar outro
            produto com contratos em {currentYear}, você perderá a seleção destes alunos para
            rematrícula.
          </>
        }
        closeText="Cancelar"
        onClose={closeChangeProductDialog}
        confirmText="Sim, trocar de produto"
        onConfirm={handleConfirmChangeProduct}
      />
    </>
  )
}

export default SelectContracts
