import { Box } from '@material-ui/core'
import styled from 'styled-components'

interface ActionsWrapperProps {
  isHovered: boolean
}

export const ActionsWrapper = styled(Box)<ActionsWrapperProps>`
  display: flex;
  flex-direction: row;
  opacity: ${({ isHovered }) => (isHovered ? 1 : 0)};
`
