import { FC, ReactNode, useState } from 'react'
import { Button, Chip, Typography } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'
import { ChevronRight, SaveAlt } from '@material-ui/icons'

import { Card } from 'src/escolas/components/Card'

type ReportCardProps = {
  description?: string | ReactNode
  hideButton?: boolean
  onClickButton: (event) => Promise<void> | void
  subTitle?: string | ReactNode
  title: string
  variant?: 'download' | 'link'
}

export const ReportCard: FC<ReportCardProps> = ({
  title,
  subTitle,
  description,
  hideButton = false,
  onClickButton,
  variant = 'download',
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleClickButton = async event => {
    setIsLoading(true)

    await onClickButton(event)

    setIsLoading(false)
  }

  return (
    <Card backgroundColor="#FCFCFC" borderColor="#F0F0F0">
      <Box padding="2.5rem 3rem" display="flex" flexDirection="column">
        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
          <Box>
            <Typography variation="headlineDesktopXsmall" withoutMargin>
              {title}
            </Typography>
            {!!subTitle &&
              (typeof subTitle === 'string' ? (
                <Box display="flex" alignItems="center" height="2rem">
                  <Typography variation="overline" withoutMargin>
                    {subTitle}
                  </Typography>
                </Box>
              ) : (
                <Box display="flex" flexDirection="column">
                  {subTitle}
                </Box>
              ))}
          </Box>
          {!hideButton ? (
            <Button
              variation="ghost"
              onClick={handleClickButton}
              disabled={isLoading}
              loading={isLoading}
              startIcon={variant === 'download' && <SaveAlt />}
            >
              {variant === 'download' ? 'Baixar' : 'Acessar'}
              {variant === 'link' && <ChevronRight />}
            </Button>
          ) : (
            <div style={{ flex: 1, marginLeft: 16, marginTop: 5 }}>
              <Chip label="Em breve" variation="blue" />
            </div>
          )}
        </Box>

        <Typography variation="bodySmall" style={{ marginTop: 5 }}>
          {description}
        </Typography>
      </Box>
    </Card>
  )
}
