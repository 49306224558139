import { useEffect, useRef, useState } from 'react'

import dayjs from 'dayjs'
import styled from 'styled-components'
import { Controller, FieldErrorsImpl, useForm } from 'react-hook-form'

import { useApi } from '@/shared/hooks'
import { Dialog } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import { Installment } from '@/escolas/services/enrollment/types'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { calcFixedDate, DATE_FORMAT, isDayTodayOrAfter } from 'src/shared/utils'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import {
  Button,
  DatePicker,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@olaisaac/design-system'

import { ChipButton } from './ChipButton'
import { validateDatesInUniqueMonths } from '../utils'
import { SelectedInstallmentsText } from './SelectedInstallmentsText'

const TextContainer = styled.div`
  margin-bottom: 24px;
`

const SectionWrapper = styled.section`
  margin: 24px 0;
`

const DayModeWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`

enum Field {
  DUE_DATE = 'due_date',
  FIXED_DAY = 'FIXED_DAY',
  WORKING_DAY = 'WORKING_DAY',
}

export type EditDueDateForm = {
  [Field.DUE_DATE]: datestring
  [Field.FIXED_DAY]: number | ''
  [Field.WORKING_DAY]: number | ''
}

const REQUIRED_MESSAGES = {
  [Field.DUE_DATE]: 'Por favor, informe uma data para o vencimento.',
  [Field.FIXED_DAY]: 'Por favor, informe um dia fixo para o vencimento.',
  [Field.WORKING_DAY]: 'Por favor, informe um dia útil para o vencimento.',
}

const DATES_IN_THE_PAST_MESSAGE =
  'Uma das parcelas selecionadas pertence ao mês atual. Para continuar, por favor, insira um dia posterior ao dia de hoje ou desmarque a parcela do mês atual e realize a alteração separadamente.'
const DATES_IN_THE_SAME_MONTH_MESSAGE = 'Mais de um vencimento no mesmo mês.'

const errorProps = (errors: Partial<FieldErrorsImpl<EditDueDateForm>>, field: Field) => {
  return {
    error: Boolean(errors[field]),
    helperText: errors[field] ? errors[field].message : '',
  }
}

/**
 * @deprecated This component will be deprecated after the EEMAT-727-ENABLE_UNIFIED_CONTRACT_EDITING rollout
 */
export const EditDueDateDialog = ({
  onClose,
  onConfirm,
  selectedInstallments,
  allInstallments,
}: {
  allInstallments: Installment[]
  onClose: () => void
  onConfirm?: (dueDates: datestring[], workingDayMode: boolean) => void
  selectedInstallments: Installment[]
}) => {
  const [workingDayMode, setWorkingDayMode] = useState(false)
  const isSingleInstallment = selectedInstallments.length === 1
  const { api } = useApi()
  const dueDates = useRef([])
  const [isLoading, setIsLoading] = useState(false)
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const selectedInstallment = selectedInstallments[0]
  const minDateSingleInstallment = dayjs(selectedInstallment.due_date)
    .startOf('month')
    .toISOString()
  const maxDateSingleInstallment = dayjs(selectedInstallment.due_date).endOf('month').toISOString()

  useEffect(() => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_EDIT,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: 'Editar vencimentos',
          $type_of_installment: selectedInstallments[0].type,
        },
      })
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<EditDueDateForm>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      [Field.DUE_DATE]: dayjs(selectedInstallment.due_date).toISOString(),
      [Field.FIXED_DAY]: '',
      [Field.WORKING_DAY]: '',
    },
  })

  const validateFixedDates = async () => {
    const dates = selectedInstallments.map(i =>
      calcFixedDate(dayjs(i.due_date).utc(), getValues().FIXED_DAY || 0).toISOString()
    )

    dueDates.current = dates

    return dates.every(d => isDayTodayOrAfter(dayjs(d)))
  }

  const validateWorkingDates = () => {
    return dueDates.current.every(d => isDayTodayOrAfter(dayjs(d)))
  }

  const calculateWorkingDates = async () => {
    setIsLoading(true)
    try {
      const dates = (
        await Promise.all(
          selectedInstallments.map(i =>
            api.date.getWorkingDateFromNthDay({
              date: dayjs(i.due_date).utc().toISOString(),
              nth_working_day: getValues().WORKING_DAY || 0,
            })
          )
        )
      ).map(d => d.data)

      dueDates.current = dates

      setIsLoading(false)
      return true
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      return false
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(() => onConfirm(dueDates.current, workingDayMode))}>
        <DialogTitle>Editar vencimento</DialogTitle>
        <DialogContent>
          <TextContainer>
            <SelectedInstallmentsText count={selectedInstallments.length} />
          </TextContainer>

          {isSingleInstallment && (
            <Controller
              name={Field.DUE_DATE}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.DUE_DATE],
                validate: {
                  minValue: v =>
                    isDayTodayOrAfter(dayjs(v)) ||
                    'Você precisa inserir uma data posterior a data de hoje.',
                  sameMonth: v => {
                    const otherInstallments = allInstallments.filter(
                      i =>
                        i.id !== selectedInstallments[0].id &&
                        i.type === selectedInstallments[0].type
                    )
                    const otherDueDates = otherInstallments.map(i => i.due_date)

                    return (
                      validateDatesInUniqueMonths([v, ...otherDueDates]) ||
                      'Já existe uma parcela para o mês selecionado.'
                    )
                  },
                  maxValue: v => {
                    const currentDate = dayjs(selectedInstallment.due_date)
                    const maxDate = dayjs(maxDateSingleInstallment)

                    return (
                      dayjs(v).isBefore(maxDate) ||
                      `A data de vencimento desta parcela, que é ${currentDate.format(
                        'DD/MM/YYYY'
                      )}, pode ser alterada no máximo para ${maxDate.format('DD/MM/YYYY')}.`
                    )
                  },
                },
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl variant="outlined">
                  <DatePicker
                    id={Field.DUE_DATE}
                    value={value}
                    onChange={e => {
                      if (e !== null && e.isValid()) {
                        dueDates.current = [e.toISOString()]
                      }

                      onChange(e)

                      isInitialized &&
                        eventDispatcherClient.sendEvent({
                          scope: EventDispatcherEventScopes.ENROLLMENT_EDIT,
                          name: EventDispatcherEvents.DATEPICKER_CLICK,
                          action: 'click',
                          customProperties: {
                            $datepicker_name: 'Data de vencimento',
                            $modal_name: 'Vencimento',
                          },
                        })
                    }}
                    label="Data de vencimento"
                    format={DATE_FORMAT}
                    disablePast
                    minDate={minDateSingleInstallment}
                    maxDate={maxDateSingleInstallment}
                    {...errorProps(errors, Field.DUE_DATE)}
                  />
                </FormControl>
              )}
            />
          )}

          {!isSingleInstallment && (
            <SectionWrapper>
              <Typography variation="bodyLarge">Regra de vencimento</Typography>
              <Typography variation="caption" color="secondary">
                Selecione a regra e dia de vencimento
              </Typography>
              <DayModeWrapper>
                <ChipButton
                  label="Vencimento por dia fixo"
                  onClick={() => setWorkingDayMode(false)}
                  $isActive={!workingDayMode}
                />
                <ChipButton
                  label="Vencimento por dia útil"
                  onClick={() => setWorkingDayMode(true)}
                  $isActive={workingDayMode}
                />
              </DayModeWrapper>
            </SectionWrapper>
          )}

          {!workingDayMode && !isSingleInstallment && (
            <Controller
              name={Field.FIXED_DAY}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.FIXED_DAY],
                validate: {
                  minValue: v => Number(v) > 0 || 'O número de dias deve ser maior que zero',
                  maxValue: v =>
                    Number(v) <= 31 ||
                    'O dia fixo deve ser maior que zero e igual ou inferior a 31',
                  dateOnThePast: async () => {
                    return (await validateFixedDates()) || DATES_IN_THE_PAST_MESSAGE // >>> TODO: review text with design
                  },
                  sameMonth: () =>
                    validateDatesInUniqueMonths(dueDates.current) ||
                    DATES_IN_THE_SAME_MONTH_MESSAGE,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    value={value}
                    onChange={onChange}
                    id={Field.FIXED_DAY}
                    type="number"
                    label="Dia fixo"
                    {...errorProps(errors, Field.FIXED_DAY)}
                  />
                </FormControl>
              )}
            />
          )}

          {workingDayMode && !isSingleInstallment && (
            <Controller
              name={Field.WORKING_DAY}
              control={control}
              rules={{
                required: REQUIRED_MESSAGES[Field.WORKING_DAY],
                validate: {
                  minValue: v => Number(v) > 0 || 'O número de dias deve ser maior que zero',
                  maxValue: v =>
                    Number(v) <= 25 ||
                    'O dia útil deve ser maior que zero e igual ou inferior a 25',
                  calcDates: async () => {
                    return (await calculateWorkingDates()) || 'Erro ao calcular datas' // >>> TODO: review text with design
                  },
                  dateOnThePast: () => validateWorkingDates() || DATES_IN_THE_PAST_MESSAGE,
                  sameMonth: () =>
                    validateDatesInUniqueMonths(dueDates.current) ||
                    DATES_IN_THE_SAME_MONTH_MESSAGE,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    value={value}
                    onChange={onChange}
                    id={Field.WORKING_DAY}
                    type="number"
                    label="Dia útil"
                    error={Boolean(errors[Field.WORKING_DAY])}
                    helperText={
                      isLoading
                        ? 'Calculando datas...'
                        : errors[Field.WORKING_DAY]
                        ? errors[Field.WORKING_DAY].message
                        : ''
                    }
                  />
                </FormControl>
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variation="ghost" onClick={onClose}>
            Cancelar
          </Button>
          <Button variation="primary" type="submit">
            Aplicar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
