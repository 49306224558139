import { colors, spacing, borderRadius } from '@gravity/tokens'
import { CircularProgress as MaterialCircularProgress } from '@material-ui/core'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: ${spacing[6]};
`

export const Box = styled.div`
  gap: ${spacing[2]};
  display: flex;
  flex-direction: column;
  background-color: ${colors['colors-border-neutral-2']};
  border-radius: ${borderRadius[2]};
  padding: ${spacing[6]};
`

export const Footer = styled.div`
  margin-top: auto;
  padding-top: ${spacing[8]};
  display: flex;
  justify-content: end;
`

export const CircularProgress = styled(MaterialCircularProgress)`
  color: #fff;
  width: 20px;
  height: 20px;
`
