import { Table } from '@gravity/table'

export const LoadingState = () => {
  return (
    <>
      {Array.from(Array(8), (_, i) => i).map(row => (
        <Table.Row key={row} style={{ pointerEvents: 'none' }}>
          <Table.LoadingCell width={313} />
          <Table.LoadingCell width={212} />
          <Table.LoadingCell width={244} />
          <Table.LoadingCell width={284} />
          <Table.LoadingCell width={244} />
          <Table.LoadingCell width={277} />
          <Table.LoadingCell />
        </Table.Row>
      ))}
    </>
  )
}
