import { InstallmentType } from '@/shared/interfaces'
import { Chip, ChipProps } from '@olaisaac/design-system'

export const toProps: PartialRecord<InstallmentType, Pick<ChipProps, 'variation' | 'label'>> = {
  [InstallmentType.ENROLLMENT]: { variation: 'yellow', label: 'Pré-matrícula' },
  [InstallmentType.TUITION]: { variation: 'blue', label: 'Mensalidade' },
}

export type Props = {
  type: InstallmentType
}

export const InstallmentTypeChip = ({ type }: Props) => {
  if (!toProps[type]) {
    return null
  }

  return <Chip {...toProps[type]} />
}
