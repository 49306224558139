import styled from 'styled-components'

import { spacing } from '@gravity/tokens'
import { Button } from '@gravity/button'

export const ButtonGroup = styled.div`
  display: flex;
  direction: column;
  align-items: center;
  margin-top: ${spacing[10]};
  gap: ${spacing[3]};
`

export const GhostButton = styled(Button)`
  min-width: 186px;
`
