import { Box } from '@material-ui/core'
import { Grid, Typography } from '@olaisaac/design-system'
import RouterBreadcrumbs from 'src/escolas/components/RouterBreadcrumbs'
import { SchoolName } from 'src/escolas/components/SchoolName'
import { Product } from 'src/shared/interfaces'

type CampaignHeaderProps = {
  nextYearProduct?: Product
}

const CampaignHeader = ({ nextYearProduct = null }: CampaignHeaderProps) => (
  <>
    <SchoolName />
    <RouterBreadcrumbs />

    <Grid container justifyContent="space-between">
      <Grid item xs={2}>
        <Box>
          <Typography variation="headlineDesktopMedium" style={{ margin: 0 }}>
            Rematrícula em lote
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={2}>
        {nextYearProduct && (
          <Box textAlign="right">
            <Typography>
              Rematriculando em <strong>{nextYearProduct.name}</strong>
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  </>
)

export default CampaignHeader
