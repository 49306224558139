import theme from '@/shared/theme'
import { Box, CircularProgress } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

const { spacing } = theme.primitiveTokens

export const TableContainer = styled(Box)`
  margin-bottom: ${({ theme }) => theme.primitiveTokens.spacing[20]};
  overflow-x: auto;

  & table > tbody > tr > td,
  table > thead > tr > th {
    max-width: 160px;

    &:last-child {
      max-width: min-content;
    }
  }

  & tbody tr {
    cursor: pointer;
  }

  & [class*='row-disabled'] {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[5]};
    cursor: default;
    .MuiTableCell-root {
      border-top: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
      border-bottom: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
    }
  }

  .MuiTableCell-root {
    border-top: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[5]};
  }
`

export const StyledBox = styled(Box)`
  width: 100%;
  min-height: 48px;
  background-color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
  display: flex;
  align-items: center;
`

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.primitiveTokens.colors.gray[0]};
  font-weight: 700;
  margin-left: ${spacing[3]};
  line-height: 1.5;
`
export const StyledCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.primitiveTokens.colors.gray[0]};
  max-width: 18px;
  max-height: 18px;
  margin-left: ${spacing[5]};
`
