import { Box } from '@material-ui/core'

import { CommunicationSection } from './components/CommunicationSection'

import { MainInfoSection } from './components/MainInfoSection'
import { QuickAccessSection } from './components/QuickAccessSection'
import { LearnMoreSection } from './components/LearnMoreSection'

import { useJWT } from '@/shared/hooks/useJWT'
import { useLayout } from '@/shared/hooks/useLayout'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { isIsaacPaySchool } from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'

export const Home = () => {
  const { hasPayoutReportAccess } = useJWT()
  const { school } = useSelectedSchool()

  const isIsaacPay = isIsaacPaySchool(school)
  const showMainInfoSection = hasPayoutReportAccess && !isIsaacPay

  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: 'Página inicial',
  })

  return (
    <Box paddingTop="2.5rem">
      <CommunicationSection />
      <QuickAccessSection />
      {showMainInfoSection && <MainInfoSection />}
      <LearnMoreSection />
    </Box>
  )
}
