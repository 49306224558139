import { useState } from 'react'
import { CardInfo } from './components'
import { StyledContainer, StyledButton, StyledButtonContainer } from './styles'
import { GuardianDetailsCardProps } from './types'
import { StudentsCarousel } from '../StudentsCarousel'
import {
  KeyboardArrowDownRounded as KeyboardArrowDownIcon,
  KeyboardArrowUpRounded as KeyboardArrowUpIcon,
} from '@material-ui/icons'

export const GuardianDetailsCard: React.FC<GuardianDetailsCardProps> = ({
  guardian,
  isLoading,
  openEditRegistrationDrawer,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const Icon = isExpanded ? KeyboardArrowUpIcon : KeyboardArrowDownIcon
  const buttonLabel = isExpanded ? 'Ver menos' : 'Ver mais'

  return (
    <StyledContainer>
      <CardInfo
        guardian={{
          cpf: guardian?.tax_id,
          email: guardian?.email,
          guardianName: guardian?.name,
          phone: guardian?.phone_number,
          debtStatus: guardian?.debt_status,
        }}
        isLoading={isLoading}
        openEditRegistrationDrawer={openEditRegistrationDrawer}
      />
      <StudentsCarousel isExpanded={isExpanded} />
      {!isLoading && (
        <StyledButtonContainer>
          <StyledButton
            aria-label="expand-student-carousel"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <Icon fontSize="small" />
            {buttonLabel}
          </StyledButton>
        </StyledButtonContainer>
      )}
    </StyledContainer>
  )
}
