import dayjs from 'dayjs'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Select } from '@gravity/select'
import { TextField } from '@gravity/text-field'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import { Box, Grid, Tooltip } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { mdiInformationSlabCircleOutline } from '@mdi/js'
import Icon from '@mdi/react'
import { TooltipButton } from '@olaisaac/design-system'

import { formatDate } from '@/shared/utils'
import { StyledInfoOutlinedIcon } from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/InstallmentFullStatus/styles'
import { useCampaignContext } from '@/modules/enrollment/contexts/CampaignContext'
import { StepFooter } from '@/modules/enrollment/components/Stepper/components/StepFooter'
import { StepPageProps } from '@/modules/enrollment/components/Stepper/types'

import { SelectProductInput } from './components/SelectedProductInput'
import { FormProps } from './types'
import * as Styled from './styles'
import { OfferList } from './components/OfferList'
import { InputWrapper } from '../styles'

export const PaymentConditionsStep = ({ onFinish, enrollmentCycle }: StepPageProps) => {
  const { setConditions, conditions } = useCampaignContext()

  const tomorrowDate = dayjs().add(1, 'day').toDate()
  const lastDayOfYearDate = dayjs(`${enrollmentCycle.reference_year}-12-01`).endOf('month').toDate()

  const form = useForm<FormProps>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: conditions ?? {
      name: '',
      product: null,
      advancePaymentInstallment: '',
      end_date: null,
      offers: [],
    },
  })

  form.watch(['advancePaymentInstallment', 'product'])
  const { advancePaymentInstallment, product } = form.getValues()

  const handleSubmit = (data: FormProps) => {
    setConditions(data)
    onFinish()
  }

  return (
    <Styled.Form onSubmit={form.handleSubmit(handleSubmit)}>
      <Heading variant="heading-h3-medium">Condições</Heading>

      <Box marginTop={2}>
        <Text variant="body-1-regular">
          {`Defina o produto de ${enrollmentCycle.reference_year} e as condições da pré-matrícula.`}
        </Text>
        <Box marginTop={4} />

        <Styled.TextFieldWrapper>
          <Controller
            rules={{
              required: true,
            }}
            name="name"
            control={form.control}
            render={({ field, fieldState }) => (
              <TextField
                label="Qual o nome da campanha?"
                aria-label="Qual o nome da campanha?"
                placeholder="Digite o nome da campanha"
                size={3}
                width="100%"
                name={field.name}
                value={field.value}
                // eslint-disable-next-line react/jsx-handler-names
                onChange={field.onChange}
                error={!!fieldState.error?.type}
                errorMessage={fieldState.error?.type ? 'Insira um nome para a campanha' : ''}
              />
            )}
          />
        </Styled.TextFieldWrapper>

        <SelectProductInput form={form} referenceYear={enrollmentCycle.reference_year.toString()} />

        <Box marginTop={3}>
          {product && (
            <Grid container spacing={6}>
              <Grid item sm={6}>
                <Controller
                  rules={{
                    required: true,
                  }}
                  name="advancePaymentInstallment"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <Styled.SelectWrapper data-testid="advance-payment-installment-wrapper">
                      <InputWrapper hasError={!!fieldState.error?.type}>
                        <Box>
                          <Text variant="subtitle-medium">Possui parcela de pré-matrícula?</Text>{' '}
                          <Tooltip
                            title="Você pode coletar apenas o interesse de rematrícula dos responsáveis ou enviar também o boleto de pré-matrícula."
                            style={{ cursor: 'pointer' }}
                          >
                            <TooltipButton style={{ width: 'auto' }}>
                              <StyledInfoOutlinedIcon color="action" fontSize="small" />
                            </TooltipButton>
                          </Tooltip>
                        </Box>
                        <Select
                          size={3}
                          variant="surface"
                          label=""
                          options={[
                            {
                              label: 'Sim',
                              value: 'Sim',
                            },
                            {
                              label: 'Não',
                              value: 'Não',
                            },
                          ]}
                          value={field.value}
                          placeholder="Selecione uma opção"
                          // eslint-disable-next-line react/jsx-handler-names
                          onValueChange={field.onChange}
                        />
                      </InputWrapper>
                    </Styled.SelectWrapper>
                  )}
                />
              </Grid>

              <Grid item sm={6}>
                <Controller
                  rules={{
                    required: true,
                  }}
                  name="end_date"
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      hasError={!!fieldState.error?.type && advancePaymentInstallment !== 'Sim'}
                    >
                      <Datepicker.Root>
                        <Box>
                          <Text variant="subtitle-medium" id="campaign-due-date-label">
                            Qual data de validade da campanha?
                          </Text>
                          <Tooltip
                            title={
                              advancePaymentInstallment === 'Sim'
                                ? 'A data de validade da campanha corresponde à maior data de validade entre as condições de pagamento. Após o encerramento da campanha, todas as propostas não aceitas serão canceladas e removidas do meuisaac.'
                                : 'Após o término da campanha, todas as propostas não aceitas serão canceladas e removidas do meuisaac.'
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            <TooltipButton style={{ width: 'auto' }}>
                              <StyledInfoOutlinedIcon color="action" fontSize="small" />
                            </TooltipButton>
                          </Tooltip>
                        </Box>
                        <Styled.DatepickerTrigger
                          disabled={advancePaymentInstallment === 'Sim'}
                          size={3}
                          aria-labelledby="campaign-due-date-label"
                          placeholder="Selecione a data"
                        >
                          {field.value ? formatDate(field.value, 'DD/MM/YYYY') : null}
                        </Styled.DatepickerTrigger>
                        <Datepicker.Calendar
                          value={field.value}
                          minDate={tomorrowDate}
                          maxDate={lastDayOfYearDate}
                          minDetail="decade"
                          onChange={date => field.onChange(date)}
                        />
                      </Datepicker.Root>
                    </InputWrapper>
                  )}
                />
              </Grid>
            </Grid>
          )}
          <Styled.OfferInfoWrapper>
            {advancePaymentInstallment === 'Sim' && (
              <Text variant="body-1-regular">
                Você pode definir até três condições de pagamento para a taxa de pré-matrícula. O
                valor do boleto é automaticamente ajustado se o responsável não efetuar o pagamento
                até a data especificada.
              </Text>
            )}

            {advancePaymentInstallment === 'Não' && (
              <Styled.AlertBox icon={<Icon path={mdiInformationSlabCircleOutline} />}>
                Lembre-se: campanhas sem parcela de pré-matrícula ainda necessitam da adição das
                parcelas de mensalidade no momento da criação do contrato!
              </Styled.AlertBox>
            )}
          </Styled.OfferInfoWrapper>
          {product && advancePaymentInstallment === 'Sim' && (
            <OfferList form={form} referenceYear={enrollmentCycle.reference_year} />
          )}
        </Box>
      </Box>

      <StepFooter />
    </Styled.Form>
  )
}
