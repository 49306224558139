export default {
  API: {
    URL: `[REACT_APP_API_HOST]`,
    GOOGLE_CLIENT_ID: `[REACT_APP_GOOGLE_CLIENT_ID]`,
    COOKIES_DOMAIN: `[REACT_APP_COOKIES_DOMAIN]`,
    PER_PAGE_MAX: `[REACT_APP_API_PER_PAGE_MAX]`,
    BACKOFFICE_MOCK_BASEURL: `[REACT_APP_BACKOFFICE_MOCK_BASEURL]`,
  },
  DEFAULT_CONTRACT_YEAR: `[REACT_APP_DEFAULT_CONTRACT_YEAR]`,
  CHECK_CONTRACT_DUPLICATION_ENABLED: `[REACT_APP_CHECK_CONTRACT_DUPLICATION_ENABLED]`,
  GIT_TAG: `[REACT_APP_GIT_TAG]`,
  HOTJAR_ID: `[REACT_APP_HOTJAR_ID]`,
  HOTJAR_SNIPPET_VERSION: `[REACT_APP_HOTJAR_SNIPPET_VERSION]`,
  REACT_APP_AGGLUTINATION_ENABLED: `[REACT_APP_AGGLUTINATION_ENABLED]`,
  SELF_ONBOARDING_REDIRECT_URL: `[REACT_APP_SELF_ONBOARDING_REDIRECT_URL]`,
  SENTRY_ENABLED: `[REACT_APP_SENTRY_ENABLED]`,
  SORTING_HAT: {
    REALM: `[REACT_APP_SORTING_HAT_REALM]`,
    HOST: `[REACT_APP_SORTING_HAT_HOST]`,
    LOGIN_PATH: `[REACT_APP_SORTING_HAT_LOGIN_PATH]`,
    GUARDIAN_SORTING_HAT_LOGIN: `[REACT_APP_GUARDIAN_SORTING_HAT_LOGIN]`,
    ADMIN_AZP: `[REACT_APP_SORTING_HAT_ADMIN_AZP]`,
    SCHOOL_AZP: `[REACT_APP_SORTING_HAT_SCHOOL_AZP]`,
    GUARDIAN_AZP: `[REACT_APP_SORTING_HAT_GUARDIAN_AZP]`,
    APP_AZP: `[REACT_APP_SORTING_HAT_APP_AZP]`,
  },
  MAX_INSTALLMENT_ENROLLMENT_CHECKOUT: `[REACT_APP_MAX_INSTALLMENT_ENROLLMENT_CHECKOUT]`,
  MANUAL_LIQUIDATION_PILOT_SCHOOLS_FEATURE_FLAG: `[REACT_APP_MANUAL_LIQUIDATION_PILOT_SCHOOLS_FEATURE_FLAG]`,
  MAX_DAYS_TO_CHANGE_CONTRACT_DUE_DAY: `[REACT_APP_MAX_DAYS_TO_CHANGE_CONTRACT_DUE_DAY]`,
  DISABLE_CHECKOUT_BUTTON: `[REACT_APP_DISABLE_CHECKOUT_BUTTON]`,
  CHECKOUT_BUTTON_ERROR_MESSAGE: `[REACT_APP_CHECKOUT_BUTTON_ERROR_MESSAGE]`,
  BACKOFFICE_MOCK_BASEURL: `[REACT_APP_BACKOFFICE_MOCK_BASEURL]`,
   UNLEASH: {
    PROXY_KEY: `[REACT_APP_UNLEASH_EDGE_TOKEN]`,
    PROXY_URL: `[REACT_APP_UNLEASH_EDGE_URL]`,
    REFRESH_INTERVAL: `[REACT_APP_UNLEASH_REFRESH_INTERVAL]`,
  },
  CHECKOUT_POLLING_INTERVAL: `[REACT_APP_CHECKOUT_POLLING_INTERVAL]`,
  DISABLE_ONBOARDING_REDIRECT: `[REACT_APP_DISABLE_ONBOARDING_REDIRECT]`,
  ENV: `[REACT_APP_ENV]`,
  USERGUIDING_ID: `[REACT_APP_USERGUIDING_ID]`,
  CSS_TELEPHONE: `[REACT_APP_CSS_TELEPHONE]`,
  EVENT_DISPATCHER: {
    PORTAL_EVENTS: {
      HOST: `[REACT_APP_EVENT_HOST]`,
      ENABLED: `[REACT_APP_EVENT_ENABLED]`,
    },
    MIXPANEL: {
      ENABLED: `[REACT_APP_MIXPANEL_ENABLED]`,
      ENABLE_DEBUG_LOGS: `[REACT_APP_MIXPANEL_ENABLE_DEBUG_LOGS]`,
      PROJECT_TOKEN: `[REACT_APP_MIXPANEL_PROJECT_TOKEN]`,
    },
  },
  BFF_API_URL: `[REACT_APP_BFF_API_URL]`,
  BEAMER: {
    HOST: `[REACT_APP_BEAMER_API_HOST]`,
    KEY: `[REACT_APP_BEAMER_API_KEY]`,
  },
}
