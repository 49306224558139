import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { includes } from 'ramda'
import { Grid } from '@olaisaac/design-system'

import { useNavigation, useQuery } from '@/escolas/hooks'
import { useJWT, useUnleashFlag, UnleashFlags } from '@/shared/hooks'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import useUserGuiding from '@/escolas/router/responsavel/[guardianId]/contratos/hooks/useUserGuiding'

import { CONTRACT_TABLE_TAB } from '@/escolas/router/responsavel/[guardianId]/contratos/constants'

import { InstallmentsDrawerContainer } from '../InstallmentsDrawerContainer/InstallmentsDrawerContainer'
import { useGuardianFiltersOptionsQuery } from '../GuardianDetails/hooks/useGuardianFiltersOptionsQuery'
import { Agglutination } from '../GuardianDetails/features/Agglutination/Agglutination'
import {
  InstallmentRowType,
  InstallmentStatusDescription,
  InstallmentType,
} from '../GuardianDetails/constants'
import { NegotiationDrawer } from '../InstallmentsDrawerContainer/NegotiationDrawer/NegotiationDrawer'
import { InstallmentsDrawerContainerV2 } from '../InstallmentsDrawerContainer/InstallmentsDrawerContainerV2'
import { useGuardiansInstallmentsQuery } from './hooks/useGuardiansInstallmentsQuery'
import { useGuardiansInstallmentsQueryParams } from './hooks/useGuardiansInstallmentsQueryParams'
import { GuardianDetailsInstallmentsTable } from './components/GuardianDetailsInstallmentsTable/GuardianDetailsInstallmentsTable'
import { GuardianDetailsInstallmentsFilter } from './components/GuardianDetailsInstallmentsFilter/GuardianDetailsInstallmentsFilter'
import { UpdateEnrollmentStatus } from './components/UpdateEnrollmentStatus/UpdateEnrollmentStatus'
import { getUniqueBufferInstallmentsByStudent } from './utils/getUniqueBufferInstallmentsOfStudent'
import { Row } from './components/GuardianDetailsInstallmentsTable/types'
import { Installment } from '../GuardianDetails/types'
import { DEFAULT_AWAITING_TIME } from '../constants'
import {
  TrackDrawerClickEvents,
  useGuardiansInstallmentsTabEvents,
} from './hooks/useGuardiansInstallmentsTabEvents'
import { GuardianDetailsDisclaimerModal } from './components/GuardianDetailsDisclaimerModal/GuardianDetailsDisclaimerModal'
import { isTimeExpired } from './utils/getTimeExpiration'
import { storeModalShownDate, getModalShownDate } from './utils/infoFromSession'
import { SHOW_MODAL_AGAIN_IN_HOURS } from './constants'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { CloseReason } from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/GuardianDetailsDisclaimerModal/types'
import { useInstallmentDrawerContext } from '@/modules/guardians/contexts/InstallmentDrawerContext'

type GuardianDetailsInstallmentsTabProps = {
  setShowPendingEnrollmentProcessingAlert: Dispatch<SetStateAction<boolean>>
  showGeneratingInvoicesLoading: boolean
}

export const GuardianDetailsInstallmentsTab = ({
  showGeneratingInvoicesLoading,
  setShowPendingEnrollmentProcessingAlert,
}: GuardianDetailsInstallmentsTabProps) => {
  const { isAdmin, userId, name, email } = useJWT()
  const { schoolId } = useNavigation()
  const { query, setOnQueryParam } = useQuery()
  const queryClient = useQueryClient()
  const { guardianId } = useParams<{ guardianId: string }>()

  const { selectedInstallment, setSelectedInstallment } = useInstallmentDrawerContext()

  const [selectedNegotiationInstallment, setSelectedNegotiationInstallment] = useState<
    Partial<Installment>
  >(null)

  const [showNewInstallmentsDetailsDrawer, setNewShowInstallmentsDetailsDrawer] = useState(false)
  const [showInstallmentsDetailsDrawer, setShowInstallmentsDetailsDrawer] = useState(false)
  const [showNegotiationDrawer, setShowNegotiationDrawer] = useState(false)
  const [refetchCount, setRefetchCount] = useState(0)

  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false)

  const { filters: installmentsFilters, updateFilters } = useGuardiansInstallmentsQueryParams()
  const { trackRowClick, trackDrawerClick } = useGuardiansInstallmentsTabEvents()

  const {
    filterOptions,
    isFiltersOptionsFetched,
    refetchFiltersOptions,
  } = useGuardianFiltersOptionsQuery({
    guardianId,
    schoolId,
  })

  const {
    guardiansInstallments,
    isFetchGuardiansInstallmentsLoading,
    refetchGuardiansInstallments,
    isRefetchingGuardiansInstallments,
    isFetchGuardiansInstallmentsFetched,
  } = useGuardiansInstallmentsQuery({
    filter: installmentsFilters,
    guardianId,
    schoolId,
  })

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const checkPendingEnrollProcessing = (data: Installment[]) => {
    const pendingEnrollProcessing = data.reduce((result, installment) => {
      return (
        result ||
        installment.status_description === InstallmentStatusDescription.PENDING_ENROLL_PROCESSING
      )
    }, false)
    if (pendingEnrollProcessing && refetchCount < 3) {
      setTimeout(() => {
        queryClient.invalidateQueries(['guardian-details-installments'])
        setRefetchCount(refetchCount + 1)
        refetchGuardiansInstallments()
      }, DEFAULT_AWAITING_TIME)
    } else if (pendingEnrollProcessing && refetchCount === 3) {
      setShowPendingEnrollmentProcessingAlert(true)
    }
  }

  const isRenegotiateAndAgglutinateButtonEnabled = useUnleashFlag(
    UnleashFlags.REG_2042_ENABLE_RENEGOTIATE_AND_AGGLUTINATE_BUTTONS
  )

  const isNewInstallmentDrawerEnabled = useUnleashFlag(
    UnleashFlags.REG_2064_ENABLE_NEW_INSTALLMENTS_DRAWER
  )

  useEffect(() => {
    const whenModalBeenShown = getModalShownDate(guardianId)
    if (!guardiansInstallments?.data && !isFetchGuardiansInstallmentsFetched) return
    checkPendingEnrollProcessing(guardiansInstallments?.data)
    const hasDownPaymentInstallment = guardiansInstallments?.data.some(
      installment => installment.type === InstallmentType.DOWN_PAYMENT
    )

    if (
      hasDownPaymentInstallment &&
      isTimeExpired(whenModalBeenShown, SHOW_MODAL_AGAIN_IN_HOURS) &&
      !isAdmin
    ) {
      setShowDisclaimerModal(true)
      isInitialized &&
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.INSTALLMENTS_LIST,
          entity: EventDispatcherEntities.OPEN_NEGOTIATION_MODAL,
          customProperties: {
            $name: 'Responsável com negociação em andamento',
          },
          name: EventDispatcherEvents.MODAL_VIEWED,
          action: 'component_view',
        })
    }
  }, [guardiansInstallments, isFetchGuardiansInstallmentsFetched, refetchCount])
  const shouldRefetch = Boolean(query.get('refetch'))

  useEffect(() => {
    if (shouldRefetch) {
      setOnQueryParam(null, 'refetch')
      queryClient.invalidateQueries(['contract'])
      refetchFiltersOptions()
      refetchGuardiansInstallments()
    }
  }, [shouldRefetch])

  const selectedInstallmentHasError = Boolean(selectedInstallment?.invoice?.err_description)

  useEffect(() => {
    if (selectedInstallment) {
      if (isNewInstallmentDrawerEnabled) {
        setNewShowInstallmentsDetailsDrawer(true)
      } else {
        setShowInstallmentsDetailsDrawer(true)
      }
    }
  }, [selectedInstallment])

  // --- AgreementUserGuiding
  useUserGuiding({
    userId,
    email,
    name,
    isAdmin,
    isLoading: isFetchGuardiansInstallmentsLoading,
    activeTab: CONTRACT_TABLE_TAB.INSTALLMENTS,
  })
  // ---

  // --- Aglutinação
  const [showAgglutinationDrawer, setShowAgglutinationDrawer] = useState<boolean>(false)

  const closeAgglutinationDrawer = () => {
    setShowAgglutinationDrawer(false)
  }

  const handleCloseInstallmentsDetailsDrawer = () => {
    setSelectedInstallment(null)
    setSelectedNegotiationInstallment(null)
    setShowInstallmentsDetailsDrawer(false)
    setNewShowInstallmentsDetailsDrawer(false)
    showAgglutinationDrawer && closeAgglutinationDrawer()
  }
  // ---

  const handleTableRowClick = (row: Row) => {
    trackRowClick(row.row_type, row.contract_id)
    if (
      [InstallmentRowType.NEGOTIATION_START, InstallmentRowType.NEGOTIATION].includes(
        row.row_type
      ) ||
      row.is_from_credit_card_flow
    ) {
      if (isNewInstallmentDrawerEnabled) {
        setSelectedInstallment(row)
        setNewShowInstallmentsDetailsDrawer(true)
      } else {
        setSelectedNegotiationInstallment({
          urn: row.urn,
          receivable_id: row.receivable_id,
          backoffice_installment_id: row.backoffice_installment_id,
          order_reference: row.order_reference,
          status: row.status,
        })
        trackDrawerClick(row.urn, TrackDrawerClickEvents.OPEN)
        setShowNegotiationDrawer(true)
      }
    } else if (
      includes(row.row_type, [
        InstallmentRowType.ENROLLMENT_INSTALLMENT,
        InstallmentRowType.INSTALLMENT,
      ])
    ) {
      trackDrawerClick(row.urn, TrackDrawerClickEvents.OPEN)
      setSelectedInstallment(row)
    }
  }

  const closeNegotiationDrawer = () => {
    setSelectedNegotiationInstallment(null)
    setShowNegotiationDrawer(false)
  }

  const handleDisclaimerModalClose = (
    _: React.SyntheticEvent<Element, Event>,
    reason: CloseReason
  ) => {
    if (reason !== 'backdropClick') {
      setShowDisclaimerModal(false)
      storeModalShownDate(guardianId)
    }
  }

  const isLoadingInstallments =
    isFetchGuardiansInstallmentsLoading || isRefetchingGuardiansInstallments

  const uniqueBufferInstallmentsByStudent = getUniqueBufferInstallmentsByStudent(
    guardiansInstallments?.data
  )

  const studentIds = uniqueBufferInstallmentsByStudent?.map(installment => installment.student.id)

  const showInstallmentsDrawerContainer = showInstallmentsDetailsDrawer || showAgglutinationDrawer
  return (
    <Grid item xs={4}>
      <GuardianDetailsDisclaimerModal
        isOpen={showDisclaimerModal}
        onClose={handleDisclaimerModalClose}
      />
      <GuardianDetailsInstallmentsFilter
        filters={installmentsFilters}
        updateFilters={updateFilters}
        filtersOptions={filterOptions?.data}
        isFiltersOptionsFetched={isFiltersOptionsFetched}
        agglutinationButton={
          isAdmin && isRenegotiateAndAgglutinateButtonEnabled ? (
            <Agglutination setShowAgglutinationDrawer={setShowAgglutinationDrawer} />
          ) : null
        }
        updateEnrollmentStatusButton={
          <UpdateEnrollmentStatus
            schoolId={schoolId}
            studentIds={studentIds}
            isLoadingInstallments={isLoadingInstallments}
          />
        }
      />
      <GuardianDetailsInstallmentsTable
        installments={guardiansInstallments?.data}
        isLoading={isLoadingInstallments}
        onRowClick={handleTableRowClick}
        showGeneratingInvoicesLoading={showGeneratingInvoicesLoading}
      />
      {isNewInstallmentDrawerEnabled && showNewInstallmentsDetailsDrawer && (
        <InstallmentsDrawerContainerV2
          onClose={handleCloseInstallmentsDetailsDrawer}
          guardianId={guardianId}
          selectedInstallment={selectedInstallment}
        />
      )}
      {showInstallmentsDrawerContainer &&
        (!isNewInstallmentDrawerEnabled ||
          (isNewInstallmentDrawerEnabled && showAgglutinationDrawer)) && (
          <InstallmentsDrawerContainer
            selectedInstallment={selectedInstallment || selectedNegotiationInstallment}
            selectedInstallmentHasError={selectedInstallmentHasError}
            showAgglutinationDrawer={showAgglutinationDrawer}
            onClose={handleCloseInstallmentsDetailsDrawer}
          />
        )}
      {!isNewInstallmentDrawerEnabled && showNegotiationDrawer && (
        <NegotiationDrawer
          isOpen={showNegotiationDrawer}
          selectedInstallmentId={selectedNegotiationInstallment.urn}
          receivableId={selectedNegotiationInstallment.receivable_id}
          urn={selectedNegotiationInstallment.urn}
          status={selectedNegotiationInstallment.status}
          onClose={closeNegotiationDrawer}
          guardianId={guardianId}
          successCallbackRenegotiationDrawer={() => {
            refetchGuardiansInstallments()
          }}
        />
      )}
    </Grid>
  )
}
