import { Typography } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Person } from '@material-ui/icons'

import { ContextDropdown } from '@/shared/components/ContextDropdown'

import { useApi } from '@/shared/hooks/useApi'

import { logOut } from '@/shared/api/sortingHatClientV3'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

import { Container, Content, Avatar } from './styles'

type UserMenuProps = {
  subtitle: string
  title: string
  variation?: 'primary' | 'secondary'
}

/**
 * TODO: Move to a environment var
 */
const FAQ_URL = 'https://centraldeajuda.olaisaac.io'

/**
 * TODO: Move to a environment var or constant
 */
const TERMS_FILE_URL =
  'https://storage.googleapis.com/plataforma-isaac-bucket/documents/termos_condicoes_isaac.pdf'

/**
 * User options dropdown component for header
 *
 * @param props.title Component title
 * @param props.subtitle Component subtitle
 * @param props.variation Indicates the color variation of the avatar component
 */
export const UserMenu = ({ title, subtitle, variation = 'primary' }: UserMenuProps) => {
  /**
   * TODO: Use apiClient
   */
  const { api } = useApi()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const sendEvent = (option: 'faq' | 'terms' | 'logout') => {
    const events: Record<'faq' | 'terms' | 'logout', EventDispatcherEvents> = {
      faq: EventDispatcherEvents.FAQ_ACCESS,
      terms: EventDispatcherEvents.TERMS_CONDITIONS_PDF_ACCESS,
      logout: EventDispatcherEvents.LOGOUT_CLICK,
    }

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.HEADER_MENU,
        name: events[option],
        action: 'click',
      })
    }
  }

  const handleContextDropdownAction = (action: 'terms' | 'faq') => {
    sendEvent(action)

    const ACTION_URLS = {
      terms: TERMS_FILE_URL,
      faq: FAQ_URL,
    }

    window.open(ACTION_URLS[action], '_blank')
  }

  const handleLogoutClick = async () => {
    sendEvent('logout')

    try {
      await api.auth.logout()
      logOut()
    } catch (err) {
      logOut()
    }
  }

  const lowerCasedTitle = title.toLowerCase()

  return (
    <Container>
      <Content>
        <Typography variation="bodySmall" style={{ fontWeight: 500, textTransform: 'capitalize' }}>
          {lowerCasedTitle}
        </Typography>

        <Typography variation="caption" color="secondary">
          {subtitle}
        </Typography>
      </Content>

      <div>
        <ContextDropdown
          button={
            <Avatar variation={variation}>
              <Person />
            </Avatar>
          }
          options={[
            {
              label: 'Central de Ajuda',
              name: 'faq',
              onClick: () => handleContextDropdownAction('faq'),
            },
            {
              label: 'Termos de uso',
              name: 'terms',
              onClick: () => handleContextDropdownAction('terms'),
            },
            { label: 'Sair', name: 'logout', onClick: handleLogoutClick },
          ]}
        />
      </div>
    </Container>
  )
}
