import { Box } from '@material-ui/core'
import { FC } from 'react'
import DiscountForm from './components/DiscountForm'
import { AddDiscountButton, FieldLegendTypography, FieldTitleTypography } from './style'
import { DiscountsListProps } from './types'

const DiscountsList: FC<DiscountsListProps> = ({
  form,
  appendDiscount,
  removeDiscount,
  discountFields,
  totalAmount,
}) => {
  const showAddDiscountButton = discountFields?.length < 3

  return (
    <Box data-testid="discounts-list">
      <FieldTitleTypography>Descontos</FieldTitleTypography>
      <FieldLegendTypography>Cadastre até 3 descontos para o contrato</FieldLegendTypography>
      {discountFields.map((field, index) => (
        <DiscountForm
          form={form}
          key={field.id}
          index={index}
          totalAmount={totalAmount}
          removeDiscount={removeDiscount}
          fieldNames={{
            amount: `discounts[${index}].amount`,
            description: `discounts[${index}].description`,
            daysBeforeDueDate: `discounts[${index}].days_before_due_date`,
            daysBeforeDueDateLabel: `discounts[${index}].label_days_before_due_date`,
            isFinished: `discounts[${index}].is_finished`,
          }}
        />
      ))}
      {showAddDiscountButton && (
        <AddDiscountButton aria-label="adicionar desconto" onClick={appendDiscount}>
          Adicionar desconto
        </AddDiscountButton>
      )}
    </Box>
  )
}

export default DiscountsList
