import { useState } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useApi, useSnackbar } from '@/shared/hooks'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

import { downloadFile } from '@/shared/utils'
import { ReportTypesEnum } from '../../pages/Reports/NewReports'

export const useDownloadIRReport = () => {
  const { api } = useApi()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { school } = useSelectedSchool()
  const { setMessage, setIsOpen } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  const sendEvent = (isDownloadSuccessful: boolean) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.DOWNLOAD_FILE,
        scope: EventDispatcherEventScopes.REPORTS,
        action: 'file_download',
        customProperties: {
          $is_download_successful: isDownloadSuccessful,
          $report: ReportTypesEnum.IR,
          $Button_name: 'Baixar relatório do demonstrativo de valores pagos 2023',
        },
      })
  }

  const downloadIRReport = async () => {
    setIsLoading(true)

    let isDownloadSuccessful = true

    try {
      const { url } = await api.schools.getPresignedUrl({
        extension: 'zip',
        file_name: school?.id,
        method: 'GET',
      })

      const zipFileDownload = async (fileName: string) => {
        const { data } = await api.schools.downloadFile(fileName)
        return data
      }

      await downloadFile(school?.id, 'zip', () => zipFileDownload(url))
    } catch (error) {
      isDownloadSuccessful = false
      setMessage(
        'Erro ao baixar relatório. Por favor tente novamente mais tarde ou entre em contato com o nosso suporte.'
      )
      setIsOpen(true, { variation: 'error' })
    } finally {
      setIsLoading(false)
      sendEvent(isDownloadSuccessful)
    }
  }

  return {
    downloadIRReport,
    isLoading,
  }
}
