import { Box } from '@material-ui/core'
import styled from 'styled-components'

import { MAX_STEP_WIDTH } from '../../constants'

export const Container = styled(Box)`
  max-width: ${MAX_STEP_WIDTH}px;
  padding-bottom: 150px;
  margin: 0 auto;
`

export const ListWrapper = styled(Box)<{ marginTop?: string }>`
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : '')}
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
`

export const ListItemSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
`
