import styled from 'styled-components'
import { Box, IconButton } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'

export const CardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  height: 118px;
  margin-right: 16px;
  background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[0]};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[2]};
  }

  &.compacted {
    width: 224px;
    height: 48px;
    margin-right: 0;

    &:hover {
      background-color: ${({ theme }) => theme.primitiveTokens.colors.gray[2]};
    }
  }
`

export const StudentInfoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  padding: 16px;
  padding-bottom: 0;

  &.compacted {
    height: 48px;
    width: 224px;
    padding: 10px 12px;
  }
`

export const StudentNameBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background-color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[20]};
`

export const StudentNameContainer = styled(Box)`
  text-align: left;
  width: 280px;
  min-width: 280px;
  padding: 0 12px;

  &.compacted {
    width: 155px;
    min-width: 155px;
    padding: 0;
    padding-left: 5px;
  }
`

export const StyledTypography = styled(Typography)`
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
`

export const EnrollmentInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  padding-left: 40px;
  margin-top: 8px;
  margin-bottom: 16px;
  max-width: 350px;
  max-height: 50px;
`

export const StatusContainer = styled(Box)`
  width: 350px;
`

export const StatusDetailsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;

  div {
    margin-left: 5px;
  }
`

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`
export const StyledNameTypography = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
