import { FC, useEffect } from 'react'
import dayjs from 'dayjs'
import { UseFormReturn, Controller } from 'react-hook-form'
import {
  Button,
  DialogContent,
  Drawer,
  Radio,
  FormControlLabel,
  ActionDrawerHeader,
  ButtonDocker,
  Notification,
} from '@olaisaac/design-system'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import { propEq } from 'ramda'
import {
  ContractCancellationReason,
  Installment,
  ProcessedInstallment,
} from 'src/shared/interfaces'
import { useContract } from 'src/escolas/hooks'
import { useJWT } from 'src/shared/hooks'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'
import { date2PTFormat, formatCentsToReal, formatDate, validateEditReason } from 'src/shared/utils'

export const contractCancellationReasonOptions = [
  { name: 'Alteração da data', value: ContractCancellationReason.WRONG_DATE },
  { name: 'Alteração de valor', value: ContractCancellationReason.WRONG_AMOUNT },
  {
    name: 'Alteração dos dados do responsável',
    value: ContractCancellationReason.WRONG_GUARDIAN_INFO,
  },
  { name: 'Aluno saiu da escola', value: ContractCancellationReason.STUDENT_LEFT },
  { name: 'Contrato duplicado', value: ContractCancellationReason.DUPLICATED_CONTRACT },
  { name: 'Outro (digite)', value: ContractCancellationReason.OTHER },
]

export type ContractCancellationForm = {
  cancellation_description?: string
  cancellation_reason: ContractCancellationReason
  installment_id: uuid
}

export type CancellationDrawerDefaultContentProps = {
  availableInstallments: Installment[]
  form: UseFormReturn<ContractCancellationForm>
  isOnTheFirstPage: boolean
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  processedInstallments: ProcessedInstallment[]
  setIsOnTheFirstPage: (value: boolean) => void
  setShowConfirmCancellationMessage: (value: boolean) => void
}

const CancellationDrawerDefaultContent: FC<CancellationDrawerDefaultContentProps> = ({
  isOpen,
  onClose,
  processedInstallments,
  availableInstallments,
  form,
  isOnTheFirstPage,
  setIsOnTheFirstPage,
  setShowConfirmCancellationMessage,
}) => {
  const { contract } = useContract()
  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { isSubmitting, isValid, isDirty, errors },
  } = form
  const { isAdmin } = useJWT()

  const isRevokedCancelContract = useUnleashFlag(UnleashFlags.B2BCOR_193_BLOCK_CONTRACT_REVOKE)

  /**
   * Caso o usuário não seja admin mas a escola tem permissão para cancelar contratos.
   */
  const isCancelContractEnabled = !isAdmin && !isRevokedCancelContract

  watch(['cancellation_reason', 'installment_id', 'cancellation_description'])
  const { cancellation_reason, installment_id, cancellation_description } = getValues()
  const isReasonOther = cancellation_reason === ContractCancellationReason.OTHER
  const hasCancellationDescription = Boolean(cancellation_description)
  const hasCancellationReason = Boolean(cancellation_reason)
  const hasCancellationDescriptionErros = Boolean(errors?.cancellation_description)
  const invalidDescription = cancellation_description && hasCancellationDescriptionErros
  const isCancellationDescriptionValid = hasCancellationDescription && !invalidDescription

  const isNextPageButtonEnabled = isCancelContractEnabled
    ? isCancellationDescriptionValid
    : hasCancellationReason && (!isReasonOther || isCancellationDescriptionValid)

  const isActionButtonDisabled =
    !isNextPageButtonEnabled || (!isOnTheFirstPage && (isSubmitting || !isValid || !isDirty))

  const installment = contract?.installments?.find(propEq('id', installment_id))

  const orderReference = processedInstallments?.find(propEq('installment_id', installment_id))
    ?.orderReference

  const formatOrderReference = (order_reference: Installment['order_reference']) =>
    order_reference?.split('/')[0]

  useEffect(() => {
    if (!isReasonOther) {
      setValue('cancellation_description', '', { shouldValidate: true })
    }
  }, [isReasonOther])

  const cancelableInstallments = isCancelContractEnabled
    ? availableInstallments
    : contract?.installments

  return (
    <Drawer open={isOpen} data-testid="cancellation-drawer-container">
      <ActionDrawerHeader
        onBack={!isOnTheFirstPage ? () => setIsOnTheFirstPage(true) : undefined}
        onClose={onClose}
        title="Cancelar contrato"
      />

      <Notification
        description="Ao cancelar o contrato, ele ficará inativo e suas parcelas serão removidas dos próximos repasses."
        title="Essa ação é irreversível e pode alterar o repasse"
        variation="warning"
      />

      <DialogContent>
        <Box display={isOnTheFirstPage ? 'block' : 'none'}>
          <Box my={3}>
            <Typography variant="subtitle1">Qual é o motivo do cancelamento?</Typography>
          </Box>
          {isCancelContractEnabled ? (
            <FormControl fullWidth variant="outlined">
              <Controller
                rules={{
                  required: true,
                  maxLength: 100,
                  minLength: 5,
                  validate: validateEditReason,
                }}
                name="cancellation_description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    data-testid="cancellation_description"
                    id="cancellation_description"
                    variant="outlined"
                    label="Motivo"
                    error={invalidDescription}
                    helperText={
                      invalidDescription
                        ? 'Esse campo é obrigatório e deve ter entre 5 e 100 caracteres (somente letras e números)'
                        : ''
                    }
                  />
                )}
              />
            </FormControl>
          ) : (
            <>
              <FormControl component="fieldset">
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="cancellation_reason"
                  render={({ field }) => (
                    <RadioGroup data-testid="cancellation_reason" aria-label="source" {...field}>
                      {contractCancellationReasonOptions.map(({ name, value }) => (
                        <FormControlLabel
                          key={value}
                          value={value}
                          control={<Radio />}
                          label={name}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </FormControl>
              <FormControl fullWidth variant="outlined">
                <Controller
                  rules={{
                    required: isReasonOther,
                    maxLength: 100,
                    minLength: 5,
                    validate: isReasonOther && validateEditReason,
                  }}
                  name="cancellation_description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="cancellation_description"
                      disabled={!isReasonOther}
                      variant="outlined"
                      label="Motivo"
                      error={invalidDescription}
                      helperText={
                        invalidDescription
                          ? 'Esse campo é obrigatório e deve ter entre 5 e 100 caracteres (somente letras e números)'
                          : ''
                      }
                    />
                  )}
                />
              </FormControl>
            </>
          )}
        </Box>
        <Box display={isOnTheFirstPage ? 'none' : 'block'}>
          <Box mb={2.5} mt={2.5}>
            <Typography variant="subtitle1">A partir de qual parcela?</Typography>
          </Box>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="installment_id">Número da parcela</InputLabel>
            <Controller
              rules={{ required: true }}
              control={control}
              name="installment_id"
              defaultValue=""
              render={({ field: { value, onChange } }) => (
                <Select
                  labelId="installment_id"
                  label="Número da parcela"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  fullWidth
                >
                  {cancelableInstallments?.map(({ id, due_date, amount, order_reference }) => (
                    <MenuItem key={id} value={id}>
                      {`${formatOrderReference(order_reference)}. ${formatDate(
                        due_date,
                        'MMM YYYY'
                      )} - ${formatCentsToReal(amount)}`}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          {installment_id && (
            <Box mt={4}>
              <Typography>O cancelamento será feito a partir da seguinte parcela:</Typography>
              <Box mt={2.5} display="flex" justifyContent="space-between">
                <Typography color="textSecondary" variant="body2">
                  Parcela
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {orderReference}
                </Typography>
              </Box>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Typography color="textSecondary" variant="body2">
                  Valor da parcela
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {formatCentsToReal(installment?.amount)}
                </Typography>
              </Box>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Typography color="textSecondary" variant="body2">
                  Vencimento
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {date2PTFormat(dayjs(installment?.due_date).toISOString())}
                </Typography>
              </Box>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Typography color="textPrimary" variant="body2">
                  Parcelas alteradas
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {date2PTFormat(dayjs(installment?.due_date).toISOString())}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>

      <ButtonDocker>
        <Button
          data-testid="cancellation-drawer-action-btn"
          type={isOnTheFirstPage ? 'button' : 'submit'}
          onClick={
            isOnTheFirstPage
              ? () => setIsOnTheFirstPage(false)
              : () => setShowConfirmCancellationMessage(true)
          }
          disabled={isActionButtonDisabled}
          loading={isSubmitting}
          fullWidth
        >
          {isOnTheFirstPage ? 'Próximo' : 'Confirmar'}
        </Button>
      </ButtonDocker>
    </Drawer>
  )
}

export default CancellationDrawerDefaultContent
