import { useRouteMatch } from 'react-router-dom'
import { routes } from './routes'
import type { ModuleDefinition } from '../core/module'
import { UnleashFlags, useJWT, useUnleashFlag } from '@/shared/hooks'

export const useRegisterCreditModule: ModuleDefinition = module => {
  const { path: basePath } = useRouteMatch()

  const { hasPayoutReportAccess } = useJWT()
  const isCreditEnabled = useUnleashFlag(UnleashFlags.CRED_670_ENABLE_CREDIT_MODULE)
  const isSchoolCreditAvailable = useUnleashFlag(UnleashFlags.CRED_773_ROLLOUT_TELAS_CREDITO)

  const hasCreditAccess = isCreditEnabled && isSchoolCreditAvailable && hasPayoutReportAccess

  module.registerRoutes(hasCreditAccess ? routes : [], { basePath })
}
