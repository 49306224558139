import { useState } from 'react'
import { mdiAccountSchool, mdiMinusCircle } from '@mdi/js'
import * as Styled from './styles'

export enum ToggleValues {
  INCLUDED = 'included',
  NOT_INCLUDED = 'not-included',
}

interface ToggleButtonProps {
  onChange: (value: ToggleValues) => void
}

export const ToggleButton = ({ onChange }: ToggleButtonProps) => {
  const includedLabel = 'Alunos incluídos'
  const notIncludedLabel = 'Alunos não incluídos'

  const [value, setValue] = useState(ToggleValues.INCLUDED)

  const handleValueChange = (_: React.MouseEvent, newValue: ToggleValues) => {
    if (newValue !== null) {
      setValue(newValue)
      onChange(newValue)
    }
  }

  return (
    <Styled.ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleValueChange}
      aria-label="selecao-alunos"
    >
      <Styled.ToggleButton value={ToggleValues.INCLUDED} aria-label="alunos-incluidos">
        <Styled.Icon path={mdiAccountSchool} size={1} />
        {includedLabel}
      </Styled.ToggleButton>
      <Styled.ToggleButton value={ToggleValues.NOT_INCLUDED} aria-label="alunos-nao-incluidos">
        <Styled.Icon path={mdiMinusCircle} size={1} />
        {notIncludedLabel}
      </Styled.ToggleButton>
    </Styled.ToggleButtonGroup>
  )
}
