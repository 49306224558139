import { ReenrollmentStatus, ReEnrollmentSubStatus } from '@/shared/interfaces'
import { Tooltip } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { TooltipButton, Typography } from '@olaisaac/design-system'
import styled from 'styled-components'

const mapToText = (
  status: ReenrollmentStatus,
  details: ReEnrollmentSubStatus[]
): [string, string] => {
  switch (status) {
    case ReenrollmentStatus.GATE_ERROR:
      return [
        'Indisponível no momento',
        'Desculpe o inconveniente. Estamos trabalhando para resolver o problema e exibir a situação de matrícula do aluno. Por favor, tente novamente mais tarde.',
      ]

    case ReenrollmentStatus.ENROLLMENT_IN_PROGRESS:
      if (!details) {
        return ['', '']
      }

      if (details.length > 1) {
        return [
          'Aguardando pagamentos',
          'Aguardando quitação dos contratos [previousYear] e pagamento da pré-matrícula',
        ]
      }

      switch (details[0]) {
        case ReEnrollmentSubStatus.PREVIOUS_YEAR_PENDING:
          return [
            'Aguardando quitação dos contratos [previousYear]',
            'É necessário quitar todas as parcelas em aberto em contratos de [previousYear].',
          ]
        case ReEnrollmentSubStatus.PRE_ENROLLMENT_PENDING:
          return [
            'Aguardando pagamento da pré-matrícula',
            'É necessário pagar a primeira parcela da pré-matrícula.',
          ]
        case ReEnrollmentSubStatus.STUDENT_RECORD_PENDING:
          return [
            'Detalhes indisponíveis',
            'Desculpe o inconveniente, não foi possível carregar os detalhes. Tente atualizar a situação do aluno ou aguarde.',
          ]
        default:
          return ['', '']
      }

    case ReenrollmentStatus.NOT_REENROLLABLE:
      if (details.includes(ReEnrollmentSubStatus.REENROLLMENT_SUSPENDED)) {
        return [
          'Matrícula [currentYear] bloqueada',
          'É necessário regularizar o pagamento das parcelas vencidas para que as parcelas do novo contrato sejam criadas.',
        ]
      }
      return [
        'Parcelas vencidas em contratos de [previousYear]',
        'É necessário regularizar o pagamento das parcelas vencidas.',
      ]

    case ReenrollmentStatus.ENROLLMENT_AT_RISK:
      return [
        'Parcelas vencidas em contratos de [previousYear]',
        'É necessário regularizar o pagamento das parcelas vencidas, do contrário a rematrícula pode ser cancelada.',
      ]
    default:
      return ['', '']
  }
}

const Container = styled.div`
  margin: 4px 10px 0;
  display: flex;
  align-items: center;
  gap: 4px;
`

type Props = {
  currentYear: number
  details: ReEnrollmentSubStatus[]
  status: ReenrollmentStatus
}

export const StatusDetailsBadge = ({ status, details, currentYear }: Props) => {
  const [textWithPlaceholder, tooltipWithPlaceholder] = mapToText(status, details)
  const previousYear = currentYear - 1

  const injectVariablesToStatusText = (str: string) =>
    str
      .replace('[previousYear]', String(previousYear))
      .replace('[currentYear]', String(currentYear))
  const tooltip = injectVariablesToStatusText(tooltipWithPlaceholder)
  const text = injectVariablesToStatusText(textWithPlaceholder)

  if (text === '') {
    return null
  }

  return (
    <Container>
      <Typography variation="caption">{text}</Typography>
      <Tooltip title={tooltip} style={{ cursor: 'pointer' }}>
        <TooltipButton>
          <InfoOutlinedIcon color="action" fontSize="small" />
        </TooltipButton>
      </Tooltip>
    </Container>
  )
}
