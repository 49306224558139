import { FC, useMemo, useState } from 'react'
import { DrawerState } from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/constants'
import {
  ActionDrawerHeader,
  ButtonDocker,
  DialogContent,
  Drawer,
  Typography,
} from '@olaisaac/design-system'
import { Box } from '@material-ui/core'
import {
  CheckoutCombinableReceivable,
  CheckoutReceivables,
  ReceivablesByProduct,
} from 'src/escolas/contexts/checkoutContext'
import { formatCentsToReal } from 'src/shared/utils'
import NegotiationFinancialSummary from './NegotiationFinancialSummary'
import { uniq } from 'ramda'

export type InstallmentNegotiationDrawerProps = {
  combinedReceivables: CheckoutCombinableReceivable[]
  isOpen: boolean
  onClose: () => void
  paymentFee: number
}
const InstallmentNegotiationDrawer: FC<InstallmentNegotiationDrawerProps> = ({
  isOpen,
  onClose,
  combinedReceivables,
  paymentFee = 0,
}) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DrawerState.DEFAULT)
  const goBackToDefaultContent = () => setDrawerState(DrawerState.DEFAULT)
  const showGoBack = drawerState !== DrawerState.DEFAULT

  const receivablesByStudentAndProduct = useMemo(() => {
    const checkoutReceivables: CheckoutReceivables[] = []

    if (combinedReceivables?.length) {
      let listOfStudents = uniq(
        combinedReceivables.map(x => {
          const container = {
            student: x.student,
            student_id: x.student_id,
          }
          return container
        })
      )
      let listOfProducts = uniq(
        combinedReceivables.map(x => {
          const container = {
            product: x.product,
            product_id: x.product_id,
          }
          return container
        })
      )
      let newList = combinedReceivables
      if (listOfStudents?.length && listOfProducts?.length) {
        listOfStudents = listOfStudents.sort((a, b) => a.student.localeCompare(b.student))
        listOfProducts = listOfProducts.sort((a, b) => a.product.localeCompare(b.product))

        for (const student of listOfStudents) {
          const studentReceivable: CheckoutReceivables = {
            student_id: null,
            student_name: '',
            products: [],
          }
          for (const product of listOfProducts) {
            const productReceivable: ReceivablesByProduct = {
              product_id: null,
              product_name: '',
              receivables: [],
            }
            for (const receivable of newList) {
              if (
                receivable?.product === product?.product &&
                receivable?.student_id === student?.student_id
              ) {
                productReceivable.receivables.push(receivable)
                newList = newList.filter(x => x.id !== receivable?.id)
              }
            }
            if (productReceivable.receivables.length > 0) {
              productReceivable.product_name = product?.product
              productReceivable.product_id = product?.product_id

              studentReceivable.products.push(productReceivable)
            }
          }
          if (studentReceivable.products.length > 0) {
            studentReceivable.student_name = student?.student
            studentReceivable.student_id = student?.student_id

            checkoutReceivables.push(studentReceivable)
          }
        }
      }
    }
    return checkoutReceivables
  }, [combinedReceivables])

  const totalAmount =
    receivablesByStudentAndProduct
      .map(students =>
        students.products
          .map(products =>
            products.receivables.reduce(
              (acc, curr) =>
                acc +
                curr.amount +
                (curr.current_fine ?? 0) +
                (curr.current_interest ?? 0) -
                (curr.current_perpetual_discount ?? 0) -
                (curr.current_early_payment_discount ?? 0) -
                (curr.current_due_payment_discount ?? 0) -
                (curr.lost_due_payment_discount ?? 0),

              0
            )
          )
          .reduce((acc, curr) => acc + curr, 0)
      )
      .reduce((acc, curr) => acc + curr, 0) + paymentFee

  return (
    <Drawer open={isOpen}>
      <ActionDrawerHeader
        title="Detalhes do pagamento"
        onClose={onClose}
        onBack={showGoBack ? goBackToDefaultContent : undefined}
      />
      <DialogContent>
        <Box mt={3}>
          <NegotiationFinancialSummary combinedReceivables={receivablesByStudentAndProduct} />
        </Box>
      </DialogContent>
      <ButtonDocker>
        <Box pb={1} display="flex" justifyContent="space-between" width="100%">
          <Typography variation="subtitleDesktopMedium" withoutMargin>
            Total
          </Typography>
          <Typography variation="subtitleDesktopMedium" withoutMargin>
            {formatCentsToReal(totalAmount)}
          </Typography>
        </Box>
      </ButtonDocker>
    </Drawer>
  )
}

export default InstallmentNegotiationDrawer
