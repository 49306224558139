export const getEditDueDayTooltipContent = (
  hasInstallmentsToChangeDueDay: boolean,
  maxDaysToChangeContractDueDay: number
): string => {
  if (!hasInstallmentsToChangeDueDay) {
    return 'Contratos sem parcelas com vencimento editável, não podem ter seu vencimento alterado.'
  }

  if (maxDaysToChangeContractDueDay > 0) {
    return `Contratos criados a mais de ${maxDaysToChangeContractDueDay} dias não podem ter seu vencimento alterado.`
  }

  if (maxDaysToChangeContractDueDay === 0) {
    return `Somente contratos criados hoje podem ter seu vencimento alterado.`
  }
}
