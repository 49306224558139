import { Dayjs } from 'dayjs'
import React from 'react'
import { HotjarEvents } from 'src/shared/hooks/useHotjar'
import { Contract, Discount, Enrollment, Product } from 'src/shared/interfaces'

export enum ActionType {
  FETCH_PRODUCTS = 'FETCH_PRODUCTS',
  SELECT_CURRENT_YEAR_PRODUCT = 'SELECT_CURRENT_YEAR_PRODUCT',
  SELECT_NEXT_YEAR_PRODUCT = 'SELECT_NEXT_YEAR_PRODUCT',
  SET_PAYMENT_PLAN = 'SET_PAYMENT_PLAN',
  SET_PRE_CONTRACT_DISCLAIMER_CHECK = 'SET_PRE_CONTRACT_DISCLAIMER_CHECK',
  UPDATE_CONTRACT_IDS_LIST = 'UPDATE_CONTRACT_IDS_LIST',
  UPDATE_CONTRACT_LIST = 'UPDATE_CONTRACT_LIST',
}

export type CampaignActionType = {
  payload: any // TODO: update with payload types
  type: ActionType
}

export type CampaignContractWithKey = Partial<Contract> & {
  key: string
}

export type CampaignContextType = {
  contractList: CampaignContractWithKey[]
  currentYear: string
  currentYearProduct: Product
  currentYearProductId: string
  nextYearProduct: Product
  nextYearProductId: string
  paymentPlan: CampaignPaymentPlanType
  preContractDisclaimerCheck: boolean
  products: Product[]
  referenceYear: string
  selectedContractIdsList: string[]
}

export type CampaignPaymentPlanType = Partial<Enrollment> & {
  custom_enrollment_monthly_amount: number
  custom_monthly_amount: number
  discounts: Array<Discount>
  due_day: number
  duration_months: number
  pre_contract_disclaimer_check: boolean
  send_signable_document: boolean
  start_month: string
  use_working_dates_tuition: boolean
}

export type PaymentPlanSummaryProps = {
  disableSendSignableDocument: boolean
  enrollmentDiscounts: Discount[]
  enrollmentDueDay: number
  enrollmentDurationMonths: number
  enrollmentFirstDueDay: Dayjs
  enrollmentMonthlyAmount: cents
  firstDueDay: Dayjs
  isEnrollmentEnabled: boolean
  sendFirstEnrollmentInstallmentMessage: boolean
  tuitionDiscounts: Discount[]
  tuitionDueDay: number
  tuitionDurationMonths: number
  tuitionMonthlyAmount: cents
}

export type SummaryItemProps = {
  isPrimary?: boolean
  testId?: string
  text: string
  value: string | number
}

export type AmountInfoProps = {
  dueDay: number
  durationMonths: number
  firstDueDate: Dayjs
  netMonthlyAmount: number
  totalDiscountsAmount: number
  totalGrossAmount: number
}

export type NewCampaignOriginPage = string

export enum CampaignEventStep {
  DEFINE_PAYMENT_PLAN = 'define-payment-plan',
  NOT_IN_CAMPAIGN = 'not-in-campaign',
  PROCESSING = 'processing',
  REVIEW_AND_SAVE = 'review-and-save',
  SELECT_NEXT_PRODUCT = 'select-next-product',
  SELECT_STUDENTS = 'select-students',
}

export type StepType = {
  content: React.ReactNode
  errorMessage?: string
  eventStep: CampaignEventStep
  hotjarStep: HotjarEvents
  title: string
  validation?: () => boolean
  validationCallback?: () => void
}

export type ContractInfoType = {
  chipText: string
  ok: boolean
  tooltipText: string
}
