import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { TooltipButton, Typography } from '@olaisaac/design-system'
import { ReenrollmentStatus } from '@/shared/interfaces'
import { Tooltip } from '@material-ui/core'

import { useStudentsEnrollmentsFilter } from '@/escolas/hooks'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EnrollmentEventDispatcherEvents } from '@/shared/models/enums/EnrollmentEventDispatcherEvents.enum'
import { StyledInfoOutlinedIcon } from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/InstallmentFullStatus/styles'
import { StatusBadge } from '@/modules/enrollment/components/StatusBadge'
import * as Styled from './styles'

export type StatusSummaryCardProps = {
  status: ReenrollmentStatus
  text: string
  tooltip: string
  value: number
}

export const StatusSummaryCard = ({ status, value, text, tooltip }: StatusSummaryCardProps) => {
  const { updateFilter, studentsEnrollmentsFilter } = useStudentsEnrollmentsFilter()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const isSelected = studentsEnrollmentsFilter.enrollmentStatus.includes(status)
  return (
    <Styled.CardContainer
      role="option"
      aria-selected={isSelected}
      aria-label={text}
      onClick={() => {
        const activeFilters = studentsEnrollmentsFilter.enrollmentStatus
        let nextFilters = [...activeFilters, status]

        if (activeFilters.includes(status)) {
          nextFilters = activeFilters.filter(e => e !== status)
        }

        updateFilter({
          enrollmentStatus: nextFilters,
          enrollmentSubStatus: studentsEnrollmentsFilter.enrollmentSubStatus,
        })
        isInitialized &&
          eventDispatcherClient.sendEvent({
            scope: EventDispatcherEventScopes.ENROLLMENT_CYCLE,
            name: EnrollmentEventDispatcherEvents.CLICKED,
            action: 'click',
            customProperties: { $value: status, $Card_name: 'BIG_NUMBERS_CARD' },
          })
      }}
      isSelected={isSelected}
    >
      <Styled.StatusWrapper>
        <StatusBadge status={status} />
        <Tooltip title={tooltip} style={{ cursor: 'pointer' }}>
          <TooltipButton>
            <StyledInfoOutlinedIcon color="action" fontSize="small" />
          </TooltipButton>
        </Tooltip>
      </Styled.StatusWrapper>
      <Styled.BigNumberWrapper>
        <Styled.BigNumber>{value}</Styled.BigNumber>
        <Typography variation="bodySmall">{text}</Typography>
      </Styled.BigNumberWrapper>
    </Styled.CardContainer>
  )
}
