import { useContext } from 'react'

import { QueryContext } from '@/modules/app/contexts/GlobalProviders/Query'

/**
 * TODO: Move to shared folder
 *
 * Custom hook to access route query params
 */
export const useQuery = () => useContext(QueryContext)
