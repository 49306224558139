import { useNavigation } from '@/escolas/hooks'
import { getParamGuardianId } from '../../utils/getParamGuardianId'
import { useGuardianQuery } from '../../hooks/useGuardianQuery'
import { Container, StudentCardPlaceholder } from './styles'
import { Typography } from '@olaisaac/design-system'
import { StudentsCarouselProps } from './types'
import { StudentsCarouselCompacted } from './components/StudentsCarouselCompacted'
import { StudentsCarouselExpanded } from './components/StudentsCarouselExpanded'
import {
  CardsContainer,
  SkeletonCompactedHeader,
} from './components/StudentsCarouselCompacted/styles'
import { useEnrollmentCycle } from '@/modules/enrollment/hooks/queries/useEnrollmentCycle'
import { DEFAULT_INITIAL_PAGE, DEFAULT_ITEMS_PER_PAGE } from './constants'
import { CircularProgress } from '@material-ui/core'
import { useSchoolEnrollmentCycleContext } from '@/modules/enrollment/contexts/SchoolEnrollmentContext'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { ENROLLMENT_YEAR } from '@/modules/enrollment/constants/enrollment'

const StudentsCarouselSkeleton = () => {
  return (
    <Container className="compacted">
      <SkeletonCompactedHeader>
        <CircularProgress style={{ width: '20px', height: '20px', marginRight: '8px' }} />
        <Typography variation="bodySmall">Carregando informações do(s) aluno(s)</Typography>
      </SkeletonCompactedHeader>
      <CardsContainer className="compacted">
        <StudentCardPlaceholder />
        <StudentCardPlaceholder />
      </CardsContainer>
    </Container>
  )
}

export const StudentsCarousel = ({ isExpanded }: StudentsCarouselProps) => {
  const { schoolId } = useNavigation()
  const guardianId = getParamGuardianId()

  const { guardian, isFetchGuardianLoading } = useGuardianQuery({ guardianId, schoolId })

  const studentIds = guardian?.data?.students?.map(s => s.id)

  const isSelectCyclesEnabled = useUnleashFlag(UnleashFlags.ENABLE_MATRICULAS_SELECT_CYCLES)

  const { selectedEnrollmentCycle, isFetchingCycles } = useSchoolEnrollmentCycleContext()
  const selectedYear = isSelectCyclesEnabled
    ? selectedEnrollmentCycle?.reference_year
    : ENROLLMENT_YEAR

  const referenceYears = selectedYear ? [selectedYear, selectedYear - 1].map(String) : []
  const { data: students, isLoading: isLoadingStudents } = useEnrollmentCycle(
    {
      schoolId,
      studentIds,
      referenceYears,
      pagination: {
        page: DEFAULT_INITIAL_PAGE,
        per_page: DEFAULT_ITEMS_PER_PAGE,
      },
    },
    { enabled: studentIds?.length > 0 && referenceYears.length > 0 }
  )

  if (isFetchingCycles || isFetchGuardianLoading || isLoadingStudents) {
    return <StudentsCarouselSkeleton />
  }

  return (
    <Container className={`${!isExpanded && 'compacted'}`}>
      {isExpanded ? (
        <StudentsCarouselExpanded
          students={students?.data}
          enrollmentYear={selectedEnrollmentCycle.reference_year}
        />
      ) : (
        <StudentsCarouselCompacted
          students={students?.data}
          enrollmentYear={selectedEnrollmentCycle.reference_year}
        />
      )}
    </Container>
  )
}
