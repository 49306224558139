import { useHistory } from 'react-router-dom'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { DashboardCard } from '../DashboardCard'
import { WeekPicker } from '@/escolas/components/WeekPicker'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

export type EnrollmentPayoutReportData = {
  ended_at: Date
  id: string
  isOpen: boolean
  payoutValue: number
  started_at: Date
}

type EnrollmentReportDashboardCardProps = {
  currentPayout?: EnrollmentPayoutReportData
  enrollmentPayouts: EnrollmentPayoutReportData[]
  hasError: boolean
  isLoading: boolean
  onChange: (enrollmentPayoutReportId: string) => void
  onRetry: () => void
}

/**
 * Specialized dashboard card for enrollment payout report
 *
 * @param props
 * @param props.currenPayout Current selected enrollment payout report
 * @param props.enrollmentPayouts List of available enrollment payout reports
 * @param props.isLoading Indicates if the loading state should be displayed
 * @param props.hasError Indicates if the error state should be displayed
 * @param props.onChange On change callback function
 * @param props.onRetry On retry callback function
 */
export const EnrollmentReportDashboardCard = ({
  currentPayout,
  enrollmentPayouts,
  isLoading,
  hasError,
  onChange,
  onRetry,
}: EnrollmentReportDashboardCardProps) => {
  const { push } = useHistory()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleNavigateToEnrollmentPayoutReport = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.REPORT_DASHBOARD,
        name: EventDispatcherEvents.NAVIGATION_TO_ENROLLMENT_REPORT,
        action: 'click',
        customProperties: { $page_name: 'Dashboard de repasses' },
      })

    push(`repasses/matriculas${currentPayout && `?id=${currentPayout.id}`}`)
  }

  const handleChangePayoutReport = (id: string) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.REPORT_DASHBOARD,
        name: EventDispatcherEvents.ENROLLMENT_PAYOUT_DATE_CHANGE_ON_REPORT_DASHBOARD,
        action: 'click',
      })

    onChange(id)
  }

  const enrollmentMinDate = enrollmentPayouts[0]?.started_at

  const enrollmentMaxDate = enrollmentPayouts[enrollmentPayouts.length - 1]?.ended_at

  return (
    <DashboardCard
      title="Repasse de matrículas"
      isPayoutOpen={currentPayout?.isOpen ?? true}
      payoutAmountFormat="microcents"
      payoutValue={currentPayout?.payoutValue ?? 0}
      isLoading={isLoading}
      hasError={hasError}
      onRequestDetails={handleNavigateToEnrollmentPayoutReport}
      onRetry={onRetry}
    >
      {currentPayout && (
        <WeekPicker<EnrollmentPayoutReportData>
          variation="ghost"
          maxDetail="year"
          minDate={enrollmentMinDate}
          maxDate={enrollmentMaxDate}
          value={currentPayout}
          options={enrollmentPayouts}
          keyExtractor={item => item.id}
          onChange={({ id }) => handleChangePayoutReport(id)}
        />
      )}
    </DashboardCard>
  )
}
