import { AxiosInstance } from 'axios'
import dayjs from 'dayjs'
import qs from 'qs'

import { convertToUTCDate } from '@/shared/utils'

import type { APIResponse } from '@/shared/interfaces'
import type { PayoutSummary } from '@/modules/report/models/PayoutSummary'
import type { PayoutReportType } from '@/modules/report/models/PayoutReportType'
import {
  FetchReportListRequestDTO,
  FetchReportListResponseDTO,
  FetchPayoutDetailsRequestDTO,
  FetchUnguaranteedReportFileRequestDTO,
  FetchPayoutReportFileRequestDTO,
  FetchPayoutDetailsResponseDTO,
  GetEmbedReportRequestDTO,
  GetEmbedReportResponseDTO,
  GetAggregatedProvidersReportResponseDTO,
  FetchPayoutMonthAggregationRequestDTO,
} from './types'
import { buildQueryParams } from '@/modules/report/utils/buildQueryParams'

export const reportService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for fetch all available reports
     *
     * @param data.schoolID School ID
     * @param data.reportType Report type
     *
     * @returns List of reports of requested type
     */
    fetchReportList: async ({
      schoolId,
      reportType,
      payoutDomain,
    }: FetchReportListRequestDTO): Promise<FetchReportListResponseDTO> => {
      const { data } = await fetcher.get<FetchReportListResponseDTO>(`/report/${schoolId}`, {
        params: {
          report_type: reportType,
          ...(payoutDomain ? { payout_domain: payoutDomain } : {}),
        },
      })

      return {
        ...data,
        data: data.data.map(item => {
          const startedAt = convertToUTCDate(item.started_at).format('YYYY-MM-DD')
          const endedAt = convertToUTCDate(item.ended_at).format('YYYY-MM-DD')

          return {
            ...item,
            started_at: dayjs(startedAt).toDate(),
            ended_at: dayjs(endedAt).toDate(),
          }
        }),
      }
    },

    /**
     * Function responsible for fetch all available payouts summary
     *
     * @param schoolId School ID
     *
     * @returns List of available payouts summary
     */
    fetchPayoutsSummary: async (schoolId: string): Promise<PayoutSummary[]> => {
      const { data: responseData } = await fetcher.get<APIResponse<PayoutSummary[] | null>>(
        `/payout/detailed-payouts/${schoolId}`,
        {
          params: {
            versions: [1, 2],
            school_id: schoolId, // FIXME: [Provisory] Backend needs for permissions
          },
          paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' }),
        }
      )

      return responseData.data ?? []
    },

    /**
     * Function to fetch payout report details
     *
     * @param reportType
     * @param data.payoutId Payout ID
     * @param data.filter Filter options
     * @param data.pagination Pagination options
     *
     * @returns Payout details
     */
    fetchPayoutDetails: async <T extends PayoutReportType>(
      reportType: T,
      { payoutId, filter = {}, pagination = {} }: FetchPayoutDetailsRequestDTO<T>
    ): Promise<FetchPayoutDetailsResponseDTO<T>> => {
      const type = reportType === 'tuition' ? 'ISAAC_PLATFORM' : 'UNGUARANTEED_ISAAC_PLATFORM'

      const parsedFilters = buildQueryParams(filter)
      const parsedPagination = buildQueryParams(pagination)

      const params = {
        type,
        ...parsedFilters,
        ...parsedPagination,
      }

      const { data } = await fetcher.get<FetchPayoutDetailsResponseDTO<T>>(
        `/report/detailed-payout/${payoutId}`,
        {
          params,
          paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' }),
        }
      )

      return data
    },

    /**
     * Function to fetch a list of financial transactions for a given period.
     * It will include transactions from multiple payouts
     *
     * @param data.schoolID School ID
     * @param data.startDate Interval Start date
     * @param data.endDate Interval End date
     * @param data.payoutDomain Payout domain ('IP' or 'E')
     * @param data.filter Filter options
     * @param data.pagination Pagination options
     *
     * @returns Payout details
     */
    fetchPayoutMonthAggregation: async <T extends PayoutReportType>({
      schoolID,
      startDate,
      endDate,
      payoutDomain,
      filter = {},
      pagination = {},
    }: FetchPayoutMonthAggregationRequestDTO<T>): Promise<
      FetchPayoutDetailsResponseDTO<'isaac-pay'>
    > => {
      const parsedFilters = buildQueryParams(filter)
      const parsedPagination = buildQueryParams(pagination)

      const params = {
        school_id: schoolID,
        start_date: startDate,
        end_date: endDate,
        payout_domain: payoutDomain,
        ...parsedFilters,
        ...parsedPagination,
      }

      const { data } = await fetcher.get<FetchPayoutDetailsResponseDTO<'isaac-pay'>>(
        `/report/financial_transaction`,
        {
          params,
          paramsSerializer: params => qs.stringify(params, { arrayFormat: 'comma' }),
        }
      )

      return data
    },

    /**
     * Function responsible for request unguaranteed report download
     *
     * @param data.schoolID School ID
     * @param data.id Payout ID
     * @param data.extension File extension
     *
     * @returns Axios response with Blob file content
     */
    fetchUnguaranteedReportFile: async ({
      schoolId,
      id,
      extension,
    }: FetchUnguaranteedReportFileRequestDTO): Promise<Blob> => {
      const { data } = await fetcher.get<Blob>(
        `/report/files/unguaranteed/${schoolId}/${id}.${extension}`,
        {
          responseType: 'blob',
        }
      )

      return data
    },

    /**
     * Function responsible for request payout report file download
     *
     * @param data.id Payout ID
     * @param data.extension File extension
     * @param data.version Payout version
     *
     * @returns Blob file content
     */
    fetchPayoutReportFile: async ({
      extension,
      id,
      version,
    }: FetchPayoutReportFileRequestDTO): Promise<Blob> => {
      const { data } = await fetcher.get<Blob>(`/report/files/${version}/${id}.${extension}`, {
        responseType: 'blob',
      })

      return data
    },
    /**
     * Function responsible for fetch all available payouts summary
     *
     * @param schoolId School ID
     *
     * @returns List of available payouts summary
     */
    fetchFidcReport: async ({ id }: GetEmbedReportRequestDTO) => {
      const { data } = await fetcher.get<APIResponse<GetEmbedReportResponseDTO>>(
        `/report/contracts-confirmation/${id}`
      )

      return data
    },
    /**
     * Function responsible for fetching the family regularization report URL
     *
     * @param schoolId School ID
     *
     * @returns Embed report URL
     */
    fetchFamilyRegularizationReport: async ({ id }: GetEmbedReportRequestDTO) => {
      const { data } = await fetcher.get<APIResponse<GetEmbedReportResponseDTO>>(
        `/report/family-regularization/${id}`
      )

      return data
    },
    /**
     * Function responsible for fetching isaac pay incomes report URL
     *
     * @param id School ID
     *
     * @returns Embed report URL
     */
    fetchIsaacPayIncomesReport: async ({ id }: GetEmbedReportRequestDTO) => {
      const { data } = await fetcher.get<APIResponse<GetEmbedReportResponseDTO>>(
        `/report/isaac-pay-incomes/${id}`
      )

      return data
    },
    /**
     * Function responsible for fetching isaac pay external payments
     *
     * @param schoolId School ID
     * @param endDate Interval End date
     * @param startDate Interval Start date
     *
     * @returns Summary of external payments and payouts
     */
    fetchAggregatedProvidersReport: async ({ schoolId, startDate, endDate, payoutDomain }: any) => {
      const { data } = await fetcher.get<APIResponse<GetAggregatedProvidersReportResponseDTO>>(
        `/report/aggregated-providers/${schoolId}/total-payment?start_date=${startDate}&end_date=${endDate}&payout_domain=${payoutDomain}`
      )

      return data
    },
  }
}
