import { FamilyRegularizationReport } from '@/modules/account-reports/pages/FamilyRegularizationReport'
import { FidcConfirmationReports } from '@/modules/account-reports/pages/FidcConfirmationReports'

import { Reports } from '../pages/Reports'

import type { RouteDefinition } from '@/modules/core/router'
import { IsaacPayIncomeReport } from '../pages/IsaacPayIncomeReport'

export const routes: RouteDefinition[] = [
  {
    key: 'REPORTS',
    path: '/relatorios',
    component: Reports,
    exact: true,
  },
  {
    key: 'FAMILY_REGULARIZATION_REPORT',
    path: '/relatorio/regularizacao-das-familias',
    component: FamilyRegularizationReport,
    exact: true,
  },
  {
    key: 'ISAAC_PAY_INCOME_REPORT',
    path: '/relatorio/visao-de-recebimentos',
    component: IsaacPayIncomeReport,
    exact: true,
  },
  {
    key: 'FIDC_REPORT',
    path: '/relatorios/validacao-de-contratos',
    component: FidcConfirmationReports,
    exact: true,
  },
  {
    key: 'REDIRECT_REPORT',
    path: '/relatorios/*',
    redirect: '/relatorios',
  },
]
