import { FC } from 'react'
import { ButtonDocker, Divider } from '@olaisaac/design-system'
import { PreContractEditSummaryProps } from './types'
import {
  PreContractEditSummaryContainer,
  SectionContainer,
  SectionSubtitle,
  SectionText,
  SectionTitle,
  SectionContentContainer,
  StyledSectionText,
  DialogContentContainer,
  SubmitButton,
  PreContractEditSummaryHeader,
} from './style'
import {
  calculateInstallments,
  calculateTotalTuition,
  calculateTuitionTotalDiscount,
  formatDiscounts,
  formatDueDay,
  formatInstallments,
  formatTuition,
} from './utils'

const PreContractEditSummary: FC<PreContractEditSummaryProps> = ({
  studentName,
  productName,
  isLoading,
  form,
  discountsDeleted = [],
  onFinish,
}) => {
  const {
    getValues,
    formState: { isValid, isDirty },
    watch,
  } = form
  watch()
  const formValues = getValues()

  const {
    discounts,
    due_day,
    duration_months,
    monthly_amount,
    use_working_dates_tuition,
  } = formValues

  const installmentsTotalTuition = calculateTotalTuition(monthly_amount, duration_months)

  const installmentsTotalDiscount = calculateTuitionTotalDiscount(discounts, duration_months)

  const installments = calculateInstallments(
    duration_months,
    installmentsTotalTuition,
    installmentsTotalDiscount
  )

  const formattedDueDay = formatDueDay(due_day, use_working_dates_tuition)
  const formattedInstallments = formatInstallments(duration_months, installments)
  const formattedDiscounts = formatDiscounts(installmentsTotalDiscount)
  const formattedTuition = formatTuition(installmentsTotalTuition)

  const isFormInvalid = isLoading || !isValid || (!isDirty && discountsDeleted.length === 0)

  return (
    <PreContractEditSummaryContainer data-testid="pre-contract-edit-summary-container">
      <PreContractEditSummaryHeader title="Resumo" />

      <DialogContentContainer>
        <SectionContainer>
          <SectionTitle>Aluno</SectionTitle>
          <SectionText data-testid="pre-contract-edit-summary-student-name">
            {studentName}
          </SectionText>
        </SectionContainer>
        <Divider />
        <SectionContainer>
          <SectionTitle>Produto</SectionTitle>
          <SectionText data-testid="pre-contract-edit-summary-product-name">
            {productName}
          </SectionText>
        </SectionContainer>
        <Divider />
        <SectionContainer>
          <SectionTitle>Plano de pagamento</SectionTitle>
          <SectionSubtitle>Mensalidade</SectionSubtitle>
          <SectionContentContainer>
            <SectionText>Valor total</SectionText>
            <SectionText data-testid="pre-contract-edit-summary-tuition-total-amount">
              {formattedTuition}
            </SectionText>
          </SectionContentContainer>
          <SectionContentContainer>
            <SectionText>Descontos</SectionText>
            <SectionText data-testid="pre-contract-edit-summary-discounts-total-amount">
              {formattedDiscounts}
            </SectionText>
          </SectionContentContainer>
          <SectionContentContainer>
            <SectionText>Data de vencimento</SectionText>
            <SectionText data-testid="pre-contract-edit-summary-due-day">
              {formattedDueDay}
            </SectionText>
          </SectionContentContainer>
          <SectionContentContainer>
            <StyledSectionText>Total com descontos</StyledSectionText>
            <StyledSectionText data-testid="pre-contract-edit-tuition-final-amount">
              {formattedInstallments}
            </StyledSectionText>
          </SectionContentContainer>
        </SectionContainer>
      </DialogContentContainer>

      <ButtonDocker>
        <SubmitButton disabled={isFormInvalid} loading={isLoading} onClick={() => onFinish()}>
          Concluir
        </SubmitButton>
      </ButtonDocker>
    </PreContractEditSummaryContainer>
  )
}

export default PreContractEditSummary
