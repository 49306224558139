import React, { useEffect, useState } from 'react'
import { Table, TableColumns, Typography } from '@olaisaac/design-system'
import { Tooltip } from '@material-ui/core'

import theme from '@/shared/theme'
import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'

import { mapDataToRows } from './utils/mapDataToRows'
import { formatter } from './utils/formatter'
import { GuardianDetailsInstallmentsTableProps, Row, Rows } from './types'
import { StyledBox, StyledCircularProgress, StyledTypography, TableContainer } from './styles'
import { InstallmentInfo } from '../InstallmentInfo/InstallmentInfo'
import { InstallmentOrder } from '../InstallmentOrder/InstallmentOrder'
import { InstallmentFullStatus } from '../InstallmentFullStatus/InstallmentStatus'

export const GuardianDetailsInstallmentsTable: React.FC<GuardianDetailsInstallmentsTableProps> = ({
  installments,
  isLoading,
  onRowClick,
  showGeneratingInvoicesLoading,
}) => {
  const [rows, setRows] = useState<Rows>(mapDataToRows(installments) || [])
  const { addInstallments } = useCheckoutCart()

  useEffect(() => {
    if (!isLoading && installments) {
      setRows(mapDataToRows(installments))
    }
  }, [isLoading, installments])

  const columns: TableColumns = [
    {
      headerName: 'Competência',
      field: 'installment_info',
      renderCell: InstallmentInfo,
      small: true,
      isComponent: true,
    },
    {
      headerName: 'Parcela',
      field: 'installment_order',
      renderCell: InstallmentOrder,
      small: true,
      isComponent: true,
    },
    {
      headerName: 'Aluno',
      field: 'student',
      renderCell: (value: Row['student']) => (
        <Tooltip title={value.name}>
          <Typography
            variation="bodyLarge"
            style={{
              maxWidth: '255px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {value.name}
          </Typography>
        </Tooltip>
      ),
      small: true,
      isComponent: true,
    },
    {
      headerName: 'Produto',
      field: 'product_name',
      renderCell: (value: Row['product_name']) => (
        <Tooltip title={value}>
          <Typography
            variation="bodyLarge"
            style={{
              maxWidth: '255px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {value}
          </Typography>
        </Tooltip>
      ),
      small: true,
      isComponent: true,
    },
    {
      headerName: 'Vencimento',
      field: 'due_date',
      renderCell: (value: Row['due_date']) => (
        <Typography variation="bodyLarge">{formatter.full_date(value)}</Typography>
      ),
      small: true,
    },
    {
      headerName: 'Valor',
      field: 'amount',
      renderCell: (value: Row['amount']) => (
        <Typography variation="bodyLarge">{formatter.amount(value)}</Typography>
      ),
      small: true,
    },
    {
      headerName: 'Situação',
      field: 'installment_status',
      renderCell: InstallmentFullStatus,
      small: true,
    },
  ]

  const handleSelectInstallment = selectedInstallments => addInstallments(selectedInstallments)

  const handleRowClick = ({ row }, e: { target: HTMLInputElement }) => {
    if (row.disabled || e.target.type === 'checkbox') return
    onRowClick(row)
  }

  const TableEmptyState = () => (
    <Typography variation="subtitleDesktopMedium" color="secondary">
      Nenhuma parcela encontrada
    </Typography>
  )

  const isRowDisabled = (row: Row) => {
    return row.disabled
  }

  return (
    <>
      <TableContainer theme={theme}>
        {showGeneratingInvoicesLoading && (
          <StyledBox>
            <StyledCircularProgress />
            <StyledTypography variation="bodyLarge">
              Gerando os boletos de parcela(s) “A vencer”
            </StyledTypography>
          </StyledBox>
        )}
        <Table
          checkable={rows?.length > 0 && rows?.some(row => row.installment_info.checkable)}
          isRowCheckable={row => row.installment_info.checkable}
          onRowClick={handleRowClick}
          setCheckedRows={handleSelectInstallment}
          data-testid="guardianInstallmentsTable"
          columns={columns}
          emptyRender={TableEmptyState()}
          rowDisabled={isRowDisabled}
          isLoading={isLoading}
          rows={rows || []}
        />
      </TableContainer>
    </>
  )
}
