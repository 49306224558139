import type { RouteDefinition } from '@/modules/core/router'
import { SchoolEnrollmentCycle } from '../pages/SchoolEnrollmentCycle'
import { StudentEnrollment } from '../pages/StudentEnrollment'
import { CampaignsList } from '../pages/Campaigns/CampaignsList'
import { IrregularStudentEnrollments } from '../pages/IrregularStudentEnrollments'
import { NewCampaign } from '../pages/Campaigns/NewCampaign'

export const routes: RouteDefinition[] = [
  {
    key: 'ENROLLMENT',
    path: '/matriculas',
    component: SchoolEnrollmentCycle,
    exact: true,
  },
  {
    key: 'STUDENT_ENROLLMENT',
    path: '/matriculas/:studentId',
    component: StudentEnrollment,
    exact: true,
  },
  {
    key: 'ENROLLMENT_CAMPAIGNS',
    path: '/campanhas/:year?',
    component: CampaignsList,
    exact: true,
  },
  {
    key: 'IRREGULARITIES',
    path: '/irregularidades',
    component: IrregularStudentEnrollments,
    exact: true,
  },
  {
    key: 'NEW_ENROLLMENT_CAMPAIGN',
    path: '/campanha/novo',
    component: NewCampaign,
    exact: true,
  },
]
