import { FC } from 'react'
import { Controller, UseFormReturn, ValidateResult } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import dayjs from 'dayjs'
import { Box, Grid, TextField } from '@material-ui/core'
import { DatepickerPrimitives as Datepicker } from '@gravity/datepicker'
import { Text } from '@gravity/text'
import { Heading } from '@gravity/heading'
import { mdiTrashCanOutline } from '@mdi/js'
import Icon from '@mdi/react'

import { formatCentsToReal, formatDate, formatRealToCents } from '@/shared/utils'

import { InputErrorText, InputWrapper } from '../../../styles'
import { FormProps } from '../../types'
import * as Styled from './styles'

type Props = {
  disableRemoveButton: boolean
  form: UseFormReturn<FormProps, any>
  index: number
  onClickRemove: () => void
  onDateChange: (date: Date) => void
  referenceYear: number
  validateOfferDueDate: (date: Date) => ValidateResult
}

export const OfferItem: FC<Props> = ({
  referenceYear,
  form,
  index,
  disableRemoveButton,
  onClickRemove,
  onDateChange,
  validateOfferDueDate,
}) => {
  const minDate = dayjs().add(1, 'day')
  const maxDate = dayjs().set('year', referenceYear).set('month', 11).endOf('month')
  const title = `Condição de pagamento ${index + 1}`
  const offerTitleId = `offer-title-${index}`

  return (
    <Box role="listitem" aria-labelledby={offerTitleId}>
      <Box mt={4} display="flex" justifyContent="space-between">
        <Heading id={offerTitleId} variant="heading-h4-medium">
          {title}
        </Heading>
        <Styled.DangerIconButton
          variant="outline"
          disabled={disableRemoveButton}
          onClick={onClickRemove}
        >
          <Icon path={mdiTrashCanOutline} />
        </Styled.DangerIconButton>
      </Box>
      <Grid container spacing={6}>
        <Grid item sm={6}>
          <Controller
            rules={{ required: true, validate: Boolean }}
            control={form.control}
            name={`offers.${index}.amount` as const}
            render={({ field, fieldState }) => (
              <InputWrapper hasError={!!fieldState.error?.type}>
                <Text variant="subtitle-medium">Qual valor da pré-mátricula?</Text>
                <Styled.TextFieldWrapper>
                  <NumberFormat
                    customInput={TextField}
                    format={formatCentsToReal}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    label=""
                    value={field.value}
                    hiddenLabel
                    variant="outlined"
                    error={Boolean(fieldState.error?.type)}
                    helperText={fieldState.error?.type && 'O valor deve ser maior que R$ 1,00'}
                    onChange={event => {
                      field.onChange(Number(formatRealToCents(event.target.value)))
                    }}
                  />
                </Styled.TextFieldWrapper>
              </InputWrapper>
            )}
          />
        </Grid>
        <Grid item sm={6}>
          <Controller
            rules={{ validate: validateOfferDueDate }}
            control={form.control}
            name={`offers.${index}.expirationDate` as const}
            render={({ field, fieldState }) => (
              <InputWrapper hasError={Boolean(fieldState.error?.type)}>
                <Datepicker.Root>
                  <Text id={`offer-${index}-date-label`} variant="subtitle-medium">
                    Qual a data de validade?
                  </Text>
                  <Styled.DatepickerTrigger size={3} aria-labelledby={`offer-${index}-date-label`}>
                    {field.value ? formatDate(field.value, 'DD/MM/YYYY') : null}
                  </Styled.DatepickerTrigger>
                  <Datepicker.Calendar
                    value={field.value}
                    minDate={minDate.toDate()}
                    maxDate={maxDate.toDate()}
                    minDetail="decade"
                    onChange={date => {
                      field.onChange(date)
                      onDateChange(date as Date)
                    }}
                  />
                  <InputErrorText variant="caption-regular">
                    {Boolean(fieldState.error?.message) && fieldState.error.message}
                  </InputErrorText>
                </Datepicker.Root>
              </InputWrapper>
            )}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
