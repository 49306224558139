import { useQuery } from '@/escolas/hooks'
import { Box } from '@material-ui/core'
import { AutorenewRounded } from '@material-ui/icons'
import { Button, Notification } from '@olaisaac/design-system'

export const PendingEnrollmentProcessingAlert = () => {
  const { setOnQueryParam } = useQuery()

  const toggleUpdate = () => {
    setOnQueryParam('true', 'refetch')
  }

  return (
    <>
      <Box py={3}>
        <Notification
          variation="warning"
          title="A geração de faturas está levando mais tempo que o previsto"
          description={`Estamos em processo de criação das faturas, e o procedimento pode estar demorando mais do que o previsto. Pedimos que aguarde um momento e, em seguida, clique em "atualizar" para verificar o andamento do processo.`}
          link={
            <>
              <Button
                startIcon={<AutorenewRounded />}
                aria-label="atualizar"
                onClick={() => toggleUpdate()}
              >
                Atualizar
              </Button>
            </>
          }
        />
      </Box>
    </>
  )
}
