import styled from 'styled-components'
import { spacing, colors } from '@gravity/tokens'
import { Text as DSText } from '@gravity/text'

export const BigNumberWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${spacing[8]};
  margin: ${spacing[8]} 0;
`

export const Text = styled(DSText)`
  color: ${colors['colors-text-main-2']};
`
export const SummaryPageWrapper = styled.div`
  padding-top: ${spacing[8]};
`
export const NoPendingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: ${spacing[12]};
  span {
    margin-top: ${spacing[2]};
    line-height: ${spacing[6]};
  }
  a {
    margin-top: ${spacing[4]};
  }
`
export const Image = styled.img`
  width: 124px;
  height: 116px;
  margin-bottom: ${spacing[2]};
`
