import dayjs, { Dayjs } from 'dayjs'
import { head } from 'ramda'
import { leftZeroPadding } from '@/shared/utils'

import localeData from 'dayjs/plugin/localeData'

const sanityCheck = fn => (n: datestring) => {
  if (!n) return null
  const dt = n.split('T')[0]
  return fn(dt)
}

export const epoch2dateString = (n: epoch) => {
  if (!n) return null
  return head(new Date(n).toISOString().split('T'))
}

export const months = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
}

export const monthsMin = {
  1: 'jan',
  2: 'fev',
  3: 'mar',
  4: 'abr',
  5: 'mai',
  6: 'jun',
  7: 'jul',
  8: 'ago',
  9: 'set',
  10: 'out',
  11: 'nov',
  12: 'dez',
}

export const splitDate = sanityCheck((n: datestring) => {
  const [year, month, day] = n.split('-')
  return { year, month, day }
})

export const month2String = sanityCheck((n: datestring) => {
  const { month, year } = splitDate(n)
  return `${months[+month]}/${year}`
})

export const DATE_FORMAT = 'DD/MM/YYYY'

export const date2PTFormat = sanityCheck((n: datestring) => {
  const { month, year, day } = splitDate(n)
  return [day, month, year].map(n => leftZeroPadding(+n, 2)).join('/')
})

export const month2StringShort = sanityCheck(
  (n: datestring) => `${leftZeroPadding(+n.split('-')[1], 2)}/${n.split('-')[0]}`
)

export const monthsToContractValidity = (p: { end_month: datestring; start_month: datestring }) => {
  if (!p?.start_month || !p?.end_month) return null
  const { start_month, end_month } = p
  return `${month2StringShort(start_month)} - ${month2StringShort(end_month)}`
}

export const dateToOnlyDateISO = (n: datestring) => {
  return dayjs(n).toISOString().substring(0, 'YYYY-MM-DD'.length)
}

export const datePTToOnlyDateISO = (n: Dayjs) => {
  if (!dayjs(n).isValid()) {
    return
  }
  return dayjs(n).format(DATE_FORMAT)
}

export const dueDateFormatter = (n: Dayjs) => {
  if (!dayjs(n).isValid()) {
    return
  }
  return dayjs(n).get('date')
}

export const unabbreviatedDate = (date?: datestring, locale = 'pt-BR') => {
  return new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'long', day: 'numeric' }).format(
    date ? new Date(date) : Date.now()
  )
}

/**
 * Function to format a date with a custom template
 *
 * @param date Date to be formatted
 * @param template Template to be used
 *
 * @returns Formatted date
 */
export const formatDate = (date: string | Date, template: string) => {
  return dayjs(date).utc().format(template)
}

/**
 * Function to format a date to a short month format (MMM/YYYY)
 * @param dateStr Date to be formatted
 * @returns Formatted date (MMM/YYYY)
 * @example
 * formatShortMonth('2023-01-01') // returns 'Jan/2023'
 * formatShortMonth('2021-12-01') // returns 'Dez/2021'
 */
export const formatShortMonth = (dateStr: string): string => {
  dayjs.extend(localeData)

  const date = dayjs(dateStr)

  const month = date.localeData().monthsShort(date)

  return `${month}/${date.format('YYYY')}`
}
