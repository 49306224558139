import styled from 'styled-components'

export const Container = styled.div`
  max-width: calc(1698px + 3rem);
  margin: 0 auto;
  padding: 0 1.5rem;
`

export const NewCampaignWrapper = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  margin: 24px 0;
  min-height: 720px;
`

export const ContentContainer = styled.div`
  flex: 3.5;
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  justify-content: space-between;
`
