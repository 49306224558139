import { AddCircle, RemoveCircle } from '@material-ui/icons'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { InfoCard } from '@/modules/report/components/InfoCard'

import { useQuery } from '@/escolas/hooks/useQuery'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

import theme from '@/shared/theme'

import { CardsContent } from './styles'
import { REPORT_PAGE_NAMES } from '@/modules/report/constants/REPORT_PAGE_NAMES'

type MainContentProps = {
  deductionsValue: number
  incomeValue: number
  isLoading: boolean
  onRequestOpenPayoutTransfersDrawer: () => void
}

const POSITIVE_COLOR = theme.primitiveTokens.colors.green[90]
const NEGATIVE_COLOR = theme.primitiveTokens.colors.coral[90]

const CARD_LABELS = {
  income: 'Ver todos os recebimentos',
  deductions: 'Ver todos os descontos',
}

/**
 * Component to group payout big number cards
 *
 * @param props
 * @param props.incomeValue Payout total income amount
 * @param props.deductionsValue Payout total deduction amount
 * @param props.isLoading Indicates if loading state should be displayed
 * @param props.onRequestOpenPayoutTransfersDrawer Callback function on click at link button
 */
export const MainContent = ({
  incomeValue,
  deductionsValue,
  isLoading,
  onRequestOpenPayoutTransfersDrawer,
}: MainContentProps) => {
  const { setOnQueryParam } = useQuery()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleCardLinkClick = (section: 'income' | 'deductions') => {
    setOnQueryParam(section, 'transfer_section', 'replace')

    onRequestOpenPayoutTransfersDrawer()

    const buttonLabel = CARD_LABELS[section]

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICK,
        scope: EventDispatcherEventScopes.PAYOUT_REPORT,
        action: 'click',
        customProperties: {
          $page_name: REPORT_PAGE_NAMES.TUITION_PAYOUT_REPORT,
          $button_label: buttonLabel,
        },
      })
  }

  return (
    <CardsContent>
      <InfoCard.Root isLoading={isLoading}>
        <InfoCard.Icon icon={AddCircle} color={POSITIVE_COLOR} />
        <InfoCard.Title>Recebimentos</InfoCard.Title>
        <InfoCard.Value>{incomeValue}</InfoCard.Value>
        <InfoCard.Action onClick={() => handleCardLinkClick('income')}>
          {CARD_LABELS.income}
        </InfoCard.Action>
      </InfoCard.Root>

      <InfoCard.Root isLoading={isLoading}>
        <InfoCard.Icon icon={RemoveCircle} color={NEGATIVE_COLOR} />
        <InfoCard.Title>Descontos</InfoCard.Title>
        <InfoCard.Value>{deductionsValue}</InfoCard.Value>
        <InfoCard.Action onClick={() => handleCardLinkClick('deductions')}>
          {CARD_LABELS.deductions}
        </InfoCard.Action>
      </InfoCard.Root>
    </CardsContent>
  )
}
