import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Box, CircularProgress } from '@material-ui/core'

import { useNavigation, useQuery } from '@/escolas/hooks'
import {
  useContractNeverPaidQuery,
  useConfirmNeverPaidMutation,
} from '@/escolas/hooks/queries/contracts'

import { LoadingBox } from '@/shared/components/LoadingBox'
import { useSnackbar } from '@/shared/hooks'

import { Header, Table, ModalConfirmation, NumbersReviewedCard, RequestError } from './components'
import { Container, Paragraph } from './styles'
import { ContractNeverPaidInstallmentType } from '@/shared/interfaces'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

export type TableDataProps = {
  guardianName: string
  id: string
  key: string
  student_name: string
  total_amount: number
  total_installments: number
  total_products: number
}

const DEFAULT_PAGE_NUMBER = 1
const DEFAULT_PAGE_COUNT = 50

export function PendingCharges() {
  const { schoolId, schoolSlug, navigateTo } = useNavigation()
  const { query } = useQuery()
  const { setMessage, setVariation, setIsOpen } = useSnackbar()

  const queryClient = useQueryClient()

  const [isOpenModalConfirmation, setIsOpenModalConfirmation] = useState(false)
  const [selectedRows, setSelectedRows] = useState<TableDataProps[]>([])

  const page = Number(query.get('page')) || DEFAULT_PAGE_NUMBER
  const per_page = Number(query.get('per_page')) || DEFAULT_PAGE_COUNT
  const pagination = { page, per_page }
  const currentYear = new Date().getFullYear()

  const { isLoading, isFetching, isError, data: result, refetch } = useContractNeverPaidQuery(
    {
      schoolId,
      pagination,
      referenceYear: currentYear.toString(),
      installmentType: ContractNeverPaidInstallmentType.NEVER_PAID,
    },
    {
      keepPreviousData: true,
    }
  )

  const confirmNeverPaidMutation = useConfirmNeverPaidMutation()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const handleConfirm = () => {
    confirmNeverPaidMutation.mutate(
      {
        schoolId,
        uniquekeys: [...selectedRows.map(row => row.key)],
      },
      {
        onSuccess: () => {
          setIsOpenModalConfirmation(false)
          setSelectedRows([])

          queryClient.invalidateQueries(['contracts', 'neverPaid'])

          if (isInitialized) {
            eventDispatcherClient.sendEvent({
              scope: EventDispatcherEventScopes.NEVER_PAID,
              name: EventDispatcherEvents.NEVER_PAID_CONFIRM_FINISH,
              action: 'click',
              customProperties: {
                $origin: 'Tela inicial - Botão "Manter cobrança" da modal',
              },
            })
          }
        },
        onError: () => {
          setIsOpenModalConfirmation(false)
          setMessage('Ocorreu um erro ao confirmar a(s) parcela(s). Tente novamente mais tarde.')
          setVariation('error')
          setIsOpen(true)
        },
      }
    )
  }

  const handleReview = () => {
    navigateTo({
      slug: schoolSlug,
      path: `/validacao-de-inadimplencia/${selectedRows[0].id}`,
    })

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NEVER_PAID,
        name: EventDispatcherEvents.NEVER_PAID_DETAILS_ACCESS,
        action: 'click',
        customProperties: {
          $origin: 'Tela inicial - Botão "Revisar cobranças" da modal',
        },
      })
    }
  }

  const rows =
    result?.data.installments?.map(row => ({
      key: row.unique_key,
      id: row.unique_key,
      ...row,
    })) || []

  if (isLoading) {
    return (
      <LoadingBox>
        <CircularProgress />
      </LoadingBox>
    )
  }

  if (isError || !result) return <RequestError onRefetch={refetch} />

  return (
    <Container marginTop="3rem">
      <Header
        onClickBack={() => {
          navigateTo({ slug: schoolSlug, path: '/' })
        }}
      />

      <NumbersReviewedCard
        totalAmount={result.data.total_amount}
        totalInstallments={result.data.total_installments}
        totalStudents={result.data.total_students}
      />

      <Box marginTop="2rem">
        <Paragraph>Escolha em qual categoria as cobranças se encaixam:</Paragraph>

        <ul style={{ paddingLeft: '1.2rem' }}>
          <li>
            <Paragraph>
              <strong>Manter cobranças:</strong> as cobranças continuam em aberto e o aluno fica
              inapto para rematrícula até a dívida ser quitada ou negociada.
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <strong>Registrar recebimento:</strong> parcelas que foram pagas na escola mas não
              tiveram sua baixa efetuada no sistema. As famílias saem do perfil de inadimplência e
              deixam de ser cobradas indevidamente.
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <strong>Cancelar o contrato:</strong> alunos que não estão mais ativos para o ano
              vigente. O cancelamento de contrato afeta o valor de repasse.
            </Paragraph>
          </li>
        </ul>
      </Box>

      <Table
        isLoading={isFetching}
        rows={rows}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        currentPage={pagination.page}
        itensPerPage={pagination.per_page}
        totalRows={result.pagination.total}
        onRequestOpenModalConfirmation={() => setIsOpenModalConfirmation(true)}
      />

      <ModalConfirmation
        isLoading={confirmNeverPaidMutation.isLoading}
        isOpen={isOpenModalConfirmation}
        onClose={() => setIsOpenModalConfirmation(false)}
        onReview={handleReview}
        onConfirm={handleConfirm}
      />
    </Container>
  )
}
