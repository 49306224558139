import { FC, useState, createContext, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { Notification, NotificationProps } from '@olaisaac/design-system'

const StyledSnackbar = styled(Notification)`
  max-width: 330px;
`

export type SnackbarContext = {
  isOpen: boolean
  keepOpen: boolean
  message: string
  setIsOpen: (value: boolean, snackbarAditionalProps?: SnackbarAditionalProps) => void
  setKeepOpen: Dispatch<SetStateAction<boolean>>
  setMessage: Dispatch<SetStateAction<string>>
  setVariation: Dispatch<SetStateAction<NotificationProps['variation']>>
  variation: NotificationProps['variation']
}
export const SnackbarContext = createContext<SnackbarContext>(null)

type SnackbarProviderProps = { children: React.ReactNode }

export type SnackbarAditionalProps = {
  description?: NotificationProps['description']
  link?: NotificationProps['link']
  title?: NotificationProps['title']
  variation?: NotificationProps['variation']
}

export const SnackbarProvider: FC<SnackbarProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [keepOpen, setKeepOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [snackbarAditionalProps, setSnackbarAditionalProps] = useState<SnackbarAditionalProps>({})
  const [variation, setVariation] = useState<NotificationProps['variation']>('success')

  const handleOpen = (value = false, aditionalProps = {}) => {
    if (!value) return setIsOpen(false)

    setIsOpen(true)
    setSnackbarAditionalProps(aditionalProps)

    if (keepOpen) return

    const timeout = setTimeout(() => {
      setIsOpen(false)
    }, 6000)

    return () => {
      clearTimeout(timeout)
    }
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <SnackbarContext.Provider
      value={{
        isOpen,
        keepOpen,
        message,
        setIsOpen: handleOpen,
        setKeepOpen,
        setMessage,
        setVariation,
        variation,
      }}
    >
      {children}
      {isOpen && (
        <StyledSnackbar
          onClose={handleClose}
          description={message}
          variation={variation}
          floating
          {...snackbarAditionalProps}
        />
      )}
    </SnackbarContext.Provider>
  )
}
