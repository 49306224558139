import { Typography } from '@olaisaac/design-system'
import { FC } from 'react'
import { formatCentsToReal } from 'src/shared/utils'
import { StyledLink, Wrapper, WrapperColumn } from './InformationTitle'
import { AgreementSimulationCombined, ReceivableStatuses } from 'src/shared/interfaces'
import { getReceivableStatus } from '../helpers/getReceivableStatus'

export interface InformationTitleAgreementProps {
  agreementSimulations: AgreementSimulationCombined[]
  handleClick: () => void
  positionAgreement: number
  totalAmount: number
}

const formatTitleV2 = (student: string, product: string) => {
  return `${student} - ${product}`
}

const formatInstallmentsV2 = (
  count: number,
  isOverdue: boolean,
  totalAmount: number,
  position: number
) => {
  const needPlural = count === 1 ? '' : 's'
  if (!isOverdue) {
    return `${position}º pagamento - ${count} parcela${needPlural} a vencer - ${formatCentsToReal(
      totalAmount
    )}`
  }
  return `${position}º pagamento - ${count} parcela${needPlural} vencida${needPlural} - ${formatCentsToReal(
    totalAmount
  )}`
}

const InformationTitleAgreement: FC<InformationTitleAgreementProps> = ({
  handleClick,
  agreementSimulations,
  totalAmount,
  positionAgreement,
}) => {
  const isOverdue = agreementSimulations[positionAgreement].receivables.every(
    receivable => getReceivableStatus(receivable) === ReceivableStatuses.OVERDUE
  )
  const student = agreementSimulations[positionAgreement].receivables[0].student.split(' ')[0]
  const product = agreementSimulations[positionAgreement].receivables[0].product
  const count = agreementSimulations[positionAgreement].receivable_ids.length

  return (
    <WrapperColumn>
      <Typography withoutMargin color="primary" variation="headlineDesktopSmall">
        {formatTitleV2(student, product)}
      </Typography>
      <Wrapper>
        <Typography color="secondary" variation="bodySmall">
          {formatInstallmentsV2(count, isOverdue, totalAmount, positionAgreement + 1)}
        </Typography>
        <StyledLink onClick={handleClick}>
          <Typography color="accent">Ver detalhes</Typography>
        </StyledLink>
      </Wrapper>
    </WrapperColumn>
  )
}

export default InformationTitleAgreement
