import dayjs from 'dayjs'
import { Grid } from '@material-ui/core'
import { FC, useEffect } from 'react'
import Icon from '@mdi/react'
import { mdiPlus } from '@mdi/js'
import { UseFormReturn, ValidateResult, useFieldArray } from 'react-hook-form'

import { OfferItem } from '../OfferItem'
import { FormProps } from '../../types'
import * as Styled from './styles'

type Props = {
  form: UseFormReturn<FormProps, any>
  referenceYear: number
}

const MAX_OFFER_ITEMS = 3

export const OfferList: FC<Props> = ({ form, referenceYear }) => {
  const { fields: offerFields, append: appendOffer, remove: removeOffer } = useFieldArray({
    control: form.control,
    name: 'offers',
  })
  const offers = form.watch('offers')

  const isAddOfferBtnDisabled = offerFields.length >= MAX_OFFER_ITEMS
  const isRemoveButtonDisabled = offerFields.length === 1

  const addNewOfferInput = () => {
    appendOffer({
      amount: 0,
      expirationDate: null,
    })
  }

  const handleRemoveOfferClick = (index: number) => {
    return () => removeOffer(index)
  }

  const calculateTheLatestOfferDate = (date: Date = null) => {
    const latestOfferDate = offers.reduce(
      (acc, curr) => (curr.expirationDate && curr.expirationDate > acc ? curr.expirationDate : acc),
      date
    )
    if (latestOfferDate) form.setValue('end_date', latestOfferDate)
  }

  const validateOfferDueDate = (date: Date, index: number): ValidateResult => {
    if (!date) return 'Você deve selecionar uma data de expiração'

    if (index === 0) return true

    const prevOfferDate = offers[index - 1].expirationDate
    if (prevOfferDate && dayjs(date).isSameOrBefore(dayjs(prevOfferDate))) {
      return 'A data de validade deve ser posterior à data da condição anterior.'
    }

    return true
  }

  useEffect(() => {
    return () => {
      form.resetField('offers')
      form.resetField('end_date')
    }
  }, [])

  useEffect(() => {
    if (offerFields.length === 0) {
      appendOffer({
        amount: 0,
        expirationDate: null,
      })
    } else {
      calculateTheLatestOfferDate()
    }
  }, [offerFields])

  return (
    <>
      <div role="list" aria-name="Lista de ofertas">
        {offerFields.map((offer, index) => (
          <OfferItem
            referenceYear={referenceYear}
            disableRemoveButton={isRemoveButtonDisabled}
            form={form}
            key={offer.id}
            index={index}
            onClickRemove={handleRemoveOfferClick(index)}
            onDateChange={calculateTheLatestOfferDate}
            validateOfferDueDate={date => validateOfferDueDate(date, index)}
          />
        ))}
      </div>

      <Grid container spacing={6}>
        <Grid item sm={6}>
          <Styled.OfferButton
            disabled={isAddOfferBtnDisabled}
            variant="outline"
            iconStart={<Icon path={mdiPlus} />}
            onClick={addNewOfferInput}
            type="button"
          >
            Adicionar nova condição
          </Styled.OfferButton>
        </Grid>
      </Grid>
    </>
  )
}
