import dayjs from 'dayjs'
import { uniq } from 'ramda'
import { processInstallments } from 'src/escolas/components/contract/ContractDetails'
import {
  AgglutinationProcessedInstallment,
  CheckoutCombinableReceivable,
} from 'src/escolas/contexts/checkoutContext'
import { Contract, ProcessedInstallment, ReceivableStatuses } from 'src/shared/interfaces'
import { months, sortByYearAndMonth } from 'src/shared/utils'
import { prettifyFullName } from 'src/shared/utils/namePrettifiers'

const findOriginalReceivables = (contract: Contract, receivable_id: uuid, installment_id: uuid) => {
  const installment = contract?.installments?.find(ins => ins.id === installment_id)
  if (installment) {
    const receivable = installment.receivables?.find(rec => rec.id === receivable_id)
    if (receivable) {
      const isNotAgglutination = receivable.original_receivables?.filter(
        original => original.status !== ReceivableStatuses.AGGLUTINATED
      )
      return isNotAgglutination?.length === 0 ? receivable.original_receivables : null
    }
  }
  return null
}
const getChildInstallments = (
  installment: ProcessedInstallment,
  contractID: string,
  productID: string,
  productName: string,
  studentID: string,
  studentName: string
) => {
  const children: AgglutinationProcessedInstallment[] = []
  if (!installment.children?.length) {
    return children
  }
  installment.children.forEach(child => {
    const newChild = getChildInstallments(
      child,
      contractID,
      productID,
      productName,
      studentID,
      studentName
    )
    children.push({
      amount: child.amount,
      contractID: contractID,
      children: newChild,
      competence: [],
      due_date: child.due_date,
      due_month: null,
      id: child.id,
      installment_id: child.installment_id,
      key: child.key,
      orderReference: child.orderReference,
      productID: productID,
      productName: productName,
      status: child.status,
      studentID: studentID,
      studentName: prettifyFullName(studentName),
      type: child.type,
    })
  })
  return children
}
export const getInstallmentsByContract = (contracts: Contract[]) => {
  const processedInstallmentsByContract: AgglutinationProcessedInstallment[] = []

  if (contracts) {
    contracts?.forEach(contract => {
      const { id: contractID, student, product } = contract || {}

      const studentName = student?.name
      const studentID = student?.id

      const productName = product?.name
      const productID = product?.id

      const installments = processInstallments(contract)
      installments?.forEach(installment => {
        const children = getChildInstallments(
          installment,
          contractID,
          productID,
          productName,
          studentID,
          studentName
        )
        const originalReceivables = findOriginalReceivables(
          contract,
          installment.id,
          installment.installment_id
        )
        let competence = [installment.due_month.format('MMMM')]
        if (originalReceivables?.length > 0) {
          const mapCompetence: string[] = uniq(
            originalReceivables.map(({ due_date }) => {
              const due_month = dayjs(due_date).utc()
              return due_month?.format('MMMM')
            })
          )
          competence = mapCompetence.sort((a, b) => {
            return months[a?.toString()] - months[b?.toString()]
          })
        }
        processedInstallmentsByContract.push({
          amount: installment.amount,
          contractID: contractID,
          competence: competence,
          children: children,
          due_date: installment.due_date,
          due_month: installment.due_month,
          id: installment.id,
          installment_id: installment.installment_id,
          key: installment.key,
          orderReference: installment.orderReference,
          productID: productID,
          productName: productName,
          status: installment.status,
          studentID: studentID,
          studentName: prettifyFullName(studentName),
          type: installment.type,
        })
      })
    })
  }
  return processedInstallmentsByContract
}

export const filterReceivables = (
  processedInstallments: AgglutinationProcessedInstallment[],
  filtersSelected: any
) => {
  let newList: AgglutinationProcessedInstallment[] = processedInstallments
  if (filtersSelected) {
    Object.keys(filtersSelected).forEach(key => {
      const lowerCaseFilters = filtersSelected[key].map(element => {
        return element?.toLowerCase()
      })
      newList = newList.filter(item => lowerCaseFilters.includes(item[key]?.toLowerCase()))
    })
    newList.sort(sortByYearAndMonth)

    return newList
  }
  processedInstallments.sort(sortByYearAndMonth)
  return processedInstallments
}

export const checkSameContract = (receivables: CheckoutCombinableReceivable[]) => {
  const contractList = []
  for (const receivable of receivables) {
    if (contractList.length === 0) {
      contractList.push(receivable.contract_id)
    } else {
      const checkAlreadyExists = contractList.find(x => x === receivable.contract_id)
      if (!checkAlreadyExists) {
        return false
      }
    }
  }
  return true
}

export const convertCombinableReceivableToProcessedInstallment = (
  receivable: CheckoutCombinableReceivable
) => {
  const newProcessedInstallment: ProcessedInstallment = {
    amount: receivable.amount,
    children: null,
    due_date: receivable.due_date,
    due_month: dayjs(receivable.due_date).utc(),
    id: receivable.id,
    installment_id: receivable.installment_id,
    orderReference: receivable.orderReference,
    status: receivable.status,
    key: `installment::${receivable?.id}`,
  }
  return newProcessedInstallment
}
