import {
  CardContainer,
  StudentInfoContainer,
  StudentNameBadge,
  EnrollmentInfoContainer,
  StatusDetailsContainer,
  StyledTypography,
  StudentNameContainer,
  StatusContainer,
  StyledIconButton,
  StyledNameTypography,
} from './styles'
import { StatusBadge } from '@/modules/enrollment/components/StatusBadge'
import { StatusDetailsBadge } from '@/modules/enrollment/components/StatusDetailsBadge'
import { ENROLLMENT_PAGE_BASE_PATH } from '@/modules/enrollment/constants/enrollment'
import OpenInNewRounded from '@material-ui/icons/OpenInNewRounded'
import { useNavigation } from '@/escolas/hooks'
import { StudentCardProps } from './types'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { Tooltip } from '@material-ui/core'
import { MAX_STUDENT_NAME_CHARACTERS } from '../../constants'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export const StudentCard = ({ student, isExpanded, enrollmentYear }: StudentCardProps) => {
  const { newTabNavigateTo } = useNavigation()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const studentInitial = student?.student_name?.split('')?.[0]
  const { isCollege } = useSelectedSchool()

  const sendEvent = () => {
    eventDispatcherClient.sendEvent({
      scope: EventDispatcherEventScopes.GUARDIANS,
      name: EventDispatcherEvents.CARD_VIEW_STUDENT_PAGE,
      action: 'click',
    })
  }

  const redirectToStudentPage = () => {
    isInitialized && sendEvent()

    newTabNavigateTo({ path: `/${ENROLLMENT_PAGE_BASE_PATH}/${student.student_id}` })
  }

  const className = !isExpanded && 'compacted'

  const disableTooltip = Boolean(student?.student_name?.length <= MAX_STUDENT_NAME_CHARACTERS)

  return (
    <CardContainer className={className} onClick={redirectToStudentPage}>
      <StudentInfoContainer className={className}>
        <StudentNameBadge className="student-name-badge">
          <StyledTypography variation="subtitleDesktopMedium" color="accent">
            {studentInitial}
          </StyledTypography>
        </StudentNameBadge>
        <StudentNameContainer className={className}>
          <Tooltip
            title={`${student?.student_name}`}
            placement="bottom-end"
            disableHoverListener={disableTooltip}
          >
            <StyledNameTypography
              color="primary"
              variation="subtitleDesktopMedium"
              title={student?.student_name}
            >
              {student?.student_name}
            </StyledNameTypography>
          </Tooltip>
        </StudentNameContainer>

        <StyledIconButton>
          <OpenInNewRounded fontSize="small" color="primary" />
        </StyledIconButton>
      </StudentInfoContainer>

      {isExpanded && !isCollege ? (
        <EnrollmentInfoContainer>
          <StatusContainer>
            <StatusBadge status={student?.student_status} />
          </StatusContainer>
          <StatusDetailsContainer>
            <StatusDetailsBadge
              status={student?.student_status}
              details={student?.student_status_details}
              currentYear={enrollmentYear}
            />
          </StatusDetailsContainer>
        </EnrollmentInfoContainer>
      ) : null}
    </CardContainer>
  )
}
