import { useEffect } from 'react'
import { useJWT } from '@/shared/hooks'
import { getSegmentFilters } from '@/shared/hooks/useBeamer'

import {
  useNotificationsCountQuery,
  useNotificationsQuery,
  useUnreadNotificationsCountQuery,
  useUnreadNotificationsQuery,
} from '../hooks'

import { notificationsService } from '../services'

import type { Pagination } from '../types'

type NotificationQueryParams = Pagination & {
  category: string
}

export const useLoadAllNotifications = ({ count, page, category }: NotificationQueryParams) => {
  const jwtResponse = useJWT()
  const queryParams = {
    userId: jwtResponse.userId,
    filter: getSegmentFilters(jwtResponse),
    category,
    pagination: {
      count,
      page: page - 1,
    },
  }

  const queryOptions = {
    cacheTime: 0,
  }

  const {
    data: notificationsCount,
    isFetching: isCountFetching,
    isError: isCountError,
    refetch: refetchNotificationsCount,
  } = useNotificationsCountQuery(queryParams, queryOptions)

  const {
    data: notifications,
    isFetching: isNotificationsFetching,
    isError: isNotificationsError,
    refetch: refetchNotifications,
  } = useNotificationsQuery(queryParams, queryOptions)

  const {
    data: unreadNotificationsCount,
    isFetching: isUnreadNotificationsCountFetching,
    isError: isUnreadNotificationsCountError,
    refetch: refetchUnreadNotificationsCount,
  } = useUnreadNotificationsCountQuery(queryParams, queryOptions)

  const {
    data: unreadNotifications,
    isFetching: isUnreadNotificationsFetching,
    isError: isUnreadNotificationsError,
    refetch: refetchUnreadNotifications,
  } = useUnreadNotificationsQuery(queryParams, queryOptions)

  // Refetch only requests with error to avoid unnecessary requests
  const refetch = () => {
    if (isCountError) {
      refetchNotificationsCount()
    }

    if (isNotificationsError) {
      refetchNotifications()
    }

    if (isUnreadNotificationsCountError) {
      refetchUnreadNotificationsCount()
    }

    if (isUnreadNotificationsError) {
      refetchUnreadNotifications()
    }
  }

  // It handles the beamer notifications badge visibility
  useEffect(
    () => async () => {
      const { fetchUnreadNotifications } = notificationsService()
      await fetchUnreadNotifications({ ...queryParams, markAsRead: true })

      const beamer = (window as any).Beamer
      beamer?.clearAlert()
      beamer?.appendAlert(true, true)
    },
    []
  )

  return {
    data: {
      notificationsCount,
      notifications,
      unreadNotificationsCount,
      unreadNotifications,
    },
    isLoading:
      isCountFetching ||
      isNotificationsFetching ||
      isUnreadNotificationsCountFetching ||
      isUnreadNotificationsFetching,
    isError:
      isCountError ||
      isNotificationsError ||
      isUnreadNotificationsCountError ||
      isUnreadNotificationsError,
    refetch,
  }
}
