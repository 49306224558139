import { Icon } from '@mdi/react'
import { mdiPencil, mdiTrashCan } from '@mdi/js'
import { Table } from '@gravity/table'
import { IconButton } from '@gravity/icon-button'
import { LoadingState } from './components/LoadingState'

import { formatCentsToReal } from '@/shared/utils/numberFormatters'

import type { Product } from '@/modules/products/models/Product'
import type { Ordering, SortOrder } from '@/shared/hooks/useOrdering/types'
import { useState } from 'react'
import * as Styled from './styles'

type Columns =
  | 'name'
  | 'total_amount'
  | 'enrollment_amount'
  | 'enrollment_installments_quantity'
  | 'monthly_amount'
  | 'installments_quantity'
  | 'actions'

export type SortableColumns =
  | 'name'
  | 'enrollment_amount'
  | 'enrollment_installments_quantity'
  | 'monthly_amount'
  | 'installments_quantity'

type ProductsTableProps = {
  isLoading?: boolean
  onChangeOrdering: (sortBy: SortableColumns, sortOrder: SortOrder) => void
  onRequestDelete: (product: Product) => void
  onRequestEdit: (product: Product) => void
  ordering: Ordering
  products: Array<Product & { total_amount: number }>
}

export const COLUMN_HEADERS: Array<{
  center?: boolean
  label: string
  name: Columns
  sortable?: boolean
}> = [
  { name: 'name', label: 'Produto', sortable: true },
  { name: 'total_amount', label: 'Anuidade', sortable: false },
  { name: 'enrollment_amount', label: 'Pré-matrícula', sortable: true },
  { name: 'enrollment_installments_quantity', label: 'Qt. pré-matrícula', sortable: true },
  { name: 'monthly_amount', label: 'Mensalidade', sortable: true },
  { name: 'installments_quantity', label: 'Qt. mensalidade', sortable: true },
  { name: 'actions', label: '', center: true },
]

export const ProductsTable = ({
  products,
  ordering,
  onChangeOrdering,
  isLoading = true,
  onRequestEdit,
  onRequestDelete,
}: ProductsTableProps) => {
  const [hoveredRow, setHoveredRow] = useState(null)

  return (
    <Table.Root>
      <Table.Head>
        {COLUMN_HEADERS.map(({ label, name, sortable = false, center = false }) => (
          <Table.HeaderCell
            key={name}
            name={name}
            sortable={!isLoading && sortable}
            sortOrder={
              ordering.sortBy === name && ordering.sortOrder
                ? (ordering.sortOrder.toLowerCase() as Lowercase<SortOrder>)
                : undefined
            }
            align={center ? 'center' : 'start'}
            onSort={(sortBy, sortOrder) =>
              onChangeOrdering(
                sortBy as SortableColumns,
                sortOrder.toLocaleUpperCase() as Uppercase<'asc' | 'desc'>
              )
            }
          >
            {label}
          </Table.HeaderCell>
        ))}
      </Table.Head>

      <Table.Body>
        {isLoading ? (
          <LoadingState />
        ) : (
          products.map(product => (
            <Table.Row
              key={product.id}
              highlighOnHover
              onMouseEnter={() => setHoveredRow(product.id)}
              onMouseLeave={() => setHoveredRow(null)}
            >
              <Table.TextCell width={313}>{product.name}</Table.TextCell>
              <Table.TextCell width={212}>{formatCentsToReal(product.total_amount)}</Table.TextCell>
              <Table.TextCell width={244}>
                {formatCentsToReal(product.enrollment_amount)}
              </Table.TextCell>
              <Table.TextCell
                width={284}
              >{`${product.enrollment_installments_quantity}x`}</Table.TextCell>
              <Table.TextCell width={244}>
                {formatCentsToReal(product.monthly_amount)}
              </Table.TextCell>
              <Table.TextCell width={277}>{`${product.installments_quantity}x`}</Table.TextCell>
              <Table.CustomCell align="center">
                <Styled.ActionsWrapper isHovered={hoveredRow === product.id}>
                  <IconButton size={2} variant="ghost" onClick={() => onRequestEdit(product)}>
                    <Icon path={mdiPencil} />
                  </IconButton>
                  <IconButton size={2} variant="ghost" onClick={() => onRequestDelete(product)}>
                    <Icon path={mdiTrashCan} />
                  </IconButton>
                </Styled.ActionsWrapper>
              </Table.CustomCell>
            </Table.Row>
          ))
        )}
      </Table.Body>
    </Table.Root>
  )
}
