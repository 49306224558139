import { useApi } from '@/shared/hooks'
import { creditService } from '../../services'
import { useQuery } from '@tanstack/react-query'
import { CreditReason } from '../../services/types'

export const creditReasonsQueryKey = 'credit-reasons'

export const useGetCreditReasons = () => {
  const { apiClient } = useApi()
  const service = creditService(apiClient.getClients().bffApi)

  return useQuery<CreditReason[]>([creditReasonsQueryKey], async () => {
    const { data } = await service.getCreditReasons()
    return data
  })
}
