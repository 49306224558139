import { useEffect, useState } from 'react'
import { Box, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useApi, useUnleashFlag, UnleashFlags, useSnackbar } from '@/shared/hooks'
import { useNavigation } from '@/escolas/hooks'
import { convertToUTCDate, downloadFile } from '@/shared/utils'
import { useProfileDetails } from '@/escolas/pages/Home/hooks/useProfileDetails'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useLayout } from '@/shared/hooks/useLayout'

import { PageWrapper } from '@/escolas/components/PageWrapper'
import { DatePicker } from '@/escolas/components/DatePicker'
import { ReportCard } from '@/escolas/components/ReportCard'

import { isIsaacPaySchool } from '@/modules/isaacpay/shared/utils/chargeOperationHelpers'

import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { Report } from '@/shared/interfaces'

import { REPORT_NAME } from '../../constants'

const REPORT_TYPES = {
  ticketSummary: 'Relatório de atendimento',
  payment: 'Relatório de pagamentos',
  ir: 'Relatório do demonstrativo de valores pagos',
}

export const OldReports = () => {
  const { api } = useApi()
  const { push } = useHistory()
  const { schoolId, schoolSlug } = useNavigation()
  const { setMessage, setIsOpen, setVariation } = useSnackbar()
  const { isMaintainer } = useProfileDetails()
  const { school } = useSelectedSchool()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: 'Relatórios',
  })

  const isDemonstrativeIRReportEnabled = useUnleashFlag(
    UnleashFlags.REG_1926_ENABLE_DEMONSTRATIVE_IR_REPORT
  )

  const isIsaacPayIncomesPanelEnabled = useUnleashFlag(
    UnleashFlags.ISAAC_PAY_261_ENABLE_INCOMES_REPORT
  )

  useLayout({ enableSideMenu: true, enableHeader: true, headerTitle: 'Relatórios' })

  const [selectedDate, setSelectedDate] = useState<Date>(dayjs().utc().toDate())
  const [availableMonths, setAvailableMonths] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const minDate = availableMonths[availableMonths.length - 1]
  const maxDate = availableMonths[0]
  const minDateUTC = convertToUTCDate(minDate).format('YYYY-MM')
  const maxDateUTC = convertToUTCDate(maxDate).format('YYYY-MM')
  const isIsaacPay = isIsaacPaySchool(school)

  useEffect(() => {
    const getListTickerSummary = async () => {
      setIsLoading(true)

      api.ticketsSummary
        .getList(schoolId)
        .then(({ data }) => {
          if (data.available_months) {
            setAvailableMonths(data.available_months)
            setSelectedDate(dayjs(data.available_months[0]).utc().toDate())
          }
        })
        .catch(() => {
          setVariation('error')
          setMessage(
            'Ocorreu um erro inesperado. Se o erro persistir, entre em contato com o suporte.'
          )
          setIsOpen(true)
        })
        .finally(() => setIsLoading(false))
    }

    if (isMaintainer) {
      getListTickerSummary()
    }

    return () => {
      setIsOpen(false)
    }
  }, [])

  const handleDownloadReportPayments = async () => {
    let isDownloadSuccessful = true

    const reportFileDownload = async (schoolId: string, reportType: Report) => {
      const { data } = await api.schools.downloadReport(schoolId, reportType)

      return data
    }

    try {
      await downloadFile('relatório-de-cobranças', 'xlsx', () =>
        reportFileDownload(schoolId, Report.PAYMENT_STATUS)
      )
    } catch {
      isDownloadSuccessful = false

      setMessage(
        'Erro ao baixar relatório. Por favor tente novamente mais tarde ou entre em contato com o nosso suporte.'
      )
      setIsOpen(true, { variation: 'error' })
    } finally {
      isInitialized &&
        eventDispatcherClient.sendEvent({
          name: EventDispatcherEvents.DOWNLOAD_FILE,
          scope: EventDispatcherEventScopes.REPORTS,
          action: 'file_download',
          customProperties: {
            $report: REPORT_TYPES.payment,
            $is_download_successful: isDownloadSuccessful,
          },
        })
    }
  }

  const handleDownloadIR = async () => {
    let isDownloadSuccessful = true

    try {
      const { url } = await api.schools.getPresignedUrl({
        extension: 'zip',
        file_name: schoolId,
        method: 'GET',
      })

      const zipFileDownload = async (fileName: string) => {
        const { data } = await api.schools.downloadFile(fileName)
        return data
      }

      await downloadFile(schoolId, 'zip', () => zipFileDownload(url))
    } catch {
      isDownloadSuccessful = false

      setMessage(
        'Erro ao baixar relatório. Por favor tente novamente mais tarde ou entre em contato com o nosso suporte.'
      )
      setIsOpen(true, { variation: 'error' })
    } finally {
      isInitialized &&
        eventDispatcherClient.sendEvent({
          name: EventDispatcherEvents.BUTTON_CLICK,
          scope: EventDispatcherEventScopes.DEMONSTRATIVE_IR_REPORT,
          action: 'file_download',
          customProperties: {
            $is_download_successful: isDownloadSuccessful,
            $report: REPORT_TYPES.ir,
            $Button_name: 'Baixar relatório do demonstrativo de valores pagos 2023',
          },
        })
    }
  }

  const handleDownloadTicketsSummaryReport = async (selectedYearAndMonth: Date) => {
    let isDownloadSuccessful = true
    const date = dayjs(selectedYearAndMonth).utc().format('YYYY-MM-DD')
    const reportFileDownload = async (schoolId: string, date: string) => {
      const { data } = await api.ticketsSummary.download(schoolId, date)

      return data
    }

    try {
      await downloadFile(`relatório-de-atendimentos-${selectedYearAndMonth}`, 'pdf', () =>
        reportFileDownload(schoolId, date)
      )
    } catch {
      isDownloadSuccessful = false

      setMessage(
        'Erro ao baixar relatório. Por favor tente novamente mais tarde ou entre em contato com o nosso suporte.'
      )
      setIsOpen(true, { variation: 'error' })
    } finally {
      isInitialized &&
        eventDispatcherClient.sendEvent({
          name: EventDispatcherEvents.DOWNLOAD_FILE,
          scope: EventDispatcherEventScopes.REPORTS,
          action: 'file_download',
          customProperties: {
            $report: REPORT_TYPES.ticketSummary,
            $is_download_successful: isDownloadSuccessful,
          },
        })
    }
  }

  const handleNavigateToFIDCReport = () => {
    push(`/${schoolSlug}/relatorios/validacao-de-contratos`)

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.FIDC_REPORT,
        name: EventDispatcherEvents.NAVIGATION_TO_FIDC_REPORT,
        action: 'click',
      })
  }

  const goToFamilyRegularizationReport = () => {
    push(`/${schoolSlug}/relatorio/${REPORT_NAME['regularizacao-das-familias']}`)

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.FAMILY_REGULARIZATION_REPORT,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: { $Button_name: 'Acessar relatório de regularização das famílias' },
      })
  }

  const goToReceiptsReport = () => {
    push(`/${schoolSlug}/relatorio/${REPORT_NAME['visao-de-recebimentos']}`)

    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICK,
        scope: EventDispatcherEventScopes.ISAAC_PAY_INCOME_PANEL,
        action: 'click',
        customProperties: { $Button_name: 'Acessar painel de recebimentos isaac pay' },
      })
  }

  if (isLoading) {
    return null
  }

  const canViewIsaacPayIncomePanel = isIsaacPay && isIsaacPayIncomesPanelEnabled && isMaintainer

  return (
    <PageWrapper>
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gridGap="2rem" paddingTop="2.5rem">
        {canViewIsaacPayIncomePanel && (
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <ReportCard
                  title="Visão de recebimentos"
                  subTitle="Atualizado a cada 3 horas"
                  description="As principais informações sobre recebimentos, atualizadas ao longo do dia."
                  variant="link"
                  onClickButton={goToReceiptsReport}
                />
              </Grid>
            </Grid>
          </Box>
        )}

        <Box>
          <Grid container>
            <Grid item xs={12}>
              <ReportCard
                title="Relatório de pagamentos"
                description="Relatórios com principais informações sobre cobranças liquidadas, inadimplentes e/ou abertas"
                onClickButton={handleDownloadReportPayments}
              />
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container>
            <Grid item xs={12}>
              <ReportCard
                title="Relatório de regularização das famílias"
                subTitle="ATUALIZADO DIARIAMENTE"
                description="As principais informações sobre a inadimplência, cobrança e regularização das famílias."
                variant="link"
                onClickButton={goToFamilyRegularizationReport}
              />
            </Grid>
          </Grid>
        </Box>

        {isDemonstrativeIRReportEnabled && (
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <ReportCard
                  title="Demonstrativo de valores pagos 2023"
                  description="Demonstrativo de valores pagos referente às despesas educacionais em 2023 para auxílio na declaração de Imposto de Renda. Para extrair os arquivos da pasta compactada, utilize como senha o CNPJ da escola (somente números)."
                  onClickButton={handleDownloadIR}
                />
              </Grid>
            </Grid>
          </Box>
        )}

        {isMaintainer
          ? availableMonths.length > 0 && (
              <Box>
                <Grid container>
                  <Grid item xs={12}>
                    <ReportCard
                      title="Relatório de atendimentos"
                      subTitle={
                        <DatePicker
                          labelSize="small"
                          maxDetail="year"
                          minDate={dayjs(minDateUTC).toDate()}
                          maxDate={dayjs(maxDateUTC).toDate()}
                          value={selectedDate}
                          onChange={month => setSelectedDate(month)}
                        />
                      }
                      description="Relatórios com principais informações sobre a qualidade do atendimento isaac prestado às famílias."
                      onClickButton={() => handleDownloadTicketsSummaryReport(selectedDate)}
                    />
                  </Grid>
                </Grid>
              </Box>
            )
          : null}
        {!isIsaacPay ? (
          <Box>
            <Grid container>
              <Grid item xs={12}>
                <ReportCard
                  variant="link"
                  title="Relatório de validação de contratos 2024"
                  subTitle="atualizado diariamente às 9h"
                  description="Relatório com a evolução de alunos com contratos 2024 validados no meu isaac."
                  onClickButton={handleNavigateToFIDCReport}
                />
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </Box>
    </PageWrapper>
  )
}
