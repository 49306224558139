import type { AxiosInstance } from 'axios'
import {
  CreateCreditSimulationRequest,
  CreditSimulationResponse,
  EligibilityResponse,
  CreditReasonsResponse,
  ChooseOfferRequest,
  GetOrderStatusResponse,
  OrderDropoutRequest,
} from './types'
import { APIResponse } from '@/shared/interfaces'

export const creditService = (fetcher: AxiosInstance) => {
  return {
    /**
     * Function responsible for answer if a school can start a credit order
     *
     * @param params.schoolId School ID
     *
     * @returns school PA and school eligibility
     */
    getSchoolEligibility: async (schoolId: string): Promise<EligibilityResponse> => {
      const { data } = await fetcher.get<EligibilityResponse>(`/credit/eligibility`, {
        params: { schoolId },
      })
      return data
    },

    /**
     * Creates a new credit simulation given a school and the requested credit value
     *
     * @body CreateCreditSimulation
     *
     * @returns credit simulation
     */
    createCreditSimulation: async (
      simulation: CreateCreditSimulationRequest
    ): Promise<CreditSimulationResponse> => {
      const { data } = await fetcher.post<CreditSimulationResponse>(`/credit/simulation`, {
        ...simulation,
      })
      return data
    },

    /**
     * Function responsible for loading credit order reasons
     *
     * @returns credit order reasons
     */
    getCreditReasons: async (): Promise<CreditReasonsResponse> => {
      const { data } = await fetcher.get<CreditReasonsResponse>('/credit/reasons')
      return data
    },

    /**
     * Function responsible for choosing the specific credit offer
     *
     * @returns status empty response and 204 for success
     */
    chooseOffer: async (chosenOffer: ChooseOfferRequest): Promise<APIResponse> => {
      const { data } = await fetcher.patch<CreditReasonsResponse>('/credit/order', {
        ...chosenOffer,
      })
      return data
    },

    /**
     * Gets the current order status for the given school
     *
     * @returns credit order status
     */
    getOrderStatus: async (schoolId: string): Promise<GetOrderStatusResponse> => {
      const { data } = await fetcher.get<GetOrderStatusResponse>(`/credit/status/${schoolId}`)
      return data
    },

    /**
     *  Event sent to inform dropout
     *
     * @returns status empty response and 204 for success
     */
    orderDropout: async (orderDropout: OrderDropoutRequest): Promise<void> => {
      const { data } = await fetcher.put<void>(
        `/credit/order/${orderDropout.creditOrderId}/dropout`,
        {
          message: orderDropout.message,
          type: orderDropout.type,
        }
      )
      return data
    },
  }
}
