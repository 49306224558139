import { useState, useRef } from 'react'
import { useHistory, Prompt } from 'react-router-dom'
import ModalCustomDialog from '../Modal'
import { useOrderDropout } from '../../hooks/queries/useOrderDropout'
import { useContextCreditOrderId } from '../../contexts/CreditDataContext'
import { NextLocation, PageExitConfirmProps } from './types'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'

const PageExitConfirm = ({
  title,
  labelReason,
  confirmButtonLabel,
  backButtonLabel,
}: PageExitConfirmProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const history = useHistory()
  const nextLocationRef = useRef(null)
  const { mutateAsync } = useOrderDropout()
  const { creditOrderId } = useContextCreditOrderId()
  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  const handleBlockedNavigationClick = (nextLocation: NextLocation) => {
    const isCreditPage = nextLocation.pathname.includes('/credito/')
    if (!isOpenModal && !isCreditPage) {
      setIsOpenModal(true)
      nextLocationRef.current = nextLocation
      history.block()
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = (value: string) => {
    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: 'Sair da oferta',
    })

    mutateAsync({
      creditOrderId,
      type: 'abandonment',
      message: value,
    })

    if (nextLocationRef.current) {
      setIsOpenModal(false)
      history.push(nextLocationRef.current.pathname)
    }
  }

  return (
    <>
      <Prompt message={handleBlockedNavigationClick} />
      {isOpenModal && (
        <ModalCustomDialog
          size={2}
          isVisible
          confirmButton={{ label: confirmButtonLabel, variation: 'solid', color: 'accent' }}
          backButton={{ label: backButtonLabel, variation: 'ghost' }}
          onClose={() => {
            sendCreditEventToMixpanel(
              EventDispatcherEvents.BUTTON_CLICKED,
              MixpanelEventActions.CLICK,
              {
                $button_name: 'Continuar na oferta',
              }
            )
            setIsOpenModal(false)
          }}
          title={title}
          submitHandler={handleConfirmNavigationClick}
          maxWidth="md"
          labelReason={labelReason}
        />
      )}
    </>
  )
}

export default PageExitConfirm
