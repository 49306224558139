import styled from 'styled-components'

export const SkeletonCompactedHeader = styled.div`
  display: flex;
  align-items: center;
  min-width: fit-content;
`

export const CompactedHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  overflow: hidden;
  width: 100%;
`

export const CardsContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 118px;

  &.compacted {
    width: 80%;
    height: 48px;
    gap: 12px;
    margin-left: 20px;
    padding: 0;
  }
`
