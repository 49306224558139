import { useEffect, useRef, useState } from 'react'

export const useNotification = () => {
  const [isOpen, setIsOpen] = useState(false)
  const timeoutID = useRef(null)

  useEffect(() => {
    if (!isOpen) return

    clearTimeout(timeoutID.current)
    const timeout = setTimeout(() => {
      setIsOpen(false)
    }, 2000)
    timeoutID.current = timeout

    return () => clearTimeout(timeoutID.current)
  }, [isOpen])

  return { isOpen, setIsOpen }
}
