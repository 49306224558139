import { Button } from '@gravity/button'
import styled from 'styled-components'

const StyledFooter = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: end;
`

interface StepFooterProps {
  disabled?: boolean
}

export const StepFooter = ({ disabled = false }: StepFooterProps) => {
  return (
    <StyledFooter>
      <Button disabled={disabled}>Continuar</Button>
    </StyledFooter>
  )
}
