import { UserGuidingGuidesIds } from '@/shared/hooks/useUserGuidingTrigger'

import type { VariantType } from '../Link/types'

import {
  People as PeopleIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
} from '@material-ui/icons'

type Props = {
  currentUrl: string
  isMaintainer: boolean
  isSecretary: boolean
}

type Link = {
  href: string
  icon: JSX.Element
  title: string
  variant: VariantType
}

export const CUSTOM_TRIGGER_QUERY = '&trigger=custom'

function withNewMatriculasAndNewRFPage({
  isMaintainer,
  currentUrl,
}: {
  currentUrl: string
  isMaintainer: boolean
  isSecretary: boolean
}) {
  const links: Link[] = []

  const guardianGuideId = UserGuidingGuidesIds.LIST_GUARDIANS_PAGE
  const negotitationGuideId = UserGuidingGuidesIds.NEGOTIATION

  if (isMaintainer) {
    links.push({
      title: 'Responsáveis financeiros',
      variant: 'secondary',
      icon: <PeopleIcon />,
      href: `${currentUrl}/responsaveis?guide=${guardianGuideId}${CUSTOM_TRIGGER_QUERY}`,
    })

    links.push({
      title: 'Negociação de parcelas',
      variant: 'secondary',
      icon: <AccountBalanceWalletIcon />,
      href: `${currentUrl}/responsaveis?guide=${negotitationGuideId}${CUSTOM_TRIGGER_QUERY}`,
    })
  } else {
    links.push({
      title: 'Negociação de parcelas',
      variant: 'secondary',
      icon: <AccountBalanceWalletIcon />,
      href: `${currentUrl}/responsaveis?guide=${negotitationGuideId}${CUSTOM_TRIGGER_QUERY}`,
    })

    links.push({
      title: 'Responsáveis financeiros',
      variant: 'secondary',
      icon: <PeopleIcon />,
      href: `${currentUrl}/responsaveis?guide=${guardianGuideId}${CUSTOM_TRIGGER_QUERY}`,
    })
  }

  return links
}

export function useLearnMoreSectionLinks({ currentUrl, isSecretary, isMaintainer }: Props) {
  return withNewMatriculasAndNewRFPage({
    isMaintainer,
    isSecretary,
    currentUrl,
  })
}
