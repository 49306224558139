import { Box, Menu, MenuItem, Tooltip, Grid } from '@material-ui/core'
import {
  ActionDrawerHeader,
  Button,
  ButtonDocker,
  IconButton,
  DialogContent,
  Divider,
  Drawer,
  Notification,
  Typography,
} from '@olaisaac/design-system'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { intersection } from 'ramda'
import copy from 'copy-to-clipboard'
import { useLocation } from 'react-router-dom'

import { useNavigation } from '@/escolas/hooks'
import {
  LiquidationPaymentMethod,
  LiquidationPaymentMethodLabel,
  LiquidationSource,
} from '@/shared/interfaces'

import { ButtonDockerContainer } from '../components/ButtonDockerContainer/ButtonDockerContainer'
import { NegotiationInstallmentDrawerProps } from './types'
import { useNegotiationInstallmentQuery } from '../hooks/useNegotiationInstallmentQuery'
import { formatter } from '../../GuardianDetailsInstallmentsTab/components/GuardianDetailsInstallmentsTable/utils/formatter'
import {
  InstallmentStatus,
  InstallmentType,
  NegotiationType,
} from '../../GuardianDetails/constants'
import { Status } from '../../GuardianDetailsInstallmentsTab/components/Status/Status'
import { LoadingNegotiationDrawer } from './LoadingNegotiationDrawer'
import useManualLiquidationNavigation from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/hooks/useManualLiquidationNavigation'
import { UnleashFlags, useJWT, useSnackbar, useUnleashFlag } from '@/shared/hooks'
import PrintReceiptButton from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/PrintReceiptButton'
import {
  TrackDrawerClickEvents,
  useGuardiansInstallmentsTabEvents,
} from '../../GuardianDetailsInstallmentsTab/hooks/useGuardiansInstallmentsTabEvents'
import { useState } from 'react'
import { RenegotiationDrawerContainer } from './RenegotiationDrawerContainer/RenegotiationDrawerContainer'
import { useGetReceivableByIDQuery } from '../hooks/useGetReceivableByIDQuery'
import { useGetReceivableByIDQueryProps } from '../../services/types'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import dayjs from 'dayjs'
import { getDiscountTitle } from '@/modules/guardians/InstallmentsDrawerContainer/utils'
import { useGuardianNavigation } from '../../hooks/useGuardianNavigation'

const LiquidationPaymentMethodLabels: { [key in LiquidationPaymentMethod]: string } = {
  [LiquidationPaymentMethod.BANK_CHECK]: LiquidationPaymentMethodLabel.BANK_CHECK,
  [LiquidationPaymentMethod.BANK_DEPOSIT]: LiquidationPaymentMethodLabel.BANK_DEPOSIT,
  [LiquidationPaymentMethod.BANK_SLIP]: LiquidationPaymentMethodLabel.BANK_SLIP,
  [LiquidationPaymentMethod.BANK_TRANSFER]: LiquidationPaymentMethodLabel.BANK_TRANSFER,
  [LiquidationPaymentMethod.CASH]: LiquidationPaymentMethodLabel.CASH,
  [LiquidationPaymentMethod.CREDIT_CARD]: LiquidationPaymentMethodLabel.CREDIT_CARD,
  [LiquidationPaymentMethod.DEBIT_CARD]: LiquidationPaymentMethodLabel.DEBIT_CARD,
  [LiquidationPaymentMethod.DIRECT_DEBIT]: LiquidationPaymentMethodLabel.DIRECT_DEBIT,
  [LiquidationPaymentMethod.OTHER]: LiquidationPaymentMethodLabel.OTHER,
  [LiquidationPaymentMethod.PIX]: LiquidationPaymentMethodLabel.PIX,
  [LiquidationPaymentMethod.SCHOOL_PORTFOLIO_REPURCHASE]: '',
  [LiquidationPaymentMethod.SUBSCRIPTION]: LiquidationPaymentMethodLabel.SUBSCRIPTION,
}

const getPaymentMethodLabel = (method: LiquidationPaymentMethod): string => {
  return LiquidationPaymentMethodLabels[method]
}

export const NegotiationDrawer = ({
  isOpen,
  onClose,
  selectedInstallmentId,
  status,
  receivableId,
  urn,
  guardianId,
  successCallbackRenegotiationDrawer,
}: NegotiationInstallmentDrawerProps) => {
  const { setIsOpen, setMessage, setVariation } = useSnackbar()
  const { schoolId } = useNavigation()
  const { search: currentPageQueryParams } = useLocation()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const [menuHTMLElement, setMenuHTMLElement] = useState<null | HTMLElement>(null)
  const [negotiationDrawerContainerOpen, setNegotiationDrawerContainerOpen] = useState<boolean>(
    false
  )
  const isMenuOpen = Boolean(menuHTMLElement)
  const { setPageToReturn } = useGuardianNavigation()

  const { isAdmin } = useJWT()
  const isManualLiquidationDisabled = useUnleashFlag(
    UnleashFlags.B2CB_984_DISABLE_MANUAL_LIQUIDATION
  )
  const isRenegotiateAndAgglutinateButtonEnabled = useUnleashFlag(
    UnleashFlags.REG_2042_ENABLE_RENEGOTIATE_AND_AGGLUTINATE_BUTTONS
  )
  const { trackDrawerClick } = useGuardiansInstallmentsTabEvents()

  const {
    negotiationInstallment,
    isNegotiationInstallmentLoading,
    isNegotiationInstallmentFetched,
  } = useNegotiationInstallmentQuery({ urn: selectedInstallmentId, schoolId, guardianId, status })

  const includeProps: useGetReceivableByIDQueryProps = {
    include_installment: true,
    include_discounts: true,
  }

  const {
    receivable,
    isGetReceivableByIDLoading,
    isGetReceivableByIDFetched,
  } = useGetReceivableByIDQuery(receivableId, includeProps)

  const handleCopyPaymentLink = () => {
    trackDrawerClick(
      selectedInstallmentId,
      TrackDrawerClickEvents.BUTTON_CLICK,
      'copy_payment_link'
    )
    copy(negotiationInstallment.invoice.payment_link)
    setMessage('Link copiado para área de transferência')
    setVariation('success')
    setIsOpen(true)
  }

  const handleCloseDrawer = () => {
    trackDrawerClick(selectedInstallmentId, TrackDrawerClickEvents.CLOSE)
    onClose()
  }

  const handleClickRenegotiation = () => {
    const canShowRenegotiationDrawer = !isGetReceivableByIDLoading && isGetReceivableByIDFetched
    isInitialized &&
      eventDispatcherClient.sendEvent({
        name: EventDispatcherEvents.BUTTON_CLICKED,
        scope: EventDispatcherEventScopes.INSTALLMENT_DRAWER,
        entity: EventDispatcherEntities.RENEGOTIATE,
        action: 'click',
        customProperties: {
          $name: 'Renegociar',
          $receivable_id: receivableId,
        },
      })

    setNegotiationDrawerContainerOpen(canShowRenegotiationDrawer)
  }

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuHTMLElement(event.currentTarget)
  }

  const { goToManualLiquidation } = useManualLiquidationNavigation({
    urn,
    callbackParams: currentPageQueryParams,
  })

  const isPaidInstallment = negotiationInstallment?.status === InstallmentStatus.PAID

  const isDownPayment = negotiationInstallment?.type === InstallmentType.DOWN_PAYMENT

  const isNegotiationV1 =
    negotiationInstallment?.origin_negotiation?.type !== NegotiationType.NEGOTIATION_API_V2

  const isNegotiationV2 =
    negotiationInstallment?.origin_negotiation?.type === NegotiationType.NEGOTIATION_API_V2

  const handleManualLiquidation = () => {
    setPageToReturn()
    onClose()
    goToManualLiquidation()
  }

  const canLiquidateDownPaymentInstallment = !isPaidInstallment

  const canLiquidateNegotiatedInstallment = !isPaidInstallment && isNegotiationV1

  const canShowManualLiquidationButton =
    !isManualLiquidationDisabled &&
    !negotiationInstallment?.is_from_credit_card_flow &&
    (canLiquidateNegotiatedInstallment || canLiquidateDownPaymentInstallment)

  const showNegotiationDrawer = !isNegotiationInstallmentLoading && isNegotiationInstallmentFetched

  const canShowRenegotiationButton =
    isRenegotiateAndAgglutinateButtonEnabled &&
    !negotiationInstallment?.is_from_credit_card_flow &&
    (isNegotiationV1 || (isNegotiationV2 && !isDownPayment)) &&
    !isPaidInstallment &&
    isAdmin

  const hasItemsOnMenu = canShowRenegotiationButton || canShowManualLiquidationButton

  const buttonCopyPaymentLinkTSX = () => {
    return (
      <Box pt={1} pb={1} display="flex" justifyContent="center">
        <Button
          onClick={handleCopyPaymentLink}
          disabled={!negotiationInstallment.invoice?.payment_link}
        >
          Copiar link de cobrança
        </Button>
      </Box>
    )
  }

  const schoolsFooterButtonsTSX = () => {
    return (
      <Box>
        {isPaidInstallment ? (
          <PrintReceiptButton receivableId={receivableId} />
        ) : (
          buttonCopyPaymentLinkTSX()
        )}

        {canShowManualLiquidationButton && (
          <Box pt={1} pb={1} display="flex" justifyContent="center">
            <Button onClick={handleManualLiquidation} variation="ghost">
              Registrar recebimento
            </Button>
          </Box>
        )}
      </Box>
    )
  }

  const adminFooterButtonsTSX = () => {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          {isPaidInstallment ? (
            <PrintReceiptButton receivableId={receivableId} />
          ) : (
            buttonCopyPaymentLinkTSX()
          )}
        </Grid>
        {hasItemsOnMenu && (
          <Grid item xs={2}>
            <IconButton
              variation="ghost"
              aria-label="mais ações"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleOpenMenu}
              data-testid="more-actions"
            >
              <MoreVertIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    )
  }

  const onRenegotiationDrawerBack = () => {
    setMenuHTMLElement(null)
    setNegotiationDrawerContainerOpen(false)
  }

  const onFinishRenegotiationDrawer = () => {
    onClose()
  }

  const getPaymentLabel = () => {
    if (!negotiationInstallment.enabled_payments_methods) return ''

    const hybridPaymentMethods = [
      LiquidationPaymentMethod.BANK_SLIP,
      LiquidationPaymentMethod.PIX,
      LiquidationPaymentMethod.CREDIT_CARD,
    ]
    const isHybridPayment =
      intersection(negotiationInstallment.enabled_payments_methods, hybridPaymentMethods).length ===
      hybridPaymentMethods.length

    const paymentMethod = isHybridPayment
      ? 'Boleto, Pix ou Cartão'
      : negotiationInstallment.enabled_payments_methods
          .map(method => {
            const checkoutLiquidationSources = [
              LiquidationSource.STONE_CHECKOUT,
              LiquidationSource.PAGARME_CHECKOUT,
            ]
            if (
              method === LiquidationPaymentMethod.CREDIT_CARD &&
              !checkoutLiquidationSources.includes(negotiationInstallment.paid_payment_source)
            ) {
              return 'Pagamento no link do cartão'
            }
            return getPaymentMethodLabel(method)
          })
          .join(', ')

    return paymentMethod
  }

  const renderHeaderSubtitle = () => {
    if (negotiationInstallment.is_from_credit_card_flow) {
      return null
    }

    if (negotiationInstallment.type === InstallmentType.DOWN_PAYMENT) {
      return `Entrada da negociação - ${formatter.negotiation_id(
        negotiationInstallment.origin_negotiation.id
      )}`
    }

    return `Negociação - ${formatter.negotiation_id(negotiationInstallment.origin_negotiation.id)}`
  }

  const renderNotifications = () => {
    if (
      negotiationInstallment.status !== 'PAID' &&
      negotiationInstallment.is_from_credit_card_flow
    ) {
      return (
        <Notification
          variation="information"
          description={`Fatura gerada para pagamento via cartão de crédito, válida até ${formatter.full_date(
            negotiationInstallment.origin_negotiation.due_date
          )}`}
        />
      )
    }

    if (
      [InstallmentStatus.OVERDUE, InstallmentStatus.CANCELED].includes(
        negotiationInstallment.status
      )
    ) {
      return (
        <Notification
          variation="warning"
          description="Uma negociação aconteceu nesta parcela, mas ela não foi concluída e/ou foi cancelada."
        />
      )
    }

    return (
      <Notification
        variation="information"
        description="Essa parcela é resultado de uma negociação."
      />
    )
  }

  return (
    <>
      <Drawer open={isOpen}>
        {showNegotiationDrawer ? (
          !negotiationDrawerContainerOpen ? (
            <>
              <ActionDrawerHeader
                title={`Parcela ${formatter.order_reference(
                  negotiationInstallment.order_reference
                )}`}
                subtitle={renderHeaderSubtitle()}
                onClose={handleCloseDrawer}
                extraContent={<Status status={negotiationInstallment.status} />}
              />
              {renderNotifications()}
              <DialogContent>
                {!negotiationInstallment.is_from_credit_card_flow && (
                  <>
                    <Box pt={3} pb={1}>
                      <Typography variation="headlineDesktopXsmall">Parcelas originais</Typography>
                    </Box>

                    {negotiationInstallment.origin_negotiation.original_receivables?.map(
                      installment => (
                        <Box
                          pt={1}
                          pb={1}
                          display="flex"
                          justifyContent="space-between"
                          key={installment.id}
                        >
                          <div>
                            <Typography variation="bodySmall">
                              {formatter.competence_date(installment.competence_dates)}
                            </Typography>
                          </div>
                          <div>
                            <Typography variation="subtitleDesktopMedium">
                              {formatter.amount(installment.current_amount)}
                            </Typography>
                          </div>
                        </Box>
                      )
                    )}

                    <Divider />
                  </>
                )}
                <Box pt={3} pb={1} display="flex" justifyContent="space-between">
                  <Typography variation="headlineDesktopXsmall" color="secondary">
                    Valor integral
                  </Typography>
                  <Typography variation="headlineDesktopXsmall">
                    {formatter.amount(negotiationInstallment.original_amount)}
                  </Typography>
                </Box>
                {!negotiationInstallment.is_from_credit_card_flow && (
                  <>
                    <Box pt={1} pb={1} display="flex" justifyContent="space-between">
                      <Typography variation="subtitleDesktopMedium" color="secondary">
                        Multa
                      </Typography>
                      <Typography variation="subtitleDesktopMedium">
                        {`+ ${formatter.amount(negotiationInstallment.current_fine)}`}
                      </Typography>
                    </Box>
                    <Box pt={1} pb={1} display="flex" justifyContent="space-between">
                      <Typography variation="subtitleDesktopMedium" color="secondary">
                        Juros
                      </Typography>
                      <Typography variation="subtitleDesktopMedium">
                        {`+ ${formatter.amount(negotiationInstallment.current_interest)}`}
                      </Typography>
                    </Box>
                    <Box pt={1} pb={1} display="flex" justifyContent="space-between">
                      <Typography variation="subtitleDesktopMedium" color="secondary">
                        <b>Valor total de encargos</b>
                      </Typography>
                      <Typography variation="subtitleDesktopMedium" style={{ color: '#AB1207' }}>
                        <b>
                          {`+ ${formatter.amount(
                            negotiationInstallment.current_interest +
                              negotiationInstallment.current_fine
                          )}`}
                        </b>
                      </Typography>
                    </Box>
                  </>
                )}
                {negotiationInstallment.discounts?.map((discount, key) => (
                  <Box key={key} pt={1} pb={1} display="flex" justifyContent="space-between">
                    <Box pt={1} pb={1} display="flex" flexDirection="column">
                      <Typography variation="subtitleDesktopMedium" color="secondary">
                        {getDiscountTitle(discount.type)}
                      </Typography>
                      {['EARLY_PAYMENT_DISCOUNT', 'DUE_PAYMENT'].includes(discount.type) && (
                        <Typography color="secondary">
                          Válido até {dayjs(discount.limit_date).format('DD/MM/YYYY')}
                        </Typography>
                      )}
                    </Box>
                    <Typography variation="subtitleDesktopMedium">
                      {`- ${formatter.amount(discount.amount)}`}
                    </Typography>
                  </Box>
                ))}
                {negotiationInstallment.is_from_credit_card_flow && (
                  <Box pt={1} pb={1} display="flex" justifyContent="space-between">
                    <Typography variation="subtitleDesktopMedium" color="secondary">
                      Taxa do Cartão
                    </Typography>
                    <Typography variation="subtitleDesktopMedium">
                      {`+ ${formatter.amount(negotiationInstallment.credit_card_fee)}`}
                    </Typography>
                  </Box>
                )}
                <Box pt={1} pb={1} display="flex" justifyContent="space-between">
                  <Typography variation="subtitleDesktopMedium" color="secondary">
                    Pagamento
                  </Typography>
                  <Typography variation="subtitleDesktopMedium">{getPaymentLabel()}</Typography>
                </Box>
                <Box pt={1} pb={1} display="flex" justifyContent="space-between">
                  <Typography variation="subtitleDesktopMedium" color="secondary">
                    Vencimento
                  </Typography>
                  <Typography variation="subtitleDesktopMedium">
                    {formatter.full_date(negotiationInstallment.due_date)}
                  </Typography>
                </Box>
                <Box pt={1} pb={1} display="flex" justifyContent="space-between">
                  <Typography variation="subtitleDesktopMedium" color="secondary">
                    Recebido em:
                  </Typography>
                  <Typography variation="subtitleDesktopMedium">
                    {negotiationInstallment.paid_date
                      ? formatter.full_date(negotiationInstallment.paid_date)
                      : '-'}
                  </Typography>
                </Box>

                {!negotiationInstallment.is_from_credit_card_flow && (
                  <>
                    <Divider />

                    <Box pt={1} pb={1} display="flex" flexDirection="column">
                      <Typography variation="subtitleDesktopMedium">
                        <b>Id da negociação:</b>
                      </Typography>
                      <Tooltip title={negotiationInstallment.origin_negotiation.id}>
                        <Typography
                          variation="subtitleDesktopMedium"
                          style={{
                            width: '300px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {negotiationInstallment.origin_negotiation.id}
                        </Typography>
                      </Tooltip>
                    </Box>

                    <Box pt={1} pb={1} display="flex" flexDirection="column">
                      <Typography variation="subtitleDesktopMedium">
                        <b>Criado em:</b>
                      </Typography>
                      <Typography variation="subtitleDesktopMedium">
                        {formatter.full_date(
                          negotiationInstallment.origin_negotiation.negotiation_date
                        )}
                      </Typography>
                    </Box>
                  </>
                )}
              </DialogContent>
              <ButtonDocker>
                <ButtonDockerContainer>
                  <Box pt={1} pb={2} display="flex" justifyContent="space-between">
                    <Typography variation="headlineDesktopXsmall" color="secondary">
                      Valor a pagar
                    </Typography>
                    <Typography variation="headlineDesktopXsmall">
                      {formatter.amount(negotiationInstallment.current_amount)}
                    </Typography>
                  </Box>
                  {isAdmin ? adminFooterButtonsTSX() : schoolsFooterButtonsTSX()}
                </ButtonDockerContainer>
              </ButtonDocker>
              <Menu
                id="long-menu"
                anchorEl={menuHTMLElement}
                keepMounted
                open={isMenuOpen}
                onClose={() => setMenuHTMLElement(null)}
              >
                <>
                  {canShowRenegotiationButton && (
                    <MenuItem onClick={handleClickRenegotiation}>Renegociar</MenuItem>
                  )}

                  {canShowManualLiquidationButton && (
                    <MenuItem onClick={handleManualLiquidation}>Registrar recebimento</MenuItem>
                  )}
                </>
              </Menu>
            </>
          ) : (
            <RenegotiationDrawerContainer
              receivableId={receivableId}
              receivables={[receivable]}
              onClose={onClose}
              onBack={onRenegotiationDrawerBack}
              onFinish={onFinishRenegotiationDrawer}
              orderReference={formatter.order_reference(negotiationInstallment.order_reference)}
              successCallback={successCallbackRenegotiationDrawer}
            />
          )
        ) : (
          <LoadingNegotiationDrawer onClose={handleCloseDrawer} />
        )}
      </Drawer>
    </>
  )
}
