import { includes } from 'ramda'

import { Installment } from '@/modules/guardians/GuardianDetails/types'
import {
  InstallmentType,
  InstallmentRowType,
  ReceivableType,
  InstallmentStatus,
} from '@/modules/guardians/GuardianDetails/constants'

import { Rows } from '../types'

const getRowType = (type: Installment['type'], receivable_type: Installment['receivable_type']) => {
  if (type === InstallmentType.DOWN_PAYMENT) return InstallmentRowType.NEGOTIATION_START

  if (receivable_type === ReceivableType.NEGOTIATION) return InstallmentRowType.NEGOTIATION

  if (receivable_type === ReceivableType.ENROLLMENT)
    return InstallmentRowType.ENROLLMENT_INSTALLMENT

  return InstallmentRowType.INSTALLMENT
}

export const mapDataToRows = (rawData: Installment[]): Rows => {
  return rawData?.map(item => {
    const row_type = getRowType(item.type, item.receivable_type)

    return {
      ...item,
      installment_order: {
        order_reference: item.order_reference,
        row_type,
      },
      installment_info: {
        checkable: includes(item.status, [
          InstallmentStatus.DUE_TODAY,
          InstallmentStatus.OPEN,
          InstallmentStatus.OVERDUE,
        ]),
        competence_date: item.competence_date,
        negotiation_id: item.negotiation_id,
        row_type,
      },
      id: item.urn,
      installment_status: {
        overdue_for_days: item.overdue_for_days,
        paid_date: item.paid_date,
        row_type: row_type,
        status: item.status,
        status_description: item.status_description,
      },
      key: item.urn,
      row_type,
      disabled: includes(item.status, [InstallmentStatus.PENDING, InstallmentStatus.CANCELED]),
    }
  })
}
