import type { ReactNode } from 'react'

import { ProviderComposer } from '@/modules/core/providers'
import { SchoolEnrollmentCycleProvider } from '@/modules/enrollment/contexts/SchoolEnrollmentContext'
import { SelectedSchoolProvider } from './SelectedSchoolContext'
import { LayoutProvider } from './Layout'
import { GuardianNavigationProvider } from '@/modules/guardians/contexts/GuardianNavigationContext'

type SchoolRoutesProvidersProps = {
  children: ReactNode
}

/**
 * Providers for routes after school slug
 */
export const SchoolRoutesProviders = ({ children }: SchoolRoutesProvidersProps) => {
  return (
    <ProviderComposer
      providers={[
        SelectedSchoolProvider,
        LayoutProvider,
        SchoolEnrollmentCycleProvider,
        GuardianNavigationProvider,
      ]}
    >
      {children}
    </ProviderComposer>
  )
}
