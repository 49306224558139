import { useContext, useEffect } from 'react'
import { Button, ButtonGroup, Notification, Typography } from '@olaisaac/design-system'
import { useHistory } from 'react-router-dom'
import campaignProcessingImg from 'src/escolas/assets/campaignProcessing.png'
import { PageTableControlBar } from 'src/escolas/components/layout/PageTableControlBar'
import { PageWithTableControlBar } from 'src/escolas/components/layout/PageWithTableControlBar'
import { useNavigation } from 'src/escolas/hooks'
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import { CampaignContext } from './context'
import { useJWT } from 'src/shared/hooks'
import { HotjarEvents, useHotjar } from 'src/shared/hooks/useHotjar'

const Wrapper = styled.div`
  margin: auto;
  max-width: 512px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const NotificationWrapper = styled.div`
  margin-top: 64px;
`

const PageHeadline = styled(Typography).attrs({
  variation: 'headlineDesktopSmall',
})`
  margin: 40px 0 8px;
`

const PageText = styled(Typography).attrs({
  variation: 'bodyLarge',
  color: 'secondary',
})`
  margin: 0;
  text-align: center;
`

const ContainerWithActionDrawer = styled(Grid)<{ $isActionDrawerOpen?: boolean }>(
  ({ $isActionDrawerOpen }) => ({
    transition:
      'flex-basis 0.3s cubic-bezier(0.42,0.29,0.39,0.83), max-width 0.3s cubic-bezier(0.42,0.29,0.39,0.83)',
    ...($isActionDrawerOpen
      ? { flexGrow: 0, maxWidth: '70.666667%', flexBasis: '66.666667%' }
      : {}),
  })
)

const CampaignProcessing = () => {
  const { state } = useContext(CampaignContext)
  const { referenceYear } = state
  const history = useHistory()
  const { getNavigationUrl } = useNavigation()
  const { email } = useJWT()
  const { sendHotjarEvent } = useHotjar()

  const handleClick = () => {
    history.push(getNavigationUrl({ path: `/contratos/${referenceYear}` }))
  }

  useEffect(() => {
    sendHotjarEvent(HotjarEvents.CAMPAIGN_PROCESSING_LANDED)
  }, [])

  return (
    <PageWithTableControlBar style={{ height: '100vh' }}>
      <ContainerWithActionDrawer item xs={12} $isActionDrawerOpen={false}>
        <Wrapper>
          <img src={campaignProcessingImg} width={512} />
          <PageHeadline>Seus contratos estão sendo processados</PageHeadline>
          <PageText>
            Fique tranquilo, quando todos os contratos forem processados te avisaremos no e-mail{' '}
            <strong>{email}</strong>.
          </PageText>
          <NotificationWrapper>
            <Notification
              variation="information"
              description="Mesmo que você saia da plataforma isaac ou desta tela, o processamento dos contratos irá continuar."
            />
          </NotificationWrapper>
        </Wrapper>
        <PageTableControlBar>
          <ButtonGroup style={{ marginLeft: 'auto' }}>
            <Button variation="primary" onClick={handleClick}>
              {`Ir para contratos ${referenceYear}`}
            </Button>
          </ButtonGroup>
        </PageTableControlBar>
      </ContainerWithActionDrawer>
    </PageWithTableControlBar>
  )
}

export default CampaignProcessing
