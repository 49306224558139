import { SortOrder } from '@/shared/hooks/useOrdering/types'
import { DebtStatus } from '@/shared/interfaces'

export type UseGuardiansQueryParamsProps = {
  filters?: FiltersType
  itemsPerPage?: number
  page?: number
  sortBy?: 'name'
  sortOrder?: SortOrder
}

export type FiltersType = {
  debt_status: DebtStatus[]
}

export type FilterOptionsType = {
  label: string
  value: string
}

export enum SortOrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}
