import { useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@/escolas/hooks'

import {
  Ordering,
  OrderingQueryParamNames,
  OrderingQueryParamsNameEnum,
  OrderingHookType,
  SortOrder,
} from './types'

/**
 * Custom hook to handle ordination query params
 */
export const useOrdering = <T extends string = string>(
  initialValue?: Ordering<T>
): OrderingHookType<T> => {
  const { query, setOnQueryParam, setMultipleQueryParams } = useQuery()

  const getOrdinationFromURL = () => {
    const getUrlValue = (paramKey: string, defaultValue?: T | SortOrder) => {
      const paramValue = query.get(paramKey)

      return paramValue ?? defaultValue
    }

    const data: Ordering<T> = {
      sortBy: getUrlValue('sort_by', initialValue?.sortBy) as T | undefined,
      sortOrder: getUrlValue('sort_order', initialValue?.sortOrder) as SortOrder | undefined,
    }

    return data
  }

  const [ordering, setOrdering] = useState<Ordering<T>>(() => getOrdinationFromURL())

  useEffect(() => {
    const sortBy = query.get('sort_by')
    const sortOrder = query.get('sort_order')

    if (!sortBy && !sortOrder && initialValue) {
      const data: Array<{ name: OrderingQueryParamNames; value: string }> = [
        { name: 'sort_by', value: String(initialValue.sortBy) },
        { name: 'sort_order', value: String(initialValue.sortOrder) },
      ]

      setMultipleQueryParams(data, 'replace')
    }
  }, [])

  useEffect(() => {
    const sortBy = query.get('sort_by')
    const sortOrder = query.get('sort_order')

    const hasChanges = sortBy !== ordering.sortBy || sortOrder !== ordering.sortOrder

    hasChanges && setOrdering(getOrdinationFromURL())
  }, [query])

  const updateOrdering = useCallback(
    ({ sortBy, sortOrder }: Ordering) => {
      const data: Array<{ name: OrderingQueryParamNames; value: string }> = [
        { name: 'sort_by', value: String(sortBy) },
        { name: 'sort_order', value: String(sortOrder) },
      ]

      setMultipleQueryParams(data, 'replace')
    },
    [setMultipleQueryParams]
  )

  const updateOrderingValue = useCallback(
    <T extends keyof Ordering>(key: T, value: Ordering[T]) => {
      setOnQueryParam(String(value), OrderingQueryParamsNameEnum[key], 'replace')
    },
    [setOnQueryParam]
  )

  const orderingHookValue = useMemo(
    () => ({
      ordering,
      updateOrdering,
      updateOrderingValue,
    }),
    [ordering, updateOrdering, updateOrderingValue]
  )

  return orderingHookValue
}
