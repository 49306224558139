import { Box } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'

/*
  Página provisória de inegibilidade de crédito e erro, aguardando definição do time de design
*/
type Props = {
  title: string
}
export const UnavailableState = ({ title }: Props) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
      <Box
        maxWidth="470px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="2rem"
      >
        <Typography variation="bodyLarge">{title}</Typography>

        <Typography variation="bodySmall" style={{ textAlign: 'center' }}>
          (página em construção)
        </Typography>
      </Box>
    </Box>
  )
}
