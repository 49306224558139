import { Dispatch, FC, SetStateAction, createContext, useState, useContext, useEffect } from 'react'
import { CreditSimulation, Eligibility, Offer, RequestReasons, StepperMap } from '../services/types'

export type CreditContext = {
  chosenOffer: Offer
  creditOrderId: string
  creditSimulation: CreditSimulation
  creditValue: string
  eligibility: Eligibility
  requestReason: RequestReasons
  setChosenOffer: Dispatch<SetStateAction<Offer>>
  setCreditOrderId: Dispatch<SetStateAction<string>>
  setCreditSimulation: Dispatch<SetStateAction<CreditSimulation>>
  setCreditValue: Dispatch<SetStateAction<string>>
  setEligibility: Dispatch<SetStateAction<Eligibility>>
  setRequestReason: Dispatch<SetStateAction<RequestReasons>>
  setStepperMap: Dispatch<SetStateAction<StepperMap>>
  stepperMap: StepperMap
}

export const CreditContext = createContext<CreditContext>(null)

type CreditProviderProps = { children: React.ReactNode }

export const CreditProvider: FC<CreditProviderProps> = ({ children }) => {
  const [eligibility, setEligibility] = useState<Eligibility>(null)
  const [creditSimulation, setCreditSimulation] = useState<CreditSimulation>(null)
  const [chosenOffer, setChosenOffer] = useState<Offer>(null)
  const [creditValue, setCreditValue] = useState<string>('')
  const [stepperMap, setStepperMap] = useState<StepperMap>(null)
  const [creditOrderId, setCreditOrderId] = useState<string>('')
  const [requestReason, setRequestReason] = useState<RequestReasons>(null)

  useEffect(() => {
    if (chosenOffer === null && creditSimulation !== null) {
      setChosenOffer(creditSimulation.offers[0])
      setCreditValue(creditSimulation.creditValue)
    }
  }, [creditSimulation])

  return (
    <CreditContext.Provider
      value={{
        creditSimulation,
        setCreditSimulation,
        eligibility,
        setEligibility,
        chosenOffer,
        setChosenOffer,
        creditValue,
        setCreditValue,
        stepperMap,
        setStepperMap,
        creditOrderId,
        setCreditOrderId,
        requestReason,
        setRequestReason,
      }}
    >
      {children}
    </CreditContext.Provider>
  )
}

export const useContextEligibility = () => {
  const { eligibility, setEligibility } = useContext(CreditContext)
  return { eligibility, setEligibility }
}

export const useContextCreditSimulation = () => {
  const { creditSimulation, setCreditSimulation } = useContext(CreditContext)
  return { creditSimulation, setCreditSimulation }
}

export const useContextChosenOffer = () => {
  const { chosenOffer, setChosenOffer } = useContext(CreditContext)
  return { chosenOffer, setChosenOffer }
}

export const useContextCreditValue = () => {
  const { creditValue, setCreditValue } = useContext(CreditContext)
  return { creditValue, setCreditValue }
}

export const useContextStepperMap = () => {
  const { stepperMap, setStepperMap } = useContext(CreditContext)
  return { stepperMap, setStepperMap }
}

export const useContextCreditOrderId = () => {
  const { creditOrderId, setCreditOrderId } = useContext(CreditContext)
  return { creditOrderId, setCreditOrderId }
}

export const useContextRequestReason = () => {
  const { requestReason, setRequestReason } = useContext(CreditContext)
  return { requestReason, setRequestReason }
}
