import { Card, Grid } from '@olaisaac/design-system'
import { useEffect, useState } from 'react'
import {
  KeyText,
  RowContainer,
  ContainerCard,
  ValueText,
  StyledSelect,
  SmallValueText,
  ColumnContainer,
  CommentText,
  StyledSkeleton,
} from './styles'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import { CreditSummaryProps } from './types'
import { Option } from '@gravity/select'
import { Skeleton } from '@gravity/skeleton'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import { Tooltip } from '@gravity/tooltip'
import { Box } from '@material-ui/core'
import { SeparatorLine } from '../SeparatorLine'

const CreditSummaryCard = ({
  creditValue,
  offers,
  chosenOffer,
  setChosenOffer,
}: CreditSummaryProps) => {
  const dropdownOptions: Option[] = offers.map(offer => ({
    label: `${
      offer.totalInstallments === 1 ? '1 parcela' : `${offer.totalInstallments} parcelas`
    } de ${offer.installmentValue}`,
    value: offer.totalInstallments.toString(),
  }))
  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  const [installment, setInstallment] = useState<Option>(dropdownOptions[0])
  const [showSkeleton, setShowSkeleton] = useState(false)

  useEffect(() => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
    setChosenOffer(offers.find(e => e.totalInstallments.toString() === installment.value))
  }, [installment])

  const handleDropdownValueChange = (value: string) => {
    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: 'Número de parcelas',
    })
    setInstallment(dropdownOptions.find(e => e.value === value))
  }

  return (
    <RowContainer>
      <Card>
        <ContainerCard>
          <KeyText>Valor simulado</KeyText>
          <ValueText>{creditValue}</ValueText>

          <SeparatorLine />

          <RowContainer>
            <KeyText>Número de parcelas</KeyText>
            <Grid item style={{ width: '450px', marginLeft: '-220px' }}>
              <Tooltip
                multiline
                text={`Número máximo de parcelas\ncondicionado à duração do\n seu contrato com o isaac.`}
              >
                <Box display="flex" justifyContent="center" alignItems="center" marginTop={1}>
                  <InfoOutlined style={{ marginTop: '-7px' }} fontSize="small" />
                </Box>
              </Tooltip>
            </Grid>
          </RowContainer>

          <StyledSelect
            size={3}
            variant="surface"
            options={dropdownOptions}
            value={installment.value}
            onValueChange={value => handleDropdownValueChange(value)}
          />

          <SeparatorLine />

          <RowContainer>
            <ColumnContainer>
              <KeyText>Forma de pagamento</KeyText>
              <SmallValueText>Desconto no repasse</SmallValueText>
            </ColumnContainer>
            <ColumnContainer>
              <KeyText>Primeiro desconto</KeyText>
              <SmallValueText>{chosenOffer.firstDiscountDate}</SmallValueText>
            </ColumnContainer>
          </RowContainer>

          <SeparatorLine />

          <RowContainer>
            <ColumnContainer>
              {showSkeleton ? (
                <>
                  <StyledSkeleton radius={8} fullWidth />
                  <Skeleton radius={8} fullWidth />
                </>
              ) : (
                <>
                  <KeyText>Taxa de juros mensal</KeyText>
                  <SmallValueText>{chosenOffer.monthlyFee + '% a.m'} </SmallValueText>
                </>
              )}
            </ColumnContainer>
            <ColumnContainer>
              {showSkeleton ? (
                <>
                  <StyledSkeleton radius={8} fullWidth />
                  <Skeleton radius={8} fullWidth />
                </>
              ) : (
                <>
                  <KeyText>Juros e IOF anuais (CET)</KeyText>
                  <SmallValueText>{chosenOffer.annualFee + '% a.a'} </SmallValueText>
                </>
              )}
            </ColumnContainer>
          </RowContainer>

          <SeparatorLine />

          {showSkeleton ? (
            <>
              <StyledSkeleton radius={8} fullWidth />
              <StyledSkeleton radius={8} fullWidth />
            </>
          ) : (
            <>
              <KeyText>Total a pagar</KeyText>
              <SmallValueText>{chosenOffer.totalAmount}</SmallValueText>
            </>
          )}

          <CommentText>
            O valor da parcela acima é aproximado e pode sofrer alterações pela data de desembolso.
            Condições da oferta válidas para a data de hoje.
          </CommentText>
        </ContainerCard>
      </Card>
    </RowContainer>
  )
}

export default CreditSummaryCard
