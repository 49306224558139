import { Route, Switch, useRouteMatch } from 'react-router-dom'
import NewCampaignPage from 'src/escolas/pages/Campaign'
import CampaignProcessingPage from 'src/escolas/pages/Campaign/CampaignProcessing'
import { PageRouteProps } from 'src/escolas/router'
import { buildRoute } from 'src/shared/router/utils'

const CampaignRoute = buildRoute(
  NewCampaignPage,
  ({ path }: PageRouteProps) => path,
  ({ breadcrumbs }: PageRouteProps, path: string) => [
    ...breadcrumbs,
    { path, name: 'Rematrícula em lote' },
  ]
)

const CampaignRoutes = ({ breadcrumbs }) => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/processando`}>
        <CampaignProcessingPage />
      </Route>
      {CampaignRoute({ path, breadcrumbs })}
    </Switch>
  )
}

export default CampaignRoutes
