import { UseQueryOptions, useQuery } from '@tanstack/react-query'

import { useApi } from '@/shared/hooks'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'

import {
  UseNegotiationInstallmentQueryProps,
  UseNegotiationInstallmentQueryResponse,
} from '../../services/types'
import { guardianService } from '../../services'
import {
  DEFAULT_QUERY_RETRY_COUNT,
  DEFAULT_QUERY_STALE_TIME,
} from '../../GuardianDetails/constants'

export const useNegotiationInstallmentQuery = (
  props: UseNegotiationInstallmentQueryProps,
  options?: UseQueryOptions<UseNegotiationInstallmentQueryResponse, XMLHttpRequest>
) => {
  const { apiClient } = useApi()
  const service = guardianService(apiClient.privateApiV2)

  const queryKey = ['guardian-details-negotiation-installment', props.urn, props.status]

  const {
    data: negotiationInstallment,
    isLoading: isNegotiationInstallmentLoading,
    isError: isNegotiationInstallmentError,
    isFetched: isNegotiationInstallmentFetched,
    refetch,
  } = useQuery<UseNegotiationInstallmentQueryResponse, XMLHttpRequest>(
    queryKey,
    () => {
      return service.fetchGuardianNegotiationInstallments({
        urn: props.urn,
        schoolId: props.schoolId,
        guardianId: props.guardianId,
        status: props.status,
      })
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled:
        Boolean(props.urn) &&
        Boolean(props.schoolId) &&
        Boolean(props.guardianId) &&
        Boolean(props.status),
      retry: DEFAULT_QUERY_RETRY_COUNT,
      staleTime: DEFAULT_QUERY_STALE_TIME,
      ...options,
    }
  )

  return {
    negotiationInstallment,
    isNegotiationInstallmentLoading,
    isNegotiationInstallmentError,
    isNegotiationInstallmentFetched,
    refetchNegotiationInstallment: refetch,
  }
}
