import useAddContractEnabled from '@/escolas/hooks/useAddContractEnabled'
import Box from '@material-ui/core/Box'
import AddIcon from '@material-ui/icons/Add'
import {
  Button,
  ButtonGroup,
  Grid,
  Pagination,
  Search,
  Table,
  TableProps,
  Typography,
  usePagination,
} from '@olaisaac/design-system'
import { assoc, mapObjIndexed } from 'ramda'
import { FC, SetStateAction, useEffect, useState } from 'react'
import { TableColumns } from 'src/escolas/components/Table/TableColumnCell'
import TableFilter, {
  TableFilterOptions,
  TableFilterParam,
} from 'src/escolas/components/TableFilter'
import { renderDebtStatus } from 'src/escolas/components/contract/ContractDetails'
import ContractYearPopover from 'src/escolas/components/contract/ContractYearPopover'
import { PageTableControlBar } from 'src/escolas/components/layout/PageTableControlBar'
import { PageWithTableControlBar } from 'src/escolas/components/layout/PageWithTableControlBar'
import ConfirmationDialog from 'src/escolas/components/modal/ConfirmationDialog'
import { useDidUpdateEffect } from 'src/escolas/hooks'
import { ContractFilters } from 'src/escolas/router/[schoolSlug]/contratos'
import { EventHandlers } from 'src/escolas/router/[schoolSlug]/contratos/2021'
import { UnleashFlags, useApi, useUnleashFlag } from 'src/shared/hooks'
import { Contract, ContractResponse, ContractStatus, DebtStatus } from 'src/shared/interfaces'
import { monthsToContractValidity, paramsToQueryString } from 'src/shared/utils'
import { prettifyFullName } from 'src/shared/utils/namePrettifiers'
import { debounce } from 'throttle-debounce'
import { DefaultGuardianType } from '../create/types'
import { useLayout } from '@/shared/hooks/useLayout'

type DataSource = Array<{
  guardian: string
  id: uuid
  lowerCaseGuardian?: string
  lowerCaseStudent?: string
  product: string
  referral: string
  status: ContractStatus
  student: string
  validity: string
}>

type Contracts2021Props = {
  addContract: (
    referral: string,
    studentName: string,
    guardian: DefaultGuardianType,
    referenceYear: string,
    previousYearContractID: uuid
  ) => void
  eventHandlers: EventHandlers
  filterOptions: Array<TableFilterOptions>
  filters: ContractFilters
  handleCloseDialog: () => void
  handleRowClick: (contract_id: any) => void
  hideCreateContractButton?: boolean
  isAdmin: boolean
  isDialogOpen: boolean
  keyprop?: string
  name: string
  schoolId: string
  schoolSlug: string
  search: () => void
  setFilters: (value: SetStateAction<ContractFilters>) => void
  setName: (name: string) => void
}

const filterToParams = (filters: Record<string, TableFilterParam<any>>) =>
  mapObjIndexed(value => value?.value, filters)

const Title = () => {
  const isFlexibleEnrollmentCycleEnabled = useUnleashFlag(
    UnleashFlags.ENABLE_MATRICULAS_SELECT_CYCLES
  )
  return (
    <>
      {isFlexibleEnrollmentCycleEnabled ? (
        <Box display="flex" justifyContent="space-between" alignItems="end">
          <Box display="flex" justifyContent="left" alignItems="center">
            <Typography variation="headlineDesktopXsmall">Contratos</Typography>
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" alignItems="end">
          <Box display="flex" justifyContent="left" alignItems="center">
            <Typography variation="headlineDesktopXsmall">Contratos 2021</Typography>

            <ContractYearPopover />
          </Box>
        </Box>
      )}
    </>
  )
}

const Contracts2021: FC<Contracts2021Props> = ({
  keyprop,
  schoolId,
  schoolSlug,
  handleRowClick,
  handleCloseDialog,
  filters,
  filterOptions,
  isAdmin,
  addContract,
  setFilters,
  name,
  setName,
  search,
  isDialogOpen,
  eventHandlers,
  hideCreateContractButton = false,
}) => {
  const { api } = useApi()
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [filteredDataSource, setFilteredDataSource] = useState<Array<any>>([])
  const [total, setTotal] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFiltered, setIsFiltered] = useState<boolean>(false)
  const [orderBy, setOrderBy] = useState<string>('')
  const isAddContractButtonEnabled = useAddContractEnabled()

  const isFlexibleEnrollmentCycleEnabled = useUnleashFlag(
    UnleashFlags.ENABLE_MATRICULAS_SELECT_CYCLES
  )

  useLayout({
    enableSideMenu: true,
    enableHeader: true,
    headerTitle: <Title />,
  })

  const {
    page,
    setPage,
    itensPerPage,
    setItensPerPage,
    initialItensPerPageOptions,
  } = usePagination()

  const { searchEventHandler, filterEventHandler } = eventHandlers

  const query = () =>
    api.contracts
      .getList2021({
        school_id: schoolId,
        school_slug: schoolSlug,
      })
      .then(({ data, pagination }) => {
        data?.sort((a, b) => a.student?.name.localeCompare(b.student?.name))
        return { data, pagination }
      })

      .then(({ data, pagination }) => ({
        data: data?.map(
          ({
            status,
            id,
            start_month,
            end_month,
            student,
            product,
            debt_status,
            guardian,
          }: ContractResponse) => {
            return {
              debt_status,
              guardian: prettifyFullName(guardian.name),
              id,
              product: product.name,
              status,
              student: prettifyFullName(student.name),
              validity: monthsToContractValidity({ start_month, end_month }),
            }
          }
        ),
        pagination,
      }))

  type FilterResponse = { data: DataSource; filtered: boolean }

  const filterDataByName = (data: DataSource): FilterResponse => {
    if (!filters.name?.value) {
      return { filtered: false, data }
    }

    const lowerCaseName = filters.name.value.toLowerCase()
    const totalFiltered = data
      ?.filter(
        ({ lowerCaseStudent, lowerCaseGuardian }) =>
          lowerCaseStudent?.includes(lowerCaseName) || lowerCaseGuardian?.includes(lowerCaseName)
      )
      .sort((a, b) => {
        // Show first matches with the student name and then matches with the guardin name
        // in each block, order by alphabetical order
        if (a.lowerCaseStudent.includes(lowerCaseName)) {
          if (b.lowerCaseStudent.includes(lowerCaseName)) {
            // alphabetical order in student matches
            return a.student < b.student ? -1 : 1
          }
          // students first
          return -1
        }
        if (b.lowerCaseStudent.includes(lowerCaseName)) {
          // students first
          return 1
        }
        // alphabetical order (by student name) in guardian matches
        return a.student < b.student ? -1 : 1
      })

    return { filtered: true, data: totalFiltered }
  }

  const filterDataByStatus = (data: DataSource): FilterResponse => {
    if (!filters.status?.value) {
      return { filtered: false, data }
    }

    return {
      filtered: true,
      data: data?.filter(({ status }) => {
        return status === filters.status.value
      }),
    }
  }

  const qs = paramsToQueryString(filterToParams(filters))
  const searchData = () => {
    setIsLoading(true)
    query()
      .then(({ data }) => {
        setTotal(data?.length || 0)
        setDataSource(data || [])

        return data
      })
      .then(data => {
        const newDataWithLowerCase = data
          ?.map(e => assoc('lowerCaseGuardian', e.guardian?.toLowerCase(), e))
          ?.map(e => assoc('lowerCaseStudent', e.student?.toLowerCase(), e))
        setDataSource(newDataWithLowerCase)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleFilters = () => {
    const { filtered: isFilteredByStatus, data: filteredDataByStatus } = filterDataByStatus(
      dataSource
    )
    const { filtered: isFilteredByName, data: filteredDataByName } = filterDataByName(
      filteredDataByStatus
    )

    setIsFiltered(isFilteredByStatus || isFilteredByName)

    setTotal(filteredDataByName?.length || 0)
    setFilteredDataSource(filteredDataByName)
  }

  useEffect(() => {
    searchData()
  }, [])

  useEffect(() => {
    handleFilters()
  }, [dataSource])

  useEffect(() => {
    search()
    setPage(0)
  }, [name])

  useDidUpdateEffect(() => {
    handleFilters()
    setPage(0)
  }, [qs, itensPerPage, orderBy])

  const content = isFiltered ? filteredDataSource : dataSource
  const source = keyprop ? content.map(r => assoc('key', r[keyprop], r)) : content

  const contractsTableColumns: TableColumns = [
    { headerName: 'Aluno', field: 'student', enableSorting: true },
    { headerName: 'Responsável', field: 'guardian', enableSorting: true },
    { headerName: 'Produto', field: 'product', enableSorting: false },
    {
      enableSorting: false,
      field: 'debt_status',
      headerName: 'Situação',
      renderCell: value => renderDebtStatus(DebtStatus[value]),
    },
  ]

  const defaultProps: TableProps<Contract> = {
    columns: contractsTableColumns,
    isLoading,
    orderBy: orderBy,
    rows: source?.slice(page * itensPerPage, (page + 1) * itensPerPage),
    sorted: true,
    sortingHandler: value => {
      if (value !== 'reEnrollData') {
        setOrderBy(value)
      }
    },
  }

  const propsWithOnClick = assoc(
    'onRowClick',
    params => {
      const { id } = params.row
      handleRowClick(id)
    },
    defaultProps
  )

  const dialogContent =
    'Esta página é apenas para consulta e não pode ser editada. Para saber detalhes dos contratos de 2021, acesse o ERP da sua escola.'

  return (
    <Box paddingTop="2.5rem">
      <PageWithTableControlBar>
        <ConfirmationDialog
          isVisible={isDialogOpen}
          onClose={handleCloseDialog}
          submitHandler={handleCloseDialog}
          buttonLabel="Ok, entendi"
        >
          {' '}
          {dialogContent}
        </ConfirmationDialog>
        <Grid item xs={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
          {isFlexibleEnrollmentCycleEnabled && <ContractYearPopover />}
          <Grid container alignItems="center">
            <Grid item sm={5}>
              <Search
                placeholder="Procure pelo nome do aluno ou responsável"
                onClick={searchEventHandler}
                onChange={debounce(800, value => {
                  setName(value)
                })}
                onClear={() => setName('')}
              />
            </Grid>

            <Grid item sm={7} style={{ textAlign: 'right' }}>
              <TableFilter
                filters={filters}
                filtersSetter={setFilters}
                filterOptions={filterOptions}
                filterEventHandler={filterEventHandler}
              />
            </Grid>

            <Grid item xs={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
              <Box mt={2} mb={3}>
                <Table<Contract> {...propsWithOnClick} />
              </Box>
            </Grid>
          </Grid>

          <PageTableControlBar>
            <Pagination
              currentPage={page}
              itensPerPage={itensPerPage}
              itensPerPageOptions={initialItensPerPageOptions}
              totalItens={total}
              onPageChange={newPage => {
                setPage(newPage)
              }}
              onItensPerChangeChange={newItensPerPage => {
                setItensPerPage(newItensPerPage)
              }}
            />
            <ButtonGroup>
              {isAdmin && !hideCreateContractButton && isAddContractButtonEnabled && (
                <Button
                  variation="primary"
                  startIcon={<AddIcon />}
                  onClick={() => addContract(null, null, null, '2021', null)}
                >
                  Adicionar contrato 2021
                </Button>
              )}
            </ButtonGroup>
          </PageTableControlBar>
        </Grid>
      </PageWithTableControlBar>
    </Box>
  )
}

export default Contracts2021
