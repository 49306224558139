import type { PayoutTransactionEvents } from '../models/PayoutTransactionEvents'

/**
 * Defines the label for each payout transaction event
 */
export const PAYOUT_TRANSACTION_EVENTS_LABEL: Record<PayoutTransactionEvents, string> = {
  AGGLUTINATION_DISCOUNT: 'Desconto por aglutinação de parcelas',
  APPLIED_DISCOUNT: 'Edição de desconto',
  CANCEL: 'Cancelado',
  CORRECTION: 'Correção pontual',
  LOST_DISCOUNT: 'Adicional desc. antecipação',
  NEW_CONTRACT: 'Novo contrato',
  SCHOOL_LIQUIDATION: 'Recebido na escola',
} as const
