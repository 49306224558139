import { useEffect, useRef, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EventIcon from '@material-ui/icons/Event'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { useApi } from '@/shared/hooks'
import { ErrorDialog } from '@/modules/enrollment/components/ErrorDialog'
import { Button, Notification, Typography } from '@olaisaac/design-system'
import { Enrollment, Installment } from '@/escolas/services/enrollment/types'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { PageWrapper } from '@/escolas/components/PageWrapper'

import { PageHeader } from './components/PageHeader'
import { PageFooter } from './components/PageFooter'
import { TableTotals } from './components/TableTotals'
import { PersonalData } from './components/PersonalData'
import { useNotification } from './hooks/useNotification'
import { InstallmentsTable } from './components/InstallmentsTable'
import { EditDueDateDialog } from './components/EditDueDateDialog'
import { EditAmountDialog, EditAmountForm } from './components/EditAmountDialog'
import { SelectedInstallmentsText } from './components/SelectedInstallmentsText'
import { AddDiscountDialog, AddDiscountForm } from './components/AddDiscountDialog'
import {
  applyPercentageDiscount,
  calculateHasUnsavedChanges,
  canAddDiscount,
  canEditAmount,
  filterSelectedInstallments,
} from './utils'
import * as Styled from './styles'

enum DialogNames {
  ADD_DISCOUNT = 'ADD_DISCOUNT',
  EDIT_AMOUNT = 'EDIT_AMOUNT',
  EDIT_DUE_DATE = 'EDIT_DUE_DATE',
}

type EnrollmentEditProps = {
  contractId: uuid
  schoolId: uuid
}

const dialogEventHash: { [key: string]: string } = {
  [DialogNames.ADD_DISCOUNT]: 'Descontos',
  [DialogNames.EDIT_AMOUNT]: 'Valor da parcela',
  [DialogNames.EDIT_DUE_DATE]: 'Vencimentos',
}

export const EditContract = ({ contractId, schoolId }: EnrollmentEditProps) => {
  const { api } = useApi()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingPersonalData, setIsLoadingPersonalData] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isError, setIsError] = useState(false)
  const [enrollment, setEnrollment] = useState<Enrollment>(null)
  const [selectedInstallmentIds, setSelectedInstallmentIds] = useState<uuid[]>([])
  const [dialogName, setDialogName] = useState<DialogNames | ''>('')
  const { isOpen: isNotificationOpen, setIsOpen: setIsNotificationOpen } = useNotification()
  const [personalData, setPersonalData] = useState<PersonalData>({
    studentName: '',
    productName: '',
    guardianName: '',
    referenceYear: '',
  })
  const savedInstallments = useRef<Installment[]>()

  useSetPageTitle('Editar Contrato')

  useEffect(() => {
    const getEnrollment = async () => {
      setIsLoading(true)
      setIsError(false)

      try {
        const data = await api.enrollment.getEnrollment({
          schoolId,
          contractId,
        })

        setEnrollment(data.data)
        savedInstallments.current = data.data.installments
      } catch (error) {
        console.error(error)
        setIsError(true)
        Sentry.captureException(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (contractId) {
      getEnrollment()
    }

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_EDIT,
        name: EventDispatcherEvents.PAGE_VIEWED,
        action: 'page_view',
      })
  }, [contractId, schoolId])

  useEffect(() => {
    const getPersonalData = async () => {
      setIsError(false)
      setIsLoadingPersonalData(true)

      try {
        const [productList, student, guardian] = await Promise.all([
          api.products.getList({
            ids: enrollment.product_id,
            per_page: 1,
            school_id: schoolId,
          }),
          api.students.get(enrollment.student_id),
          api.guardians.get(enrollment.guardian_id),
        ])

        setPersonalData({
          productName: productList.data[0].name,
          studentName: student.name,
          guardianName: guardian.name,
          referenceYear: enrollment.reference_year,
        })
      } catch (error) {
        console.error(error)
        setIsError(true)
        Sentry.captureException(error)
      } finally {
        setIsLoadingPersonalData(false)
      }
    }

    if (enrollment !== null) {
      getPersonalData()
    }
  }, [enrollment?.id])

  const handleCloseDialog = () => {
    setDialogName('')
  }

  const handleCancel = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_EDIT,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: 'Cancelar',
          $modal_name: dialogEventHash[dialogName],
        },
      })
    handleCloseDialog()
  }

  const handleSaveEditAmount = (values: EditAmountForm) => {
    const newInstallments = enrollment.installments.map(installment => {
      if (selectedInstallmentIds.includes(installment.id)) {
        return {
          ...installment,
          amount: values.amount,
        }
      }

      return installment
    })

    setEnrollment({ ...enrollment, installments: newInstallments })

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_EDIT,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: 'Aplicar',
          $modal_name: dialogEventHash[dialogName],
          $type_of_installment: enrollment.installments.find(installment =>
            selectedInstallmentIds.includes(installment.id)
          )?.type,
          $installment_number: selectedInstallmentIds.length,
          $number_of_installments: enrollment.installments.length,
        },
      })

    handleCloseDialog()
  }

  const handleAddDiscount = (values: AddDiscountForm, percentMode: boolean) => {
    const newInstallments = enrollment.installments.map(installment => {
      if (selectedInstallmentIds.includes(installment.id)) {
        return {
          ...installment,
          discounts: [
            ...installment.discounts,
            {
              ...values,
              id: null,
              amount: percentMode
                ? applyPercentageDiscount(values.percentage, installment)
                : values.amount,
              days_before_due_date: Number(values.days_before_due_date),
              type: values.discountRule,
            },
          ],
        }
      }

      return installment
    })

    setEnrollment({ ...enrollment, installments: newInstallments })

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_EDIT,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: 'Aplicar',
          $modal_name: 'Descontos',
          $type_of_installment: enrollment.installments.find(installment =>
            selectedInstallmentIds.includes(installment.id)
          )?.type,
          $installment_number: selectedInstallmentIds.length,
          $chip_selected: percentMode ? 'percentage' : 'value',
          $discount_rule: values.discountRule,
        },
      })

    handleCloseDialog()
  }

  const handleEditDueDates = (dueDates: datestring[], workingDayMode: boolean) => {
    const mapIDToDueDate = {}
    for (let i = 0; i < selectedInstallmentIds.length; i++) {
      mapIDToDueDate[selectedInstallmentIds[i]] = dueDates[i]
    }

    const newInstallments = enrollment.installments.map(installment => {
      if (installment.id in mapIDToDueDate) {
        return {
          ...installment,
          due_date: mapIDToDueDate[installment.id],
        }
      }

      return installment
    })

    setEnrollment({ ...enrollment, installments: newInstallments })

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_EDIT,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: 'Aplicar',
          $modal_name: 'Vencimentos',
          $type_of_installment: enrollment.installments.find(installment =>
            selectedInstallmentIds.includes(installment.id)
          )?.type,
          $installment_number: selectedInstallmentIds.length,
          $number_of_installments: enrollment.installments.length,
          $selected_rule: workingDayMode ? 'dia útil' : 'dia fixo',
        },
      })

    handleCloseDialog()
  }

  const handleDeleteDiscount = (installmentId: uuid, discountIndexToDelete: number) => {
    const newInstallments = enrollment.installments.map(installment => {
      if (installment.id !== installmentId) {
        return installment
      }

      return {
        ...installment,
        discounts: installment.discounts.filter((_, index) => index !== discountIndexToDelete),
      }
    })

    setEnrollment({ ...enrollment, installments: newInstallments })

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_EDIT,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: 'Remover desconto',
          $chip_name: 'Desconto',
          $type_of_installment: enrollment.installments.find(installment =>
            selectedInstallmentIds.includes(installment.id)
          )?.type,
          $installment_number: selectedInstallmentIds.length,
          $discount_rule: enrollment.installments.find(
            installment => installment.id === installmentId
          ).discounts[discountIndexToDelete]?.type,
        },
      })
  }

  const handleSubmit = async () => {
    setIsSubmitting(true)
    setIsError(false)

    try {
      await api.enrollment.putEnrollment(schoolId, enrollment.id, enrollment.installments)
      setIsNotificationOpen(true)
      savedInstallments.current = enrollment.installments
    } catch (error) {
      console.error(error)
      setIsError(true)
      Sentry.captureException(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const isSelected = selectedInstallmentIds.length > 0
  const installments = enrollment !== null ? enrollment.installments : []
  const selectedInstallments = filterSelectedInstallments(installments, selectedInstallmentIds)

  const dialogComponent =
    {
      [DialogNames.EDIT_AMOUNT]: (
        <EditAmountDialog
          onClose={handleCancel}
          onConfirm={handleSaveEditAmount}
          selectedInstallments={selectedInstallments}
        />
      ),
      [DialogNames.EDIT_DUE_DATE]: (
        <EditDueDateDialog
          onClose={handleCancel}
          onConfirm={handleEditDueDates}
          selectedInstallments={selectedInstallments}
          allInstallments={installments}
        />
      ),
      [DialogNames.ADD_DISCOUNT]: (
        <AddDiscountDialog
          onClose={handleCancel}
          onConfirm={handleAddDiscount}
          selectedInstallments={selectedInstallments}
        />
      ),
    }[dialogName] || null

  const editAmountDisabled = !canEditAmount(selectedInstallments)
  const addDiscountDisabled = !canAddDiscount(selectedInstallments)
  const hasUnsavedChanges = calculateHasUnsavedChanges(savedInstallments.current, installments)

  return (
    <>
      <PageWrapper>
        <Styled.FooterSpaceClearance>
          <PageHeader isLoading={isLoadingPersonalData} enrollment={enrollment} />
          <PersonalData isLoading={isLoadingPersonalData} data={personalData} />
          <Typography variation="headlineDesktopXsmall">Plano de pagamento</Typography>
          <InstallmentsTable
            enrollment={enrollment}
            data={installments}
            isLoading={isLoading}
            onDeleteDiscount={handleDeleteDiscount}
            selectedInstallmentIds={selectedInstallmentIds}
            setSelectedInstallmentIds={setSelectedInstallmentIds}
          />

          {isSelected && (
            <Styled.StickyComponent>
              <Styled.ActionBarContainer>
                <Styled.SelectedTableFooterContainer>
                  <SelectedInstallmentsText count={selectedInstallmentIds.length} />
                  <Tooltip
                    title={addDiscountDisabled ? 'Cada parcela pode ter no máximo 3 descontos' : ''}
                  >
                    <span>
                      <Button
                        onClick={() => setDialogName(DialogNames.ADD_DISCOUNT)}
                        startIcon={<AddIcon />}
                        variation="ghost"
                        disabled={addDiscountDisabled}
                      >
                        Aplicar descontos
                      </Button>
                    </span>
                  </Tooltip>

                  <Tooltip
                    title={
                      editAmountDisabled
                        ? 'Você não pode alterar o valor de parcelas que possuem descontos aplicados.'
                        : ''
                    }
                  >
                    <span>
                      <Button
                        onClick={() => setDialogName(DialogNames.EDIT_AMOUNT)}
                        startIcon={<AttachMoneyIcon />}
                        variation="ghost"
                        disabled={editAmountDisabled}
                      >
                        Editar valor das parcelas
                      </Button>
                    </span>
                  </Tooltip>

                  <Button
                    onClick={() => setDialogName(DialogNames.EDIT_DUE_DATE)}
                    startIcon={<EventIcon />}
                    variation="ghost"
                  >
                    Editar vencimentos
                  </Button>
                </Styled.SelectedTableFooterContainer>
              </Styled.ActionBarContainer>
            </Styled.StickyComponent>
          )}

          {!isSelected && (
            <Styled.ActionBarContainer>
              <TableTotals installments={installments} />
            </Styled.ActionBarContainer>
          )}

          <PageFooter
            hasUnsavedChanges={hasUnsavedChanges}
            isSubmitting={isSubmitting}
            isNotificationOpen={isNotificationOpen}
            handleSubmit={handleSubmit}
          />
        </Styled.FooterSpaceClearance>
      </PageWrapper>
      {dialogComponent}
      {isNotificationOpen && (
        <Notification description="As edições no contrato foram salvas." floating />
      )}
      <ErrorDialog isError={isError} />
    </>
  )
}
