import { Installment } from '@/escolas/services/enrollment/types'
import { Dialog } from '@material-ui/core'
import { useEffect } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import FormControl from '@material-ui/core/FormControl'
import {
  TextField,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@olaisaac/design-system'
import { Controller, FieldErrorsImpl, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { formatCentsToReal } from 'src/shared/utils'
import styled from 'styled-components'
import { MINIMUM_AMOUNT_CENTS, calculateCommomAmount, validateEditedAmount } from '../utils'
import { SelectedInstallmentsText } from './SelectedInstallmentsText'

const TextContainer = styled.div`
  margin-bottom: 24px;
`

enum Field {
  AMOUNT = 'amount',
}

export type EditAmountForm = {
  [Field.AMOUNT]: cents
}

const REQUIRED_MESSAGES = {
  [Field.AMOUNT]: 'Por favor, informe o valor da parcela.',
}

const errorProps = (errors: Partial<FieldErrorsImpl<EditAmountForm>>, field: Field) => {
  return {
    error: Boolean(errors[field]),
    helperText: errors[field] ? errors[field].message : '',
  }
}

/**
 * @deprecated This component will be deprecated after the EEMAT-727-ENABLE_UNIFIED_CONTRACT_EDITING rollout
 */
export const EditAmountDialog = ({
  onClose,
  onConfirm,
  selectedInstallments,
}: {
  onClose: () => void
  onConfirm?: (e: any) => void
  selectedInstallments: Installment[]
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EditAmountForm>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { amount: calculateCommomAmount(selectedInstallments) },
  })

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  useEffect(() => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_EDIT,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $button_name: 'Editar valor das parcelas',
          $type_of_installment: selectedInstallments[0].type,
        },
      })
  })

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onConfirm)}>
        <DialogTitle>Editar valor da parcela</DialogTitle>
        <DialogContent>
          <TextContainer>
            <SelectedInstallmentsText count={selectedInstallments.length} />
          </TextContainer>
          <Controller
            rules={{
              required: REQUIRED_MESSAGES[Field.AMOUNT],
              validate: {
                minValue: v => v >= MINIMUM_AMOUNT_CENTS || 'O valor mínimo da parcela é R$ 1,00',
                netInstallmentAmount: v =>
                  validateEditedAmount(v, selectedInstallments) ||
                  'As parcelas selecionadas têm descontos aplicados, e a soma dos descontos não pode ultrapassar o valor da parcela.',
              },
            }}
            control={control}
            name={Field.AMOUNT}
            render={({ field: { onChange, value } }) => (
              <FormControl variant="outlined" fullWidth>
                <NumberFormat
                  id={Field.AMOUNT}
                  onValueChange={({ floatValue }) => {
                    if (floatValue === undefined) {
                      onChange(null)
                      return
                    }

                    onChange(floatValue)
                  }}
                  customInput={TextField}
                  variant="outlined"
                  label="Valor da parcela"
                  format={formatCentsToReal}
                  value={value}
                  {...errorProps(errors, Field.AMOUNT)}
                />
              </FormControl>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variation="ghost" onClick={onClose}>
            Cancelar
          </Button>
          <Button variation="primary" type="submit">
            Aplicar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
