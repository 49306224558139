import { useEffect, useState } from 'react'
import { useQuery } from './useQuery'

export type StatusSearch = {
  isCPF: boolean
  isGuardian: boolean
  searchText: string
}

const checkIsNumberAndLengthLesserThan11 = (value: string): boolean => /^\d{1,11}$/.test(value)

type CallbackChangeText = (text: string, isCPF: boolean) => void

export const IS_GUARDIAN_QUERY_PARAM_NAME = 'resp'
export const IS_GUARDIAN_QUERY_PARAM_TRUTHY_VALUE = '1'

const useSearchByStudentOrGuardian = (
  text: string,
  callbackChangeSearch: CallbackChangeText,
  search: (statusSearch: StatusSearch) => void
) => {
  const { query, setOnQueryParam } = useQuery()
  const [statusSearch, setStatusSearch] = useState<StatusSearch>({
    searchText: text,
    isGuardian: query.get(IS_GUARDIAN_QUERY_PARAM_NAME) === IS_GUARDIAN_QUERY_PARAM_TRUTHY_VALUE,
    isCPF: checkIsNumberAndLengthLesserThan11(text),
  })

  useEffect(() => {
    callbackChangeSearch(statusSearch.searchText, statusSearch.isCPF)
  }, [statusSearch])

  useEffect(() => {
    search(statusSearch)
  }, [text, statusSearch.isGuardian])

  const handleStatusSearch = <T extends keyof StatusSearch>(
    key: T,
    value: StatusSearch[T]
  ): void => {
    if (key === 'searchText') {
      setStatusSearch(prevState => ({
        ...prevState,
        [key]: value,
        isCPF: checkIsNumberAndLengthLesserThan11(<string>value),
      }))

      return
    }

    setStatusSearch(prevState => ({ ...prevState, [key]: value }))
  }

  const changeTypeSearch = (isGuardian: boolean) => {
    setOnQueryParam(
      isGuardian ? IS_GUARDIAN_QUERY_PARAM_TRUTHY_VALUE : '',
      IS_GUARDIAN_QUERY_PARAM_NAME
    )
    if (isGuardian === statusSearch.isGuardian) return

    handleStatusSearch('isGuardian', isGuardian)
  }

  return { statusSearch, handleStatusSearch, changeTypeSearch }
}

export default useSearchByStudentOrGuardian
