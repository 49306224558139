import { useState } from 'react'
import styled from 'styled-components'

import { MenuItem, Popover } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import { Table, TableColumns, IconButton } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'

import {
  renderDebtStatus,
  renderContractStatus,
} from '@/escolas/components/contract/ContractDetails'
import {
  ContractResponse,
  MatriculasApiContractStatus,
  ReenrollmentStatus,
} from '@/shared/interfaces'
import { useNavigation } from '@/escolas/hooks'
import { MATRICULAS_API_FIRST_YEAR } from '@/escolas/components/contract/constants'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { UnleashFlags, useUnleashFlag } from 'src/shared/hooks/useUnleashFlag'

type Rows = Array<
  ContractResponse & {
    actions: {
      contractId: string
      guardianId: string
      productId: string
      referenceYear: string
      status: ContractResponse['status']
      studentId: string
      studentName: string
    }
    key: string
  }
>

const mapDataToRows = (rawData: ContractResponse[]): Rows => {
  return rawData.map(item => ({
    ...item,
    key: item.id,
    actions: {
      contractId: item.id,
      guardianId: item.guardian_id,
      referenceYear: item.reference_year,
      studentName: item.student.name,
      studentId: item.student_id,
      productId: item.product_id,
      status: item.status,
    },
  }))
}

type StudentTableProps = {
  data: ContractResponse[]
  enrollmentStatus: ReenrollmentStatus
  isLoading: boolean
  isPreContractMap: Record<uuid, boolean>
}

const TableContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[5]};

  & tbody tr {
    cursor: pointer;
  }
`

const ActionContainer = styled.div`
  margin-left: auto;
`

const NODES_NOT_ALLOWED_CLICK = ['LI', 'BUTTON']

export const ContractsTable = ({
  data,
  isLoading,
  isPreContractMap,
  enrollmentStatus,
}: StudentTableProps) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedRow, setSelectedRow] = useState<null | Rows[number]['actions']>(null)
  const useUnifiedContractEditing = useUnleashFlag(UnleashFlags.ENABLE_UNIFIED_CONTRACT_EDITING)
  const { navigateTo, newTabNavigateTo } = useNavigation()

  const handleClickMoreOptions = (
    event: React.MouseEvent<HTMLElement>,
    row?: Rows[number]['actions']
  ) => {
    setAnchorEl(event.currentTarget)
    setSelectedRow(row)
  }

  const handleClickToSeeInstallments = (row?: Rows[number]) => {
    if (row?.status === MatriculasApiContractStatus.PENDING_GUARDIAN_APPROVAL) return

    let rowData: any = selectedRow
    if (row) {
      rowData = {
        guardianId: row.guardian_id,
        studentId: row.student_id,
        referenceYear: row.reference_year,
        productId: row.product_id,
      }
    }

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
        name: row ? EventDispatcherEvents.TABLE_ROW_CLICK : EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $Button_name: 'VER_PARCELAS',
          $table_name: 'LISTA_DE_PRODUTOS',
          $Enrollment_status: enrollmentStatus,
        },
      })

    const pathInstallments = `/responsaveis/${rowData?.guardianId}/parcelas?student_ids=${rowData?.studentId}&product_ids=${rowData?.productId}&reference_years=${rowData.referenceYear}`

    setAnchorEl(null)
    if (row) {
      newTabNavigateTo({ path: pathInstallments })
    } else {
      navigateTo({
        path: pathInstallments,
      })
    }
  }

  const handleClickToSeeContract = () => {
    const pathContracts = `/responsaveis/${selectedRow?.guardianId}/contratos?student_ids=${selectedRow?.studentId}&product_ids=${selectedRow?.productId}`

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $Button_name: 'VER_CONTRATOS',
          $table_name: 'LISTA_DE_PRODUTOS',
          $Enrollment_status: enrollmentStatus,
        },
      })

    setAnchorEl(null)

    navigateTo({
      path: pathContracts,
    })
  }

  const handleClickToEditPreContract = () => {
    setAnchorEl(null)

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_STUDENT,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        customProperties: {
          $Button_name: 'EDITAR_CONTRATOS',
          $table_name: 'LISTA_DE_PRODUTOS',
          $Enrollment_status: enrollmentStatus,
        },
      })

    navigateTo({
      path: `/contratos/${selectedRow?.contractId}/editar`,
    })
  }

  const showEditPreContract = () => {
    const isMatriculasPreContract =
      Number(selectedRow?.referenceYear) >= MATRICULAS_API_FIRST_YEAR &&
      isPreContractMap[selectedRow?.contractId]

    return isMatriculasPreContract
  }

  const columns: TableColumns = [
    {
      headerName: 'Status',
      field: 'status',
      renderCell: renderContractStatus,
    },
    { headerName: 'Ano', field: 'reference_year' },
    {
      headerName: 'Produto',
      field: 'product',
      valueFormatter: e => e.name,
    },
    {
      headerName: 'Situação Financeira',
      field: 'debt_status',
      renderCell: value => renderDebtStatus(value),
    },
    {
      field: 'actions',
      renderCell: (value: Rows[number]['actions']) =>
        value.status === MatriculasApiContractStatus.PENDING_GUARDIAN_APPROVAL ? null : (
          <ActionContainer>
            <IconButton variation="ghost" onClick={e => handleClickMoreOptions(e, value)}>
              <MoreVert />
            </IconButton>
            <Popover
              anchorEl={selectedRow?.contractId === value.contractId ? anchorEl : null}
              keepMounted={false}
              open={selectedRow?.contractId === value.contractId}
              onClose={() => {
                setAnchorEl(null)
                setSelectedRow(null)
              }}
            >
              <MenuItem onClick={() => handleClickToSeeInstallments()}>Ver parcelas</MenuItem>
              <MenuItem onClick={() => handleClickToSeeContract()}>Ver contrato</MenuItem>
              {(showEditPreContract() || useUnifiedContractEditing) && (
                <MenuItem onClick={() => handleClickToEditPreContract()}>Editar contrato</MenuItem>
              )}
            </Popover>
          </ActionContainer>
        ),
    },
  ]

  const rows: Rows = isLoading ? [] : mapDataToRows(data)

  return (
    <TableContainer>
      <Table
        isLoading={isLoading}
        columns={columns}
        rows={rows}
        onRowClick={(params, e) => {
          !NODES_NOT_ALLOWED_CLICK.includes(e.target.nodeName) &&
            !anchorEl &&
            handleClickToSeeInstallments(params.row)
        }}
      />
    </TableContainer>
  )
}
