import { Dialog } from '@material-ui/core'
import {
  Button,
  DialogActions,
  DialogTitle,
  Typography,
  DialogContent,
} from '@olaisaac/design-system'

type CampaignDialogProps = {
  closeText: string
  confirmText?: string
  content: string | React.ReactNode
  onClose: () => any
  onConfirm?: () => any
  open: boolean
  title: string
}

const CampaignDialog = ({
  open,
  title,
  content,
  onClose,
  closeText,
  onConfirm,
  confirmText,
}: CampaignDialogProps) => (
  <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle>
      <Typography variation="headlineDesktopMedium" style={{ margin: 0 }}>
        {title}
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography>{content}</Typography>
    </DialogContent>
    <DialogActions>
      {onConfirm && (
        <Button variation="ghost" onClick={onClose}>
          {closeText}
        </Button>
      )}
      <Button variation="primary" onClick={onConfirm ?? onClose}>
        {onConfirm ? confirmText : closeText}
      </Button>
    </DialogActions>
  </Dialog>
)

export default CampaignDialog
