import { FC, useContext, useState } from 'react'
import { Controller } from 'react-hook-form'
import styled from 'styled-components'

import { Button, Typography, FormControlLabel, Checkbox } from '@olaisaac/design-system'
import { Box, Collapse } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import { FilterReportContext } from './FilterReportContext'
import { FilterDataProps } from './types'

const StyledFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;
  margin-bottom: 0.75rem;

  p {
    margin-top: 0.125rem;
  }
`

const StyledButton = {
  fontSize: `0.75rem`,
  padding: `0 0.5rem`,
  marginRight: `0.5rem`,
  minWidth: `auto`,
  maxWidth: `auto`,
  height: '1.75rem',
}

type FilterProps = {
  filter: FilterDataProps
}

export const FilterReportGroupList: FC<FilterProps> = ({ filter }) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const { filterFormState, loadMore } = useContext(FilterReportContext)

  const Icon = isExpanded ? KeyboardArrowUpIcon : KeyboardArrowDownIcon

  const checkboxIds = filterFormState.watch(filter.type) || []
  const searchValue = filterFormState.watch('search') || ''
  const totalFilter = filter.total
  const totalLabel =
    searchValue === '' ? `(${checkboxIds.length} de ${totalFilter})` : `(${totalFilter})`

  if (totalFilter === 0) {
    return null
  }

  return (
    <Box mb="2.5rem">
      <Box mb="1rem" display="flex" justifyContent="space-between" alignItems="center">
        <Typography>
          {filter.title} {totalLabel}
        </Typography>

        <Box display="flex" justifyContent="flex-end">
          <Button
            style={StyledButton}
            variation="ghost"
            disabled={checkboxIds.length === 0}
            onClick={() => {
              filterFormState.setValue(filter.type, [])
            }}
          >
            Limpar
          </Button>

          <Button style={StyledButton} variation="ghost" onClick={() => setIsExpanded(!isExpanded)}>
            <Icon fontSize="small" />
          </Button>
        </Box>
      </Box>

      <Collapse in={isExpanded}>
        <Box display="flex" flexDirection="column">
          <Controller
            control={filterFormState.control}
            name={filter.type}
            render={({ field: { onBlur } }) => {
              return (
                <>
                  {filter.data.map(({ id, label }) => (
                    <StyledFormControlLabel
                      key={id}
                      label={label}
                      control={
                        <Checkbox
                          onBlur={onBlur}
                          onChange={() => {
                            if (checkboxIds.includes(id)) {
                              filterFormState.setValue(
                                filter.type,
                                checkboxIds.filter(_id => _id !== id)
                              )
                            } else {
                              filterFormState.setValue(filter.type, [...checkboxIds, id])
                            }
                          }}
                          checked={checkboxIds.includes(id)}
                          name={filter.type}
                          value={id}
                        />
                      }
                    />
                  ))}
                </>
              )
            }}
          />
        </Box>

        {filter.total > filter.data.length && (
          <Button style={StyledButton} variation="ghost" onClick={() => loadMore(filter.type)}>
            Ver mais
          </Button>
        )}
      </Collapse>
    </Box>
  )
}
