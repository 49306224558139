import { Box, Tooltip } from '@material-ui/core'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { Chip } from '@olaisaac/design-system'
import styled from 'styled-components'

const TooltipIconWrapper = styled.div`
  color: ${({ theme }) => theme.primitiveTokens.colors.gray[50]};
  padding-left: 24px;
`

const ContractInfo = ({ chipText, tooltipText }) => (
  <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
    <Chip label={chipText} />
    <Tooltip title={tooltipText} placement="bottom-end">
      <TooltipIconWrapper>
        <WarningRoundedIcon />
      </TooltipIconWrapper>
    </Tooltip>
  </Box>
)

export default ContractInfo
