import { Installment } from '@/escolas/services/enrollment/types'

import { Discount, InstallmentType } from '@/shared/interfaces'
import { roundCents } from '@/shared/utils'
import dayjs from 'dayjs'
import { equals } from 'ramda'

const MAXIMUM_NUMBER_OF_DISCOUNTS = 3
export const MINIMUM_AMOUNT_CENTS = 100
export const MINIMUM_DISCOUNT_AMOUNT_CENTS = 1

export enum DiscountRule {
  DUE_PAYMENT = 'DUE_PAYMENT',
  EARLY_PAYMENT = 'EARLY_PAYMENT',
  PERPETUAL = 'PERPETUAL',
}

export const DiscountRuleLabels = {
  [DiscountRule.PERPETUAL]: {
    name: 'Desconto permanente',
    description: 'O desconto será aplicado, independente da data de pagamento',
  },
  [DiscountRule.DUE_PAYMENT]: {
    name: 'Desconto para pagamento em dia',
    description: 'O desconto só será aplicado se o pagamento ocorrer até a data de vencimento',
  },
  [DiscountRule.EARLY_PAYMENT]: {
    name: 'Desconto para pagamento antecipado',
    description:
      'O desconto só será aplicado se o pagamento ocorrer X dias antes da data de vencimento',
  },
}

export const filterSelectedInstallments = (
  installments: Installment[],
  selectedIDs: uuid[]
): Installment[] => {
  return installments.filter(i => selectedIDs.includes(i.id))
}

export const calculateOrderReferences = (installments: Pick<Installment, 'type'>[]): string[] => {
  const total = {
    [InstallmentType.ENROLLMENT]: installments.filter(i => i.type === InstallmentType.ENROLLMENT)
      .length,
    [InstallmentType.TUITION]: installments.filter(i => i.type === InstallmentType.TUITION).length,
  }

  const count = {
    [InstallmentType.ENROLLMENT]: 0,
    [InstallmentType.TUITION]: 0,
  }

  const result = []

  for (let i = 0; i < installments.length; i++) {
    const type = installments[i].type
    count[type] += 1

    result.push(`${count[type]} de ${total[type]}`)
  }

  return result
}

export const calculateCommomAmount = (installments: Installment[]): cents => {
  if (installments.length === 0) {
    return 0
  }

  if (installments.length === 1) {
    return installments[0].amount
  }

  for (let i = 1; i < installments.length; i++) {
    if (installments[0].amount !== installments[i].amount) {
      return 0
    }
  }

  return installments[0].amount
}

export const calculateTotalDiscount = (discounts: Discount[]): cents => {
  return discounts.reduce((total, curr) => total + curr.amount, 0)
}

export const validateEditedAmount = (editedAmount: cents, installments: Installment[]): boolean => {
  return installments.every(
    i => editedAmount - calculateTotalDiscount(i.discounts) >= MINIMUM_AMOUNT_CENTS
  )
}

export const canAddDiscount = (installments: Installment[]): boolean => {
  const maxNumberOfDiscounts = Math.max(...installments.map(i => i.discounts.length))

  return maxNumberOfDiscounts < MAXIMUM_NUMBER_OF_DISCOUNTS
}

export const canEditAmount = (installments: Installment[]): boolean => {
  const numberOfDiscounts = Math.max(...installments.map(i => i.discounts.length))

  return numberOfDiscounts === 0
}

export const validateDiscountAmount = (
  newDiscountAmount: cents,
  installments: Installment[]
): boolean => {
  return installments.every(
    i => i.amount - calculateTotalDiscount(i.discounts) - newDiscountAmount >= MINIMUM_AMOUNT_CENTS
  )
}

export const applyPercentageDiscount = (percentage: number, installment: Installment) => {
  return roundCents((installment.amount * percentage) / 100)
}

export const validateDiscountPercentage = (
  newDiscountPercentage: number,
  installments: Installment[]
): boolean => {
  return installments.every(
    i =>
      i.amount -
        calculateTotalDiscount(i.discounts) -
        applyPercentageDiscount(newDiscountPercentage, i) >=
      MINIMUM_AMOUNT_CENTS
  )
}

export const validateDatesInUniqueMonths = (dueDates: datestring[]): boolean => {
  const visitedMonths = new Set()

  for (const dueDate of dueDates) {
    const hash = dayjs(dueDate).format('MMYYYY')

    if (visitedMonths.has(hash)) {
      return false
    }

    visitedMonths.add(hash)
  }

  return true
}

export const calculateHasUnsavedChanges = (
  savedInstallments: Installment[],
  currentInstallments: Installment[]
): boolean => {
  return !equals(savedInstallments, currentInstallments)
}
