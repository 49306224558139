import { Box } from '@material-ui/core'
import {
  Pagination,
  Radio,
  Table as TableDS,
  TableColumns,
  TableControlBar,
} from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { Dispatch, SetStateAction } from 'react'

import { formatCentsToReal, prettifyFullName } from '@/shared/utils'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import { useNavigation, useQuery } from '@/escolas/hooks'

import { TableDataProps } from '../../PendingCharges'
import { ActionMenu } from '../ActionMenu'

import { StyledTab, StyledTabs } from './styles'
import { Paragraph } from '../../styles'

type TableProps = {
  currentPage: number
  isLoading?: boolean
  itensPerPage: number
  onRequestOpenModalConfirmation: () => void
  rows: any
  selectedRows: TableDataProps[]
  setSelectedRows: Dispatch<SetStateAction<TableDataProps[]>>
  totalRows: number
}

export function Table({
  isLoading = false,
  rows,
  setSelectedRows,
  selectedRows,
  currentPage,
  itensPerPage,
  totalRows = 0,
  onRequestOpenModalConfirmation = () => null,
}: TableProps) {
  const { setOnQueryParam } = useQuery()
  const { schoolSlug, navigateTo } = useNavigation()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const tabs = [
    {
      label: `Nenhuma parcela paga (${rows.length})`,
    },
  ]

  const handleChangeSelected = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (event.target.checked) {
      setSelectedRows([rows.find(row => row.student_name === value)])
    }
  }

  const tableColumns: TableColumns = [
    {
      headerName: 'Aluno',
      enableSorting: true,
      field: 'student_name',
      renderCell: (value: string) => {
        return (
          <Box display="flex" alignItems="center">
            <Radio
              checked={selectedRows.some(row => row.student_name === value)}
              onChange={event => handleChangeSelected(event, value)}
            />

            <Box marginRight="1rem">{prettifyFullName(value)}</Box>
          </Box>
        )
      },
    },
    {
      headerName: 'Responsável',
      field: 'guardian_name',
      enableSorting: true,
      renderCell: (value: string) => {
        return <Box component="span">{prettifyFullName(value)}</Box>
      },
    },
    {
      headerName: 'Produtos',
      field: 'total_products',
      enableSorting: true,
    },
    {
      headerName: 'Parcelas',
      field: 'total_installments',
      enableSorting: true,
    },
    {
      headerName: 'Valor total',
      field: 'total_amount',
      enableSorting: true,
      valueFormatter: (value: string) => (value ? formatCentsToReal(value) : ''),
    },
  ]

  if (rows.length === 0) {
    return (
      <Box marginTop="2rem">
        <Paragraph>
          Parabéns! Você não possui nenhuma cobrança para ser revisada no momento.
        </Paragraph>
      </Box>
    )
  }

  const handleRowClick = (params, e) => {
    if (e.clientX < 210) return

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NEVER_PAID,
        name: EventDispatcherEvents.NEVER_PAID_DETAILS_ACCESS,
        action: 'click',
        customProperties: {
          $origin: 'Tela inicial - Linha da tabela exibida na listagem de NPs',
        },
      })
    }

    navigateTo({ slug: schoolSlug, path: `/validacao-de-inadimplencia/${params.row.id}` })
  }

  const handleReview = () => {
    navigateTo({
      slug: schoolSlug,
      path: `/validacao-de-inadimplencia/${selectedRows[0].id}`,
    })
  }

  return (
    <Box marginTop="2rem">
      <StyledTabs variant="scrollable" value={0} aria-label="Escolha a visualização">
        {tabs.map(({ label }) => (
          <StyledTab key={label} label={label} />
        ))}
      </StyledTabs>

      <Box paddingBottom="6rem">
        <TableDS<TableDataProps>
          rows={rows}
          columns={tableColumns}
          isLoading={isLoading}
          onRowClick={handleRowClick}
        />
      </Box>

      <Box position="fixed" bottom="0" left="6rem" right="0">
        <TableControlBar>
          <Pagination
            currentPage={currentPage}
            itensPerPage={itensPerPage}
            totalItens={totalRows}
            onPageChange={value => {
              setOnQueryParam(`${value}`, 'page')
            }}
            onItensPerChangeChange={value => {
              setOnQueryParam(`${value}`, 'per_page')
            }}
          />

          <ActionMenu
            disabled={selectedRows.length === 0}
            onConfirm={onRequestOpenModalConfirmation}
            onReview={handleReview}
          />
        </TableControlBar>
      </Box>
    </Box>
  )
}
