import styled from 'styled-components'
import { colors, fontSize, fontWeight, spacing } from '@gravity/tokens'
import { Heading } from '@gravity/heading'
import { Text } from '@gravity/text'
import { Link } from '@material-ui/core'

export const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 1fr;
  align-items: flex-start;
  grid-template-rows: fit-content(100%);
  grid-column-gap: ${spacing[8]};
  padding: 0;
`

export const GuardianNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing[6]};
  width: 100%;
  min-width: 0;
  flex-wrap: wrap;
`

export const GuardianNameContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContainerCardInfo = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1.5fr 2fr;
  justify-items: flex-start;
  padding-top: 17px;
  gap: ${spacing[8]};
  flex-wrap: wrap;
`

export const BoxCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const InfoText = styled(Text)`
  color: ${colors['colors-text-main-3']};
`

export const TitleText = styled(Heading)`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize['2xl']};
  font-weight: ${fontWeight.medium};
`

export const WrapText = styled(Text)`
  word-break: break-word;
  width: -webkit-fill-available;
`

export const LinkText = styled(Link)`
  color: ${colors['colors-background-accent-1']};
  font-size: ${fontSize.sm};
  font-weight: ${fontWeight.normal};
`
