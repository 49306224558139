import { useMutation } from '@tanstack/react-query'

import { useApi } from '@/shared/hooks'
import { CreateCampaignRequest } from '@/escolas/services/enrollment/types'

type UseCreateCampaignMutationParams = CreateCampaignRequest & {
  schoolId: uuid
}
export const useCreateCampaignMutation = () => {
  const { api } = useApi()

  return useMutation(({ schoolId, ...params }: UseCreateCampaignMutationParams) => {
    return api.enrollment.createCampaign(schoolId, params)
  })
}
