import theme from '@/shared/theme'
import { Tab, Tabs } from '@material-ui/core'
import styled from 'styled-components'

const { spacing } = theme.primitiveTokens

export const Header = styled.div`
  margin: ${spacing[8]} 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: ${spacing[6]};
`

export const CardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: ${spacing[8]};
  min-width: 100%;
`

export const StyledTabs = styled(Tabs)`
  width: 100%;
  border-bottom: 1px solid #dadada;
`

export const StyledTab = styled(Tab)`
  color: #999999;
  font-size: 0.875rem;
  text-transform: none;
  width: 230px;
  height: 68px;
  font-weight: 400;
  border-bottom: 2px solid transparent;
  transition: border 0.25s ease-in-out;

  &.Mui-selected {
    color: #414141;
  }

  &:hover {
    border-bottom: 2px solid ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
  }
`
