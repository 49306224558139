import { createContext, useContext, useEffect, useState } from 'react'

import { InstallmentDrawerInfo } from '@/modules/guardians/InstallmentsDrawerContainer/NegotiationDrawer/types'
import { InstallmentDrawerTypes } from '@/modules/guardians/InstallmentsDrawerContainer/hooks/useDrawerType'
import { Installment } from '@/modules/guardians/GuardianDetails/types'

type InstallmentDrawerContextProps = { children: React.ReactNode }

interface InstallmentDrawerContextData {
  installmentDrawerInfo: InstallmentDrawerInfo
  selectedDrawer: InstallmentDrawerTypes
  selectedInstallment?: Installment
  setInstallmentDrawerInfo: (installmentDrawer: InstallmentDrawerInfo) => void
  setSelectedDrawer: (drawer: InstallmentDrawerTypes) => void
  setSelectedInstallment: (installment: Installment) => void
  setShowChangePaymentModal: (show: boolean) => void
  setShowFailedDialog: (show: boolean) => void
  showChangePaymentModal: boolean
  showFailedDialog: boolean
}

const useCreateContext = (): InstallmentDrawerContextData => {
  const [installmentDrawerInfo, setInstallmentDrawerInfo] = useState<InstallmentDrawerInfo>(null)
  const [selectedInstallment, setSelectedInstallment] = useState<Installment>(null)

  const [selectedDrawer, setSelectedDrawer] = useState<InstallmentDrawerTypes>(null)

  const [showFailedDialog, setShowFailedDialog] = useState<boolean>(false)
  const [showChangePaymentModal, setShowChangePaymentModal] = useState(false)

  useEffect(() => {
    setShowFailedDialog(false)
    setShowChangePaymentModal(false)
  }, [installmentDrawerInfo?.urn])

  return {
    installmentDrawerInfo: (installmentDrawerInfo || {}) as InstallmentDrawerInfo,
    selectedDrawer,
    selectedInstallment,
    setInstallmentDrawerInfo,
    setSelectedDrawer,
    setSelectedInstallment,
    setShowChangePaymentModal,
    setShowFailedDialog,
    showChangePaymentModal,
    showFailedDialog,
  }
}

export const InstallmentDrawerStore = createContext<InstallmentDrawerContextData>(
  {} as InstallmentDrawerContextData
)
InstallmentDrawerStore.displayName = 'InstallmentDrawerStore'

export const InstallmentDrawerProvider = ({ children }: InstallmentDrawerContextProps) => {
  const context = useCreateContext()

  return (
    <InstallmentDrawerStore.Provider value={context}>{children}</InstallmentDrawerStore.Provider>
  )
}

export const useInstallmentDrawerContext = (): InstallmentDrawerContextData => {
  const context = useContext(InstallmentDrawerStore)

  if (!context) {
    throw new Error('useInstallmentDrawerContext must be used within a InstallmentDrawerProvider')
  }

  return context
}
