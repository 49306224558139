import { ContractResponse, ContractStatus, Discount, Product } from 'src/shared/interfaces'
import { ContractInfoType } from './types'

export const sumDiscounts = (discounts: Discount[]) =>
  discounts?.reduce((acum, curr) => acum + curr.amount, 0) ?? 0

export const calculateAmountInfo = (
  monthlyAmount: number,
  durationMonths: number,
  discounts: Discount[]
) => {
  const totalGrossAmount = monthlyAmount * durationMonths || 0
  const discountsMonthlyAmount = sumDiscounts(discounts)
  const totalDiscountsAmount = discountsMonthlyAmount * durationMonths
  const netMonthlyAmount = monthlyAmount - discountsMonthlyAmount
  const totalNetAmount = totalGrossAmount - totalDiscountsAmount

  return [totalGrossAmount, totalDiscountsAmount, netMonthlyAmount, totalNetAmount]
}

export const calculateProductTotalTuitionAmount = (product: Product): cents =>
  product.monthly_amount * product.installments_quantity

export const getContractInfo = (
  contract: Pick<ContractResponse, 'next_year_contract' | 'is_reenrollable' | 'reference_year'>
): ContractInfoType => {
  const referenceYear = parseInt(contract.reference_year)

  const conditionInfos: Array<[condition: boolean, info: ContractInfoType]> = [
    [
      contract?.next_year_contract && contract.next_year_contract.status === ContractStatus.OPEN,
      {
        ok: false,
        chipText: `Já possui contrato ${referenceYear + 1}`,
        tooltipText: `Este aluno já possui um contrato em andamento em ${referenceYear + 1}`,
      },
    ],
    [
      !contract.is_reenrollable,
      {
        ok: false,
        chipText: `Com pendências em ${referenceYear}`,
        tooltipText: `Para rematricular esse aluno é preciso renegociar faturas pendentes em ${referenceYear}`,
      },
    ],
  ]

  for (const [condition, info] of conditionInfos) {
    if (condition) return info
  }

  return {
    ok: true,
    chipText: '',
    tooltipText: '',
  }
}
