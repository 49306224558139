import {
  ActionDrawerHeader,
  ButtonDocker,
  DialogContent,
  Typography,
} from '@olaisaac/design-system'
import { Box, CircularProgress } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { LoadingNegotiationDrawerProps } from './types'
import { ButtonDockerContainer } from '../components/ButtonDockerContainer/ButtonDockerContainer'

export const LoadingNegotiationDrawer = ({ onClose }: LoadingNegotiationDrawerProps) => {
  return (
    <>
      <ActionDrawerHeader
        title="Carregando"
        onClose={onClose}
        extraContent={
          <>
            <Typography
              variation="subtitleDesktopMedium"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <CircularProgress style={{ height: '1rem', width: '1rem', marginRight: '12px' }} />
              Por favor, aguarde
            </Typography>
          </>
        }
      />
      <DialogContent>
        <Box pt={3}>
          <Skeleton height="38px" />
        </Box>
        {[...Array(4)].map((_, idx) => (
          <Box pt={1} pb={1} display="flex" justifyContent="space-between" key={idx}>
            <Skeleton height="38px" width="48%" />
            <Skeleton height="38px" width="28%" />
          </Box>
        ))}
      </DialogContent>
      <ButtonDocker>
        <ButtonDockerContainer>
          <Box pt={1} pb={1} display="flex" justifyContent="space-between">
            <Skeleton height="38px" width="48%" />
            <Skeleton height="38px" width="28%" />
          </Box>
          <Skeleton height="48px" width="100%" />
          <Skeleton height="48px" width="100%" />
        </ButtonDockerContainer>
      </ButtonDocker>
    </>
  )
}
