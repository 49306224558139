import { Grid } from '@olaisaac/design-system'
import styled from 'styled-components'

export const ContainerWithActionDrawer = styled(Grid)<{ $isActionDrawerOpen?: boolean }>(
  ({ $isActionDrawerOpen }) => ({
    transition:
      'flex-basis 0.3s cubic-bezier(0.42,0.29,0.39,0.83), max-width 0.3s cubic-bezier(0.42,0.29,0.39,0.83)',
    ...($isActionDrawerOpen
      ? { flexGrow: 0, maxWidth: '66.666667%', flexBasis: '66.666667%' }
      : {}),
  })
)

export const PageContainer = styled.div`
  padding-bottom: 84px;
`

export const ControlBarContainer = styled(Grid).attrs({
  container: true,
})<{ $isActionDrawerOpen?: boolean }>`
  padding-left: 32px;
  padding-right: ${props => (props.$isActionDrawerOpen ? '0' : '32px')};
`
