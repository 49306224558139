import StatusBadge, { StatusBadgeColor } from '@/escolas/components/StatusBadge'
import { useNavigation } from '@/escolas/hooks'
import { Enrollment, Installment, Status } from '@/escolas/services/enrollment/types'
import { InstallmentType } from '@/shared/interfaces'
import { formatCentsToReal, formatDate } from '@/shared/utils'
import { CircularProgress, Grid, Tooltip } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCellMUI from '@material-ui/core/TableCell'
import TableHeadMUI from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import OpenInNewRounded from '@material-ui/icons/OpenInNewRounded'
import { Link, TooltipButton, Typography } from '@olaisaac/design-system'
import { ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { calculateOrderReferences, calculateTotalDiscount } from '../utils'
import { DiscountChips } from './DiscountChips'
import { InstallmentTypeChip } from './InstallmentTypeChip'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

const Container = styled.div`
  padding: 16px 16px 0;
  border: 1px solid;
  border-bottom: 0;
  border-color: ${props => props.theme.primitiveTokens.colors.gray[10]};
  border-radius: 8px 8px 0 0;
  margin-top: 24px;
`

const WarningContainer = styled(Grid)`
  padding: 8px;
  border-radius: 4px;
  margin: 16px 0;
  background-color: #f6f6f6;
`

const StyledTableContainer = styled.div`
  margin: 16px 0 0;
  padding: 8px 0;
  overflow-x: auto;
`

const TableHead = styled(TableHeadMUI)`
  tr th {
    position: sticky;
    top: 0;
    background-color: ${props => props.theme.primitiveTokens.colors.gray[0]};
    box-shadow: inset 0 -1px ${props => props.theme.primitiveTokens.colors.gray[5]};
    z-index: 2;
  }
`

const TableTitle = styled(Typography)`
  margin-bottom: 16px;
`

const OrderReferenceWrapper = styled.div`
  display: flex;
  gap: 8px;
  span {
    min-width: 8ch;
  }
`

type TableRowProps = {
  disabled: boolean
}

const BodyTableRow = styled(TableRow)<TableRowProps>`
  background-color: ${props => props.disabled && props.theme.primitiveTokens.colors.gray[2]};
  :hover {
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`

const TableCell = styled(TableCellMUI)`
  height: 64px;
`

const StatusContainer = styled.div`
  width: max-content;
  padding: 16px 0;
  display: flex;
  gap: 4px;
  align-items: center;
`

export type Row = Installment & {
  disabled: boolean
  month_reference: datestring
  net_amount: number
  order_reference: string
  selected: boolean
}

type TableColumn = {
  content: (row: Row) => ReactNode
  field: string
  headerName: string
}

const mapDataToRows = (rawData: Installment[], selectedIds: uuid[]): Row[] => {
  const orderReferences = calculateOrderReferences(rawData)
  return rawData.map((item, i) => ({
    ...item,
    month_reference: item.due_date,
    order_reference: orderReferences[i],
    net_amount: item.amount - calculateTotalDiscount(item.discounts),
    disabled: item.status !== Status.PENDING,
    selected: selectedIds.includes(item.id),
  }))
}

export const paramsDict: PartialRecord<
  Status,
  { color: StatusBadgeColor; detail: string; text: string }
> = {
  CANCELED: { color: 'grey', text: 'Cancelada', detail: null },
  OPEN: { color: 'primary', text: 'A vencer', detail: null },
  PENDING: {
    color: 'darkGrey',
    text: 'Aguardando',
    detail:
      'A parcela será gerada após o pagamento da pré-matrícula e/ou quitação dos contratos de ',
  },
}

export const renderStatus = (status: Status, referenceYear: any) => (
  <StatusContainer>
    <StatusBadge color={paramsDict[status]?.color} />
    <Typography variation="bodySmall">{paramsDict[status]?.text}</Typography>
    {paramsDict[status]?.detail != null && (
      <Tooltip title={paramsDict[status].detail + (referenceYear - 1)}>
        <TooltipButton>
          <InfoOutlinedIcon color="action" fontSize="small" />
        </TooltipButton>
      </Tooltip>
    )}
  </StatusContainer>
)

type InstallmentsTableProps = {
  data: Installment[]
  enrollment: Enrollment
  isLoading: boolean
  onDeleteDiscount: (installmentId: uuid, discountIndex: number) => void
  selectedInstallmentIds: uuid[]
  setSelectedInstallmentIds: (ids: uuid[]) => void
}

/**
 * @deprecated This component will be deprecated after the EEMAT-727-ENABLE_UNIFIED_CONTRACT_EDITING rollout
 */
export const InstallmentsTable = ({
  data,
  enrollment,
  isLoading,
  onDeleteDiscount,
  selectedInstallmentIds,
  setSelectedInstallmentIds,
}: InstallmentsTableProps) => {
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { getNavigationUrl } = useNavigation()
  const hasOpenPreEnrollment = data.some((installment: Installment) => {
    return installment.type === InstallmentType.ENROLLMENT && installment.status === Status.OPEN
  })
  const tableRef = useRef<HTMLTableElement>()

  useEffect(() => {
    const isTableTopBellowScreenTop = tableRef.current?.getBoundingClientRect().top > 0

    if (selectedInstallmentIds.length > 0 && isTableTopBellowScreenTop) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selectedInstallmentIds])

  const columns: TableColumn[] = [
    {
      headerName: 'Competência',
      field: 'month_reference',
      content: row => formatDate(row.month_reference, 'MMM. YYYY'),
    },
    {
      headerName: 'Parcela',
      field: 'order_reference',
      content: row => (
        <OrderReferenceWrapper>
          <span>{row.order_reference}</span>
          <InstallmentTypeChip type={row.type} />
        </OrderReferenceWrapper>
      ),
    },
    {
      headerName: 'Vencimento',
      field: 'due_date',
      content: row => formatDate(row.due_date, 'DD/MM/YYYY'),
    },
    { headerName: 'Valor', field: 'amount', content: row => formatCentsToReal(row.amount) },
    {
      headerName: 'Desconto',
      field: 'discounts',
      content: row => <DiscountChips installment={row} onDelete={onDeleteDiscount} />,
    },
    {
      headerName: 'Valor com o desconto',
      field: 'net_amount',
      content: row => formatCentsToReal(row.net_amount),
    },
    {
      headerName: 'Situação',
      field: 'status',
      content: row => renderStatus(row.status, enrollment.reference_year),
    },
  ]
  const rows = mapDataToRows(data, selectedInstallmentIds)

  const handleSelectRow = (row: Row) => {
    if (row.disabled) {
      return
    }

    if (row.selected) {
      setSelectedInstallmentIds(selectedInstallmentIds.filter(i => i !== row.id))
    } else {
      setSelectedInstallmentIds([...selectedInstallmentIds, row.id])
    }

    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_EDIT,
        name: EventDispatcherEvents.TABLE_ROW_CLICK,
        action: 'click',
        customProperties: {
          $checkbox_name: 'parcela',
          $type_of_installment: row.type,
          $installment_number: row.order_reference.split('de')[0].trim(),
        },
      })
  }

  const sendEventEditEnrollmentInstallments = () => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.ENROLLMENT_EDIT,
        name: EventDispatcherEvents.LINK_CLICK,
        action: 'click',
        customProperties: {
          $link_name: 'Editar parcelas de pré-matrícula',
        },
      })
  }

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedInstallmentIds(rows.filter(i => !i.disabled).map(i => i.id))
      return
    }
    setSelectedInstallmentIds([])
  }

  const numSelected = selectedInstallmentIds.length
  const rowCount = rows.filter(i => !i.disabled).length

  const rowDisabledHelperText =
    'Não é possível editar parcelas de pré-matricula em situação a vencer ou vencida a partir desta página de edições.'

  return (
    <Container>
      <TableTitle variation="headlineDesktopXsmall">Configurar parcelas</TableTitle>
      {hasOpenPreEnrollment && (
        <WarningContainer container alignItems="center">
          <Grid item sm={8}>
            <InfoOutlinedIcon
              color="action"
              fontSize="small"
              style={{ verticalAlign: 'bottom', marginRight: '4px' }}
            />
            {rowDisabledHelperText}
          </Grid>
          <Grid item sm={4} style={{ textAlign: 'right' }}>
            <Link
              href={getNavigationUrl({
                path: `/responsaveis/${enrollment?.guardian_id}/parcelas?student_ids=${enrollment?.student_id}&product_ids=${enrollment?.product_id}`,
              })}
              rel="noreferrer"
              target="_blank"
              onClick={() => sendEventEditEnrollmentInstallments()}
            >
              Editar parcelas de pré-matrícula
              <OpenInNewRounded
                fontSize="small"
                color="primary"
                style={{ verticalAlign: 'bottom', marginLeft: '4px' }}
              />
            </Link>
          </Grid>
        </WarningContainer>
      )}
      <Typography variation="bodySmall" color="secondary">
        Selecione as parcelas para aplicar descontos, alterar valores ou data de vencimento.
      </Typography>
      <StyledTableContainer>
        <Table style={{ isolation: 'isolate' }} ref={tableRef}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={(_, checked) => handleSelectAll(checked)}
                />
              </TableCell>
              {columns.map(column => (
                <TableCell key={column.field}>{column.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={columns.length} height={64} style={{ textAlign: 'center' }}>
                  <CircularProgress size={32} />
                </TableCell>
              </TableRow>
            )}

            {!isLoading &&
              rows.map(row => (
                <BodyTableRow
                  key={row.id}
                  hover
                  onClick={() => handleSelectRow(row)}
                  selected={row.selected}
                  disabled={row.disabled}
                  title={row.disabled ? rowDisabledHelperText : ''}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={row.selected} disabled={row.disabled} />
                  </TableCell>
                  {columns.map(column => (
                    <TableCell key={column.field}>{column.content(row)}</TableCell>
                  ))}
                </BodyTableRow>
              ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Container>
  )
}
