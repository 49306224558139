import { AxiosInstance } from 'axios'
import {
  CreateCampaignRequest,
  EnrollmentCycleRequest,
  EnrollmentCycleResponse,
  GetEnrollmentCycleSituationResponse,
  GetEnrollmentRequest,
  GetEnrollmentResponse,
  GetGuardiansRequest,
  GetGuardiansResponse,
  Installment,
  StatusSummaryRequest,
  StatusSummaryResponse,
} from './types'

const getListStudentEnrollmentsQueryParams = (params: EnrollmentCycleRequest) => ({
  params: {
    school_id: params.schoolId,
    name: params.name,
    reference_years: params.referenceYears,
    ...params.pagination,
    ...(params.studentId && { student_id: params.studentId }),
    ...(params.studentIds && { students_ids: params.studentIds }),
    status: params.enrollmentStatus,
    enrollment_sub_status: params.enrollmentSubStatus,
  },
})

export const enrollmentService = (fetcher: AxiosInstance) => {
  return {
    fetchEnrollmentCycle: async (
      params: EnrollmentCycleRequest
    ): Promise<EnrollmentCycleResponse> => {
      return (
        await fetcher.get<EnrollmentCycleResponse>(
          `/v1/student/enrollment`,
          getListStudentEnrollmentsQueryParams(params)
        )
      ).data
    },
    fetchStatusSummary: async (params: StatusSummaryRequest): Promise<StatusSummaryResponse> => {
      return (
        await fetcher.get<StatusSummaryResponse>(`/v1/student/enrollment/status-summary`, {
          params: {
            school_id: params.schoolId,
            reference_year: params.referenceYear,
          },
        })
      ).data
    },
    updateEnrollmentSituation: async (
      schoolID: string,
      studentID: string,
      enrollmentCycle: number
    ): Promise<GetEnrollmentCycleSituationResponse> => {
      return (
        await fetcher.get<GetEnrollmentCycleSituationResponse>(
          `/v1/student/situation/${schoolID}/${studentID}/${enrollmentCycle}`
        )
      ).data
    },
    fetchStudentGuardians: async (params: GetGuardiansRequest): Promise<GetGuardiansResponse> => {
      return (
        await fetcher.get<GetGuardiansResponse>(`/v2/enrollment/guardian`, {
          params: {
            school_id: params.schoolId,
            student_id: params.studentId,
            tax_id: params.taxID,
          },
        })
      ).data
    },
    /**
     * @deprecated This function will be deprecated after the EEMAT-727-ENABLE_UNIFIED_CONTRACT_EDITING rollout
     */
    getEnrollment: async (params: GetEnrollmentRequest) => {
      return (
        await fetcher.get<GetEnrollmentResponse>(
          `/v1/enrollment/${params.schoolId}/${params.contractId}`
        )
      ).data
    },
    putEnrollment: async (schoolId: uuid, enrollmentId: uuid, installments: Installment[]) => {
      return await fetcher.put(`v1/installment/${schoolId}/${enrollmentId}/edit`, { installments })
    },
    createCampaign: async (schoolId: uuid, params: CreateCampaignRequest) => {
      return await fetcher.post(`v1/campaign/${schoolId}`, params)
    },
  }
}
