import CreditSummaryCard from '../../components/CreditSummaryCard'
import { ButtonGroup, GhostButton } from './styles'
import { Button } from '@gravity/button'
import {
  useContextChosenOffer,
  useContextCreditOrderId,
  useContextCreditSimulation,
  useContextRequestReason,
  useContextStepperMap,
} from '../../contexts/CreditDataContext'
import { Redirect, useHistory } from 'react-router-dom'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useEffect } from 'react'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useChooseOffer } from '../../hooks/queries/useChooseOffer'
import DefaultTemplate from '../../templates/DefaultTemplate'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import PageExitConfirm from '../../components/PageExitConfirm'

export const CreditSummary = () => {
  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  const { push } = useHistory()
  const { schoolSlug } = useSelectedSchool()

  const { creditSimulation } = useContextCreditSimulation()
  const { chosenOffer, setChosenOffer } = useContextChosenOffer()
  const { setCreditOrderId } = useContextCreditOrderId()
  const { setStepperMap } = useContextStepperMap()
  const { requestReason } = useContextRequestReason()
  const { mutateAsync } = useChooseOffer()

  useEffect(() => {
    sendCreditEventToMixpanel(EventDispatcherEvents.PAGE_VIEWED, MixpanelEventActions.PAGE_VIEW, {
      $page_name: CreditEventComponentNames.SIMULATION_RESULT,
    })
  }, [])

  const handleRequestCredit = () => {
    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: 'Solicitar crédito',
      $selected: requestReason.reason,
      $note: requestReason.note,
      $request_value: chosenOffer.totalAmount,
      $installments: chosenOffer.totalInstallments,
    })

    mutateAsync({
      orderId: creditSimulation.orderId,
      chooseOfferId: chosenOffer.id,
    })

    setCreditOrderId(creditSimulation.orderId)
    setStepperMap({ step: 0, finished: true })

    push(`/${schoolSlug}/credito/estado`)
  }

  const handleSimulateNewOffer = () => {
    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: 'Simular nova oferta',
    })
    push(`/${schoolSlug}/credito/simulacao`)
  }

  // TODO: this is a quick solution to redirect user if there is no data in context
  // we should persist data in browser storage or use useNavigate to avoid this
  if (!creditSimulation) {
    return <Redirect to={`/${schoolSlug}/credito`} />
  }

  return (
    <DefaultTemplate
      title="Resumo da sua oferta"
      child={
        <>
          <CreditSummaryCard
            chosenOffer={chosenOffer}
            setChosenOffer={setChosenOffer}
            {...creditSimulation}
          />
          <ButtonGroup>
            <GhostButton variant="ghost" onClick={handleSimulateNewOffer}>
              Simular nova oferta
            </GhostButton>
            <Button fullWidth onClick={handleRequestCredit}>
              Solicitar crédito
            </Button>
          </ButtonGroup>

          <PageExitConfirm
            title="Nos ajude a construir a oferta de crédito ideal para você"
            labelReason="Quais pontos da oferta simulada não te atenderam?"
            confirmButtonLabel="Sair da oferta"
            backButtonLabel="Continuar na oferta"
          />
        </>
      }
      handleBackPreviousRoute={() => {
        push(`/${schoolSlug}/credito/simulacao`)
      }}
    />
  )
}
