import { Tooltip } from '@material-ui/core'
import { Button } from '@olaisaac/design-system'
import React from 'react'

export const EditActionButton = ({
  text,
  disabled,
  tooltip,
  icon,
  onClick,
}: {
  disabled: boolean
  icon: React.ReactElement
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  text: string
  tooltip: string
}) => (
  <Tooltip title={tooltip}>
    <span>
      <Button onClick={onClick} startIcon={icon} variation="ghost" disabled={disabled}>
        {text}
      </Button>
    </span>
  </Tooltip>
)
