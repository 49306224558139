import { propEq } from 'ramda'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { getConditionalValueRender } from 'src/escolas/components/contract/create/conditions'
import useDueDayCalculator from 'src/escolas/components/contract/create/hooks/useDueDayCalculator'
import PaymentPlanForm from 'src/escolas/components/contract/create/PaymentPlanForm'
import { EnrollmentFormFields, FormProps } from 'src/escolas/components/contract/create/types'
import { getDefaultEnrollmentFormFields } from 'src/escolas/components/contract/create/utils'
import { AddDiscountsFormType } from 'src/escolas/components/DiscountForm/DiscountForm'
import { Enrollment, Product } from 'src/shared/interfaces'
import { CampaignContext } from '../../context'
import { ActionType, CampaignPaymentPlanType } from '../../types'
import PaymentPlanSummary from './PaymentPlanSummary'

const getEnrollmentData = (paymentPlanFormValues, enrollmentDiscountsFormValues): Enrollment => {
  const {
    enrollment_due_day,
    enrollment_start_month,
    use_working_dates_enrollment,
    enrollment_duration_months,
    send_first_enrollment_installment_message,
  } = paymentPlanFormValues

  return {
    enrollment_discounts: enrollmentDiscountsFormValues.discounts.map(discount => ({
      amount: discount.amount,
      description: discount.description,
      days_before_due_date: +discount.days_before_due_date,
    })),
    enrollment_due_day: +enrollment_due_day || enrollment_start_month?.date(),
    use_working_dates_enrollment: use_working_dates_enrollment === 'true',
    enrollment_duration_months: +enrollment_duration_months,
    enrollment_start_month: enrollment_start_month?.toISOString(),
    send_first_enrollment_installment: send_first_enrollment_installment_message,
  }
}

const getPaymentPlan = (
  paymentPlanFormValues: FormProps,
  discountsFormValues: AddDiscountsFormType,
  isEnrollmentEnabled: boolean,
  enrollmentDiscountsFormValues: AddDiscountsFormType
): CampaignPaymentPlanType => {
  let { start_month, duration_months, due_day, use_working_dates_tuition } = paymentPlanFormValues

  if (!due_day) {
    due_day = start_month.date()
  }

  const enrollmentData = isEnrollmentEnabled
    ? getEnrollmentData(paymentPlanFormValues, enrollmentDiscountsFormValues)
    : {}

  return {
    discounts: discountsFormValues.discounts.map(discount => ({
      amount: discount?.amount,
      days_before_due_date: discount?.days_before_due_date,
      description: discount?.description,
      type: discount?.type,
    })),
    due_day: +due_day,
    use_working_dates_tuition: use_working_dates_tuition === 'true',
    duration_months: +duration_months,
    send_signable_document: paymentPlanFormValues?.product?.envelope_template_referral_id
      ? !paymentPlanFormValues.disable_send_signable_document
      : false,
    start_month: start_month.toISOString(),
    custom_monthly_amount: paymentPlanFormValues.custom_monthly_amount,
    custom_enrollment_monthly_amount: paymentPlanFormValues.custom_enrollment_monthly_amount,
    pre_contract_disclaimer_check: paymentPlanFormValues.pre_contract_disclaimer_check,
    ...enrollmentData,
  }
}

type PaymentPlanProps = {
  isGoingToSaveStep: boolean
  product: Product
  setIsGoingToSaveStep: (value: boolean) => void
  setIsPaymentPlanValid: (value: boolean) => void
}

const PaymentPlan = ({
  setIsPaymentPlanValid,
  setIsGoingToSaveStep,
  isGoingToSaveStep,
  product,
}: PaymentPlanProps) => {
  const { state, dispatch } = useContext(CampaignContext)
  const { nextYearProductId, products } = state
  const [isEnrollmentEnabled, setEnrollmentEnabled] = useState(true)
  const [enrollmentFormFields, setEnrollmentFormFields] = useState<EnrollmentFormFields>(
    getDefaultEnrollmentFormFields(0)
  )

  const paymentPlanForm = useForm<FormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    defaultValues: {
      disable_send_signable_document: false,
      due_day: null,
      use_working_dates_tuition: 'true',
      duration_months: '',
      enrollment_due_day: null,
      use_working_dates_enrollment: 'true',
      enrollment_duration_months: '',
      enrollment_start_month: null,
      guardian: null,
      product_id: '',
      start_month: null,
      student: null,
      custom_monthly_amount: null,
      custom_enrollment_monthly_amount: null,
      product: null,
      reference_year: state.referenceYear,
      send_first_enrollment_installment_message: true,
    },
  })

  const { watch } = paymentPlanForm

  watch(['disable_send_signable_document', 'send_first_enrollment_installment_message'])

  const discountsForm = useForm<AddDiscountsFormType>({
    mode: 'onChange',
  })

  const enrollmentDiscountsForm = useForm<AddDiscountsFormType>({
    mode: 'onChange',
  })

  const {
    enrollment_start_month,
    enrollment_due_day,
    use_working_dates_enrollment,
    start_month,
    use_working_dates_tuition,
    due_day,
  } = paymentPlanForm.getValues()

  const firstDueDay = useDueDayCalculator(
    start_month,
    due_day,
    use_working_dates_tuition === 'true'
  )

  const enrollmentFirstDueDay = useDueDayCalculator(
    enrollment_start_month,
    enrollment_due_day,
    use_working_dates_enrollment === 'true'
  )

  useEffect(() => {
    const product = products.find(propEq('id', nextYearProductId))

    paymentPlanForm.setValue('product', product)
    paymentPlanForm.setValue('product_id', nextYearProductId)
  }, [nextYearProductId])

  useEffect(() => {
    const subscription = watch(() => {
      setIsGoingToSaveStep(false)
      setIsPaymentPlanValid(false)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    const verifyForm = async () => {
      const formIsValid = await paymentPlanForm.trigger()
      const discountsFormIsValid = await discountsForm.trigger()
      const enrollmentDiscountsFormIsValid = await enrollmentDiscountsForm.trigger()
      const formsAreValid = formIsValid && discountsFormIsValid && enrollmentDiscountsFormIsValid

      const tuitionDiscounts = discountsForm.getValues().discounts
      const enrollmentDiscounts = enrollmentDiscountsForm.getValues()?.discounts ?? []

      const formValues = paymentPlanForm.getValues()

      const { canSaveContract } = getConditionalValueRender({
        customMonthlyAmount: formValues.custom_monthly_amount,
        customEnrollmentMonthlyAmount: formValues.custom_enrollment_monthly_amount,
        discounts: tuitionDiscounts,
        enrollmentDiscounts,
      })

      const isValid = formsAreValid && canSaveContract

      setIsPaymentPlanValid(isValid)

      if (isValid) {
        dispatch({
          type: ActionType.SET_PAYMENT_PLAN,
          payload: {
            paymentPlan: getPaymentPlan(
              paymentPlanForm.getValues(),
              discountsForm.getValues(),
              isEnrollmentEnabled,
              enrollmentDiscountsForm.getValues()
            ),
          },
        })
      }
    }

    if (isGoingToSaveStep) verifyForm()
  }, [isGoingToSaveStep])

  const formValues = paymentPlanForm.getValues()

  return (
    <div data-testid="campaign-payment-plan-step">
      <PaymentPlanForm
        form={paymentPlanForm}
        product={product}
        discountsForm={discountsForm}
        enrollmentForm={enrollmentDiscountsForm}
        enrollmentFormFields={enrollmentFormFields}
        setEnrollmentFormFields={setEnrollmentFormFields}
        isEnrollmentEnabled={isEnrollmentEnabled}
        setEnrollmentEnabled={setEnrollmentEnabled}
      />
      <PaymentPlanSummary
        tuitionDurationMonths={+formValues.duration_months}
        tuitionMonthlyAmount={formValues.custom_monthly_amount}
        tuitionDiscounts={discountsForm.getValues().discounts}
        enrollmentDurationMonths={+formValues.enrollment_duration_months}
        enrollmentMonthlyAmount={formValues.custom_enrollment_monthly_amount}
        enrollmentDiscounts={enrollmentDiscountsForm.getValues().discounts}
        isEnrollmentEnabled={isEnrollmentEnabled}
        enrollmentFirstDueDay={enrollmentFirstDueDay}
        firstDueDay={firstDueDay}
        sendFirstEnrollmentInstallmentMessage={formValues.send_first_enrollment_installment_message}
        disableSendSignableDocument={formValues.disable_send_signable_document}
        tuitionDueDay={formValues.due_day}
        enrollmentDueDay={formValues.enrollment_due_day}
      />
    </div>
  )
}

export default PaymentPlan
