import { FC, useEffect } from 'react'
import { Route, useLocation } from 'react-router-dom'
import { PageRouteProps } from '@/escolas/router'
import { useBreadcrumbs } from '@/escolas/hooks'
import { Breadcrumb } from '@/escolas/contexts/BreadcrumbsContext'

const BuildBreadcrumbsHOC = function <T extends PageRouteProps = PageRouteProps>({
  buildBreadcrumbs,
  children,
  routeProps,
}: {
  buildBreadcrumbs: (props: T, path: string) => Breadcrumb[]
  children: React.ReactElement
  routeProps: T
}) {
  const { pathname } = useLocation()
  const { updateBreadcrumbs } = useBreadcrumbs()

  useEffect(() => {
    const breadcrumbs = buildBreadcrumbs?.(routeProps, pathname)

    updateBreadcrumbs(breadcrumbs ?? [])
  }, [pathname])
  return children
}

/**
 * @deprecated
 */
export const buildRoute = function <T extends PageRouteProps = PageRouteProps>(
  Page: FC,
  getPathname: (props: T) => string,
  buildBreadcrumbs?: (props: T, path: string) => Breadcrumb[]
): FC<T> {
  return (props: T) => (
    <Route exact path={getPathname(props)}>
      <BuildBreadcrumbsHOC<T> buildBreadcrumbs={buildBreadcrumbs} routeProps={props}>
        <Page />
      </BuildBreadcrumbsHOC>
    </Route>
  )
}
