import { ChangeEvent, useState } from 'react'
import { Box, Collapse } from '@material-ui/core'
import { Typography, SystemButton, Link, FormControlLabel, Checkbox } from '@olaisaac/design-system'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import { useFormContext, useController } from 'react-hook-form'

import { CollapsibleFilterGroupProps } from './types'
import * as Styled from './styles'

/**
 * Collapsible filter group for contract groups
 * @param param0
 * @returns
 */
export const CollapsibleFilterGroup = ({
  label,
  filterName,
  options,
  searchText,
  totalOptions,
  externalPagination = 1,
  externalPaginationControl,
  onClearFilter,
  pageSize = 4,
  singleSelection = false,
}: CollapsibleFilterGroupProps) => {
  const { field } = useController({ name: filterName })
  const { getValues } = useFormContext()

  const [isOpen, setIsOpen] = useState(true)
  const [page, setPage] = useState(externalPagination)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const handleLoadMoreItems = async () => {
    setIsLoadingMore(true)

    const updatedPage = page + 1

    if (externalPaginationControl) {
      await externalPaginationControl(updatedPage, searchText)
    }

    setPage(updatedPage)
    setIsLoadingMore(false)
  }

  const fieldValue = singleSelection ? getValues(filterName) : (getValues(filterName) as string[])

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    if (singleSelection) {
      if (event.target.checked) {
        return field.onChange(value)
      }

      return field.onChange(undefined)
    }

    if (event.target.checked) {
      return field.onChange([...fieldValue, value])
    }

    field.onChange(fieldValue.filter(option => option !== value))
  }

  const slicedOptions = options?.slice(0, pageSize * page)

  const selectionInfo =
    searchText || singleSelection
      ? `(${totalOptions})`
      : `(${fieldValue.length} de ${totalOptions})`

  return (
    <>
      <Box mb="1.375rem" display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex">
          <Styled.SectionLabel>
            {label}
            {` `}
            {selectionInfo}
          </Styled.SectionLabel>
        </Box>

        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Box mr="0.5rem">
            <Link onClick={() => onClearFilter(filterName)} data-testid="clear-selection-button">
              Limpar
            </Link>
          </Box>

          <SystemButton
            onClick={() => setIsOpen(prevState => !prevState)}
            data-testid="expand-button"
          >
            {isOpen ? (
              <ExpandLess fontSize="small" data-testid="expand-less-icon" />
            ) : (
              <ExpandMore fontSize="small" data-testid="expand-more-icon" />
            )}
          </SystemButton>
        </Box>
      </Box>

      <Collapse in={isOpen}>
        <Box display="flex" flexDirection="column">
          {slicedOptions?.map((item, index) => {
            const isChecked = singleSelection
              ? item?.value === fieldValue
              : fieldValue.includes(item?.value)

            if (index === 0) {
              return (
                <FormControlLabel
                  key={item?.value}
                  label={item?.label}
                  control={
                    <Checkbox
                      {...field}
                      checked={isChecked}
                      onChange={event => {
                        handleOnChange(event, item?.value)
                      }}
                      data-testid="option-checkbox"
                    />
                  }
                />
              )
            }

            return (
              <Box key={item?.value as string} mt="0.75rem">
                <FormControlLabel
                  key={item?.value}
                  label={item?.label}
                  control={
                    <Checkbox
                      {...field}
                      checked={isChecked}
                      onChange={event => {
                        handleOnChange(event, item?.value)
                      }}
                      data-testid="option-checkbox"
                    />
                  }
                />
              </Box>
            )
          })}
        </Box>

        {totalOptions > slicedOptions?.length && (
          <Box mt="0.75rem">
            {isLoadingMore ? (
              <Typography variation="bodySmall" color="secondary" withoutMargin>
                Carregando...
              </Typography>
            ) : (
              <Link onClick={handleLoadMoreItems} data-testid="load-more-button">
                Ver mais
              </Link>
            )}
          </Box>
        )}
      </Collapse>
    </>
  )
}
