import { useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery } from '@/escolas/hooks'

import { checkIfHasChanges } from '../useEnrollmentPayoutReportFilter/utils/checkIfFilterHasChanges'
import { countSelectedFilters } from '../useEnrollmentPayoutReportFilter/utils/countSelectedFilters'

import type { Filter, FilterQueryParams, TuitionPayoutReportFilterHookValue } from './types'

/**
 * Custom hook to manage filters at Tuition payout page
 */
export const useTuitionPayoutReportFilter = (): TuitionPayoutReportFilterHookValue => {
  const { query, setMultipleQueryParams } = useQuery()

  const getFiltersFromUrl = (): Filter => {
    const parseQueryParamArray = <T extends string[]>(paramName: FilterQueryParams): T => {
      return (query.get(paramName)?.split(',') ?? []) as T
    }

    const filter: Filter = {
      amountSigns: parseQueryParamArray<Filter['amountSigns']>('amount_signs'),
      events: parseQueryParamArray<Filter['events']>('events'),
      products: parseQueryParamArray('products'),
    }

    return filter
  }

  const [filter, setFilter] = useState<Filter>(() => getFiltersFromUrl())

  useEffect(() => {
    const urlFilter = getFiltersFromUrl()
    const hasChangesOnFilter = checkIfHasChanges(urlFilter, filter)

    hasChangesOnFilter && setFilter(urlFilter)
  }, [query])

  const updateFilter = useCallback(
    (updatedFilter: Filter) => {
      const stringifyArray = <T extends unknown>(data: T[]) => {
        return data.join(',')
      }

      const data: Array<{ name: FilterQueryParams; value: string | undefined }> = [
        { name: 'events', value: stringifyArray(updatedFilter.events) },
        { name: 'amount_signs', value: stringifyArray(updatedFilter.amountSigns) },
        { name: 'products', value: stringifyArray(updatedFilter.products) },
      ]

      setMultipleQueryParams(data, 'replace')
    },
    [setMultipleQueryParams]
  )

  const clearFilter = useCallback(() => {
    const data: Array<{ name: FilterQueryParams }> = [
      { name: 'events' },
      { name: 'products' },
      { name: 'amount_signs' },
    ]

    setMultipleQueryParams(data, 'replace')
  }, [setMultipleQueryParams])

  const filterCount = useMemo(() => countSelectedFilters(filter), [filter])

  return { filter, updateFilter, filterCount, clearFilter }
}
