import { useState } from 'react'
import { Box, Tooltip } from '@material-ui/core'
import { Menu } from './components/Menu/Menu'
import { Button, Typography, NotificationProps } from '@olaisaac/design-system'
import envConfig from '@/config'
import { useAgreementSimulationQuery } from '@/escolas/hooks'
import { HotjarEvents, useHotjar } from '@/shared/hooks/useHotjar'
import shoppingCart from '@/escolas/assets/shopping-cart.svg'
import disabledShoppingCart from '@/escolas/assets/disable-shopping-cart.svg'
import { useAgreement } from '@/escolas/contexts/agreementContext'
import { useHistory } from 'react-router-dom'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from '@/escolas/components/modal/ConfirmationDialog'
import { getParamGuardianId } from '../../utils/getParamGuardianId'
import { CURRENT_YEAR } from '@/escolas/components/contract/constants'
import { Divider, InfoIcon, PageTableControlBar } from './styles'
import { formatCentsToReal } from '@/shared/utils'

import { Status } from '../../../GuardianDetailsInstallmentsTab/components/Status/Status'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import adaptCombinedReceivablesToAgreementParam from './utils/adaptCombinedReceivablesToAgreementParam'
import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'
import { getTotalOverdueAmount, getTotalUpToDateAmount } from './utils/getTotalAmount'
import { validateCart } from './utils/validateCart'
import { InstallmentStatus, MIN_CART_ITEMS } from '../../constants'
import useDownPaymentFlow from '@/modules/guardians/Negotiation/hooks/useDownPaymentFlow'
import adaptInstallmentV2ToCombinedReceivables from '@/modules/guardians/Negotiation/helpers/adaptInstallmentV2ToCombinableReceivable'
import useGuardiansInstallmentsByUrnQuery from '@/modules/guardians/ManualLiquidation/hooks/manualLiquidation/useGuardianInstallmentsQueryByUrn'
import { useSnackbar } from '@/shared/hooks'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useGuardianNavigation } from '@/modules/guardians/hooks/useGuardianNavigation'
import { ErrorDialog } from './types'
import { useNegotiationFlow } from '@/modules/guardians/Negotiation/hooks/useNegotiationFlow'
import { useNegotiation } from '@/modules/guardians/Negotiation/hooks/useNegotiation'

export const GuardianDetailsFooter = () => {
  const { schoolSlug } = useSelectedSchool()
  const { sendHotjarEvent } = useHotjar()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()
  const { setPageToReturn } = useGuardianNavigation()
  const { isEnableCheckoutWithoutRestrictions } = useNegotiationFlow()

  const guardianId = getParamGuardianId()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [errorDialog, setErrorDialog] = useState<ErrorDialog>({ visible: false })
  const [anchorEl, setAnchorEl] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const { checkoutCart, totalAmount } = useCheckoutCart()

  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    setVariation: setSnackbarVariation,
  } = useSnackbar()

  const showSnackbar = (variation: NotificationProps['variation'], message: string) => {
    setSnackbarVariation(variation)
    setSnackbarMessage(message)
    setSnackbarIsOpen(true)
  }

  const { validateCheckoutCart, goToNegotiation } = useNegotiation({
    setLoading,
    setErrorDialog,
  })

  const { initAgreementSimulationFlow } = useAgreement()
  const { isDownPaymentEnabled } = useDownPaymentFlow()

  const agreementSimulationParams = adaptCombinedReceivablesToAgreementParam(
    checkoutCart,
    guardianId
  )

  const agreementSimulationQuery = useAgreementSimulationQuery(agreementSimulationParams)

  const checkoutErrorMessage = envConfig.CHECKOUT_BUTTON_ERROR_MESSAGE
  const disablePayButton = envConfig.DISABLE_CHECKOUT_BUTTON === 'true' ?? true

  const handleErrorDialogClose = () => {
    setErrorDialog({ visible: false })
  }

  const urns = checkoutCart.map(item => item.urn)

  const guardiansInstallmentsByUrQuery = useGuardiansInstallmentsByUrnQuery(
    {
      guardianId,
      urns,
    },
    {
      enabled: false,
    }
  )

  const goToNegotiationFlow = () => {
    const url = `/${schoolSlug}/${CURRENT_YEAR}/responsavel/${guardianId}/negociacao`

    setPageToReturn()

    if (checkoutCart?.length < MIN_CART_ITEMS) {
      showSnackbar('error', 'Antes de pagar, selecione as parcelas a serem pagas')
      return
    }

    if (isDownPaymentEnabled) {
      if (guardiansInstallmentsByUrQuery.isFetchGuardiansInstallmentsSuccess) {
        history.push(url)
        return
      }
      setLoading(true)
      guardiansInstallmentsByUrQuery
        .refetchGuardiansInstallments({ throwOnError: true })
        .then(() => {
          history.push(url)
        })
        .catch(() => {
          const tryAgain = () => {
            setErrorDialog({ visible: false })
            goToNegotiationFlow()
          }
          setErrorDialog({
            visible: true,
            onTryAgain: tryAgain,
          })
        })
        .finally(() => setLoading(false))
      return
    }

    if (agreementSimulationQuery.isSuccess) {
      if (guardiansInstallmentsByUrQuery.isFetchGuardiansInstallmentsSuccess) {
        const receivables = adaptInstallmentV2ToCombinedReceivables(
          guardiansInstallmentsByUrQuery.guardiansInstallments.data
        )
        initAgreementSimulationFlow(agreementSimulationQuery.data, receivables)
        history.push(url)
        return
      }
      setLoading(true)
      guardiansInstallmentsByUrQuery
        .refetchGuardiansInstallments({ throwOnError: true })
        .then(response => {
          const receivables = adaptInstallmentV2ToCombinedReceivables(response.data.data)
          initAgreementSimulationFlow(agreementSimulationQuery.data, receivables)
          history.push(url)
        })
        .catch(() => {
          const tryAgain = () => {
            setErrorDialog({ visible: false })
            goToNegotiationFlow()
          }
          setErrorDialog({
            visible: true,
            onTryAgain: tryAgain,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(true)
      Promise.all([
        agreementSimulationQuery.refetch({ throwOnError: true }),
        guardiansInstallmentsByUrQuery.refetchGuardiansInstallments({ throwOnError: true }),
      ])
        .then(results => {
          const agreements = results[0].data
          const receivables = adaptInstallmentV2ToCombinedReceivables(results[1].data.data)

          initAgreementSimulationFlow(agreements, receivables)

          history.push(url)
        })
        .catch(() => {
          const tryAgain = () => {
            setErrorDialog({ visible: false })
            goToNegotiationFlow()
          }
          setErrorDialog({
            visible: true,
            onTryAgain: tryAgain,
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const sendEvent = (scope: EventDispatcherEventScopes, name: EventDispatcherEvents) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope,
        name,
        action: 'click',
      })
  }

  const handlePayButtonClick = () => {
    if (disablePayButton) return

    const isCartValid = isEnableCheckoutWithoutRestrictions
      ? validateCheckoutCart()
      : validateCart(checkoutCart)

    if (!isCartValid?.isValid) {
      showSnackbar('error', isCartValid.message)
      return
    }

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.GUARDIANS,
        name: EventDispatcherEvents.BUTTON_CLICKED,
        action: 'click',
        customProperties: {
          $name: 'Pagar resumo financeiro',
        },
      })
    }

    sendHotjarEvent(HotjarEvents.CHECKOUT)
    sendEvent(EventDispatcherEventScopes.GUARDIANS, EventDispatcherEvents.CHECKOUT_PROCESS_CLICK)

    isEnableCheckoutWithoutRestrictions ? goToNegotiation() : goToNegotiationFlow()
  }

  const defaultMessage = agreementSimulationQuery.isFetching ? 'Carregando...' : 'Pagar'

  const handleHover = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget)
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
    setAnchorEl(null)
  }

  const totalUpToDateAmount = getTotalUpToDateAmount(checkoutCart)
  const totalOverdueAmount = getTotalOverdueAmount(checkoutCart)

  return (
    <>
      <FailureFeedbackDialog
        isVisible={errorDialog.visible}
        buttonLabel="Tentar novamente"
        onClose={handleErrorDialogClose}
        submitHandler={errorDialog.onTryAgain}
      >
        <FailureFeedbackContent />
      </FailureFeedbackDialog>
      <PageTableControlBar>
        <Box display="flex" alignItems="center">
          <Box mr={4} textAlign="right" minWidth="150px">
            <Typography variation="subtitleDesktopSmall" color="secondary">
              Total selecionado
            </Typography>

            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center" mr={1} justifyContent="left">
                <InfoIcon
                  color="action"
                  fontSize="small"
                  onMouseOver={handleHover}
                  onMouseLeave={handleClose}
                />
              </Box>

              <Menu open={isOpen} anchorEl={anchorEl} onClose={handleClose}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  height="60px"
                  p={2}
                >
                  <Status status={InstallmentStatus.OVERDUE} />
                  <Typography variation="headlineDesktopXsmall" color="primary">
                    {`${formatCentsToReal(totalOverdueAmount)}`}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  height="60px"
                  p={2}
                >
                  <Status status={InstallmentStatus.OPEN} />
                  <Typography variation="headlineDesktopXsmall" color="primary">
                    {`${formatCentsToReal(totalUpToDateAmount)}`}
                  </Typography>
                </Box>
              </Menu>
              <Typography variation="headlineDesktopXsmall" color="accent">
                {`${formatCentsToReal(totalAmount)}`}
              </Typography>
            </Box>
          </Box>
          <Tooltip title={disablePayButton ? checkoutErrorMessage : defaultMessage}>
            <Box display="flex" alignItems="center" onClick={handlePayButtonClick} mr={4}>
              <Button
                id="agreement-pay-btn"
                data-testid="pay-btn"
                startIcon={
                  !loading && <img src={disablePayButton ? disabledShoppingCart : shoppingCart} />
                }
                loading={loading}
                variation="primary"
                disabled={disablePayButton}
              >
                Pagar
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </PageTableControlBar>
    </>
  )
}
