import { useApi } from '@/shared/hooks'
import { creditService } from '../../services'
import { useMutation } from '@tanstack/react-query'
import { CreateCreditSimulationRequest } from '../../services/types'

export const createCreditSimulationQueryKey = 'create-credit-simulation'

export const useCreateCreditSimulation = () => {
  const { apiClient } = useApi()
  const service = creditService(apiClient.getClients().bffApi)

  return useMutation(
    [createCreditSimulationQueryKey],
    async (body: CreateCreditSimulationRequest) => service.createCreditSimulation(body)
  )
}
