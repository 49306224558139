import { useState } from 'react'

import { Installment } from '@/modules/contract/services/types'
import { EditAmountForm } from '../components/EditAmountDialog'
import { applyPercentageDiscount } from '../utils/indexV2'
import { AddDiscountForm } from '../components/AddDiscountDialog'

export function useUpdateInstallments(originalInstallments: Installment[]) {
  const [selectedInstallmentIds, setSelectedInstallmentIds] = useState<uuid[]>([])
  const [updatedInstallments, setUpdatedInstallments] = useState(originalInstallments)

  const updateAmounts = (values: EditAmountForm) => {
    const newInstallments = updatedInstallments.map(installment => {
      if (selectedInstallmentIds.includes(installment.backoffice_installment_id)) {
        return {
          ...installment,
          amount: values.amount,
        }
      }

      return installment
    })

    setUpdatedInstallments(newInstallments)
  }

  const addDiscounts = (values: AddDiscountForm, percentMode: boolean) => {
    const newInstallments = updatedInstallments.map(installment => {
      if (selectedInstallmentIds.includes(installment.backoffice_installment_id)) {
        return {
          ...installment,
          discounts: [
            ...installment.discounts,
            {
              ...values,
              id: null,
              amount: percentMode
                ? applyPercentageDiscount(values.percentage, installment.amount)
                : values.amount,
              days_before_due_date: Number(values.days_before_due_date),
              type: values.discountRule,
            },
          ],
        }
      }

      return installment
    })

    setUpdatedInstallments(newInstallments)
  }

  const removeDiscounts = (installmentId: uuid, discountIndexToDelete: number) => {
    const newInstallments = updatedInstallments.map(installment => {
      if (installment.backoffice_installment_id !== installmentId) {
        return installment
      }

      return {
        ...installment,
        discounts: installment.discounts.filter((_, index) => index !== discountIndexToDelete),
      }
    })

    setUpdatedInstallments(newInstallments)
  }

  const updateDueDates = (dueDates: datestring[]) => {
    const mapIDToDueDate = {}
    for (let i = 0; i < selectedInstallmentIds.length; i++) {
      mapIDToDueDate[selectedInstallmentIds[i]] = dueDates[i]
    }

    const newInstallments = updatedInstallments.map(installment => {
      if (installment.backoffice_installment_id in mapIDToDueDate) {
        return {
          ...installment,
          due_date: mapIDToDueDate[installment.backoffice_installment_id],
        }
      }

      return installment
    })

    setUpdatedInstallments(newInstallments)
  }

  return {
    setUpdatedInstallments,
    selectedInstallmentIds,
    setSelectedInstallmentIds,
    updatedInstallments,
    updateAmounts,
    removeDiscounts,
    updateDueDates,
    addDiscounts,
  }
}
