import { useParams } from 'react-router-dom'

import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { EventDispatcherEntities } from '@/shared/models/enums/EventDispatcherEntities.enum'

export type CheckoutEventPayload = {
  guardian_id?: string
  installment_type?: string
  location?: string
  name: string
  number_over_due_contracts?: string
  number_selected_contracts?: string
  number_selected_invoices?: string
  product?: string
  selected_payment_method?: string
  status?: string
  student?: string
  total_of_contracts?: string
  total_of_generated_agreements?: string
  total_of_overdue_installments?: string
  total_of_unpaid_agreements_remaining?: string
}

export const useSendCheckoutEvent = () => {
  const { schoolSlug } = useSelectedSchool()
  const { guardianId } = useParams<{ guardianId: uuid }>()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  if (!isInitialized) {
    return () => null
  }

  return (entity: EventDispatcherEntities, metadata?: CheckoutEventPayload) => {
    const completeMetadata = {
      ...metadata,
      school_slug: schoolSlug,
      guardian_id: guardianId,
    }

    eventDispatcherClient.sendEvent({
      name: EventDispatcherEvents.BUTTON_CLICKED,
      action: 'click',
      scope: EventDispatcherEventScopes.CHECKOUT,
      customProperties: completeMetadata,
      entity,
    })
  }
}
