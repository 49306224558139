import { UseFormSetValue } from 'react-hook-form'
import {
  FiltersInstallmentsFormType,
  FiltersInstallmentsGroupType,
} from '../components/GuardianDetailsInstallmentsFilter/types'
import { AllFiltersOptionsType } from '../../GuardianDetailsContractsTab/types'

/**
 * @description Make a group of filters to be used in Filters Drawer.
 * @param allFiltersOptions Options of filters by the API.
 * @param setValue React Hook Form setValue function.
 * @returns A group of filters FiltersGroupType[].
 */
export const getInstallmentsFiltersGroup = (
  allFiltersOptions: AllFiltersOptionsType,
  setValue: UseFormSetValue<FiltersInstallmentsFormType>
): FiltersInstallmentsGroupType[] => {
  return [
    {
      label: 'Ano',
      filterName: 'reference_years',
      options: allFiltersOptions.year,
      totalOptions: allFiltersOptions.year?.length,
      searchText: '',
      onClearFilter: () => setValue('reference_years', []),
    },
    {
      label: 'Aluno',
      filterName: 'student_ids',
      options: allFiltersOptions?.student,
      totalOptions: allFiltersOptions.student?.length || 0,
      searchText: '',
      onClearFilter: () => setValue('student_ids', []),
    },
    {
      label: 'Produto',
      filterName: 'product_ids',
      options: allFiltersOptions.products,
      totalOptions: allFiltersOptions.products?.length || 0,
      searchText: '',
      onClearFilter: () => setValue('product_ids', []),
    },
    {
      label: 'Tipo de parcela',
      filterName: 'installment_types',
      options: allFiltersOptions.installmentType,
      totalOptions: allFiltersOptions.installmentType?.length,
      searchText: '',
      onClearFilter: () => setValue('installment_types', []),
    },
    {
      label: 'Situação',
      filterName: 'installment_statuses',
      options: allFiltersOptions.installmentStatus,
      totalOptions: allFiltersOptions.installmentStatus?.length,
      searchText: '',
      onClearFilter: () => setValue('installment_statuses', []),
    },
    {
      label: 'Situação do contrato',
      filterName: 'contract_status',
      options: allFiltersOptions.installmentContractStatus,
      totalOptions: allFiltersOptions.installmentContractStatus?.length,
      searchText: '',
      onClearFilter: () => setValue('contract_status', undefined),
    },
  ]
}
