import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const useEnrollmentCampaignRedirect = () => {
  const enrollmentCampaignFF = useUnleashFlag(UnleashFlags.ENABLE_MATRICULAS_CAMPAIGN)
  const history = useHistory()
  const { schoolSlug } = useSelectedSchool()

  const redirect = () => {
    if (!enrollmentCampaignFF) {
      history.replace(`/${schoolSlug}/matriculas`)
    }
  }

  useEffect(() => {
    redirect()
  }, [])
}
