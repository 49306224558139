import { CheckoutCombinableReceivable } from '@/escolas/contexts/checkoutContext'
import type { InstallmentType } from './installment'
import { InstallmentStudent, Negotiation, Product } from '@/modules/guardians/services/types'

export interface GetAgreementEntryResponse {
  data: AgreementEntry
}

export interface AgreementEntry {
  competenceMonths?: string[]
  dueDate: string
  maxInstallment: number
  negotiationID: uuid
  orderReference: string
  paymentLink: string
  paymentPlan?: ChosenPaymentPlan
  product: Product[]
  simulationID: uuid
  student: InstallmentStudent
  value: number
}

export interface AgreementSimulationParams {
  guardian_id: uuid
  receivables: {
    contract_id: uuid
    current_due_payment_discount: cents
    current_early_payment_discount: cents
    current_perpetual_discount: cents
    due_date: datestring
    id: uuid
    installment_type: InstallmentType
    original_amount: cents
    total_amount: cents
  }[]
}

export interface AgreementStatus {
  finished: boolean
  invoices: AgreementInvoice[]
}

export interface MakeAgreementBody {
  agreement_amount: cents
  agreement_simulation_id: uuid
  channel: string
  guardian_id: uuid
  payment_plan: ChosenPaymentPlan
  receivable_ids: string[]
}

export interface AgreementInvoice {
  amount: cents
  due_date: datestring
  id?: uuid
  installment?: string
  instalment_credit_card?: number
  payment_link?: string
  payment_method: SimulationPaymentMethod
  receivable_id: uuid
}
export interface MakeAgreementResponse {
  agreement_amount: cents
  id: uuid
  invoices: AgreementInvoice[]
}

export interface AgreementSimulation {
  agreement_simulation_id?: uuid
  credit_card_fee?: cents
  credit_card_fee_percentage?: number
  due_date: datestring
  due_payment_discount_applied: cents
  early_payment_discount_applied: cents
  exempted_fine: cents
  exempted_interest: cents
  fine_applied: cents
  interest_applied: cents
  original_amount: cents
  original_fine: cents
  original_interest: cents
  payment_plans: PaymentPlan[]
  perpetual_discount_applied: cents
  receivable_ids: uuid[]
  short_id: string
  total_amount: cents
}

type NegotiationData = {
  id: uuid
  receivable_id: uuid
  short_id: string
}

export interface AgreementSimulationCombined extends AgreementSimulation {
  negotiation_data?: NegotiationData
  origin_negotiation?: Negotiation
  receivables: CheckoutCombinableReceivable[]
  type?: 'agreement' | 'down_payment'
}

export interface PaymentPlan {
  max_installments_allowed: number
  min_down_payment: cents
  payment_method: SimulationPaymentMethod
}

export interface ChosenPaymentPlan {
  down_payment_amount: cents
  due_date: string
  number_of_installments: number
  payment_method: SimulationPaymentMethod
}

export interface DuplicateAgreementRequest {
  guardian_id: uuid
  payment_plan: ChosenPaymentPlan
  simulation_id: uuid
}

export enum SimulationPaymentMethod {
  BANK_SLIP = 'BANK_SLIP',
  CREDIT_CARD = 'CREDIT_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
  PIX = 'PIX',
  POS = 'POS',
}

export enum FormOfPayment {
  INSTALLMENTS = 'INSTALLMENTS',
  ONE_LUMP_SUM = 'ONE_LUMP_SUM',
  OTHER = 'OTHER',
  POS = 'POS',
  POS_V2 = 'POS_V2',
}
