import { Box } from '@material-ui/core'
import { Link, TableColumns, Table } from '@olaisaac/design-system'
import { useContext, useState } from 'react'
import { HotjarEvents, useHotjar } from 'src/shared/hooks/useHotjar'
import { formatCentsToReal } from 'src/shared/utils'
import formatListWithKeys from 'src/shared/utils/formatListWithKeys'
import styled from 'styled-components'
import { CampaignContext } from '../../context'
import CampaignDialog from '../../CampaignDialog'
import { CampaignContractWithKey } from '../../types'

const StyledLink = styled(Link)`
  cursor: pointer;
`

type ReviewAndSaveProps = {
  onRemoveLastContract: () => void
}

const ReviewAndSave = ({ onRemoveLastContract }: ReviewAndSaveProps) => {
  const { state } = useContext(CampaignContext)
  const {
    nextYearProduct,
    contractList,
    paymentPlan,
    selectedContractIdsList,
    referenceYear,
  } = state
  const [isRemoveContractDialogOpen, setRemoveContractDialogOpen] = useState(false)
  const [selecteContractToRemove, setSelectedContractToRemove] = useState<CampaignContractWithKey>(
    null
  )
  const { sendHotjarEvent } = useHotjar()

  const selectedContractList = (contracts = []) => {
    const selectedContracts = contracts.filter(contract =>
      selectedContractIdsList?.includes(contract.id)
    )

    return formatListWithKeys(selectedContracts, 'id')
  }

  const openRemoveContractDialog = contractId => {
    const selectedContract = contractList.find(contract => contract.id === contractId)

    setSelectedContractToRemove(selectedContract)
    setRemoveContractDialogOpen(true)
  }

  const closeRemoveContractDialog = () => {
    setRemoveContractDialogOpen(false)
  }

  const handleRemoveContract = contractIdToRemove => {
    if (selectedContractIdsList.length === 1) {
      onRemoveLastContract()
    }

    // TODO: refact to remove this gambiarra
    document
      .querySelectorAll(`[data-testid="table-row${contractIdToRemove}"]`)[0]
      .getElementsByTagName('input')[0]
      .click()

    closeRemoveContractDialog()

    sendHotjarEvent(HotjarEvents.CAMPAIGN_REMOVE_STUDENT_ALERT)
  }

  const columns: TableColumns = [
    {
      headerName: 'Aluno',
      field: 'student',
      renderCell: value => value.name,
    },
    {
      headerName: 'Responsável',
      field: 'guardian',
      renderCell: value => value.name,
    },
    {
      headerName: 'Pré-matrícula',
      field: 'id',
      valueFormatter: () =>
        paymentPlan &&
        paymentPlan.custom_enrollment_monthly_amount &&
        paymentPlan.enrollment_duration_months
          ? `${paymentPlan.enrollment_duration_months}x ${formatCentsToReal(
              +paymentPlan.custom_enrollment_monthly_amount
            )}`
          : '-',
    },
    {
      headerName: 'Mensalidades',
      field: 'id',
      valueFormatter: () =>
        paymentPlan &&
        `${paymentPlan.duration_months}x ${formatCentsToReal(+paymentPlan.custom_monthly_amount)}`,
    },
    {
      field: 'id',
      headerName: '',
      renderCell: value => (
        <StyledLink onClick={() => openRemoveContractDialog(value)}>Remover</StyledLink>
      ),
    },
  ]

  return (
    <>
      <Box paddingTop="20px">
        <div data-testid="review-contracts-table">
          <Table<CampaignContractWithKey>
            columns={columns}
            rows={selectedContractList(contractList)}
            sorted
          />
        </div>
      </Box>
      <CampaignDialog
        open={isRemoveContractDialogOpen}
        title="Deseja remover aluno da rematrícula?"
        content={
          <>
            Você está removendo <strong>{selecteContractToRemove?.student.name}</strong> da
            rematrícula para o curso{' '}
            <strong>
              {nextYearProduct?.name} ({referenceYear})
            </strong>
            . <br /> <br />
            Tem certeza que deseja continuar?
          </>
        }
        closeText="Voltar"
        onClose={closeRemoveContractDialog}
        confirmText="Remover"
        onConfirm={() => handleRemoveContract(selecteContractToRemove?.id)}
      />
    </>
  )
}

export default ReviewAndSave
