import { NewReports } from './NewReports'
import { OldReports } from './OldReports'

import { UnleashFlags, useUnleashFlag } from '@/shared/hooks/useUnleashFlag'

export const Reports = () => {
  const isNewReportsEnabled = useUnleashFlag(UnleashFlags.PE_ENABLE_NEW_REPORTS)

  return <>{isNewReportsEnabled ? <NewReports /> : <OldReports />}</>
}
