import { formatCentsToReal } from '@/shared/utils'
import { Chip, Typography } from '@olaisaac/design-system'

import MuiPopover from '@material-ui/core/Popover'
import { useRef, useState } from 'react'
import { Discount } from '@/shared/interfaces'
import { Installment } from '@/modules/contract/services/types'
import { DiscountRule, DiscountRuleLabels } from '../../utils/indexV2'
import * as Styled from './styles'

const daysToRuleName = (daysBeforeDueDate: Discount['days_before_due_date']): string =>
  ({
    0: DiscountRuleLabels[DiscountRule.DUE_PAYMENT].name,
    [-1]: DiscountRuleLabels[DiscountRule.PERPETUAL].name,
  }[daysBeforeDueDate] || DiscountRuleLabels[DiscountRule.EARLY_PAYMENT].name)

type DiscountChipProps = {
  disabled: boolean
  discount: Discount
  onDelete: () => void
}

const DiscountChip = ({ discount, onDelete, disabled }: DiscountChipProps) => {
  const anchorEl = useRef(null)
  const [open, setOpen] = useState(false)

  return (
    <>
      <Chip
        disabled={disabled}
        ref={anchorEl}
        label={formatCentsToReal(discount.amount)}
        variation="green"
        onDelete={onDelete}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      />
      <MuiPopover
        style={{ pointerEvents: 'none' }}
        open={open}
        container={anchorEl.current?.parentNode}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Styled.PopoverContainer>
          <div>
            <Typography variation="bodySmall" color="secondary">
              Nome do desconto
            </Typography>
            <Typography variation="bodyLarge">{discount.description}</Typography>
          </div>
          <Typography variation="bodySmall" color="secondary">
            Regra do desconto
          </Typography>
          <Typography variation="bodyLarge">
            {daysToRuleName(discount.days_before_due_date)}
          </Typography>
        </Styled.PopoverContainer>
      </MuiPopover>
    </>
  )
}

export const DiscountChips = ({
  onDelete,
  installment,
}: {
  installment: Installment
  onDelete: (installmentId: uuid, discountIndex: number) => void
}) => {
  const discounts = installment.discounts

  if (discounts.length === 0) {
    return <Chip label="Nenhum desconto aplicado" />
  }

  return (
    <Styled.Container>
      {discounts.map((discount, index) => {
        return (
          <DiscountChip
            key={index}
            discount={discount}
            onDelete={() => onDelete(installment.backoffice_installment_id, index)}
            disabled={!installment.can_edit}
          />
        )
      })}
    </Styled.Container>
  )
}
