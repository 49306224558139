import { FC } from 'react'
import logo from 'src/shared/assets/isaac-logo.svg'
import styled from 'styled-components'

const StyledImg = styled.img`
  width: ${({ width }) => width};
  height: auto;
`

type IsaacLogoProps = {
  width?: string
}
const IsaacLogo: FC<IsaacLogoProps> = ({ width = '130px' }) => (
  <StyledImg src={logo} alt="" width={width} />
)

export default IsaacLogo
