import { Typography } from '@olaisaac/design-system'
import { Row } from '../GuardianDetailsInstallmentsTable/types'
import { Status } from '../Status/Status'
import { formatter } from '../GuardianDetailsInstallmentsTable/utils/formatter'
import {
  InstallmentStatusDescription,
  InstallmentStatus as InstallmentStatusValues,
} from '@/modules/guardians/GuardianDetails/constants'
import { Tooltip } from '@material-ui/core'
import { Container, StatusDescriptionContainer, StyledInfoOutlinedIcon } from './styles'

export const InstallmentFullStatus = (value: Row['installment_status']) => {
  const isPending = value.status === InstallmentStatusValues.PENDING

  const mapOfComponentsForPendingStatus = {
    [InstallmentStatusDescription.PENDING_DOWNPAYMENT]: (
      <Tooltip title="Para gerar todas as parcelas da negociação, é necessário realizar o pagamento da parcela de entrada.">
        <StatusDescriptionContainer>
          <Typography variation="caption">Pgto. da entrada</Typography>
          <StyledInfoOutlinedIcon color="action" />
        </StatusDescriptionContainer>
      </Tooltip>
    ),
    [InstallmentStatusDescription.PENDING_PAYMENT]: (
      <Tooltip title="As parcelas serão geradas apenas após: o pagamento da pré-matrícula e/ou quitação dos contratos do ano anterior">
        <StatusDescriptionContainer>
          <Typography variation="caption">Pgtos. pendentes</Typography>
          <StyledInfoOutlinedIcon color="action" />
        </StatusDescriptionContainer>
      </Tooltip>
    ),
    [InstallmentStatusDescription.PENDING_INVOICE_GENERATION]: (
      <Tooltip title="Os boletos dessa fatura ainda não foram gerados">
        <StatusDescriptionContainer>
          <Typography variation="caption">Geração de fatura</Typography>
          <StyledInfoOutlinedIcon color="action" />
        </StatusDescriptionContainer>
      </Tooltip>
    ),
    [InstallmentStatusDescription.PENDING_ENROLL_PROCESSING]: (
      <Tooltip title="Os boletos dessa fatura ainda não foram gerados">
        <StatusDescriptionContainer>
          <Typography variation="caption">Geração de fatura</Typography>
          <StyledInfoOutlinedIcon color="action" />
        </StatusDescriptionContainer>
      </Tooltip>
    ),
  }

  return (
    <Container>
      <Status
        status={value.status}
        text={value.overdue_for_days ? ` há ${value.overdue_for_days} dia(s)` : ''}
      />
      {value.paid_date && (
        <Typography variation="caption">
          Recebido em {formatter.full_date(value.paid_date)}
        </Typography>
      )}
      {isPending ? mapOfComponentsForPendingStatus[value.status_description] : null}
      {value.status_description === InstallmentStatusDescription.NEGOTIATION_EXPIRED ? (
        <Tooltip title="Uma negociação foi realizada nesta parcela, no entanto, a negociação expirou e/ou foi cancelada.">
          <StatusDescriptionContainer>
            <Typography variation="caption">Negociação expirada</Typography>
            <StyledInfoOutlinedIcon color="action" />
          </StatusDescriptionContainer>
        </Tooltip>
      ) : null}
    </Container>
  )
}
