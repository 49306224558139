import { Box } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { CardToggle, CardTogglePlaceholder } from '../CardToggle'

import {
  AssignmentRounded as AssignmentRoundedIcon,
  ReportProblemRounded as ReportProblemRoundedIcon,
} from '@material-ui/icons'

import taskCategoryIcon from '../../assets/task-category-blue-icon.svg'

import { useQuery } from '@/escolas/hooks'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import {
  NOTIFICATION_INITIAL_PAGE,
  NOTIFICATION_MIN_COUNT,
  NOTIFICATION_STATUS,
} from '../../constants'

import type { Notification } from '../../types'
import type { OverviewSectionProps } from './types'

import theme from '@/shared/theme'

export const { spacing, colors } = theme.primitiveTokens

const hasUnreadNotificationsByCategory = (notifications: Notification[], category: string) =>
  notifications?.filter(
    notification =>
      notification.category === category && notification.status === NOTIFICATION_STATUS.UNREAD
  ).length > 0

export const OverviewSection = (props: OverviewSectionProps) => {
  const { notifications, isLoading } = props

  const { query, setOnQueryParam } = useQuery()
  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const categoryQuery = query.get('category') || ''

  const handleCategoryToggle = (categoryTitle: string) => () => {
    const category = categoryQuery.includes(categoryTitle)
      ? categoryQuery.replace(`${categoryTitle}`, '')
      : categoryTitle

    // Reset page and count on category change
    setOnQueryParam(`${NOTIFICATION_INITIAL_PAGE}`, 'page')
    setOnQueryParam(`${NOTIFICATION_MIN_COUNT}`, 'count')
    setOnQueryParam(`${category}`, 'category')

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.NOTIFICATIONS,
        name: EventDispatcherEvents.BUTTON_CLICK,
        action: 'click',
        entity: 'Aviso',
        customProperties: {
          $page_name: EventDispatcherEventScopes.NOTIFICATIONS,
          $title: categoryTitle,
          $button: 'Category filter button',
        },
      })
    }
  }

  const categories = [
    {
      title: 'Alertas',
      icon: <ReportProblemRoundedIcon htmlColor={colors.yellow[80]} />,
      details: hasUnreadNotificationsByCategory(notifications, 'alertas')
        ? 'Existem alertas não lidos'
        : 'Todos lidos',
      description: 'Informações sobre instabilidades e manutenções que impactam sua escola.',
    },
    {
      title: 'Tarefas',
      icon: <img src={taskCategoryIcon} alt="" />,
      details: hasUnreadNotificationsByCategory(notifications, 'tarefas')
        ? 'Existem tarefas não lidas'
        : 'Todas lidas',
      description: 'Ações que sua escola deve realizar dentro da Plataforma isaac.',
    },
    {
      title: 'Informes',
      icon: <AssignmentRoundedIcon htmlColor={colors.gray[80]} />,
      details: hasUnreadNotificationsByCategory(notifications, 'informes')
        ? 'Existem informes não lidos'
        : 'Todos lidos',
      description: 'Novidades na plataforma isaac e outras comunicações pontuais.',
    },
  ]

  return (
    <Box component="section" display="grid" gridGap={spacing[8]} marginBottom={spacing[12]}>
      <Typography as="h2" variation="headlineDesktopSmall">
        Visão geral
      </Typography>

      <Box display="grid" gridGap={spacing[8]} gridTemplateColumns="repeat(3, 1fr)">
        {isLoading
          ? [1, 2, 3].map(item => <CardTogglePlaceholder key={item} />)
          : categories.map(category => (
              <CardToggle
                key={category.title}
                title={category.title}
                icon={category.icon}
                details={category.details}
                description={category.description}
                isActive={categoryQuery.includes(category.title.toLowerCase())}
                onToggle={handleCategoryToggle(category.title.toLowerCase())}
              />
            ))}
      </Box>
    </Box>
  )
}
