import { formatter } from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/GuardianDetailsInstallmentsTable/utils/formatter'
import { InstallmentDrawerInfo } from '@/modules/guardians/InstallmentsDrawerContainer/NegotiationDrawer/types'

import { InstallmentStatus, InstallmentType } from '@/modules/guardians/GuardianDetails/constants'
import { useFooterMessage } from '@/modules/guardians/InstallmentsDrawerContainer/hooks/useFooterMessage'

const titleMap = {
  ENROLLMENT: 'Pré-matrícula',
  TUITION: 'Mensalidade',
} as Record<InstallmentType, string>

const installmentStatusMap = {
  OPEN: {
    color: 'accent',
    title: 'A vencer',
  },
  OVERDUE: {
    color: 'error',
    title: 'Vencida',
  },
  PAID: {
    color: 'success',
    title: 'Pago',
  },
} as Record<
  InstallmentStatus,
  { color?: 'accent' | 'error' | 'warning' | 'success'; title: string }
>

export const useInstallmentDrawer = ({ installment }: { installment: InstallmentDrawerInfo }) => {
  const { type, status, negotiation, paid, due_date, is_from_credit_card_flow } = installment
  const footerMessage = useFooterMessage(status, due_date, paid)

  const getInstallmentStatus = (
    status: InstallmentStatus
  ): { color?: 'accent' | 'error' | 'warning' | 'success'; title: string } => {
    return installmentStatusMap[status] ?? null
  }

  const getNotification = (
    status: InstallmentStatus
  ): { description: string; variation: 'information' } => {
    if (is_from_credit_card_flow && status !== 'PAID') {
      return {
        variation: 'information',
        description: `Fatura gerada para pagamento via cartão de crédito, válida até ${formatter.full_date(
          negotiation.down_payment_due_date
        )}`,
      }
    }

    return null
  }

  return {
    headerTitle: titleMap[type],
    headerStatusInfo: getInstallmentStatus(status),
    notification: getNotification(status),
    footerMessage,
  }
}
