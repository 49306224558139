import { ActionType, CampaignContextType } from './types'

export const campaignReducer = (state: CampaignContextType, action) => {
  switch (action.type) {
    case ActionType.FETCH_PRODUCTS: {
      return {
        ...state,
        products: action.payload.products,
      }
    }
    case ActionType.SELECT_NEXT_YEAR_PRODUCT: {
      const nextYearProduct = state.products.find(
        product => product.id === action.payload.nextYearProductId
      )
      return {
        ...state,
        nextYearProductId: action.payload.nextYearProductId,
        nextYearProduct,
      }
    }
    case ActionType.SELECT_CURRENT_YEAR_PRODUCT: {
      const currentYearProduct = state.products.find(
        product => product.id === action.payload.currentYearProductId
      )
      return {
        ...state,
        currentYearProductId: action.payload.currentYearProductId,
        currentYearProduct,
      }
    }
    case ActionType.UPDATE_CONTRACT_IDS_LIST: {
      return {
        ...state,
        selectedContractIdsList: action.payload.selectedContractIdsList,
      }
    }
    case ActionType.UPDATE_CONTRACT_LIST: {
      return {
        ...state,
        contractList: action.payload.contractList,
      }
    }
    case ActionType.SET_PAYMENT_PLAN: {
      return {
        ...state,
        paymentPlan: action.payload.paymentPlan,
      }
    }
    case ActionType.SET_PRE_CONTRACT_DISCLAIMER_CHECK: {
      return {
        ...state,
        preContractDisclaimerCheck: action.payload.preContractDisclaimerCheck,
      }
    }
    default: {
      return state
    }
  }
}
