import React from 'react'
import { DebtStatusProps } from './types'
import { Badge } from '@gravity/badge'

export const DebtStatus: React.FC<DebtStatusProps> = ({ status }) => {
  const getDebtStatusVariation = {
    WARNING: { color: 'warning' as const, text: 'Atenção' },
    UP_TO_DATE: { color: 'success' as const, text: 'Em dia' },
    PENDING: { color: 'accent' as const, text: 'Aguardando' },
    OVERDUE: { color: 'error' as const, text: 'Com pendências' },
  }

  return (
    <Badge color={getDebtStatusVariation[status].color} variant="soft" size={1}>
      {getDebtStatusVariation[status].text}
    </Badge>
  )
}
