import { useQuery } from '@/escolas/hooks'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import {
  GuardianInstallmentFilterType,
  GuardianInstallmentFilterObjectKeys,
  GuardianInstallmentFilterQueryParams,
} from '../constants'
import { GuardianFinanceSummaryStatus } from '@/modules/guardians/GuardianDetails/constants'

export const useBigNumber = (installmentStatus: GuardianFinanceSummaryStatus) => {
  const [isSelected, setIsSelected] = useState(false)
  const { query, setMultipleQueryParams } = useQuery()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const getFiltersFromUrl = (): GuardianInstallmentFilterType => {
    const parseQueryParamArray = (paramName: GuardianInstallmentFilterQueryParams) => {
      return query.get(paramName)?.split(',') ?? []
    }

    const data: Record<GuardianInstallmentFilterObjectKeys, string[]> = {
      installmentStatus: parseQueryParamArray('installment_status'),
    }

    return (data as unknown) as GuardianInstallmentFilterType
  }

  const [
    guardianInstallmentFilter,
    setGuardianInstallmentFilter,
  ] = useState<GuardianInstallmentFilterType>(() => getFiltersFromUrl())

  useEffect(() => {
    type GuardianInstallmentFilterType = {
      [key: string]: string[]
    }

    const checkIfHasChanges = (
      urlFilter: GuardianInstallmentFilterType,
      stateFilter: GuardianInstallmentFilterType
    ) => {
      let hasChanges = false
      const stateFilterKeys = Object.keys(stateFilter) as Array<keyof GuardianInstallmentFilterType>

      stateFilterKeys.every(key => {
        if (stateFilter[key].join(',') !== urlFilter[key].join(',')) {
          hasChanges = true
          return false
        }
        return true
      })

      return hasChanges
    }

    const urlGuardianInstallmentFilter = getFiltersFromUrl()

    const hasChangesOnFilter = checkIfHasChanges(
      urlGuardianInstallmentFilter,
      guardianInstallmentFilter
    )

    hasChangesOnFilter && setGuardianInstallmentFilter(urlGuardianInstallmentFilter)
  }, [query])

  useEffect(() => {
    setIsSelected(guardianInstallmentFilter.installmentStatus.includes(installmentStatus))
  }, [])

  const updateFilter = useCallback(
    (
      updatedFilter: GuardianInstallmentFilterType = {
        installmentStatus: [],
      }
    ) => {
      const data: Array<{
        name: GuardianInstallmentFilterQueryParams
        value: string
      }> = [
        {
          name: 'installment_status',
          value: updatedFilter.installmentStatus.join(','),
        },
      ]

      setMultipleQueryParams(data)
    },
    [setMultipleQueryParams]
  )
  const handleBigNumberClick = () => {
    const activeFilters = guardianInstallmentFilter.installmentStatus
    let nextFilters = [...activeFilters, installmentStatus]

    if (activeFilters.includes(installmentStatus)) {
      nextFilters = activeFilters.filter(e => e !== installmentStatus)
    }

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.GUARDIANS,
        name: EventDispatcherEvents.CARD_CLICKED,
        action: 'click',
        customProperties: {
          $installment_status: installmentStatus,
          $name: 'Filtrar resumo financeiro',
        },
      })
    }

    updateFilter({
      installmentStatus: nextFilters,
    })
    setIsSelected(!isSelected)
  }
  return useMemo(
    () => ({
      handleBigNumberClick,
      isSelected,
    }),
    [handleBigNumberClick, isSelected]
  )
}
