import styled from 'styled-components'
import { Typography, DialogContent, DialogActions, DialogTitle } from '@olaisaac/design-system'
import { spacing, colors, fontWeight, fontSize, lineHeight, letterSpacing } from '@gravity/tokens'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import { TextArea } from '@gravity/text-area'

export const DialogWrapper = styled.div<{ customSize: number }>`
  width: ${props => (props.customSize === 1 ? '31.8rem' : '593px')};
`
export const DialogCustom = styled(Dialog)`
  display: flex;
  padding: ${spacing[4]} ${spacing.none};
  flex-direction: column;
  align-items: center;
  gap: ${spacing[8]};
  align-self: stretch;

  .MuiBackdrop-root {
    background-color: ${colors['colors-background-accent-2']};
    opacity: 0.3 !important;
  }

  .MuiPaper-rounded {
    border-radius: 0.5rem;
  }
`

export const DialogContainer = styled(DialogTitle)`
  margin: 16px 16px 32px;
`

export const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing[3]};
  align-self: stretch;
`

export const Title = styled(Typography)`
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize.lg};
  font-style: normal;
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.normal};
  //margin: 0px 24px 0px 0px;
`

export const Description = styled(Typography)`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize.sm};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight.snug};
  letter-spacing: ${letterSpacing.tight};
  margin-top: ${spacing[3]};
  white-space: pre-line;
`

export const CloseButton = styled(IconButton)`
  width: 1.5rem;
  height: 1.5rem;
`

export const CardReason = styled(DialogContent)`
  gap: ${spacing[1]};
  padding: 0rem 1rem;
`

export const LabelReason = styled(Typography)`
  color: ${colors['colors-text-main-2']};
  font-size: ${fontSize.base};
  font-style: normal;
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.normal};
  letter-spacing: ${letterSpacing.normal};
  margin-bottom: 0.25rem;
`

export const ContainerActions = styled(DialogActions)`
  display: flex;
  gap: ${spacing[2]};
  border-top: none;
  margin-top: 16px;
  padding: 16px;
`

export const StyledTextArea = styled(TextArea)`
  width: 100%;
`
