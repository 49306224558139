import { MenuProps } from '../types'
import { MUIMenu } from './styles'

export const Menu = (props: MenuProps) => {
  const { children, open, anchorEl, onClose } = props

  return (
    <MUIMenu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 195,
        horizontal: 85,
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      {children}
    </MUIMenu>
  )
}
