import { CSSProperties, ReactNode } from 'react'

import { Container, Border } from './styles'

type CardProps = {
  accentColor?: string
  backgroundColor: string
  borderColor?: string
  children: ReactNode
  style?: CSSProperties
}

/**
 * Standard card component
 *
 * @param props
 * @param props.accentColor Optional internal border-top color
 * @param props.borderColor Optional card border color
 * @param props.backgroundColor Card background color
 * @param props.children Card content
 */
export const Card = ({
  accentColor,
  borderColor,
  backgroundColor,
  style = {},
  children,
}: CardProps) => {
  return (
    <Container borderColor={borderColor} backgroundColor={backgroundColor} style={style}>
      {!!accentColor && <Border color={accentColor} data-testid="border" />}

      {children}
    </Container>
  )
}
