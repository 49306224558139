import { useEffect, useState } from 'react'

import { useQuery } from 'src/escolas/hooks/useQuery'
import { GuardiansInstallmentsQueryParamsNameEnum } from '../constants'
import { InstallmentsFiltersType } from '../types'
import { ContractStatus, InstallmentType } from '@/shared/interfaces'
import { InstallmentStatus } from '../../GuardianDetails/constants'

const parseArrayToFilter = <T>(filter: string) => {
  return (!filter || filter === '' ? [] : filter.split(',')) as T[]
}

/**
 * @description Hook to handle query params for guardian installments tab
 */
export const useGuardiansInstallmentsQueryParams = () => {
  const { query, setMultipleQueryParams } = useQuery()

  const [filters, setFilters] = useState<InstallmentsFiltersType>({
    installment_statuses: parseArrayToFilter<InstallmentStatus>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.STATUSES)
    ),
    installment_types: parseArrayToFilter<InstallmentType>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.TYPES)
    ),
    reference_years: parseArrayToFilter<string>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.YEARS)
    ),
    student_ids: parseArrayToFilter<string>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.STUDENT_IDS)
    ),
    product_ids: parseArrayToFilter<string>(
      query.get(GuardiansInstallmentsQueryParamsNameEnum.PRODUCT_IDS)
    ),
    contract_status: query.get(
      GuardiansInstallmentsQueryParamsNameEnum.CONTRACT_STATUS
    ) as ContractStatus,
  })

  const updateFilters = (data: InstallmentsFiltersType) => {
    setFilters(data)
  }

  const filtersToQueryParams = (filters: InstallmentsFiltersType) =>
    Object.entries(filters).reduce((prev, [name, value]) => {
      prev.push({ name, value: value && value?.length ? value : null })
      return prev
    }, [])

  useEffect(() => {
    setMultipleQueryParams([...filtersToQueryParams(filters)])
  }, [filters])

  return {
    filters,
    updateFilters,
  }
}
