import { Box, Collapse, Divider, ListItem } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'
import { FC, useEffect } from 'react'
import * as S from './EditRegistrationDrawer.styles'
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form'
import { useJWT } from '@/shared/hooks'
import { useParams } from 'react-router-dom'

export type CollapseStudentProps = {
  edit?: boolean
  form: UseFormReturn<any>
  handleOpenCollapse: (value: string) => void
  openCollapse: string
}

const CollapseStudent: FC<CollapseStudentProps> = ({
  edit = false,
  form,
  openCollapse,
  handleOpenCollapse,
}) => {
  const isOpen = openCollapse === 'student'
  const { isAdmin } = useJWT()
  const { control, trigger } = form
  const { referenceYear } = useParams<{ referenceYear: string }>()
  const { fields } = useFieldArray({
    control,
    name: 'students',
  })

  useEffect(() => {
    if (isOpen) {
      trigger()
    }
  }, [openCollapse])

  return (
    <Box>
      <ListItem
        button
        style={{ justifyContent: 'space-between', paddingLeft: '22px' }}
        onClick={() => handleOpenCollapse('student')}
      >
        <Typography variation="subtitleDesktopLarge">Informações dos alunos</Typography>
        {isOpen ? <S.ExpandLess /> : <S.ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <S.StyledBox>
          {fields?.length ? (
            fields?.map((item, index) => (
              <Controller
                key={item.id}
                name={`students.${index}.name`}
                control={control}
                rules={{ required: true }}
                render={({ field, field: { value }, fieldState: { error } }) => (
                  <S.StyledTextField
                    {...field}
                    value={value}
                    fullWidth
                    label={`Nome do Aluno - ${index + 1}`}
                    error={Boolean(error)}
                    helperText={error ? 'O nome é obrigatório' : ''}
                    disabled={!(edit && isAdmin)}
                  />
                )}
              />
            ))
          ) : (
            <Box textAlign="center">
              <Typography variation="bodyLarge">
                {`Nenhum estudante cadastrado em ${referenceYear}`}
              </Typography>
            </Box>
          )}
        </S.StyledBox>
      </Collapse>
      <Box paddingRight={3} paddingLeft={2.8}>
        <Divider variant="middle" style={{ margin: '0' }} />
      </Box>
    </Box>
  )
}

export default CollapseStudent
