import { Stepper } from '@/modules/enrollment/components/Stepper'
import { useCampaignStepper } from '@/modules/enrollment/components/Stepper/hooks/useCampaignStepper'
import { CampaignProvider } from '@/modules/enrollment/contexts/CampaignContext'
import { useEnrollmentCampaignRedirect } from '@/modules/enrollment/hooks/useEnrollmentCampaignRedirect'

import { PaymentConditionsStep } from './components/PaymentConditionsStep'
import { StudentsStep } from './components/StudentsStep'
import { CampaignPublicityStep } from './components/CampaignPublicityStep'
import { ReviewStep } from './components/ReviewStep'
import * as Styled from './styles'
import { useSchoolEnrollmentCycleContext } from '@/modules/enrollment/contexts/SchoolEnrollmentContext'

const NewCampaignWrapper = () => {
  const campaignSteps = [
    { label: 'Condições', component: PaymentConditionsStep },
    { label: 'Alunos', component: StudentsStep },
    { label: 'Divulgação', component: CampaignPublicityStep },
    { label: 'Revisão', component: ReviewStep },
  ]

  const { activeStep, ActiveComponent, handleButtonClick } = useCampaignStepper(campaignSteps)

  const { selectedEnrollmentCycle } = useSchoolEnrollmentCycleContext()

  const handleStepFinish = () => {
    handleButtonClick(activeStep + 1)
  }

  return (
    <Styled.Container>
      <Styled.NewCampaignWrapper>
        <Stepper steps={campaignSteps} title="Nova campanha" />
        <Styled.ContentContainer>
          <ActiveComponent enrollmentCycle={selectedEnrollmentCycle} onFinish={handleStepFinish} />
        </Styled.ContentContainer>
      </Styled.NewCampaignWrapper>
    </Styled.Container>
  )
}

export const NewCampaign = () => {
  useEnrollmentCampaignRedirect()

  return (
    <CampaignProvider>
      <NewCampaignWrapper />
    </CampaignProvider>
  )
}
