import { ArrowBack } from '@material-ui/icons'
import { Button, Notification } from '@olaisaac/design-system'
import { useHistory } from 'react-router-dom'
import { FC } from 'react'
import { Title, GuardianHeader, TitleBox, Subtitle } from './style'

const ChangeGuardianHeader: FC = () => {
  const history = useHistory()

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <GuardianHeader data-testid="change-guardian-header-container">
      <Button variation="ghost" startIcon={<ArrowBack />} onClick={handleGoBack}>
        Voltar
      </Button>
      <TitleBox>
        <Title>Alterar titularidade do contrato</Title>
        <Subtitle>
          Preencha o formulário com os dados do novo titular do contrato, revise e confirme a
          alteração
        </Subtitle>
      </TitleBox>
      <Notification
        title="A alteração de responsável financeiro irá refletir apenas nesse contrato"
        description={`1.Caso esse responsável tenha mais de um contrato, a alteração não será refletida nos demais.\n2. Parcelas desse contrato com o vencimento no d͟i͟a͟ ͟d͟e͟ ͟h͟o͟je͟, não terão faturas atualizadas.`}
        variation="warning"
      />
    </GuardianHeader>
  )
}

export default ChangeGuardianHeader
