import { useGetContract } from '@/escolas/hooks/useGetContract'
import { Installment, PathParams } from '@/shared/interfaces'
import { formatCPF, formatPhoneNumber, formatDate } from '@/shared/utils'
import { formatZipcode } from '@/shared/utils/zipcodeFormatters'
import Box from '@material-ui/core/Box'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import {
  ResumeDrawer,
  ResumeActionDrawerHeader,
  ResumeDialogContent,
  ResumeSectionBox,
  ResumeSubtilePrimary,
  ResumeSubtileSecondary,
  ResumeTextBox,
  ResumeTextPrimary,
  ResumeTextSecondary,
  ResumeTextBold,
  ResumeDivider,
} from './style'

const ChangeGuardianResume: FC = () => {
  const { contractId } = useParams<PathParams>()
  const { contract } = useGetContract(contractId)

  const { guardian, student, product, installments } = contract || {}

  const getInstalmentsDates = (installments: Installment[]) => {
    const dates = installments.reduce(
      (result, curr) => {
        if (curr.status === 'OPEN') {
          if (result.first === null) {
            result.first = curr.due_date
          } else {
            result.last = curr.due_date
          }
        }
        return result
      },
      { first: null, last: null }
    )
    return `${formatDate(dates.first, 'MMM/YY')}${
      dates.last ? ' até ' + formatDate(dates.last, 'MMM/YY') : ''
    }`
  }

  return (
    <ResumeDrawer data-testid="change-guardian-resume-container">
      <ResumeActionDrawerHeader />
      <ResumeDialogContent>
        <ResumeSectionBox>
          <ResumeTextBox display="flex" flexDirection="row" justifyContent="space-between">
            <Box mb={2}>
              <ResumeTextBold>O contrato será alterado a partir da(s) parcela(s):</ResumeTextBold>
            </Box>
            <ResumeTextPrimary>{getInstalmentsDates(installments)}</ResumeTextPrimary>
          </ResumeTextBox>
        </ResumeSectionBox>
        <ResumeDivider />
        <ResumeSectionBox>
          <ResumeTextBox display="flex" flexDirection="row" justifyContent="space-between">
            <Box mb={2}>
              <ResumeTextBold>Dados do aluno</ResumeTextBold>
            </Box>
            <ResumeSubtileSecondary>Nome do aluno</ResumeSubtileSecondary>
            <ResumeSubtilePrimary>{`${student?.name}`}</ResumeSubtilePrimary>
            <Box mt={1}>
              <ResumeSubtileSecondary>Produto contratado</ResumeSubtileSecondary>
              <ResumeSubtilePrimary>{`${product?.name}`}</ResumeSubtilePrimary>
            </Box>
          </ResumeTextBox>
        </ResumeSectionBox>
        <ResumeDivider />
        <ResumeSectionBox>
          <ResumeTextBox display="flex" flexDirection="row" justifyContent="space-between">
            <Box mb={2}>
              <ResumeTextBold>Dados do responsável atual</ResumeTextBold>
            </Box>
            <ResumeTextSecondary>CPF</ResumeTextSecondary>
            <ResumeTextPrimary>{`${formatCPF(guardian?.tax_id)}`}</ResumeTextPrimary>
            <Box mt={1}>
              <ResumeTextSecondary>Nome do responsável financeiro</ResumeTextSecondary>
              <ResumeTextPrimary>{`${guardian?.name}`}</ResumeTextPrimary>
            </Box>
            <Box mt={1}>
              <ResumeTextSecondary>Email</ResumeTextSecondary>
              <ResumeTextPrimary>{`${guardian?.email}`}</ResumeTextPrimary>
            </Box>
            <Box mt={1}>
              <ResumeTextSecondary>Celular</ResumeTextSecondary>
              <ResumeTextPrimary>{`${formatPhoneNumber(
                guardian?.phone_number
              )}`}</ResumeTextPrimary>
            </Box>
          </ResumeTextBox>
        </ResumeSectionBox>
        <ResumeDivider />
        <ResumeSectionBox>
          <ResumeTextBox display="flex" flexDirection="row" justifyContent="space-between">
            <Box mb={2}>
              <ResumeTextBold>Endereço do responsável atual</ResumeTextBold>
            </Box>
            <ResumeTextSecondary>CEP</ResumeTextSecondary>
            <ResumeTextPrimary>{`${formatZipcode(guardian?.address?.zip)}`}</ResumeTextPrimary>
            <Box mt={1}>
              <ResumeTextSecondary>Logradouro (rua, avenida, praça)</ResumeTextSecondary>
              <ResumeTextPrimary>{`${guardian?.address?.street}`}</ResumeTextPrimary>
            </Box>
            <Box mt={1}>
              <ResumeTextSecondary>Número</ResumeTextSecondary>
              <ResumeTextPrimary>{`${guardian?.address?.number}`}</ResumeTextPrimary>
            </Box>
            <Box mt={1}>
              <ResumeTextSecondary>Complemento (opcional)</ResumeTextSecondary>
              <ResumeTextPrimary>{`${guardian?.address?.additional_information}`}</ResumeTextPrimary>
            </Box>
            <Box mt={1}>
              <ResumeTextSecondary>Cidade</ResumeTextSecondary>
              <ResumeTextPrimary>{`${guardian?.address?.city}`}</ResumeTextPrimary>
            </Box>
            <Box mt={1}>
              <ResumeTextSecondary>Estado</ResumeTextSecondary>
              <ResumeTextPrimary>{`${guardian?.address?.state_code}`}</ResumeTextPrimary>
            </Box>
          </ResumeTextBox>
        </ResumeSectionBox>
      </ResumeDialogContent>
    </ResumeDrawer>
  )
}

export default ChangeGuardianResume
