import { Box } from '@material-ui/core'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 764px;
  flex: 1;
`

export const StyledButtonContainer = styled(Box)`
  display: flex;
  justify-content: right;
  width: 100%;
  cursor: pointer;
`

export const StyledButton = styled(Box).attrs({
  tabindex: 0,
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  height: 24px;
  color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1px;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[10]};
  padding: 2px 16px 2px 8px;

  position: relative;
  bottom: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[20]};
  }

  &:focus {
    outline: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[0]};
    box-shadow: 0px 0px 0px 3px ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[30]},
      0px 0px 0px 1px ${({ theme }) => theme.primitiveTokens.colors.gray[0]};
  }

  svg {
    margin-right: 4px;
  }
`
