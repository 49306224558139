import styled from 'styled-components'
import { MobileStepper } from '@material-ui/core'
import { SystemButton } from '@olaisaac/design-system'
import theme from '@/shared/theme'

const { spacing } = theme.primitiveTokens

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: ${spacing[6]};
  margin-bottom: ${spacing[5]};
`

export const SliderContainer = styled.div`
  display: flex;
  margin-right: 4rem;
`

export const CarouselStepper = styled(MobileStepper)`
  background-color: transparent;

  .MuiMobileStepper-dots {
  }

  .MuiMobileStepper-dot {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[20]};
    transition: ${({ theme }) => theme.transitions.duration.standard}ms
      ${({ theme }) => theme.transitions.easing.easeInOut} 0ms;
  }

  .MuiMobileStepper-dotActive {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
    width: 16px;
    height: 8px;
    border-radius: 5px;
  }
`

export const StyledSystemButton = styled(SystemButton)`
  color: ${({ theme }) => theme.primitiveTokens.colors.gray[30]};

  &:hover {
    color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
    background-color: transparent;
  }

  &:disabled {
    color: ${({ theme }) => theme.primitiveTokens.colors.gray[10]};
  }
`
