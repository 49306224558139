import { Typography } from '@olaisaac/design-system'
import styled from 'styled-components'
import { Skeleton } from '@material-ui/lab'

const ItemContainer = styled.div`
  &:not(:last-child) {
    border-right: 1px solid ${props => props.theme.primitiveTokens.colors.gray[10]};
    padding-right: 24px;
  }
`

const PersonalDataItem = ({
  title,
  text,
  isLoading,
}: {
  isLoading: boolean
  text: string
  title: string
}) => {
  return (
    <ItemContainer>
      <Typography variation="bodySmall" color="secondary">
        {title}
      </Typography>
      {isLoading ? (
        <Skeleton height={32} width={96} />
      ) : (
        <Typography variation="bodyLarge">{text}</Typography>
      )}
    </ItemContainer>
  )
}

const PersonalDataContainer = styled.div`
  display: flex;
  gap: 24px;
  margin: 24px 0 32px;
`

export type PersonalData = {
  guardianName: string
  productName: string
  referenceYear: string
  studentName: string
}

type Props = {
  data: PersonalData
  isLoading: boolean
}

/**
 * @deprecated This component will be deprecated after the EEMAT-727-ENABLE_UNIFIED_CONTRACT_EDITING rollout
 */
export const PersonalData = ({ data, isLoading }: Props) => {
  return (
    <div>
      <Typography variation="headlineDesktopXsmall">Produto e dados cadastrais</Typography>
      <PersonalDataContainer>
        <PersonalDataItem title="Período letivo" text={data.referenceYear} isLoading={isLoading} />
        <PersonalDataItem title="Produto" text={data.productName} isLoading={isLoading} />
        <PersonalDataItem title="Aluno" text={data.studentName} isLoading={isLoading} />
        <PersonalDataItem
          title="Responsável financeiro"
          text={data.guardianName}
          isLoading={isLoading}
        />
      </PersonalDataContainer>
    </div>
  )
}
