import { Box, CircularProgress } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'

import { StyledContainer, StyledMain } from './styles'
import { useEffect } from 'react'
import { DEFAULT_AWAITING_TIME } from '../constants'
import { useHistory, useLocation } from 'react-router-dom'

export default function Awaiting() {
  const { search, pathname } = useLocation()
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      const urlParams = pathname.split('/')
      const path = urlParams.slice(0, urlParams.length - 1).join('/') + '/parcelas'
      history.push({ pathname: path, search })
    }, DEFAULT_AWAITING_TIME)
  }, [])

  return (
    <StyledMain>
      <StyledContainer>
        <Box textAlign="center" position="relative" zIndex={1}>
          <CircularProgress />
          <Box mt={4}>
            <Typography variation="headlineDesktopSmall">
              Aguarde enquanto processamos a criação do contrato...
            </Typography>
          </Box>
        </Box>
      </StyledContainer>
    </StyledMain>
  )
}
