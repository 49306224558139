import RenegotiationContent from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/RenegotiationContent'
import { NegotiationDrawerContainerProps } from './types'
import { useState } from 'react'
import { ActionDrawerHeader } from '@olaisaac/design-system'

export const RenegotiationDrawerContainer = ({
  receivableId,
  receivables,
  onClose,
  onBack,
  onFinish,
  orderReference,
  successCallback,
}: NegotiationDrawerContainerProps) => {
  const [, setSelectedReceivableId] = useState<string>(receivableId)

  return (
    <>
      <ActionDrawerHeader
        title={`Parcela ${orderReference}`}
        subtitle="Renegociar"
        onClose={onClose}
        onBack={onBack}
      />
      <RenegotiationContent
        onFinish={onFinish}
        receivables={receivables}
        selectedReceivableId={receivableId}
        setSelectedReceivableId={setSelectedReceivableId}
        successCallback={successCallback}
      />
    </>
  )
}
