import { useCallback, useEffect, useMemo, useState } from 'react'

import { useQuery } from '../useQuery'

import { countSelectedFilters } from '@/modules/report/hooks/useEnrollmentPayoutReportFilter/utils/countSelectedFilters'

import {
  StudentsContractsFilterType,
  ContractsFilterHookType,
  StudentsContractsFilterQueryParams,
  StudentsContractsFilterObjectKeys,
  GuardiansContractsFilterObjectKeys,
  GuardiansContractsFilterType,
  GetFiltersFromUrlReturnType,
  GuardiansContractsFilterQueryParams,
} from './types'

/**
 * TODO:
 *  - Handle name and taxId filters
 *
 * Custom hook to manage contracts page filter
 */
export const useContractsFilter = () => {
  const { query, setMultipleQueryParams } = useQuery()

  const getFiltersFromUrl = <T extends 'guardians' | 'students'>(
    filterType: T
  ): GetFiltersFromUrlReturnType[T] => {
    const parseQueryParamArray = (
      paramName: StudentsContractsFilterQueryParams | GuardiansContractsFilterQueryParams
    ) => {
      return query.get(paramName)?.split(',') ?? []
    }

    if (filterType === 'guardians') {
      const data: Record<GuardiansContractsFilterObjectKeys, string[]> = {
        contractStatus: parseQueryParamArray('guardians_contract_status'),
      }

      return (data as unknown) as GetFiltersFromUrlReturnType[T]
    }

    const data: Record<StudentsContractsFilterObjectKeys, string[]> = {
      contractStatus: parseQueryParamArray('contract_status'),
      debtStatus: parseQueryParamArray('debt_status'),
      productId: parseQueryParamArray('product_id'),
    }

    return (data as unknown) as GetFiltersFromUrlReturnType[T]
  }

  const [guardiansFilter, setGuardiansFilter] = useState<GuardiansContractsFilterType>(() =>
    getFiltersFromUrl('guardians')
  )
  const [studentsFilter, setStudentsFilter] = useState<StudentsContractsFilterType>(() =>
    getFiltersFromUrl('students')
  )

  useEffect(() => {
    const checkIfHasChanges = (
      urlFilter: GuardiansContractsFilterType | StudentsContractsFilterType,
      stateFilter: GuardiansContractsFilterType | StudentsContractsFilterType
    ) => {
      let hasChanges = false

      Object.keys(stateFilter).every(key => {
        if (stateFilter[key].join(',') !== urlFilter[key].join(',')) {
          hasChanges = true

          return false
        }

        return true
      })

      return hasChanges
    }

    const urlGuardiansFilter = getFiltersFromUrl('guardians')
    const urlStudentsFilter = getFiltersFromUrl('students')

    const hasChangesOnGuardiansFilter = checkIfHasChanges(urlGuardiansFilter, guardiansFilter)
    const hasChangesOnStudentsFilter = checkIfHasChanges(urlStudentsFilter, studentsFilter)

    hasChangesOnGuardiansFilter && setGuardiansFilter(urlGuardiansFilter)
    hasChangesOnStudentsFilter && setStudentsFilter(urlStudentsFilter)
  }, [query])

  const updateFilter = useCallback(
    <T extends 'guardians' | 'students'>(
      filterType: T,
      updatedFilter: GetFiltersFromUrlReturnType[T]
    ) => {
      let data: Array<{
        name: StudentsContractsFilterQueryParams | GuardiansContractsFilterQueryParams
        value: string
      }>

      if (filterType === 'guardians') {
        const updatedGuardiansFilter = updatedFilter as GuardiansContractsFilterType

        data = [
          {
            name: 'guardians_contract_status',
            value: updatedGuardiansFilter.contractStatus.join(','),
          },
        ]

        setMultipleQueryParams(data)
        return
      } else {
        updatedFilter as GetFiltersFromUrlReturnType[T]
      }

      const updatedStudentsFilter = updatedFilter as StudentsContractsFilterType

      data = [
        { name: 'contract_status', value: updatedStudentsFilter.contractStatus.join(',') },
        { name: 'debt_status', value: updatedStudentsFilter.debtStatus.join(',') },
        { name: 'product_id', value: updatedStudentsFilter.productId.join(',') },
      ]

      setMultipleQueryParams(data)
    },
    [setMultipleQueryParams]
  )

  const clearFilter = useCallback(
    (filterType: 'guardians' | 'students') => {
      let data: Array<{
        name: StudentsContractsFilterQueryParams | GuardiansContractsFilterQueryParams
        value: null
      }>

      if (filterType === 'guardians') {
        data = [{ name: 'guardians_contract_status', value: null }]
      } else {
        data = [
          { name: 'contract_status', value: null },
          { name: 'debt_status', value: null },
          { name: 'product_id', value: null },
        ]
      }

      setMultipleQueryParams(data)
    },
    [setMultipleQueryParams]
  )

  const studentsFilterCount = useMemo(() => countSelectedFilters(studentsFilter), [studentsFilter])

  const guardiansFilterCount = useMemo(() => countSelectedFilters(guardiansFilter), [
    guardiansFilter,
  ])

  const contractsFilterHookValue: ContractsFilterHookType = useMemo(
    () => ({
      studentsFilter,
      studentsFilterCount,
      guardiansFilter,
      guardiansFilterCount,
      updateFilter,
      clearFilter,
    }),
    [
      studentsFilter,
      studentsFilterCount,
      guardiansFilter,
      guardiansFilterCount,
      updateFilter,
      clearFilter,
    ]
  )

  return contractsFilterHookValue
}
