import { FC } from 'react'
import { Button, Chip, ButtonProps } from '@olaisaac/design-system'
import { Box } from '@material-ui/core'
import { FilterListRounded } from '@material-ui/icons'

type FilterButtonProps = ButtonProps & {
  onClick: () => void
  selectedFilters?: number
  showFilterDrawer: boolean
}

export const FilterReportButton: FC<FilterButtonProps> = ({
  onClick,
  showFilterDrawer,
  selectedFilters = 0,
  ...props
}) => {
  return (
    <Button
      onClick={onClick}
      variation="ghost"
      startIcon={<FilterListRounded />}
      data-testid="filter-button"
      style={{
        backgroundColor: showFilterDrawer ? '#3D4ED714' : '',
      }}
      {...props}
    >
      <span>Filtrar</span>

      {selectedFilters > 0 && (
        <Box ml="0.5rem">
          <Chip variation="blue" label={`${selectedFilters}`} />
        </Box>
      )}
    </Button>
  )
}
