import { Discount, InstallmentStatuses, InstallmentType } from '@/shared/interfaces'
import { roundCents } from '@/shared/utils'
import dayjs from 'dayjs'
import { equals } from 'ramda'
import { Installment, NoEditReason } from '@/modules/contract/services/types'

const MAXIMUM_NUMBER_OF_DISCOUNTS = 3
export const MINIMUM_AMOUNT_CENTS = 100
export const MAXIMUM_AMOUNT = 3600000
export const MINIMUM_DISCOUNT_AMOUNT_CENTS = 1

export enum DiscountRule {
  DUE_PAYMENT = 'DUE_PAYMENT',
  EARLY_PAYMENT = 'EARLY_PAYMENT',
  PERPETUAL = 'PERPETUAL',
}

export const DiscountRuleLabels = {
  [DiscountRule.PERPETUAL]: {
    name: 'Desconto permanente',
    description: 'O desconto será aplicado, independente da data de pagamento',
  },
  [DiscountRule.DUE_PAYMENT]: {
    name: 'Desconto para pagamento em dia',
    description: 'O desconto só será aplicado se o pagamento ocorrer até a data de vencimento',
  },
  [DiscountRule.EARLY_PAYMENT]: {
    name: 'Desconto para pagamento antecipado',
    description:
      'O desconto só será aplicado se o pagamento ocorrer X dias antes da data de vencimento',
  },
}

const noEditReasonText: Record<NoEditReason, string> = {
  [NoEditReason.CANCELED]: 'parcelas canceladas',
  [NoEditReason.HAS_PAID_PAYOUT]: 'parcelas com competência menor ou igual ao mês atual',
  [NoEditReason.NEGOTIATION]: 'parcelas renegociadas',
  [NoEditReason.OVERDUE]: 'parcelas com competência menor ou igual ao mês atual',
  [NoEditReason.PAID]: 'parcelas pagas',
  [NoEditReason.OTHER]: 'esta parcela',
  [NoEditReason.ENROLLMENT]:
    'parcelas de pré-matricula em situação a vencer ou vencida a partir desta página de edições',
}

export const splitOrderReference = (orderReference: string) => {
  const splittedOrderReference = orderReference.split('/')
  return `${splittedOrderReference[0]} de ${splittedOrderReference[1]}`
}

export const filterSelectedInstallments = (
  installments: Installment[],
  selectedIDs: uuid[]
): Installment[] => {
  return installments.filter(i => selectedIDs.includes(i.backoffice_installment_id))
}

export const calculateCommomAmount = (installments: Installment[]): cents => {
  if (installments.length === 0) {
    return 0
  }

  if (installments.length === 1) {
    return installments[0].amount
  }

  for (let i = 1; i < installments.length; i++) {
    if (installments[0].amount !== installments[i].amount) {
      return 0
    }
  }

  return installments[0].amount
}

export const calculateTotalDiscount = (discounts: Discount[]): cents => {
  return discounts.reduce((total, curr) => total + curr.amount, 0)
}

export const validateEditedAmount = (editedAmount: cents, installments: Installment[]): boolean => {
  return installments.every(
    i => editedAmount - calculateTotalDiscount(i.discounts) >= MINIMUM_AMOUNT_CENTS
  )
}

const isMultipleInstallmentTypeSelected = (installments: Installment[]) => {
  return (
    installments.some(i => i.receivable_type === InstallmentType.ENROLLMENT) &&
    installments.some(i => i.receivable_type === InstallmentType.TUITION)
  )
}
export const canAddDiscount = (
  installments: Installment[],
  installmentIDsToCancel: uuid[]
): [boolean, string] => {
  if (installments.some(i => installmentIDsToCancel.includes(i.backoffice_installment_id))) {
    return [false, 'Não é possível aplicar descontos em parcelas canceladas']
  }

  const blockedInstallment = installments.find(i => !i.can_edit)
  if (isMultipleInstallmentTypeSelected(installments)) {
    return [false, 'Não é possível editar tipos de parcelas diferentes']
  }

  if (blockedInstallment) {
    if (blockedInstallment.no_edit_reason === '') return [false, 'Não é possível aplicar descontos']
    return [
      false,
      `Não é possível aplicar descontos em ${noEditReasonText[blockedInstallment.no_edit_reason]}`,
    ]
  }

  const maxNumberOfDiscounts = Math.max(...installments.map(i => i.discounts.length))

  const canEdit = maxNumberOfDiscounts < MAXIMUM_NUMBER_OF_DISCOUNTS

  if (!canEdit) {
    return [false, 'Cada parcela pode ter no máximo 3 descontos']
  }

  return [true, '']
}

const isPendingEdition = (installments: Installment[]): boolean => {
  return installments.every(i => i.status === InstallmentStatuses.PENDING)
}

export const canEditAmount = (
  installments: Installment[],
  enableEditAmountFF = false
): [boolean, string] => {
  if (!enableEditAmountFF && !isPendingEdition(installments)) {
    return [false, 'Essa edição ainda não está disponível']
  }
  if (isMultipleInstallmentTypeSelected(installments)) {
    return [false, 'Não é possível editar tipos de parcelas diferentes']
  }

  const blockedInstallment = installments.find(i => !i.can_edit)

  if (blockedInstallment) {
    if (blockedInstallment.no_edit_reason === '') return [false, 'Não é possível editar valores']
    return [
      false,
      `Não é possível editar valores em ${noEditReasonText[blockedInstallment.no_edit_reason]}`,
    ]
  }

  const numberOfDiscounts = Math.max(...installments.map(i => i.discounts.length))

  const canEdit = numberOfDiscounts === 0

  if (!canEdit) {
    return [false, 'Você não pode alterar o valor de parcelas que possuem descontos aplicados']
  }

  return [true, '']
}

export const validateDiscountAmount = (
  newDiscountAmount: cents,
  installments: Installment[]
): boolean => {
  return installments.every(
    i => i.amount - calculateTotalDiscount(i.discounts) - newDiscountAmount >= MINIMUM_AMOUNT_CENTS
  )
}

export const applyPercentageDiscount = (percentage: number, installmentAmount: number) => {
  return roundCents((installmentAmount * percentage) / 100)
}

export const validateDiscountPercentage = (
  newDiscountPercentage: number,
  installments: Installment[]
): boolean => {
  return installments.every(
    i =>
      i.amount -
        calculateTotalDiscount(i.discounts) -
        applyPercentageDiscount(newDiscountPercentage, i.amount) >=
      MINIMUM_AMOUNT_CENTS
  )
}

export const validateDatesInUniqueMonths = (
  dueDates: datestring[],
  selectedInstallments: Installment[]
): boolean => {
  if (selectedInstallments.some(i => i.receivable_type === InstallmentType.ENROLLMENT)) return true
  const visitedMonths = new Set()

  for (const dueDate of dueDates) {
    const hash = dayjs.utc(dueDate).format('MMYYYY')

    if (visitedMonths.has(hash)) {
      return false
    }

    visitedMonths.add(hash)
  }

  return true
}

export const calculateHasUnsavedChanges = (
  savedInstallments: Installment[],
  currentInstallments: Installment[],
  installmentIDsToCancel: uuid[]
): boolean => {
  return !equals(savedInstallments, currentInstallments) || installmentIDsToCancel.length > 0
}

/**
 * Returns whether the due date of the selected installments can be edited.
 * Returns false and a reason otherwise.
 *
 * The user cannot edit the due dates of the selected installments under the following conditions:
 * - Both tuition and enrollment installments are selected
 * - Any canceled installment is selected
 * - Have not selected every tuition that can be edited
 * - Have selected any installment that cannot be edit
 */
export const canEditDueDates = (
  installments: Installment[],
  selectedIDs: string[],
  installmentIDsToCancel: uuid[]
): [boolean, string] => {
  const selectedInstallments = filterSelectedInstallments(installments, selectedIDs)

  const someCanceledInstallmentSelected = selectedInstallments.some(i =>
    installmentIDsToCancel.includes(i.backoffice_installment_id)
  )
  if (someCanceledInstallmentSelected) {
    return [false, 'Não é possível editar vencimentos de parcelas canceladas']
  }

  const installmentsToEdit = installments.filter(i =>
    selectedIDs.includes(i.backoffice_installment_id)
  )

  if (isMultipleInstallmentTypeSelected(installmentsToEdit)) {
    return [false, 'Não é possível editar tipos de parcelas diferentes']
  }

  const blockedInstallment = selectedInstallments.find(i => !i.can_edit)
  if (blockedInstallment) {
    if (blockedInstallment.no_edit_reason === '')
      return [false, 'Não é possível editar vencimentos']
    return [
      false,
      `Não é possível editar vencimentos de ${noEditReasonText[blockedInstallment.no_edit_reason]}`,
    ]
  }

  const onlyEnrollmentInstallmentsSelected = selectedInstallments.every(
    i => i.receivable_type === InstallmentType.ENROLLMENT
  )
  if (onlyEnrollmentInstallmentsSelected) {
    return [true, '']
  }

  const tuitionInstallmentsIDs = installments.filter(
    installment => installment.can_edit && installment.receivable_type === InstallmentType.TUITION
  )

  const canEditTuition = tuitionInstallmentsIDs.every(installmentItem => {
    return selectedIDs.some(
      selectedItem => selectedItem === installmentItem.backoffice_installment_id
    )
  })

  if (!canEditTuition) {
    return [
      false,
      'Para editar vencimentos, selecione todas as mensalidades de competência maior que o mês atual',
    ]
  }

  return [true, '']
}

export const canCancelInstallment = (i: Installment, enableCancelFF = true): boolean => {
  if (!enableCancelFF) {
    return false
  }
  const cancelEnabledInType = {
    [InstallmentType.TUITION]: true,
    [InstallmentType.ISAACPAY]: true,
    [InstallmentType.ENROLLMENT]: true,
    [InstallmentType.NEGOTIATION]: false,
  }

  if (!(i.receivable_type in cancelEnabledInType)) return false

  if (!cancelEnabledInType[i.receivable_type]) {
    return false
  }

  const allowedStatus = new Set([
    InstallmentStatuses.OPEN,
    InstallmentStatuses.OVERDUE,
    InstallmentStatuses.DUE_TODAY,
    InstallmentStatuses.PAID,
    InstallmentStatuses.PENDING,
  ])

  if (i.status === InstallmentStatuses.PAID && i.receivable_type === InstallmentType.TUITION)
    return false

  return allowedStatus.has(i.status)
}

export const canCancel = (
  installments: Installment[],
  {
    enableCancelFF = true,
    enableEnrolmmentCancelFF = false,
    schoolBlockedForCancel = false,
    isAdmin = false,
  } = {}
): [boolean, string] => {
  if (schoolBlockedForCancel && !isAdmin) {
    return [false, 'Essa ação não está disponível']
  }

  const hasAnyEnrollment = installments.some(i => i.receivable_type === InstallmentType.ENROLLMENT)
  const hasAnyPending = installments.some(i => i.status === InstallmentStatuses.PENDING)

  if (
    (hasAnyEnrollment && !enableEnrolmmentCancelFF) ||
    (!enableEnrolmmentCancelFF && hasAnyPending)
  )
    return [false, 'Não é possível cancelar as parcelas selecionadas']
  if (isMultipleInstallmentTypeSelected(installments)) {
    return [false, 'Não é possível editar tipos de parcelas diferentes']
  }

  const enabled = installments.every(i => canCancelInstallment(i, enableCancelFF))

  if (!enabled) {
    return [false, 'Não é possível cancelar as parcelas selecionadas']
  }

  return [true, '']
}

export const canSelectRow = (installment: Installment, enableCancelFF = true): boolean => {
  return installment.can_edit || canCancelInstallment(installment, enableCancelFF)
}

/**
 * Returns whether the contract must be canceled. If every cancellable installment
 * is selected and at least one of them is open, then the contract must be canceled.
 */
export const cancelContract = (
  installments: Installment[],
  installmentIDsToCancel: uuid[]
): boolean => {
  const cancellableInstallmentIDs = installments
    .filter(i => canCancelInstallment(i))
    .filter(i => i.receivable_type === InstallmentType.TUITION)
    .map(i => i.backoffice_installment_id)

  const selectedEveryCancellableInstallment = cancellableInstallmentIDs.every(i =>
    installmentIDsToCancel.includes(i)
  )

  const installmentsToCancel = installments.filter(i =>
    installmentIDsToCancel.includes(i.backoffice_installment_id)
  )

  const hasAnyOpenInstallmentToCancel =
    installmentsToCancel.filter(
      i => i.status === InstallmentStatuses.OPEN || i.status === InstallmentStatuses.DUE_TODAY
    ).length > 0

  const hasAnyEnrollment = installmentsToCancel.some(
    i => i.receivable_type === InstallmentType.ENROLLMENT
  )

  return selectedEveryCancellableInstallment && hasAnyOpenInstallmentToCancel && !hasAnyEnrollment
}
