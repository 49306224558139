import { useHistory, useLocation } from 'react-router-dom'
import { StyledTab, StyledTabs } from './styles'
import { useEffect, useState } from 'react'
import { replaceTabInPathname } from './utils/replaceTabInPathname'
import { useQuery } from '@/escolas/hooks'
import { GuardianDetailsTabs } from './constants'

import { GuardianDetailsContractsTab } from '../GuardianDetailsContractsTab/GuardianDetailsContractsTab'
import { GuardianDetailsFinancialSummaryTab } from '../GuardianDetailsFinancialSummaryTab/GuardianDetailsFinancialSummaryTab'

import { GuardianDetailsCard } from './components/GuardianDetailsCard'
import { getParamGuardianId } from './utils/getParamGuardianId'
import { useGuardianQuery } from './hooks/useGuardianQuery'
import {
  StateQueryParamsEnum,
  useGuardiansStateQueryParams,
} from '../hooks/useGuardiansStateQueryParams'
import { GuardianDetailsInstallmentsTab } from '../GuardianDetailsInstallmentsTab/GuardianDetailsInstallmentsTab'
import EditRegistrationDrawer from './features/EditRegistrationDrawer/EditRegistrationDrawer'

import { PageWithTableControlBar } from '@/escolas/components/layout/PageWithTableControlBar'

import { FeedbacksCashier } from './features/FeedbacksCashier/FeedbacksCashier'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'
import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { UserGuidingGuidesIds } from '@/shared/hooks/useUserGuidingTrigger'
import { UnleashFlags, useUnleashFlag } from '@/shared/hooks'
import { GuardiansQueryParamsNameEnum } from '../constants'
import { GuardianDetailsTab } from './types'
import { GuardianDetailsFooter } from './features/GuardianDetailsFooter/GuardianDetailsFooter'
import { ContractProvider } from '@/escolas/contexts/contractContext'
import { queryClient } from '@/modules/app/contexts/GlobalProviders/ReactQuery'
import { PendingEnrollmentProcessingAlert } from './components/PendingEnrollmentProcessingAlert'
import { GuardianDetailsHeader } from './components/GuardianDetailsHeader'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

const GuardianDetails = () => {
  const history = useHistory()
  const { query, setOnQueryParam } = useQuery()
  const { pathname, search } = useLocation()
  const { school } = useSelectedSchool()

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  const isGuardianSummaryEnabled = useUnleashFlag(
    UnleashFlags.REG_2202_ENABLE_GUARDIAN_SUMMARY_PAGE
  )

  const guardianId = getParamGuardianId()

  const {
    guardian,
    isFetchGuardianLoading,
    isFetchGuardianFetched,
    refetchGuardian,
  } = useGuardianQuery({ guardianId, schoolId: school.id })

  const {
    statesQueryParams,
    handleEditRegistrationDrawerClose,
    openEditRegistrationDrawer,
  } = useGuardiansStateQueryParams()

  const [showGeneratingInvoicesLoading, setShowGeneratingInvoicesLoading] = useState<boolean>(false)
  const [
    showPendingEnrollmentProcessingAlert,
    setShowPendingEnrollmentProcessingAlert,
  ] = useState<boolean>(false)
  const abrirCadastro = Boolean(statesQueryParams.get(StateQueryParamsEnum.ABRIR_CADASTRO))

  useSetPageTitle(guardian ? `${guardian.data.name} - isaac` : `Responsáveis - isaac`)

  const refreshData = () => {
    refetchGuardian()
    handleEditRegistrationDrawerClose()
  }

  const [activeTab, setActiveTab] = useState<GuardianDetailsTabs>(() => {
    const tab = Object.values(GuardianDetailsTabs).find(substring => pathname.includes(substring))
    if (isGuardianSummaryEnabled) return tab ?? GuardianDetailsTabs.RESUMO
    return pathname.includes(GuardianDetailsTabs.CONTRATOS)
      ? GuardianDetailsTabs.CONTRATOS
      : GuardianDetailsTabs.PARCELAS
  })

  useEffect(() => {
    const foundTab = Object.values(GuardianDetailsTabs).find(substring =>
      pathname.includes(substring)
    )
    if (isGuardianSummaryEnabled) {
      setActiveTab(foundTab ?? GuardianDetailsTabs.RESUMO)
    } else {
      setActiveTab(
        pathname.includes(GuardianDetailsTabs.CONTRATOS)
          ? GuardianDetailsTabs.CONTRATOS
          : GuardianDetailsTabs.PARCELAS
      )
    }
  }, [pathname])

  const renderSummaryTab = isGuardianSummaryEnabled
    ? [
        {
          label: 'Resumo financeiro',
          value: GuardianDetailsTabs.RESUMO,
        },
      ]
    : []
  const tabs: Array<GuardianDetailsTab> = [
    ...renderSummaryTab,
    {
      label: 'Parcelas',
      value: GuardianDetailsTabs.PARCELAS,
    },
    {
      label: 'Contratos',
      value: GuardianDetailsTabs.CONTRATOS,
    },
  ]

  const sendEvent = (scope: EventDispatcherEventScopes, name: EventDispatcherEvents) => {
    isInitialized &&
      eventDispatcherClient.sendEvent({
        scope,
        name,
        action: 'click',
      })
  }

  const getEventName = (value: GuardianDetailsTabs) =>
    value === GuardianDetailsTabs.PARCELAS
      ? EventDispatcherEvents.GUARDIAN_INSTALLMENTS_LIST
      : EventDispatcherEvents.GUARDIAN_CONTRACT_LIST

  const handleActiveTab = (value: GuardianDetailsTabs) => {
    setActiveTab(value)

    const eventName = getEventName(value)

    sendEvent(EventDispatcherEventScopes.GUARDIANS, eventName)

    const newPath = replaceTabInPathname(pathname, value)

    history.replace({
      pathname: newPath,
      search: search,
    })
  }

  const guideId = isIntegratedSchool
    ? UserGuidingGuidesIds.GUARDIAN_DETAILS_PAGE_INTEGRATED_SCHOOL
    : UserGuidingGuidesIds.GUARDIAN_DETAILS_PAGE

  useEffect(() => {
    setOnQueryParam(guideId, GuardiansQueryParamsNameEnum.guide)
  }, [isIntegratedSchool])

  const shouldRefetch = Boolean(query.get('refetch'))

  useEffect(() => {
    if (shouldRefetch) {
      queryClient.invalidateQueries({
        predicate: query => {
          const queryKeyName = String(query.queryKey[0])
          return Boolean(queryKeyName.match(/^guardian-details-.*/g))
        },
      })
    }
  }, [shouldRefetch])

  useEffect(() => {
    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.GUARDIANS,
        name: EventDispatcherEvents.PAGE_VIEWED,
        action: 'page_view',
        customProperties: {
          $name: 'Resumo financeiro',
        },
      })
    }
  }, [])

  return (
    <ContractProvider>
      <GuardianDetailsHeader
        schoolSlug={school.slug}
        guardianId={guardianId}
        isLoading={isFetchGuardianLoading}
        openEditRegistrationDrawer={openEditRegistrationDrawer}
      />
      <GuardianDetailsCard
        guardian={guardian?.data}
        isLoading={isFetchGuardianLoading}
        openEditRegistrationDrawer={openEditRegistrationDrawer}
      />

      {activeTab === GuardianDetailsTabs.PARCELAS && (
        <FeedbacksCashier setShowGeneratingInvoicesLoading={setShowGeneratingInvoicesLoading} />
      )}

      {activeTab === GuardianDetailsTabs.PARCELAS && showPendingEnrollmentProcessingAlert && (
        <PendingEnrollmentProcessingAlert />
      )}

      <StyledTabs variant="scrollable" value={activeTab} aria-label="Escolha a visualização">
        {tabs.map(tab => (
          <StyledTab
            key={tab.label}
            label={tab.label}
            value={tab.value}
            onClick={() => handleActiveTab(tab.value)}
          />
        ))}
      </StyledTabs>
      {activeTab === GuardianDetailsTabs.RESUMO && (
        <>
          <GuardianDetailsFinancialSummaryTab changeActiveTab={setActiveTab} />
          <GuardianDetailsFooter />
        </>
      )}
      {activeTab === GuardianDetailsTabs.CONTRATOS && <GuardianDetailsContractsTab />}
      {activeTab === GuardianDetailsTabs.PARCELAS && (
        <PageWithTableControlBar>
          <GuardianDetailsInstallmentsTab
            showGeneratingInvoicesLoading={showGeneratingInvoicesLoading}
            setShowPendingEnrollmentProcessingAlert={setShowPendingEnrollmentProcessingAlert}
          />
          <GuardianDetailsFooter />
        </PageWithTableControlBar>
      )}

      {abrirCadastro && isFetchGuardianFetched && (
        <EditRegistrationDrawer
          isOpen={Boolean(abrirCadastro)}
          onClose={handleEditRegistrationDrawerClose}
          guardian={guardian?.data}
          refreshData={refreshData}
        />
      )}
    </ContractProvider>
  )
}

export default GuardianDetails
