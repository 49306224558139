import React, { createContext, useState, useMemo } from 'react'

type GuardianNavigationContextType = {
  pageToReturn?: string
  setPageToReturn: (pageToReturn?: string) => void
}

export const GuardianNavigationContext = createContext<GuardianNavigationContextType>(
  {} as GuardianNavigationContextType
)

export const GuardianNavigationProvider = ({ children }: { children: React.ReactNode }) => {
  const [pageToReturn, setPageToReturn] = useState<string>()

  const contextValue = useMemo(
    () => ({
      pageToReturn,
      setPageToReturn,
    }),
    [pageToReturn, setPageToReturn]
  )

  return (
    <GuardianNavigationContext.Provider value={contextValue}>
      {children}
    </GuardianNavigationContext.Provider>
  )
}
