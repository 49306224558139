import {
  APIResponse,
  CommonMetadata,
  DebtStatus,
  Discount,
  Guardian,
  InstallmentType,
  PaginationRequest,
  Product,
  ReEnrollmentSubStatus,
  ReenrollmentStatus,
} from 'src/shared/interfaces'

export type EnrollmentCycleRequest = {
  enrollmentStatus?: Omit<ReenrollmentStatus, ReenrollmentStatus.GATE_ERROR>[]
  enrollmentSubStatus?: ReEnrollmentSubStatus[]
  name?: string
  pagination: PaginationRequest
  referenceYears?: string[]
  schoolId: uuid
  studentId?: uuid
  studentIds?: uuid[]
}

export type EnrollmentCycleResponse = APIResponse<EnrollmentCycleItem[]>
export type GetEnrollmentCycleSituationResponse = APIResponse<Situation>

export type Situation = {
  is_reenrollable: boolean
  status: ReenrollmentStatus
  sub_status: ReEnrollmentSubStatus[]
}

export type EnrollmentCycleItem = {
  debt_status: DebtStatus
  is_reenrollable: boolean
  main_contract_id: uuid
  main_guardian: Guardian
  main_product: Product
  other_guardians: Guardian[]
  other_products: Product[]
  started_enrollment: boolean
  student_id: uuid
  student_name: string
  student_referral: string
  student_status: ReenrollmentStatus
  student_status_details: ReEnrollmentSubStatus[]
}

export type GetGuardiansRequest = {
  schoolId: uuid
  studentId?: uuid
  taxID?: string
}

export type GetGuardiansResponse = APIResponse<Guardian[]>

export type StatusSummaryRequest = {
  referenceYear: string
  schoolId: uuid
}

export type StatusSummaryResponse = APIResponse<{
  status: Partial<Record<ReenrollmentStatus, number>>
  total: number
}>

export type GetEnrollmentRequest = {
  contractId: uuid
  schoolId: uuid
}

export enum Status {
  CANCELED = 'CANCELED',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
}

export type Installment = CommonMetadata & {
  amount: number
  discounts: Discount[]
  due_date: datestring
  status: Status
  type: InstallmentType
  use_working_dates: boolean
}

export type Enrollment = CommonMetadata & {
  guardian_id: uuid
  installments: Installment[]
  product_id: uuid
  reference_year: string
  status: Status
  student_id: uuid
}

export type GetEnrollmentResponse = APIResponse<Enrollment>

export type CreateCampaignRequest = {
  end_date: datestring
  name: string
  notification_channels: {
    channel: 'EMAIL'
    enabled: boolean
    text: string
  }[]
  payment_plans: {
    amount: number
    due_date: datestring
    duration_months: number
    type: 'ENROLLMENT'
  }[]
  product_id: uuid
  reference_year: number
  school_enrollment_cycle_id: uuid
  source_products_ids: uuid[]
  start_date: datestring
  student_exclusions: uuid[]
}
