import { FC, useState } from 'react'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'

import {
  Title,
  Description,
  CardReason,
  LabelReason,
  DialogCustom,
  DialogContainer,
  CardTitle,
  ContainerActions,
  StyledTextArea,
  DialogWrapper,
} from './styled'
import { modalCustomProps } from './types'
import { Button } from '@gravity/button'
import { IconButton } from '@gravity/icon-button'

const ModalCustomDialog: FC<modalCustomProps> = ({
  isVisible,
  onClose,
  submitHandler,
  title,
  isConfirmDisabled,
  description,
  labelReason,
  backButton,
  confirmButton,
  size,
}) => {
  const [note, setNote] = useState<string>('')

  return (
    <DialogWrapper customSize={size}>
      <DialogCustom data-testid="feedback-dialog" open={isVisible} onClose={onClose} fullWidth>
        <DialogContainer>
          <CardTitle>
            <Title>{title}</Title>

            <IconButton onClick={onClose} size={1} color="accent" variant="ghost">
              <Icon path={mdiClose} />
            </IconButton>
          </CardTitle>

          {description && <Description>{description}</Description>}
        </DialogContainer>

        <CardReason>
          <LabelReason>{labelReason}</LabelReason>
          <StyledTextArea
            placeholder="Preencha as informações (opcional)"
            size={3}
            onChange={v => setNote(v.target.value)}
          />
        </CardReason>

        <ContainerActions>
          <Button variant={backButton.variation} onClick={onClose}>
            {backButton.label}
          </Button>
          <Button
            color={confirmButton.color}
            variant={confirmButton.variation}
            onClick={() => {
              submitHandler(note)
            }}
            disabled={isConfirmDisabled?.()}
          >
            {confirmButton.label}
          </Button>
        </ContainerActions>
      </DialogCustom>
    </DialogWrapper>
  )
}

export default ModalCustomDialog
