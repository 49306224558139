import { IconButton } from '@gravity/icon-button'
import { Icon } from '@mdi/react'
import { mdiClose, mdiArrowLeft } from '@mdi/js'

import { Text } from '@gravity/text'
import { Badge } from '@gravity/badge'
import { Heading } from '@gravity/heading'
import { Box } from '@material-ui/core'

import { InstallmentDrawerInfo } from '@/modules/guardians/InstallmentsDrawerContainer/NegotiationDrawer/types'

import { InstallmentDrawerHeaderInfo, InstallmentDrawerHeaderTitleWrapper } from './styles'

type InstallmentDrawerHeaderProps = {
  installment: InstallmentDrawerInfo
  onBack?: () => void
  onClose: () => void
  statusInfo?: {
    color?: 'accent' | 'error' | 'warning' | 'success'
    title: string
  }
  subTitle?: string
  title: string
}

export const InstallmentDrawerHeader = ({
  installment,
  statusInfo,
  title,
  subTitle,
  onClose,
  onBack,
}: InstallmentDrawerHeaderProps) => {
  return (
    <Box>
      {onBack && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <IconButton onClick={onBack} variant="ghost" size={1}>
            <Icon path={mdiArrowLeft} />
          </IconButton>
          <IconButton onClick={onClose} variant="ghost" size={1}>
            <Icon path={mdiClose} />
          </IconButton>
        </Box>
      )}

      {!onBack && (
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton onClick={onClose} variant="ghost" size={1}>
            <Icon path={mdiClose} />
          </IconButton>
        </Box>
      )}

      <InstallmentDrawerHeaderInfo variant="body-1-regular">{title}</InstallmentDrawerHeaderInfo>
      <InstallmentDrawerHeaderTitleWrapper>
        <Heading variant="heading-h3-medium">
          {`Parcela ${installment?.order_reference?.replace('/', ' de ')}`}
        </Heading>
      </InstallmentDrawerHeaderTitleWrapper>
      {statusInfo && (
        <Badge color={statusInfo?.color} variant="soft">
          {statusInfo?.title}
        </Badge>
      )}
      {subTitle && <Text variant="body-1-regular">{subTitle}</Text>}
    </Box>
  )
}
