import dayjs from 'dayjs'

import { useCampaignContext } from '@/modules/enrollment/contexts/CampaignContext'
import { useCreateCampaignMutation } from '@/modules/enrollment/hooks/queries/useCampaigns'
import { SchoolEnrollmentCycle } from '@/modules/enrollment/services/types'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'

export const useCreateCampaign = (enrollmentCycle: SchoolEnrollmentCycle) => {
  const {
    conditions,
    customEmailMsg,
    chosenProducts,
    nonParticipatingStudentsIds,
  } = useCampaignContext()
  const offers = conditions?.offers ? conditions.offers : []

  const { school } = useSelectedSchool()

  const createCampaignMutation = useCreateCampaignMutation()

  const createCampaign = () => {
    return createCampaignMutation.mutateAsync({
      schoolId: school.id,
      name: conditions.name,
      product_id: conditions.product.id,
      end_date: dayjs(conditions.end_date).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      start_date: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      reference_year: enrollmentCycle.reference_year,
      school_enrollment_cycle_id: enrollmentCycle.school_enrollment_cycle_id,
      payment_plans: offers.map(offer => ({
        due_date: offer.expirationDate.toISOString(),
        amount: offer.amount,
        type: 'ENROLLMENT',
        duration_months: 1,
      })),
      source_products_ids: chosenProducts.map(product => product.id),
      student_exclusions: nonParticipatingStudentsIds,
      notification_channels: [
        {
          channel: 'EMAIL',
          text: customEmailMsg,
          enabled: true,
        },
      ],
    })
  }

  return { createCampaign, isLoading: createCampaignMutation.isLoading }
}
