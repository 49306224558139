import styled from 'styled-components'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import theme from '@/shared/theme'

const { spacing } = theme.primitiveTokens

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const StatusDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  font-size: 1rem;
  margin-left: ${spacing[1]};
`
