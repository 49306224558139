import styled from 'styled-components'

import { Button } from '@gravity/button'
import { colors, spacing } from '@gravity/tokens'

export const OfferInfoWrapper = styled.div`
  border-top: 1px solid ${colors['colors-border-neutral-2']};
  margin: ${spacing[8]} 0;
  padding-top: ${spacing[8]};
  width: 100%;
`

export const OfferButton = styled(Button)`
  margin-top: ${spacing[2]};
  width: 100%;
`
