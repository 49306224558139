import { createContext, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { useApi } from '@/shared/hooks/useApi'
import { useNavigation, useQuery } from '@/escolas/hooks'

import { paramsEventDict } from '@/escolas/components/report/Chips/ChangesChip'

import {
  FilterDataProps,
  FilterReportContextProps,
  FilterType,
  FilterTypeEnum,
  FormInputsProps,
} from '@/escolas/components/report/FilterReportDrawer/types'

export const FilterReportContext = createContext({
  fetchData: () => null,
  filterData: [],
  filterFormState: null,
  filtersActiveList: [],
  isLoading: true,
  loadMore: () => null,
  queryEvents: '',
  queryProducts: '',
  totalFiltersActive: () => 0,
} as FilterReportContextProps)

export const FilterReportProvider: FC = ({ children }) => {
  const { api } = useApi()
  const { query } = useQuery()
  const { schoolId } = useNavigation()

  const [totalPerPage] = useState(4)
  const [isLoading, setIsLoading] = useState(true)
  const [filterData, setFilterData] = useState<FilterDataProps[]>([])

  const queryEvents = query.get(FilterTypeEnum.FILTER_EVENTS) || ''
  const queryProducts = query.get(FilterTypeEnum.FILTER_PRODUCTS) || ''

  const defaultEvents = queryEvents !== '' ? queryEvents.split(',') : []
  const defaultProducts = queryProducts !== '' ? queryProducts.split(',') : []

  const filterFormState = useForm<FormInputsProps>({
    defaultValues: {
      search: '',
      events: defaultEvents,
      products: defaultProducts,
    },
  })

  const eventsData = useMemo(
    () =>
      [
        ...Object.keys(paramsEventDict).map(key => ({
          id: key,
          label: paramsEventDict[key].text,
          type: FilterTypeEnum.FILTER_EVENTS,
        })),
      ].sort((a, b) => a.label.localeCompare(b.label)),
    [paramsEventDict]
  )

  const totalFiltersActive = useCallback(() => {
    return defaultEvents.length + defaultProducts.length
  }, [defaultEvents, defaultProducts])

  async function fetchData(search?: string) {
    try {
      setIsLoading(true)

      const response = await api.products.getList({
        page: 1,
        per_page: totalPerPage,
        school_id: schoolId,
        ...(search && { name: search }),
      })

      const products: FilterDataProps = {
        type: FilterTypeEnum.FILTER_PRODUCTS,
        title: 'Produtos',
        page: 1,
        total: response.pagination.total,
        data: [
          ...response.data.map(product => ({
            id: product.id,
            label: product.name,
            type: FilterTypeEnum.FILTER_PRODUCTS,
          })),
        ],
      }

      let events: FilterDataProps = {
        type: FilterTypeEnum.FILTER_EVENTS,
        title: 'Mudanças',
        page: 1,
        total: eventsData.length,
        data: [...eventsData],
      }

      if (search) {
        events = {
          ...events,
          data: [
            ...eventsData.filter(value => value.label.toLowerCase().includes(search.toLowerCase())),
          ],
        }
      }

      events = {
        ...events,
        total: events.data.length,
        data: [...events.data.slice(0, totalPerPage)],
      }

      const filterOrder = [events, products]

      setFilterData(filterOrder.filter(item => item.data.length > 0))
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  async function loadMore(type: FilterType) {
    const filter = filterData.find(item => item.type === type)
    const search = filterFormState.getValues().search || ''

    if (type === FilterTypeEnum.FILTER_PRODUCTS) {
      try {
        const response = await api.products.getList({
          name: search || '',
          page: filter.page + 1,
          per_page: totalPerPage,
          school_id: schoolId,
        })

        const productsData = [
          ...response.data.map(product => ({
            id: product.id,
            label: product.name,
            type: FilterTypeEnum.FILTER_PRODUCTS,
          })),
        ]

        setFilterData(prevState => {
          const newState = [...prevState]
          const index = newState.findIndex(item => item.type === type)
          newState[index] = {
            ...newState[index],
            page: newState[index].page + 1,
            data: [...newState[index].data, ...productsData],
          }
          return newState
        })
      } catch (error) {
        console.error(error)
      }
    }

    if (type === FilterTypeEnum.FILTER_EVENTS) {
      setFilterData(prevState => {
        const newState = [...prevState]
        const index = newState.findIndex(item => item.type === type)
        let newData = [...eventsData]

        if (search && search.length > 0) {
          newData = [
            ...newData.filter(value => value.label.toLowerCase().includes(search.toLowerCase())),
          ]
        }

        newState[index] = {
          ...newState[index],
          page: newState[index].page + 1,
          total: newData.length,
          data: [...newData.slice(0, totalPerPage * (newState[index].page + 1))],
        }
        return newState
      })
    }
  }

  useEffect(() => {
    fetchData(filterFormState.getValues().search || '')
  }, [])

  return (
    <FilterReportContext.Provider
      value={{
        filterData,
        isLoading,
        fetchData,
        filterFormState,
        loadMore,
        queryEvents,
        queryProducts,
        totalFiltersActive,
      }}
    >
      {children}
    </FilterReportContext.Provider>
  )
}
