import { useJWT } from '@/shared/hooks/useJWT'
import { useUnleashFlag, UnleashFlags } from '@/shared/hooks/useUnleashFlag'

import CreditBannerIllustration from 'src/escolas/assets/credit-banner-illustration.png'
import EarlyPayoutBanner from 'src/escolas/assets/early-payment-payout-illustration.png'
import IrregularitiesBanner from 'src/escolas/assets/irregularities-banner-illustration.png'
import CreditSimulationIllustration from 'src/escolas/assets/credit-simulation-banner-illustration.png'
import EnrollmentIllustration from 'src/escolas/assets/enrollment-illustration.svg'
import ManagementPanelsIllustration from '../../assets/management-panels-illustration.svg'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import type { BannerInfo, UseBannerInfosProps, UseBannerInfosReturn } from './types'
import { useHasPendingIrregularitiesQuery } from '../../hooks/useHasPendingIrregularitiesQuery'

const getEnrollmentsPageBanner = (): BannerInfo => {
  const title = 'Ano letivo 2024: você tem matrículas para gerenciar'
  return {
    title: title,
    explanation:
      'Com a visão geral na página Matrículas 2024, você pode rematricular, adicionar novo aluno e adicionar novo contrato. Tudo em um só lugar! Vamos lá?',
    actionLabel: 'Gerenciar matrículas',
    actionPath: `/matriculas`,
    imageSrc: EnrollmentIllustration,
    event: {
      scope: EventDispatcherEventScopes.HOME,
      action: 'click',
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

const getManagementPageBanner = (): BannerInfo => {
  const title = 'Confira o novo painel de gestão!'

  return {
    title,
    explanation:
      'Tenha uma visão clara e detalhada da receita da sua escola, segmentada por produtos escolares, para uma gestão mais eficiente e estratégica!',
    actionLabel: 'Acessar agora!',
    actionPath: `/painel-de-gestao`,
    imageSrc: ManagementPanelsIllustration,
    event: {
      scope: EventDispatcherEventScopes.HOME,
      action: 'click',
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

const getIrregularitiesBanner = (): BannerInfo => {
  const title = 'Evite bloqueios no envio de boletos: corrija o registro dos alunos'
  return {
    title: title,
    explanation:
      'Pendências de registro impedem que os alunos sejam registrados e os valores sejam repassados.',
    actionLabel: 'Liberar alunos pendentes',
    actionPath: `/irregularidades`,
    imageSrc: IrregularitiesBanner,
    backgroundColor: '#FFEAEE',
    event: {
      scope: EventDispatcherEventScopes.HOME,
      action: 'click',
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

// Antecipação de repasse
const getCreditBanner1 = (): BannerInfo => {
  const title = 'Campanha de Antecipação de Repasse'
  return {
    title: title,
    explanation:
      'Precisa de um impulso extra? Antecipe seu repasse de Julho e pague só em Setembro! *Sujeito à análise de crédito isaac.',
    actionLabel: 'Saiba como solicitar!',
    actionPath: `https://centraldeajuda.olaisaac.io/credito-isaac`,
    actionExternalLink: true,
    imageSrc: EarlyPayoutBanner,
    event: {
      scope: EventDispatcherEventScopes.HOME,
      action: 'click',
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

// Crédito Parcelado - Escolas que estarão FORA do Rollout de autosserviço
const getCreditBanner2 = (): BannerInfo => {
  const title = 'Campanha de Crédito: você tem valor pré-aprovado!'
  return {
    title: title,
    explanation:
      'Precisa de um impulso extra? Valor pré-aprovado* e condições especiais para o pagamento! *Sujeito à análise de crédito isaac.',
    actionLabel: 'Saiba como solicitar!',
    actionPath: `https://centraldeajuda.olaisaac.io/seguro-familiar-isaac/credito-isaac`,
    actionExternalLink: true,
    imageSrc: CreditBannerIllustration,
    event: {
      scope: EventDispatcherEventScopes.HOME,
      action: 'click',
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

// Crédito Parcelado - Escolas que estarão DENTRO do Rollout e DENTRO da Campanha de Crédito
const getCreditBanner3 = (): BannerInfo => {
  const title = 'Campanha de Crédito'
  return {
    title,
    explanation:
      'Agora você pode simular e contratar o crédito ideal para a sua escola diretamente na Plataforma isaac. Sujeito à análise de crédito isaac.',
    actionLabel: 'Simular agora',
    actionPath: '/credito',
    imageSrc: CreditSimulationIllustration,
    backgroundColor: '#131B5C',
    color: '#FFFFFF',
    event: {
      scope: EventDispatcherEventScopes.HOME,
      action: 'click',
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

// Crédito Parcelado - Escolas que estarão DENTRO do Rollout e FORA da Campanha de Crédito
const getCreditBanner4 = (): BannerInfo => {
  const title = 'Crédito pensado e feito para escolas'
  return {
    title,
    explanation:
      'Agora você pode simular e contratar o crédito ideal para a sua escola diretamente na Plataforma isaac. Sujeito à análise de crédito isaac.',
    actionLabel: 'Simular agora',
    actionPath: '/credito',
    imageSrc: CreditSimulationIllustration,
    backgroundColor: '#131B5C',
    color: '#FFFFFF',
    event: {
      scope: EventDispatcherEventScopes.HOME,
      action: 'click',
      name: EventDispatcherEvents.BUTTON_CLICK,
      props: {
        $origin: 'Banner',
        $button: title,
      },
    },
  }
}

export const useBannerInfos = ({
  referenceYear,
  schoolId,
}: UseBannerInfosProps): UseBannerInfosReturn => {
  const { hasPayoutReportAccess, isGroupManager } = useJWT()

  const infos: BannerInfo[] = []

  const isManagementDashboardEnabled = useUnleashFlag(UnleashFlags.PE_ENABLE_MANAGEMENT_DASHBOARD)
  const isEnrollmentsPageBannerEnabled = useUnleashFlag(
    UnleashFlags.PE_315_ENABLE_ENROLLMENTS_PAGE_BANNER
  )

  const isIntegratedSchool = useUnleashFlag(UnleashFlags.IS_INTEGRATED_SCHOOL)

  const isCreditBanner1Enabled = useUnleashFlag(UnleashFlags.PE_423_ENABLE_EARLY_PAYOUT_BANNER)
  const isCreditBanner2Enabled = useUnleashFlag(UnleashFlags.PE_423_ENABLE_CREDIT_BANNER)
  const isCreditBanner3Enabled = useUnleashFlag(UnleashFlags.PE_26_ENABLE_CREDIT_SIMULATION_BANNER)
  const isCreditBanner4Enabled = useUnleashFlag(UnleashFlags.PE_45_ENABLE_CREDIT_SIMULATION_BANNER)

  const hasPendingIrregularitiesQuery = useHasPendingIrregularitiesQuery({
    referenceYear,
    schoolId,
  })

  function refetch(): void {
    hasPendingIrregularitiesQuery.refetch()
  }

  if (isIntegratedSchool && hasPendingIrregularitiesQuery?.data?.has_pending_irregularities) {
    infos.push(getIrregularitiesBanner())
  }

  if (isGroupManager || hasPayoutReportAccess) {
    if (isCreditBanner4Enabled) {
      infos.push(getCreditBanner4())
    }

    if (isCreditBanner3Enabled) {
      infos.push(getCreditBanner3())
    }

    if (isCreditBanner2Enabled) {
      infos.push(getCreditBanner2())
    }

    if (isCreditBanner1Enabled) {
      infos.push(getCreditBanner1())
    }

    if (isManagementDashboardEnabled) {
      infos.push(getManagementPageBanner())
    }
  }

  isEnrollmentsPageBannerEnabled && infos.push(getEnrollmentsPageBanner())

  return {
    isError: hasPendingIrregularitiesQuery.isError,
    isLoading: hasPendingIrregularitiesQuery.isLoading || hasPendingIrregularitiesQuery.isFetching,
    data: infos,
    refetch,
  }
}
