import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@olaisaac/design-system'
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'

import IsaacLogo from '@/escolas/components/IsaacLogo'
import { AutocompleteInput } from './components/AutocompleteInput'

import { useJWT } from 'src/shared/hooks/useJWT'
import { useApi } from 'src/shared/hooks/useApi'

import { logOut } from '@/shared/api/sortingHatClientV3'

import { StyledContainer, StyledGraphism, StyledMain } from './styles'

export const SchoolSelection = () => {
  const { api } = useApi()
  const { isSchool, schoolId, schoolIds } = useJWT()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const history = useHistory()

  const [hasDismissedDisclaimer, setHasDismissedDisclaimer] = useState(false)

  useEffect(() => {
    const isOnlySchool = !schoolIds || schoolIds?.length === 1

    if (isSchool && isOnlySchool) {
      const schoolIdToFetch = schoolIds?.[0] || schoolId
      api.schools.get(schoolIdToFetch).then(school => {
        history.push(school.slug)
      })
    }
  }, [schoolId, isSchool])

  const handleOnLogoutClick = async () => {
    try {
      await api.auth.logout()

      logOut()
    } catch {
      logOut()
    }
  }

  const showMobileDisclaimer = !hasDismissedDisclaimer && isMobile
  const showSchoolSelection = !showMobileDisclaimer

  return (
    <StyledMain>
      <Box position="absolute" top={{ xs: 16, sm: 28 }} left={{ xs: 24, sm: 32 }}>
        <Button onClick={handleOnLogoutClick} variation="ghost" startIcon={<ExitToApp />}>
          Sair
        </Button>
      </Box>

      <StyledContainer maxWidth="sm">
        <Box pt={28} textAlign="center" position="relative" zIndex={1}>
          <IsaacLogo />

          <Box pt={7}>
            {showSchoolSelection && (
              <>
                <Box pb={3}>
                  <Typography variant="subtitle1">Escolha uma escola para começar</Typography>
                </Box>
                <AutocompleteInput />
              </>
            )}

            {showMobileDisclaimer && (
              <>
                <Box pb={3}>
                  <Typography variant="subtitle1">
                    Acesse o portal por computador ou tablet para obter a melhor experiência e ter
                    acesso a todos os serviços isaac.
                  </Typography>
                </Box>

                <Button fullWidth onClick={() => setHasDismissedDisclaimer(true)}>
                  Continuar
                </Button>
              </>
            )}
          </Box>
        </Box>

        <StyledGraphism />
      </StyledContainer>
    </StyledMain>
  )
}
