import styled from 'styled-components'
import {
  ToggleButton as MUIToggleButton,
  ToggleButtonGroup as MUIToggleButtonGroup,
} from '@material-ui/lab'
import { Icon as MDIIcon } from '@mdi/react'

export const ToggleButtonGroup = styled(MUIToggleButtonGroup)(({ theme }) => ({
  '&.MuiToggleButtonGroup-root': {
    backgroundColor: theme.gravity.colors['colors-background-neutral-2'],
    borderRadius: theme.gravity.borderRadius['4'],
  },
  '.MuiToggleButtonGroup-grouped': {
    '&:not(:first-child)': {
      borderColor: theme.gravity.colors['colors-border-neutral-2'],
    },
  },
}))

export const ToggleButton = styled(MUIToggleButton)(({ theme }) => ({
  '&.MuiToggleButton-root': {
    backgroundColor: theme.gravity.colors['colors-background-neutral-2'],
    borderRadius: theme.gravity.borderRadius['6'],
    border: 'none',
    color: theme.gravity.colors['colors-text-main-3'],
    margin: theme.gravity.spacing['1'],
    textTransform: 'none',

    '&.Mui-selected': {
      backgroundColor: theme.gravity.colors['colors-background-accent-1'],
      color: theme.gravity.colors['colors-text-main-5'],
    },
  },
}))

export const Icon = styled(MDIIcon)(({ theme }) => ({
  marginRight: theme.gravity.spacing['2'],
}))
