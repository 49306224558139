import { useState } from 'react'
import { MIN_CARDS } from '../../constants'
import { StudentsCarouselExpandedProps } from './types'
import { Typography } from '@olaisaac/design-system'
import { CarouselStepper, Header, SliderContainer, StyledSystemButton } from './styles'
import SwipeableViews from 'react-swipeable-views'
import { StudentCard } from '../StudentCard'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { Box } from '@material-ui/core'

export const StudentsCarouselExpanded = ({
  students,
  enrollmentYear,
}: StudentsCarouselExpandedProps) => {
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = students?.length ? Math.ceil(students.length / MIN_CARDS) : 0

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  return (
    <>
      <Header>
        <Box ml="64px">
          <Typography variation="subtitleDesktopMedium">
            {`${students?.length || 0} aluno(s) vinculado(s)`}
          </Typography>
        </Box>

        {students?.length > MIN_CARDS ? (
          <SliderContainer>
            <CarouselStepper
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={activeStep}
              nextButton={
                <StyledSystemButton
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                  aria-label="Próximo slide"
                >
                  <KeyboardArrowRight />
                </StyledSystemButton>
              }
              backButton={
                <StyledSystemButton
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  ria-label="Slide anterior"
                >
                  <KeyboardArrowLeft />
                </StyledSystemButton>
              }
            />
          </SliderContainer>
        ) : null}
      </Header>

      <SwipeableViews
        axis="x"
        index={activeStep}
        ignoreNativeScroll
        resistance
        animateHeight
        onChangeIndex={handleStepChange}
        slideStyle={{
          overflow: 'hidden',
          display: 'flex',
          gap: 16,
          height: 118,
          width: 'min-content',
        }}
        containerStyle={{
          ...(students?.length > MIN_CARDS && activeStep !== 0 && { marginLeft: '32px' }),
        }}
      >
        {students?.map((student, index) => (
          <Box key={student.student_id} ml={index === 0 ? '64px' : 0}>
            <StudentCard student={student} enrollmentYear={enrollmentYear} isExpanded />
          </Box>
        ))}
      </SwipeableViews>
    </>
  )
}
