import { Dayjs } from 'dayjs'
import { Dispatch, SetStateAction } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Address, Discount, Enrollment, Product } from 'src/shared/interfaces'
import {
  AddDiscountFormType,
  AddDiscountsFormType,
} from '@/modules/guardians/InstallmentsDrawerContainer/InstallmentDrawer/AddDiscountContent'

export type DefaultGuardianType = Omit<GuardianFormType, 'name'> & {
  name: string
}

export type StudentAutoCompleteOption = {
  description?: string
  key: string
  value: string
}

export type FormProps = EnrollmentFormProps & {
  custom_enrollment_monthly_amount: number
  custom_monthly_amount: number
  disable_send_signable_document: boolean
  due_day: number
  duration_months: string
  enrollment_due_day: number
  guardian: GuardianFormType
  pre_contract_disclaimer_check: boolean
  product: Product
  product_id: uuid
  reference_year: string
  start_month: Dayjs
  student: StudentFormType
  use_working_dates_enrollment: string
  use_working_dates_tuition: string
}

export type NewContractFormDefaults = {
  guardian: DefaultGuardianType
  previousYearContractID: uuid
  referenceYear: string
  student: {
    guardian_id: uuid
    name: string
    referral?: string
  }
}

export type EnrollmentFormFields = {
  discounts: AddDiscountFormType[]
  durationMonths?: string
  monthlyAmount?: cents
}

export type EnrollmentFormProps = {
  enrollment_amount: string
  enrollment_due_day: number
  enrollment_duration_months: string
  enrollment_start_month: Dayjs
  referenceYear: number
  send_first_enrollment_installment_message: boolean
}

export type EnrollmentProps = {
  enrollmentForm: UseFormReturn<EnrollmentFormFields>
  enrollmentFormFields: EnrollmentFormFields
  form: UseFormReturn<FormProps>
  handleEnrollmentFormFieldsChange: (newFormFields: Partial<EnrollmentFormFields>) => void
  isEnrollmentEnabled: boolean
  setEnrollmentEnabled: (value: boolean) => void
}

export type FormDiscount = Discount & {
  error?: {
    amount?: string
    days_before_due_date?: string
    description?: string
    no_days_before_due_date_selected?: string
  }
}

export type ContractSelectYearProps = {
  form: UseFormReturn<FormProps>
  referenceYear: string
  setReferenceYear: (value: string) => void
}

export type DiscountInputProps = {
  amountChangeHandler: (value: number, discounts: Array<Discount>, index: number) => void
  daysBeforeDueDateChangeHandler: (value: number, discounts: Array<Discount>, index: number) => void
  deleteHandler: (index: number) => void
  descriptionChangeHandler: (value: string, discounts: Array<Discount>, index: number) => void
  discounts: Array<FormDiscount>
  idPrefix: string
  index: number
  installmentLabel: string
  totalAmount: cents
}

export type SelectProductProps = {
  form: UseFormReturn<FormProps>
  referenceYear: string
}

export type PaymentPlanFormProps = {
  discounts?: Discount[]
  discountsForm?: UseFormReturn<AddDiscountsFormType>
  enrollmentForm?: UseFormReturn<EnrollmentFormFields>
  enrollmentFormFields: EnrollmentFormFields
  form: UseFormReturn<FormProps>
  isEnrollmentEnabled: boolean
  product: Product
  setDiscounts?: Dispatch<SetStateAction<Discount[]>>
  setEnrollmentEnabled: Dispatch<SetStateAction<boolean>>
  setEnrollmentFormFields: Dispatch<SetStateAction<EnrollmentFormFields>>
}

export type GuardianFormType = {
  address: Omit<Address, 'created_at' | 'updated_at' | 'id'>
  email: email
  id: uuid
  name: string
  phone_number: phone
  tax_id: cpf
}

export type StudentFormType = {
  id: uuid
  name: string
}

export type GuardianFormProps = {
  form: UseFormReturn<FormProps>
  setIsFeedbackDialogOpen: (value: boolean) => void
}

export type SummaryProps = {
  contractCreationProgress: string
  discounts: Array<AddDiscountFormType>
  enrollmentAmount?: cents
  enrollmentDiscount?: Array<Discount>
  enrollmentFirstDueDay: Dayjs
  enrollmentInstallments?: number
  envelopeTemplateReferralId?: string
  firstDueDay: Dayjs
  formValues: FormProps
  installmentAmount: number
  installments: number
  isEnrollmentEnabled: boolean
  isLoading: boolean
}

export type ContractFormProps = {
  contractCreationProgress: string
  errorFieldNames: string[]
  isFeedbackDialogOpen: boolean
  isInvoiceLimitError: boolean
  isLoading: boolean
  onSubmit: (
    enrollmentData: Enrollment | Record<string, never>,
    formValues: FormProps,
    discountsValues: AddDiscountFormType[]
  ) => void
  referenceYear: string
  setIsFeedbackDialogOpen: (value: boolean) => void
  setReferenceYear: (data) => void
}

export enum GuardianFormNames {
  ADDITIONAL_INFO = 'guardian.address.additional_information',
  ADDRESS = 'guardian.address',
  ADDRESS_NUMBER = 'guardian.address.number',
  CITY = 'guardian.address.city',
  EMAIL = 'guardian.email',
  NAME = 'guardian.name',
  PHONE_NUMBER = 'guardian.phone_number',
  STATE_CODE = 'guardian.address.state_code',
  STREET = 'guardian.address.street',
  TAX_ID = 'guardian.tax_id',
  ZIP = 'guardian.address.zip',
}

export type PreContractDisclaimerProps = {
  form: UseFormReturn<FormProps>
  referenceYear: string
}
