import { createContext, useReducer, Dispatch, FC } from 'react'

import { campaignReducer } from './reducers'
import { CampaignActionType, CampaignContextType, CampaignPaymentPlanType } from './types'

const REFERENCE_YEAR = '2023' // campaign is not avaiable for another reference year yet
const CURRENT_YEAR = '2022' // campaign is not avaiable for another current year yet

const initialPaymentPlan: CampaignPaymentPlanType = {
  send_first_enrollment_installment: true,
  send_signable_document: true,
  due_day: null,
  use_working_dates_tuition: true,
  duration_months: 0,
  enrollment_due_day: null,
  use_working_dates_enrollment: true,
  enrollment_duration_months: 0,
  enrollment_start_month: null,
  start_month: null,
  custom_monthly_amount: null,
  custom_enrollment_monthly_amount: null,
  pre_contract_disclaimer_check: false,
  discounts: [],
  enrollment_discounts: [],
}

const initialCampaignValues: CampaignContextType = {
  nextYearProductId: '',
  nextYearProduct: null,
  currentYearProductId: '',
  currentYearProduct: null,
  contractList: [],
  selectedContractIdsList: [],
  products: [],
  paymentPlan: initialPaymentPlan,
  preContractDisclaimerCheck: false,
  referenceYear: REFERENCE_YEAR,
  currentYear: CURRENT_YEAR,
}

const CampaignContext = createContext<{
  dispatch: Dispatch<CampaignActionType>
  state: CampaignContextType
}>({
  state: initialCampaignValues,
  dispatch: () => null,
})

export type CampaignContextProviderProps = {
  initialState?: CampaignContextType
}

const CampaignContextProvider: FC<CampaignContextProviderProps> = ({
  initialState = initialCampaignValues,
  children,
}) => {
  const [state, dispatch] = useReducer(campaignReducer, initialState)
  return <CampaignContext.Provider value={{ state, dispatch }}>{children}</CampaignContext.Provider>
}

export { CampaignContextProvider, CampaignContext, initialPaymentPlan, initialCampaignValues }
