import React, { useEffect, useState } from 'react'
import { Table, TableColumns, Typography } from '@olaisaac/design-system'
import { Tooltip } from '@material-ui/core'
import { useEventDispatcher } from '@olaisaac/event-dispatcher-sdk'

import { EventDispatcherEventScopes } from '@/shared/models/enums/EventDispatcherEventScopes.enum'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'

import theme from '@/shared/theme'
import useCheckoutCart from '@/modules/guardians/hooks/useCheckoutCart'

import { mapDataToRows } from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/GuardianDetailsInstallmentsTable/utils/mapDataToRows'
import { formatter } from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/GuardianDetailsInstallmentsTable/utils/formatter'
import {
  GuardianDetailsInstallmentsTableProps,
  Row,
} from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/GuardianDetailsInstallmentsTable/types'
import { TableContainer } from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/GuardianDetailsInstallmentsTable/styles'
import { InstallmentOrder } from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/InstallmentOrder/InstallmentOrder'
import { InstallmentFullStatus } from '@/modules/guardians/GuardianDetailsInstallmentsTab/components/InstallmentFullStatus/InstallmentStatus'

export const SummaryTable: React.FC<GuardianDetailsInstallmentsTableProps> = ({
  installments,
  isLoading,
  onRowClick,
}) => {
  const [selectedRow, setSelectedRow] = useState<Row[]>()
  const { addInstallments } = useCheckoutCart()
  const rows = mapDataToRows(installments)

  const { isInitialized, eventDispatcherClient } = useEventDispatcher()

  useEffect(() => {
    addInstallments(selectedRow)
  }, [selectedRow])

  const columns: TableColumns = [
    {
      headerName: 'Vencimento',
      field: 'due_date',
      renderCell: (value: Row['due_date']) => (
        <Typography variation="bodyLarge">{formatter.full_date(value)}</Typography>
      ),
      small: true,
    },
    {
      headerName: 'Parcela',
      field: 'installment_order',
      renderCell: InstallmentOrder,
      small: true,
      isComponent: true,
    },
    {
      headerName: 'Aluno',
      field: 'student',
      renderCell: (value: Row['student']) => (
        <Tooltip title={value.name}>
          <Typography
            variation="bodyLarge"
            style={{
              maxWidth: '255px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {value.name}
          </Typography>
        </Tooltip>
      ),
      small: true,
      isComponent: true,
    },
    {
      headerName: 'Produto',
      field: 'product_name',
      renderCell: (value: Row['product_name']) => (
        <Tooltip title={value}>
          <Typography
            variation="bodyLarge"
            style={{
              maxWidth: '255px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {value}
          </Typography>
        </Tooltip>
      ),
      small: true,
      isComponent: true,
    },
    {
      headerName: 'Valor',
      field: 'amount',
      renderCell: (value: Row['amount']) => (
        <Typography variation="bodyLarge">{formatter.amount(value)}</Typography>
      ),
      small: true,
    },
    {
      headerName: 'Situação',
      field: 'installment_status',
      renderCell: InstallmentFullStatus,
      small: true,
    },
  ]

  const handleRowClick = ({ row }: { row: Row }, e: { target: HTMLInputElement }) => {
    if (row.disabled || e.target.type === 'checkbox') {
      if (isInitialized) {
        eventDispatcherClient.sendEvent({
          scope: EventDispatcherEventScopes.GUARDIANS,
          name: EventDispatcherEvents.CHECKBOX_CLICKED,
          action: 'click',
          customProperties: {
            $installment_id: row.id,
            $name: 'Seleção de parcela',
            $checked: !!e.target.checked,
          },
        })
      }
      return
    }

    if (isInitialized) {
      eventDispatcherClient.sendEvent({
        scope: EventDispatcherEventScopes.GUARDIANS,
        name: EventDispatcherEvents.TABLE_ROW_CLICKED,
        action: 'click',
        customProperties: {
          $installment_id: row.id,
          $name: 'Ver detalhe da parcela',
        },
      })
    }

    onRowClick(row)
  }

  const TableEmptyState = () => (
    <Typography variation="subtitleDesktopMedium" color="secondary">
      Nenhuma parcela encontrada
    </Typography>
  )

  const isRowDisabled = (row: Row) => {
    return row.disabled
  }

  return (
    <TableContainer theme={theme}>
      <Table
        checkable
        isRowCheckable={row => !!row.key}
        onRowClick={handleRowClick}
        setCheckedRows={setSelectedRow}
        columns={columns}
        emptyRender={TableEmptyState()}
        rowDisabled={isRowDisabled}
        isLoading={isLoading}
        rows={rows || []}
        allCheckables={!isLoading}
      />
    </TableContainer>
  )
}
