import dayjs from 'dayjs'
import { Box } from '@material-ui/core'
import { Notification, Button, ButtonGroup } from '@olaisaac/design-system'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from 'src/escolas/components/modal/ConfirmationDialog'
import { useAgreement } from 'src/escolas/contexts/agreementContext'
import { useMakeAgreementMutation } from 'src/escolas/hooks/queries/useMakeAgreementMutation'

import type { PaymentOptionsValue, StepComponentProps } from '@/modules/guardians/Negotiation/types'

import {
  AgreementInvoice,
  AgreementStatus,
  Checkout,
  CheckoutStatus,
  CheckoutType,
  ChosenPaymentPlan,
  FormOfPayment,
  MakeAgreementBody,
  SimulationPaymentMethod,
  CheckoutProvider,
  PaymentMethod,
  Receivable,
  DuplicateAgreementRequest,
} from 'src/shared/interfaces'
import { Footer } from '../../components/Footer'
import { HelpButton } from '../../components/HelpButton'
import { SharePaymentOptionsButton } from '../../components/SharePaymentOptionsButton'
import { getIdsForPayment } from '../FormOfPaymentStep/components/helpers/getIdsForPayment'
import { isPaymentByPos } from '../FormOfPaymentStep/components/helpers/isPaymentByPos'
import StatusCheckout from '../../components/StatusCheckout/StatusCheckout'
import StatusDialog from '../../components/StatusDialog'
import useFlowCheckout from '../../hooks/useFlowCheckout'
import OtherMethod from './component/Methods/OtherMethod'
import PosMethod from './component/Methods/POSMethod'
import * as Styled from './styles'
import { usePaymentCreditCard } from '@/escolas/hooks'
import { getDuplicateAgreementPaymentPlan } from '../../helpers/agreement'
import { useDuplicateAgreement } from '@/escolas/hooks/useDuplicateAgreement'

const PaymentOptionStep = ({ id, onFinish, goBack }: StepComponentProps) => {
  const [isValid, setIsValid] = useState(false)
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false)
  const [chosenOption, setChosenOption] = useState<PaymentOptionsValue>(null)
  const [showFailModal, setShowFailModal] = useState(false)

  const { guardianId } = useParams<{ guardianId: uuid }>()

  const {
    selectedFormOfPayment,
    positionAgreement,
    agreementSimulations,
    hasPaidAgreement,
    totalAmount,
    totalAmountWithFee,
    handlerAgreementStatus,
    setCheckoutData,
    updateAgreementSimulation,
  } = useAgreement()

  const { paymentFee } = usePaymentCreditCard()

  const makeAgreementMutation = useMakeAgreementMutation()
  const duplicateAgreement = useDuplicateAgreement()

  const selectedAgreement = agreementSimulations[positionAgreement]

  const statusDialogTitle =
    !selectedAgreement?.negotiation_data?.id && selectedAgreement.type === 'down_payment'
      ? 'Carregando informações de pagamento da entrada'
      : undefined

  const goToNextStep = (invoices: AgreementInvoice[]) => {
    const agreementStatus: AgreementStatus = {
      finished: true,
      invoices: invoices,
    }

    handlerAgreementStatus(positionAgreement, agreementStatus)
    onFinish()
  }

  const handleOnFinishCheckout = (checkoutData: Checkout) => {
    setCheckoutData(checkoutData)
    const invoices = [
      {
        amount: checkoutData.amount,
        due_date: dayjs().toString(),
        payment_method: SimulationPaymentMethod.POS,
        receivable_id: selectedAgreement.receivable_ids[0],
        instalment_credit_card: checkoutData.installments_count,
      },
    ]

    goToNextStep(invoices)
  }

  const checkoutValues = useFlowCheckout(handleOnFinishCheckout)

  const currentPaymentTotalAmount =
    selectedFormOfPayment === FormOfPayment.POS_V2 ? totalAmount : selectedAgreement.total_amount

  const getCurrentTotalAmount = () => {
    if (
      paymentFee?.enabled &&
      chosenOption?.paymentOption === SimulationPaymentMethod.CREDIT_CARD
    ) {
      return selectedFormOfPayment === FormOfPayment.POS_V2
        ? totalAmountWithFee
        : selectedAgreement.total_amount + selectedAgreement.credit_card_fee
    }
    return selectedFormOfPayment === FormOfPayment.POS_V2
      ? totalAmount
      : selectedAgreement.total_amount
  }

  const handleDownPaymentCheckout = (chosenOption: PaymentOptionsValue) => {
    setIsLoadingCheckout(true)

    const { installments, pos, paymentOption } = chosenOption

    if (selectedAgreement?.negotiation_data?.id) {
      checkoutValues.startCheckout(
        installments,
        paymentOption,
        pos.pos_id,
        [selectedAgreement.negotiation_data?.receivable_id],
        pos.provider,
        true
      )
      return
    }

    const paymentPlan = getDuplicateAgreementPaymentPlan(selectedAgreement?.origin_negotiation)

    const body: DuplicateAgreementRequest = {
      simulation_id: selectedAgreement?.origin_negotiation?.simulation_id,
      guardian_id: guardianId,
      payment_plan: paymentPlan,
    }

    duplicateAgreement.mutate(body, {
      onSuccess: data => {
        const invoices = data.invoices.map(invoice => {
          if (paymentPlan.payment_method === SimulationPaymentMethod.CREDIT_CARD) {
            return { ...invoice, instalment_credit_card: paymentPlan.number_of_installments }
          }
          return invoice
        })
        const firstInvoice = invoices.find(invoice => invoice.installment.startsWith('1/'))

        updateAgreementSimulation(positionAgreement, {
          ...selectedAgreement,
          negotiation_data: {
            id: data.id,
            short_id: data.id.substring(0, 5).toUpperCase(),
            receivable_id: firstInvoice.receivable_id,
          },
        })

        checkoutValues.startCheckout(
          installments,
          paymentOption,
          pos.pos_id,
          [firstInvoice.receivable_id],
          pos.provider,
          true
        )
      },
      onError: () => {
        setTimeout(() => {
          setShowFailModal(true)
        }, 300)
        setIsLoadingCheckout(false)
      },
    })
  }

  const handleCheckout = (chosenOption: PaymentOptionsValue) => {
    setIsLoadingCheckout(true)

    const { installments, pos, paymentOption } = chosenOption

    const receivableIds = getIdsForPayment(
      agreementSimulations,
      positionAgreement,
      selectedFormOfPayment === FormOfPayment.POS || selectedFormOfPayment === FormOfPayment.POS_V2
    )

    checkoutValues.startCheckout(
      installments,
      paymentOption,
      pos.pos_id,
      receivableIds,
      pos.provider
    )
  }

  const handleAgreement = (chosenOption: PaymentOptionsValue) => {
    const { dueDate, installments, minDownPayment, paymentOption } = chosenOption

    const paymentPlan: ChosenPaymentPlan = {
      number_of_installments:
        paymentOption === SimulationPaymentMethod.BANK_SLIP ? installments + 1 : installments,
      down_payment_amount:
        paymentOption === SimulationPaymentMethod.CREDIT_CARD ? null : minDownPayment,
      payment_method:
        paymentOption === SimulationPaymentMethod.CREDIT_CARD
          ? SimulationPaymentMethod.CREDIT_CARD
          : SimulationPaymentMethod.BANK_SLIP,
      due_date: dayjs(dueDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
    }

    const body: MakeAgreementBody = {
      channel: 'PI-ESCOLA',
      agreement_amount: getCurrentTotalAmount(),
      agreement_simulation_id: selectedAgreement?.agreement_simulation_id,
      guardian_id: guardianId,
      payment_plan: paymentPlan,
      receivable_ids: selectedAgreement?.receivable_ids,
    }

    makeAgreementMutation.mutate(body, {
      onSuccess: data => {
        const invoices = data.invoices.map(invoice => {
          if (paymentPlan.payment_method === SimulationPaymentMethod.CREDIT_CARD) {
            return { ...invoice, instalment_credit_card: paymentPlan.number_of_installments }
          }
          return invoice
        })
        invoices.length > 0 ? goToNextStep(invoices) : setShowFailModal(true)
      },
      onError: () => {
        setShowFailModal(true)
      },
    })
  }

  const handleNextStep = () => {
    if (selectedAgreement?.type === 'down_payment') return handleDownPaymentCheckout(chosenOption)

    const { creditCardPaymentOption, paymentOption } = chosenOption
    if (isPaymentByPos(selectedFormOfPayment, paymentOption, creditCardPaymentOption)) {
      handleCheckout(chosenOption)
      return
    }
    handleAgreement(chosenOption)
  }

  const handleGoBack = () => {
    goBack()
  }

  const handleChosenOption = (value: PaymentOptionsValue) => {
    setChosenOption(value)
  }

  const handleValidate = (value: boolean) => setIsValid(value)

  const goToCheckoutFailStep = () => {
    const { installments, pos } = chosenOption
    const receivableIds = getIdsForPayment(
      agreementSimulations,
      positionAgreement,
      selectedFormOfPayment === FormOfPayment.POS || selectedFormOfPayment === FormOfPayment.POS_V2
    )

    const checkoutType =
      pos.provider === CheckoutProvider.STONE_PAGARME
        ? CheckoutType.PAGARME_CHECKOUT
        : CheckoutType.STONE_CHECKOUT

    const checkoutFailureData = {
      id: '',
      created_at: '',
      amount: 0,
      external_transaction_id: '',
      keep_due_payment_discounts: true,
      keep_early_payment_discounts: false,
      keep_fine_and_interest: true,
      receivables: [] as Receivable[],
      receivablesIds: [] as string[],
      school_id: '',
      status: CheckoutStatus.FAILURE_TO_CONNECT,
      type: checkoutType,
      payment_methods: [] as PaymentMethod[],
      installments_count: installments,
      pos_id: pos.pos_id,
      receivables_ids: receivableIds,
    }

    setCheckoutData({
      ...checkoutFailureData,
      originalReceivableIds: receivableIds,
      amount: currentPaymentTotalAmount,
    })
    goToNextStep([])
  }

  useEffect(() => {
    if (checkoutValues.checkoutObject || checkoutValues.showErrorDialog) setIsLoadingCheckout(false)
  }, [checkoutValues.checkoutObject, checkoutValues.showErrorDialog])

  useEffect(() => {
    setCheckoutData(null)
  }, [])

  return (
    <Styled.Container>
      {selectedFormOfPayment !== FormOfPayment.POS_V2 ? (
        <OtherMethod
          handleValidate={handleValidate}
          handleValue={handleChosenOption}
          agreement={selectedAgreement}
        />
      ) : (
        <PosMethod
          handleValidate={handleValidate}
          totalAmount={totalAmount}
          handleValue={handleChosenOption}
        />
      )}
      <StatusCheckout
        closeErrorDialog={checkoutValues.closeCheckoutErrorDialog}
        {...checkoutValues}
        pos={chosenOption?.pos}
        onClickOtherPaymentOptions={goToCheckoutFailStep}
        shortId={selectedAgreement?.negotiation_data?.short_id}
      />
      <StatusDialog
        guardianName={checkoutValues.guardianName}
        isOpen={isLoadingCheckout}
        isTextVisible={!statusDialogTitle}
        posName={chosenOption?.pos?.pdv_name}
        totalAmount={getCurrentTotalAmount()}
        title={statusDialogTitle}
      >
        {selectedAgreement?.negotiation_data?.short_id ? (
          <Box width="100%" paddingX={2}>
            <Notification
              variation="information"
              title="O código identificador da negociação mudou"
              description={`A negociação agora é a #${selectedAgreement.negotiation_data.short_id}`}
            />
          </Box>
        ) : null}
      </StatusDialog>
      {showFailModal && (
        <FailureFeedbackDialog
          isVisible
          buttonLabel="Fechar"
          onClose={() => {
            setShowFailModal(false)
          }}
          submitHandler={() => {
            setShowFailModal(false)
          }}
        >
          <FailureFeedbackContent />
        </FailureFeedbackDialog>
      )}

      <Footer
        button={
          <Box width="100%" display="flex">
            <HelpButton eventLocationName={id} />
            <SharePaymentOptionsButton />
          </Box>
        }
      >
        <ButtonGroup>
          <Button
            variation="ghost"
            onClick={handleGoBack}
            disabled={
              hasPaidAgreement || checkoutValues.waitingApi || makeAgreementMutation?.isLoading
            }
          >
            Voltar
          </Button>
          <Button
            variation="primary"
            disabled={!isValid}
            loading={
              checkoutValues.waitingApi || makeAgreementMutation?.isLoading || isLoadingCheckout
            }
            onClick={handleNextStep}
          >
            Continuar
          </Button>
        </ButtonGroup>
      </Footer>
    </Styled.Container>
  )
}

export default PaymentOptionStep
