import { useRouteMatch } from 'react-router-dom'

import { Section } from '../Section'
import { Subsection } from '../Subsection'
import { ButtonLink } from '../ButtonLink'

import { useProfileDetails } from '@/escolas/pages/Home/hooks/useProfileDetails'
import { useLearnMoreSectionLinks } from './useLearnMoreSectionLinks'

const COLUMNS_COUNT = 3

export const LearnMoreSection = () => {
  const { isMaintainer, isSecretary } = useProfileDetails()
  const { url } = useRouteMatch()

  const learnMoreLinks = useLearnMoreSectionLinks({
    currentUrl: url,
    isSecretary,
    isMaintainer,
  })

  return (
    <Section title="Novidades na plataforma isaac para o período de matrículas!">
      <Subsection columnsCount={COLUMNS_COUNT}>
        {learnMoreLinks.map(({ title, icon, href, variant }, i) => (
          <li key={i}>
            <ButtonLink href={href} icon={icon} title={title} $variant={variant} />
          </li>
        ))}
      </Subsection>
    </Section>
  )
}
