import { InstallmentDrawerInfo } from '@/modules/guardians/InstallmentsDrawerContainer/NegotiationDrawer/types'
import { Receivable } from '@/shared/interfaces'

export const parseInstallmentToReceivable = (installment: InstallmentDrawerInfo): Receivable => {
  const {
    urn,
    type,
    contract_id,
    installment_id,
    order_reference,
    due_date,
    discounts,
    current_fine,
    current_amount,
    current_interest,
    original_amount,
  } = (installment || {}) as InstallmentDrawerInfo

  const receivableId = urn?.replace('urn:receivable:', '')
  const allDiscounts = discounts?.flatMap(({ discounts }) => {
    return discounts
  })

  const { currentPerpetualDiscount, currentDuePaymentDiscount, currentEarlyPaymentDiscount } =
    discounts?.reduce(
      (acm, { total_amount, is_active, type }) => {
        if (type === 'PERPETUAL') {
          return {
            ...acm,
            currentPerpetualDiscount: total_amount,
          }
        }

        if (type === 'EARLY_PAYMENT_DISCOUNT' && is_active) {
          return {
            ...acm,
            currentEarlyPaymentDiscount: total_amount,
          }
        }

        if (type === 'DUE_PAYMENT' && is_active) {
          return {
            ...acm,
            currentDuePaymentDiscount: total_amount,
          }
        }

        return acm
      },
      {
        currentPerpetualDiscount: 0,
        currentEarlyPaymentDiscount: 0,
        currentDuePaymentDiscount: 0,
      }
    ) || {}

  return {
    base_amount: original_amount,
    created_at: '',
    current_additional_discount: 0,
    current_due_payment_discount: currentDuePaymentDiscount,
    current_amount,
    current_discount:
      currentDuePaymentDiscount + currentEarlyPaymentDiscount + currentPerpetualDiscount,
    current_early_payment_discount: currentEarlyPaymentDiscount,
    current_fine,
    current_interest,
    current_perpetual_discount: currentPerpetualDiscount,
    description: '',
    discounts: allDiscounts,
    due_date,
    id: receivableId,
    installment_id,
    installment: {
      id: installment_id,
      amount: current_amount,
      contract_id,
      due_date,
      order_reference,
      status: undefined,
      created_at: '',
    },
    invoice_id: '',
    original_amount,
    status: undefined,
    type,
  }
}
