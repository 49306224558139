import { Tooltip } from '@material-ui/core'
import { Typography } from '@olaisaac/design-system'

import { Row } from '../GuardianDetailsInstallmentsTable/types'
import { formatter } from '../GuardianDetailsInstallmentsTable/utils/formatter'

export const InstallmentOrder = (value: Row['installment_order']) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Tooltip title={formatter.installmentType(value.row_type)}>
      <Typography
        variation="bodyLarge"
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {formatter.installmentType(value.row_type)}
      </Typography>
    </Tooltip>
    <Typography variation="caption">{formatter.order_reference(value.order_reference)}</Typography>
  </div>
)
