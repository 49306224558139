import { useState } from 'react'
import { Box, Step, Stepper } from '@material-ui/core'

import {
  BaseStepContent,
  BaseStepLabel,
  CompletedIcon,
  CompletedIconMargin,
  StepDescription,
  StepLabelText,
  TitleStepLabel,
} from './styles'

const steps = [
  {
    label: 'Solicitação recebida \n com sucesso!',
    description:
      'Em até 2 dias úteis, a equipe de crédito entrará em \n contato para continuar a contratação.',
    finishedDescription: 'Contratação iniciada',
  },
  {
    label: 'Coleta de documentos',
    description:
      'Contrato social e suas alterações \n  Documento com foto dos representantes legais',
    finishedDescription: 'Concluído',
  },
  {
    label: 'Assinatura de contrato',
    description: 'Aditivo de contrato \n CCB (Cédula de Crédito Bancário)',
    finishedDescription: 'Concluído',
  },
  {
    label: 'Transferência do crédito',
    description: 'Você irá receber a transferência na data combinada',
    finishedDescription: 'Concluído',
  },
]

const StepIconCompleted = () => <CompletedIcon style={{ fontSize: 29 }} />
const StepIconMargin = () => <CompletedIconMargin style={{ fontSize: 29 }} />

export const StatusStepper = ({ statusStepper }) => {
  const [activeStep] = useState(statusStepper.step)

  const isStepCompleted = (currentStep: number) => {
    return activeStep > currentStep || (currentStep === activeStep && statusStepper.finished)
  }

  return (
    <Box mb={4}>
      <Stepper style={{ padding: 0 }} activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step expanded key={step.label}>
            {index === 0 ? (
              <TitleStepLabel StepIconComponent={StepIconMargin}>{step.label}</TitleStepLabel>
            ) : (
              <BaseStepLabel StepIconComponent={isStepCompleted(index) ? StepIconCompleted : null}>
                <StepLabelText>{step.label}</StepLabelText>
              </BaseStepLabel>
            )}
            <BaseStepContent isTitle={index === 0}>
              <StepDescription>
                {isStepCompleted(index) ? step.finishedDescription : step.description}
              </StepDescription>
            </BaseStepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
