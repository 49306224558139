import { useContext, useEffect, useRef, useState } from 'react'
import { Box } from '@material-ui/core'
import { Grid, Stepper, Step, StepLabel, Button } from '@olaisaac/design-system'
import { useNavigation } from 'src/escolas/hooks'
import * as Steps from './Steps'
import { CampaignContext, CampaignContextProvider } from './context'
import CampaignHeader from './CampaignHeader'
import { useApi, useJWT, useSnackbar } from 'src/shared/hooks'
import { ActionType, CampaignEventStep, StepType } from './types'
import { FailureFeedbackDialog } from 'src/escolas/components/modal/ConfirmationDialog'
import DisclaimerTermsAndConditions from './Steps/ReviewAndSave/DisclaimerTermsAndConditions'
import { PageTableControlBar } from 'src/escolas/components/layout/PageTableControlBar'
import { ContainerWithActionDrawer, ControlBarContainer, PageContainer } from './styles'
import {
  HotjarEvents,
  hotjarPageFactory,
  HotjarPageNames,
  useHotjar,
} from 'src/shared/hooks/useHotjar'
import useRouterReferenceYear from 'src/escolas/hooks/useRouterReferenceYear'
import { useHistory } from 'react-router-dom'

import { calculateProductTotalTuitionAmount } from './utils'
import { isApiNamedError } from 'src/shared/utils/isApiNamedError'
import { API_NAMED_ERRORS } from 'src/shared/interfaces'

import CampaignDialog from './CampaignDialog'
import LoadingProgress from 'src/escolas/components/CircularProgress/CircularProgress'

export enum StepsEnum {
  PAYMENT_PLAN = 2,
  REVIEW_SAVE = 3,
  SELECT_PRODUCT_BASE = 0,
  SELECT_CONTRACTS = 1,
}

const CampaignContent = () => {
  const { schoolId, getNavigationUrl } = useNavigation()
  const { api } = useApi()
  const { state } = useContext(CampaignContext)
  const { nextYearProduct, selectedContractIdsList, preContractDisclaimerCheck } = state
  const [activeStep, setActiveStep] = useState(0)
  const [isPaymentPlanValid, setIsPaymentPlanValid] = useState<boolean>(false)
  const [isGoingToSaveStep, setIsGoingToSaveStep] = useState<boolean>(false)
  const isPaymentPlanStep = activeStep === StepsEnum.PAYMENT_PLAN
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState<boolean>(false)
  const [isCampaignInProcessDialogOpen, setIsCampaignInProcessDialogOpen] = useState<boolean>(false)
  const { dispatch } = useContext(CampaignContext)
  const { email } = useJWT()
  const { setMessage: setSnackbarMessage, setIsOpen: setSnackbarIsOpen } = useSnackbar()
  const pageReferenceYear = useRouterReferenceYear()
  const { setHotjarPage, sendHotjarEvent } = useHotjar()
  const [userIsLeaving, setIsUserLeaving] = useState(false)
  const history = useHistory()
  const leavingPathname = useRef('')
  const navigationUnblock = useRef(() => null)
  const [isLoadingCampaignStatus, setIsLoadingCampaignStatus] = useState(false)
  const [shouldQuitOnError, setShouldQuitOnError] = useState(false)

  const steps: StepType[] = [
    {
      title: `Selecionar produto ${state.referenceYear}`,
      content: <Steps.SelectNextYearProduct />,
      eventStep: CampaignEventStep.SELECT_NEXT_PRODUCT,
      validation: () => nextYearProduct !== null,
      errorMessage: 'Para prosseguir você precisa selecionar um produto',
      hotjarStep: HotjarEvents.CAMPAIGN_SELECT_NEXT_PRODUCT_LANDED,
      validationCallback: () => {
        sendHotjarEvent(HotjarEvents.CAMPAIGN_NO_PRODUCT_SELECTED_ALERT)
      },
    },
    {
      title: 'Selecionar alunos',
      content: <Steps.SelectContracts />,
      eventStep: CampaignEventStep.SELECT_STUDENTS,
      validation: () => selectedContractIdsList.length > 0,
      errorMessage: 'Para prosseguir você precisa selecionar um ou mais alunos',
      hotjarStep: HotjarEvents.CAMPAIGN_SELECT_STUDENTS_LANDED,
      validationCallback: () => {
        sendHotjarEvent(HotjarEvents.CAMPAIGN_NO_STUDENT_SELECTED_ALERT)
      },
    },
    {
      title: 'Definir plano de pagamento',
      content: (
        <Steps.PaymentPlan
          setIsPaymentPlanValid={setIsPaymentPlanValid}
          setIsGoingToSaveStep={setIsGoingToSaveStep}
          isGoingToSaveStep={isGoingToSaveStep}
          product={nextYearProduct}
        />
      ),
      eventStep: CampaignEventStep.DEFINE_PAYMENT_PLAN,
      validation: () => {
        if (!isPaymentPlanValid) {
          setIsGoingToSaveStep(true)
          return false
        }
        return true
      },
      errorMessage: 'É necessário preencher todos os campos de pagamento',
      hotjarStep: HotjarEvents.CAMPAIGN_DEFINE_PAYMENT_PLAN_LANDED,
      validationCallback: () => {
        if (isGoingToSaveStep) {
          sendHotjarEvent(HotjarEvents.CAMPAIGN_ERROR_WHILE_PROCEEDING)
        }
      },
    },
    {
      title: 'Revisar e salvar',
      content: (
        <Steps.ReviewAndSave
          onRemoveLastContract={() => setActiveStep(StepsEnum.SELECT_CONTRACTS)}
        />
      ),
      eventStep: CampaignEventStep.REVIEW_AND_SAVE,
      hotjarStep: HotjarEvents.CAMPAIGN_REVIEW_AND_SAVE_LANDED,
    },
  ]

  const openSnackbar = (errorText = '') => {
    setSnackbarIsOpen(true, { variation: 'error' })
    setSnackbarMessage(errorText)
  }

  const handleBack = () => {
    setSnackbarIsOpen(false)
    setActiveStep(prevActiveStep => Math.max(prevActiveStep - 1, 0))
  }

  const handleNext = () => {
    const step = steps[activeStep]

    if (step.validation()) {
      setActiveStep(prevActiveStep => {
        setSnackbarIsOpen(false)
        return Math.min(steps.length - 1, prevActiveStep + 1)
      })
    } else {
      openSnackbar(step.errorMessage)
      step.validationCallback()
    }
  }

  const handleSubmit = async () => {
    if (!preContractDisclaimerCheck) {
      openSnackbar('É necessário concordar com os Termos de Uso')

      return
    }

    try {
      const payload = {
        ...state.paymentPlan,
        contract_ids: state.selectedContractIdsList,
        product_id: state.nextYearProductId,
        pre_contract_disclaimer_check: state.preContractDisclaimerCheck,
        school_id: schoolId,
        reference_year: state.referenceYear,
        recipient_email: email,
      }
      await api.contracts.createCampaign(payload)
      navigationUnblock.current()
      history.push(
        getNavigationUrl({
          path: `contratos/${state.referenceYear}/rematricula-em-lote/processando`,
        })
      )
    } catch (error: any) {
      if (isApiNamedError(error, API_NAMED_ERRORS.LOCKED_RESOURCE)) {
        setIsCampaignInProcessDialogOpen(true)
        return
      }

      setIsErrorDialogOpen(true)
    }
  }

  const redirectToContracts = () => {
    navigationUnblock.current()
    history.push(getNavigationUrl({ path: `/contratos/${state.referenceYear}` }))
  }

  const handleCloseErrorDialog = () => {
    if (shouldQuitOnError) {
      redirectToContracts()
    }

    setIsErrorDialogOpen(false)
  }

  const handleConfirmLeaving = () => {
    sendHotjarEvent(HotjarEvents.CAMPAIGN_ABANDON_ALERT_CONFIRMATION_BUTTON_CLICKED)

    navigationUnblock.current()
    history.push(leavingPathname.current)
  }

  useEffect(() => {
    api.products
      .getList({
        school_id: schoolId,
        per_page: 300,
        page: 1,
      })
      .then(({ data: products }) => {
        const productsWithTotalTuitionAmount = products.map(product => ({
          ...product,
          totalTuitionAmount: calculateProductTotalTuitionAmount(product),
        }))

        dispatch({
          type: ActionType.FETCH_PRODUCTS,
          payload: {
            products: productsWithTotalTuitionAmount,
          },
        })
      })
  }, [schoolId])

  useEffect(() => {
    if (isPaymentPlanValid && isPaymentPlanStep) {
      handleNext()
    }
  }, [isPaymentPlanValid])

  useEffect(() => {
    if (isPaymentPlanStep) {
      setIsPaymentPlanValid(false)
      setIsGoingToSaveStep(false)
    }
  }, [activeStep])

  useEffect(() => {
    const step = steps[activeStep]
    sendHotjarEvent(step.hotjarStep)
  }, [activeStep])

  useEffect(() => {
    setHotjarPage(hotjarPageFactory(HotjarPageNames.CONTRACTS_CAMPAIGN, pageReferenceYear))
  }, [pageReferenceYear])

  useEffect(() => {
    const unblock = history.block(location => {
      leavingPathname.current = location.pathname
      setIsUserLeaving(true)

      sendHotjarEvent(HotjarEvents.CAMPAIGN_ABANDON_ALERT)
      return false
    })

    navigationUnblock.current = unblock

    return () => unblock()
  }, [])

  useEffect(() => {
    const getCampaignStatus = async () => {
      setIsLoadingCampaignStatus(true)
      try {
        const res = await api.contracts.getCampaignStatus(schoolId)
        setIsCampaignInProcessDialogOpen(!res.data.campaign_allow)
      } catch {
        setIsErrorDialogOpen(true)
        setShouldQuitOnError(true)
      }
      setIsLoadingCampaignStatus(false)
    }

    getCampaignStatus()
  }, [schoolId])

  if (isLoadingCampaignStatus) {
    return <LoadingProgress />
  }

  return (
    <PageContainer>
      <ContainerWithActionDrawer $isActionDrawerOpen={isPaymentPlanStep} xs={4}>
        <CampaignHeader nextYearProduct={nextYearProduct} />

        <Box>
          <Stepper activeStep={activeStep} connector={undefined}>
            {steps.map((step, index) => {
              const classes = activeStep === index ? 'MuiStep-active' : ''
              return (
                <Step key={step.title} className={classes}>
                  <StepLabel>{step.title}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          {steps.map((step, index) => {
            const showStep = activeStep === index ? 'block' : 'none'
            return (
              <Box key={step.title} marginBottom={4} style={{ display: showStep }}>
                {step.content}
              </Box>
            )
          })}
        </Box>

        <PageTableControlBar
          $isActionDrawerOpen={isPaymentPlanStep}
          sizeRight="calc((100% - 160px) / 3)" // drawer width
        >
          <ControlBarContainer $isActionDrawerOpen={isPaymentPlanStep}>
            <Grid item xs={2}>
              {activeStep === StepsEnum.REVIEW_SAVE && <DisclaimerTermsAndConditions />}
            </Grid>
            <Grid item xs={2} style={{ textAlign: 'right' }}>
              <Button
                disabled={activeStep === 0}
                variation="ghost"
                onClick={handleBack}
                style={{ marginRight: '12px' }}
              >
                Voltar
              </Button>
              <Button
                variation="primary"
                data-testid="btn-campaign-next-step"
                onClick={activeStep === StepsEnum.REVIEW_SAVE ? handleSubmit : handleNext}
              >
                {activeStep === StepsEnum.REVIEW_SAVE ? 'Salvar contratos' : 'Próximo'}
              </Button>
            </Grid>
          </ControlBarContainer>
        </PageTableControlBar>
      </ContainerWithActionDrawer>
      <FailureFeedbackDialog
        isVisible={isErrorDialogOpen}
        onClose={handleCloseErrorDialog}
        submitHandler={handleCloseErrorDialog}
      />
      <CampaignDialog
        open={userIsLeaving}
        title="Tem certeza que deseja sair?"
        content="Você ainda não terminou o processo de rematrícula em lote. Ao ir para outra página, as
  informações e configurações já realizadas serão perdidas.
"
        closeText="Voltar"
        confirmText="Sair e descartar"
        onClose={() => setIsUserLeaving(false)}
        onConfirm={handleConfirmLeaving}
      />
      <CampaignDialog
        open={isCampaignInProcessDialogOpen}
        title="Você tem contratos sendo processados"
        content={
          <>
            Ainda existem contratos de rematrícula sendo processados.
            <br />
            <br />
            Assim que o processamento for finalizado, você receberá um e-mail com o resultado e
            poderá iniciar uma nova rematrícula em massa.
          </>
        }
        onClose={redirectToContracts}
        closeText="Entendi"
      />
    </PageContainer>
  )
}

const CampaignPage = () => {
  return (
    <CampaignContextProvider>
      <CampaignContent />
    </CampaignContextProvider>
  )
}

export default CampaignPage
