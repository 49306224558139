import { ActionDrawerHeader, DialogContent, Drawer, Typography } from '@olaisaac/design-system'
import { useContext } from 'react'
import {
  AmountInfoProps,
  PaymentPlanSummaryProps,
  SummaryItemProps,
} from 'src/escolas/pages/Campaign/types'
import { calculateAmountInfo } from 'src/escolas/pages/Campaign/utils'
import { SENT_BY } from 'src/shared/interfaces'
import { datePTToOnlyDateISO, dueDateFormatter, formatCentsToReal } from 'src/shared/utils'
import { CampaignContext } from '../../context'
import {
  LabelWrapper,
  StyledDivider,
  SubSectionWrapper,
  SummaryItemLabel,
  SummaryItemWrapper,
} from './styles'

const SectionTitle = ({ text }) => (
  <Typography variation="headlineDesktopXsmall" color="primary" withoutMargin>
    {text}
  </Typography>
)

const SectionSubTitle = ({ text }) => (
  <Typography variation="bodyLarge" color="primary" withoutMargin>
    {text}
  </Typography>
)

const SummaryItem = ({ text, value, isPrimary = false, testId }: SummaryItemProps) => {
  const color = isPrimary ? 'primary' : 'secondary'
  return (
    <SummaryItemWrapper>
      <LabelWrapper>
        <SummaryItemLabel variation="bodySmall" color={color} withoutMargin>
          {text}
        </SummaryItemLabel>
      </LabelWrapper>
      <Typography data-testid={testId} variation="bodySmall" color={color} withoutMargin>
        {value}
      </Typography>
    </SummaryItemWrapper>
  )
}

const AmountInfo = ({
  totalGrossAmount,
  totalDiscountsAmount,
  durationMonths,
  netMonthlyAmount,
  firstDueDate,
  dueDay,
}: AmountInfoProps) => {
  const firstDueDateFormatted = firstDueDate ? datePTToOnlyDateISO(firstDueDate.utc()) : '-'
  const otherDueDayFormatted = (dueDay || dueDateFormatter(firstDueDate)) ?? '-'

  return (
    <div>
      <SummaryItem text="Valor total" value={formatCentsToReal(totalGrossAmount)} />
      <SummaryItem text="Descontos" value={`- ${formatCentsToReal(totalDiscountsAmount)}`} />
      <SummaryItem text="Vencimento 1a parcela" value={firstDueDateFormatted} />
      <SummaryItem text="Dia de vencimento demais parcelas" value={otherDueDayFormatted} />
      <SummaryItem
        text="Total com descontos"
        value={`${durationMonths}x ${formatCentsToReal(netMonthlyAmount)}`}
        isPrimary
      />
    </div>
  )
}

const PaymentPlanSummary = ({
  isEnrollmentEnabled,
  enrollmentDurationMonths,
  enrollmentMonthlyAmount,
  enrollmentDiscounts,
  tuitionDurationMonths,
  tuitionMonthlyAmount,
  tuitionDiscounts,
  enrollmentFirstDueDay,
  enrollmentDueDay,
  firstDueDay,
  tuitionDueDay,
  disableSendSignableDocument,
  sendFirstEnrollmentInstallmentMessage,
}: PaymentPlanSummaryProps) => {
  const { state } = useContext(CampaignContext)
  const { nextYearProduct, selectedContractIdsList } = state

  const [
    enrollmentTotalGrossAmount,
    enrollmentTotalDiscountsAmount,
    enrollmentNetMonthlyAmount,
    enrollmentTotalNetAmount,
  ] = isEnrollmentEnabled
    ? calculateAmountInfo(enrollmentMonthlyAmount, enrollmentDurationMonths, enrollmentDiscounts)
    : calculateAmountInfo(0, 0, [])

  const [
    tuitionTotalGrossAmount,
    tuitionTotalDiscountsAmount,
    tuitionNetMonthlyAmount,
    tuitionTotalNetAmount,
  ] = calculateAmountInfo(tuitionMonthlyAmount, tuitionDurationMonths, tuitionDiscounts)

  const totalNetAmount = enrollmentTotalNetAmount + tuitionTotalNetAmount

  const selectedContractsQty = selectedContractIdsList.length.toFixed(0)

  return (
    <Drawer open>
      <ActionDrawerHeader title="Resumo" />

      <DialogContent style={{ paddingTop: 16 }}>
        <SectionTitle text="Produto" />
        {nextYearProduct && <SummaryItem text={`${nextYearProduct.name}`} value="" />}

        <StyledDivider />

        <SectionTitle text="Alunos" />
        <SummaryItem text="Alunos selecionados" value={`${selectedContractsQty} aluno(s)`} />

        <StyledDivider />

        <SectionTitle text="Plano de Pagamento" />

        {isEnrollmentEnabled && (
          <SubSectionWrapper>
            <SectionSubTitle text="Pré-matrícula" />
            <AmountInfo
              totalGrossAmount={enrollmentTotalGrossAmount}
              totalDiscountsAmount={enrollmentTotalDiscountsAmount}
              durationMonths={enrollmentDurationMonths}
              netMonthlyAmount={enrollmentNetMonthlyAmount}
              firstDueDate={enrollmentFirstDueDay}
              dueDay={enrollmentDueDay}
            />
          </SubSectionWrapper>
        )}

        <SubSectionWrapper>
          <SectionSubTitle text="Mensalidade" />
          <AmountInfo
            totalGrossAmount={tuitionTotalGrossAmount}
            totalDiscountsAmount={tuitionTotalDiscountsAmount}
            durationMonths={tuitionDurationMonths}
            netMonthlyAmount={tuitionNetMonthlyAmount}
            firstDueDate={firstDueDay}
            dueDay={tuitionDueDay}
          />
        </SubSectionWrapper>

        <SummaryItem text="Total final" value={formatCentsToReal(totalNetAmount)} isPrimary />

        <StyledDivider />

        {isEnrollmentEnabled && (
          <SummaryItem
            text="Primeira cobrança"
            value={sendFirstEnrollmentInstallmentMessage ? SENT_BY.ISAAC : SENT_BY.SCHOOL}
          />
        )}
        {nextYearProduct && nextYearProduct.envelope_template_referral_id && (
          <SummaryItem
            text="Envio do contrato"
            value={disableSendSignableDocument ? SENT_BY.SCHOOL : SENT_BY.ISAAC}
          />
        )}
      </DialogContent>
    </Drawer>
  )
}

export default PaymentPlanSummary
