import { FC, useEffect } from 'react'
import { UseFormReturn, Controller } from 'react-hook-form'
import {
  Button,
  DialogContent,
  Drawer,
  Radio,
  FormControlLabel,
  ActionDrawerHeader,
  ButtonDocker,
  Notification,
} from '@olaisaac/design-system'
import Box from '@material-ui/core/Box'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { ContractCancellationReason } from 'src/shared/interfaces'
import { validateEditReason } from 'src/shared/utils'
import { useContract } from 'src/escolas/hooks'
import { ContractCancellationForm } from './CancellationDrawerDefaultContent'

export const preContractCancellationReasonOptions = [
  {
    name: 'O aluno não será mais matriculado na escola',
    value: ContractCancellationReason.STUDENT_LEFT,
  },
  {
    name: 'Alteração de vencimento',
    value: ContractCancellationReason.WRONG_DATE,
  },
  {
    name: 'Alteração de desconto',
    value: ContractCancellationReason.WRONG_DISCOUNT,
  },
  {
    name: 'Alteração de valor',
    value: ContractCancellationReason.WRONG_AMOUNT,
  },
  {
    name: 'Cancelamento de parcela única',
    value: ContractCancellationReason.CANCEL_INSTALLMENT,
  },
  { name: 'Outro', value: ContractCancellationReason.OTHER },
]

export type CancellationDrawerPreContractContentProps = {
  form: UseFormReturn<ContractCancellationForm>
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

const CancellationDrawerPreContractContent: FC<CancellationDrawerPreContractContentProps> = ({
  isOpen,
  onClose,
  onConfirm,
  form,
}) => {
  const { contract } = useContract()

  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { isSubmitting, isValid, errors },
  } = form

  watch(['cancellation_reason', 'cancellation_description'])

  const { cancellation_reason, cancellation_description } = getValues()

  const isReasonOther = cancellation_reason === ContractCancellationReason.OTHER

  const hasCancellationDescriptionErros = Boolean(errors?.cancellation_description)
  const invalidDescription = !!(cancellation_description && hasCancellationDescriptionErros)

  const isActionButtonDisabled =
    (isReasonOther && !cancellation_description) || isSubmitting || !isValid

  useEffect(() => {
    if (!isReasonOther) {
      setValue('cancellation_description', '', { shouldValidate: true })
    }
  }, [isReasonOther])

  return (
    <Drawer open={isOpen} data-testid="pre-contract-cancellation-drawer-container">
      <ActionDrawerHeader
        onClose={onClose}
        title="Cancelar contrato"
        subtitle={contract?.student?.name}
      />

      <Notification
        description="Ao cancelar o contrato, ele não será recuperado. Caso necessário, um novo contrato precisará ser criado."
        title="Essa ação é irreversível"
        variation="warning"
      />

      <DialogContent>
        <Box display="block">
          <Box my={3}>
            <Typography variant="subtitle1">Qual é o motivo do cancelamento?</Typography>
          </Box>
          <FormControl component="fieldset">
            <Controller
              rules={{ required: true }}
              control={control}
              name="cancellation_reason"
              render={({ field }) => (
                <RadioGroup data-testid="cancellation_reason" aria-label="source" {...field}>
                  {preContractCancellationReasonOptions.map(({ name, value }) => (
                    <FormControlLabel key={value} value={value} control={<Radio />} label={name} />
                  ))}
                </RadioGroup>
              )}
            />
          </FormControl>
          {isReasonOther && (
            <FormControl fullWidth variant="outlined">
              <Controller
                rules={{
                  required: isReasonOther,
                  maxLength: 100,
                  minLength: 5,
                  validate: isReasonOther && validateEditReason,
                }}
                name="cancellation_description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="cancellation_description"
                    disabled={!isReasonOther}
                    variant="outlined"
                    label="Motivo"
                    error={invalidDescription}
                    helperText={
                      invalidDescription
                        ? 'Esse campo é obrigatório e deve ter entre 5 e 100 caracteres (somente letras e números)'
                        : ''
                    }
                  />
                )}
              />
            </FormControl>
          )}
        </Box>
      </DialogContent>

      <ButtonDocker>
        <Button
          data-testid="pre-contract-cancellation-drawer-action-btn"
          type="submit"
          onClick={() => {
            onConfirm()
          }}
          disabled={isActionButtonDisabled}
          loading={isSubmitting}
          fullWidth
        >
          Confirmar
        </Button>
      </ButtonDocker>
    </Drawer>
  )
}

export default CancellationDrawerPreContractContent
