import React, { FC } from 'react'

import StepItem from './components/StepItem'
import type { StepperProps } from './types'
import * as S from './styles'

const Stepper: FC<StepperProps> = ({ activeStepIndex, steps, onItemClick, disabled = false }) => {
  return (
    <S.Wrapper>
      {steps.map((step, index) => (
        <StepItem
          activeStepIndex={activeStepIndex}
          disabled={disabled}
          key={index}
          index={index}
          label={step}
          onClick={onItemClick}
        />
      ))}
    </S.Wrapper>
  )
}

export default Stepper
