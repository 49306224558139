import { Box, FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { DatePicker, TextField } from '@olaisaac/design-system'
import { MAX_TUITION_INSTALLMENTS } from './constants'
import {
  DueDayBox,
  FieldLegendTypography,
  FieldTitleTypography,
  StartMonthBox,
  ArithmeticOperator,
} from './style'
import { FC } from 'react'
import {
  DATE_FORMAT,
  formatCentsToReal,
  isCurrentMonthOrAfter,
  isDayTodayOrAfter,
  renderNumericSelectOptions,
} from '@/shared/utils'
import SelectDueDay from '@/escolas/components/contract/ContractEditDrawer/components/SelectNewDueDay/SelectNewDueDay'
import { TuitionInstallmentsFormProps } from './types'
import { omit } from 'ramda'

const TuitionInstallmentsForm: FC<TuitionInstallmentsFormProps> = ({ form, style }) => {
  const { formState, control, setValue, getValues, watch, trigger } = form
  watch()
  const formValues = getValues()
  const durationMonthsString = getValues('duration_months')
  const durationMonths = durationMonthsString ? +durationMonthsString : 0

  const installmentTotalAmount = formValues.monthly_amount * durationMonths
  const hasMultipleInstallments = durationMonths > 1

  return (
    <>
      <Box borderTop={style?.borderTop}>
        <FieldLegendTypography>
          O valor da mensalidade é definido a partir das configurações do produto. Caso queira, você
          pode alterar o valor da parcela abaixo.
        </FieldLegendTypography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="bottom" mb={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="duration_months-label">Parcelas</InputLabel>
          <Controller
            name="duration_months"
            control={control}
            render={({ formState, field, field: { value } }) => {
              if (value === undefined) return <></>
              return (
                <Select
                  {...field}
                  data-testid="duration_months-select"
                  labelId="duration_months-label"
                  id="duration_months-select"
                  label="Parcelas de mensalidade"
                  error={Boolean(formState.errors.duration_months)}
                >
                  {renderNumericSelectOptions({
                    start: 1,
                    end: MAX_TUITION_INSTALLMENTS,
                    name: 'duration_months-option',
                  })}
                </Select>
              )
            }}
          />
          {formState.errors.duration_months && (
            <FormHelperText error>{formState.errors.duration_months.message}</FormHelperText>
          )}
        </FormControl>
        <ArithmeticOperator>x</ArithmeticOperator>
        <FormControl variant="outlined" fullWidth>
          <Controller
            control={control}
            name="monthly_amount"
            render={({ formState, field: { onChange, ...rest } }) => (
              <NumberFormat
                {...rest}
                onValueChange={({ floatValue }) => {
                  onChange(floatValue)
                  trigger(['monthly_amount'])
                }}
                id="monthly_amount-input"
                data-testid="monthly_amount-input"
                name="monthly_amount"
                customInput={TextField}
                variant="outlined"
                label="Valor"
                format={formatCentsToReal}
                error={Boolean(formState.errors.monthly_amount)}
                helperText={
                  formState.errors.monthly_amount ? formState.errors.monthly_amount.message : ''
                }
              />
            )}
          />
        </FormControl>
        <ArithmeticOperator>=</ArithmeticOperator>
        <FormControl fullWidth disabled variant="outlined">
          <NumberFormat
            disabled
            value={installmentTotalAmount}
            placeholder="R$ 0,00"
            customInput={TextField}
            variant="outlined"
            label="Valor total"
            format={formatCentsToReal}
            id="installment-total-amount"
          />
        </FormControl>
      </Box>
      <StartMonthBox>
        <FieldTitleTypography>
          {hasMultipleInstallments ? 'Início das mensalidades' : 'Data de vencimento'}
        </FieldTitleTypography>
        <FieldLegendTypography>
          {hasMultipleInstallments
            ? 'Selecione o mês de início das parcelas'
            : 'Selecione o dia de vencimento'}
        </FieldLegendTypography>
        <FormControl variant="outlined">
          {hasMultipleInstallments ? (
            <Controller
              name="start_month"
              rules={{ required: true, validate: isDayTodayOrAfter }}
              control={control}
              render={({ formState, field }) => (
                <DatePicker
                  {...omit(['ref'], field)}
                  views={['year', 'month']}
                  id="start_month"
                  label="Mês"
                  placeholder="MM/YYYY"
                  format="MM/YYYY"
                  disablePast
                  error={Boolean(formState.errors.start_month)}
                  helperText={
                    formState.errors.start_month ? 'Selecione uma data de vencimento válida' : ''
                  }
                  maxDate={new Date(new Date().getFullYear() + 2, 11, 31)}
                  minDateMessage="A data não pode ser anterior a hoje"
                  invalidDateMessage="Formato de data inválido"
                />
              )}
            />
          ) : (
            <Controller
              name="start_month"
              rules={{ required: true, validate: isCurrentMonthOrAfter }}
              control={control}
              render={({ formState, field: { value } }) => (
                <DatePicker
                  id="start_month"
                  label="Dia"
                  placeholder={DATE_FORMAT}
                  format={DATE_FORMAT}
                  disablePast
                  maxDate={new Date(new Date().getFullYear() + 2, 11, 31)}
                  minDateMessage="A data não pode ser anterior a hoje"
                  invalidDateMessage="Formato de data inválido"
                  error={Boolean(formState.errors.start_month)}
                  helperText={
                    formState.errors.start_month ? 'Selecione uma data de vencimento válida' : ''
                  }
                  value={value}
                  onChange={v =>
                    setValue('start_month', v, {
                      shouldDirty: true,
                      shouldValidate: true,
                    })
                  }
                />
              )}
            />
          )}
        </FormControl>
      </StartMonthBox>
      {hasMultipleInstallments && (
        <Box mb={3} mt={4}>
          <Fade in={hasMultipleInstallments}>
            <>
              <DueDayBox>
                <FieldTitleTypography>Data de vencimento</FieldTitleTypography>
                <FieldLegendTypography>
                  Selecione a regra de dia de vencimento das mensalidades
                </FieldLegendTypography>
              </DueDayBox>
              <SelectDueDay form={form} inputName="due_day" radioName="use_working_dates_tuition" />
            </>
          </Fade>
        </Box>
      )}
    </>
  )
}

export default TuitionInstallmentsForm
