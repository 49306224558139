import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { useApi } from '@/shared/hooks'
import { SOFTCACHE_OPTIONS_BASE } from '@/shared/constants'
import { useGetReceivableByIDQueryProps } from '../../services/types'

import {
  DEFAULT_QUERY_RETRY_COUNT,
  DEFAULT_QUERY_STALE_TIME,
} from '../../GuardianDetails/constants'
import { getReceivableByID } from '../../services/apiV1'
import { Receivable } from '@/shared/interfaces'

export const useGetReceivableByIDQuery = (
  receivableID: uuid,
  props: useGetReceivableByIDQueryProps,
  options?: UseQueryOptions<Receivable, XMLHttpRequest>
) => {
  const { apiClient } = useApi()
  const queryKey = ['get-receivable-by-id', receivableID]

  const {
    data: receivable,
    isLoading: isGetReceivableByIDLoading,
    isError: isGetReceivableByIDError,
    isFetched: isGetReceivableByIDFetched,
    refetch,
  } = useQuery<Receivable, XMLHttpRequest>(
    queryKey,
    () => {
      return getReceivableByID(apiClient.privateApi, receivableID, props)
    },
    {
      ...SOFTCACHE_OPTIONS_BASE,
      enabled: Boolean(receivableID),
      retry: DEFAULT_QUERY_RETRY_COUNT,
      staleTime: DEFAULT_QUERY_STALE_TIME,
      ...options,
    }
  )

  return {
    receivable,
    isGetReceivableByIDLoading,
    isGetReceivableByIDError,
    isGetReceivableByIDFetched,
    refetchGetReceivableByID: refetch,
  }
}
