import { FC, Fragment } from 'react'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { formatCentsToReal } from 'src/shared/utils'
import {
  CheckoutCombinableReceivable,
  CheckoutReceivables,
} from 'src/escolas/contexts/checkoutContext'
import { Typography } from '@olaisaac/design-system'
import dayjs from 'dayjs'
import { prettifyFullName } from 'src/shared/utils/namePrettifiers'
import { usePaymentCreditCard } from '@/escolas/hooks'
import { useAgreement } from '@/escolas/contexts/agreementContext'
import { SimulationPaymentMethod } from '@/shared/interfaces'

export type NegotiationFinancialSummaryProps = {
  combinedReceivables: CheckoutReceivables[]
}
export type TypographyType = {
  isDiscount?: boolean
  value: string
  variation: any
}
const NewTypography: FC<TypographyType> = ({ variation, value, isDiscount }) => {
  return (
    <Typography withoutMargin variation={variation} style={{ marginBottom: 10 }}>
      {isDiscount ? ' - ' : ''}
      {value}
    </Typography>
  )
}
const NegotiationFinancialSummary: FC<NegotiationFinancialSummaryProps> = ({
  combinedReceivables,
}) => {
  const { isCreditCardFlow } = usePaymentCreditCard()
  const { checkoutData, selectedSimulationPaymentMethod } = useAgreement()

  const findCreditCardFeeValue = (combinableReceivables: CheckoutCombinableReceivable[]) => {
    if (selectedSimulationPaymentMethod !== SimulationPaymentMethod.CREDIT_CARD) return 0
    const receivables = combinableReceivables
      .map(combinableReceivable =>
        checkoutData?.receivables?.find(item => item.id === combinableReceivable.id)
      )
      .filter(Boolean)
    if (receivables.length === 0) return 0
    return receivables.reduce((acc, receivable) => acc + (receivable.credit_card_fee ?? 0), 0)
  }

  const findValues = (receivables: CheckoutCombinableReceivable[]) => {
    return receivables.reduce(
      (acc, curr) => {
        const duePaymentDiscount = acc.duePaymentDiscount + curr.lost_due_payment_discount

        const fineAndInterest =
          acc.finesAndInterest + (curr.current_fine ?? 0) + (curr.current_interest ?? 0)

        const baseAmount =
          acc.baseAmount +
          curr.amount +
          (curr.current_fine ?? 0) +
          (curr.current_interest ?? 0) -
          (curr.current_perpetual_discount ?? 0) -
          (curr.current_early_payment_discount ?? 0) -
          (curr.current_due_payment_discount ?? 0) -
          (curr.lost_due_payment_discount ?? 0)

        return {
          finesAndInterest: fineAndInterest,
          baseAmount: baseAmount,
          duePaymentDiscount: duePaymentDiscount,
        }
      },
      {
        finesAndInterest: 0,
        duePaymentDiscount: 0,
        baseAmount: 0,
      }
    )
  }
  return (
    <>
      {combinedReceivables.map(student => (
        <Fragment key={student.student_id}>
          <Typography
            withoutMargin
            variation="bodySmall"
            color="secondary"
            style={{ marginBottom: 10 }}
          >
            {prettifyFullName(student.student_name)}
          </Typography>

          {student.products.map(product => {
            const values = findValues(product.receivables)
            const creditCardFeeValue = findCreditCardFeeValue(product.receivables)
            const subTotal = values?.baseAmount + creditCardFeeValue
            return (
              <Fragment key={product.product_id}>
                <NewTypography variation="subtitleDesktopLarge" value={product.product_name} />
                <Divider style={{ marginBottom: 20 }} />
                {product.receivables.map(receivable => {
                  const currentAmount =
                    receivable?.amount -
                    (receivable?.current_perpetual_discount ?? 0) -
                    (receivable?.current_early_payment_discount ?? 0) -
                    (receivable?.current_due_payment_discount ?? 0)
                  return (
                    <Fragment key={receivable.id}>
                      <Box pb={1} display="flex" justifyContent="space-between">
                        <NewTypography
                          variation="subtitleDesktopMedium"
                          value={dayjs(receivable?.competenceDate).utc().format('MMMM')}
                        />
                        <NewTypography
                          variation="subtitleDesktopMedium"
                          value={formatCentsToReal(currentAmount)}
                        />
                      </Box>
                    </Fragment>
                  )
                })}
                <Box pb={1} display="flex" justifyContent="space-between">
                  <NewTypography variation="subtitleDesktopMedium" value="Multa e Juros" />

                  <NewTypography
                    variation="subtitleDesktopMedium"
                    value={formatCentsToReal(values?.finesAndInterest)}
                  />
                </Box>
                <Box pb={1} display="flex" justifyContent="space-between">
                  <NewTypography
                    variation="subtitleDesktopMedium"
                    value="Desconto de pontualidade devolvido"
                  />

                  <NewTypography
                    variation="subtitleDesktopMedium"
                    value={formatCentsToReal(values?.duePaymentDiscount)}
                    isDiscount
                  />
                </Box>

                {isCreditCardFlow && (
                  <Box pb={1} display="flex" justifyContent="space-between">
                    <NewTypography variation="subtitleDesktopMedium" value="Taxa do cartão" />

                    <NewTypography
                      variation="subtitleDesktopMedium"
                      value={formatCentsToReal(creditCardFeeValue)}
                    />
                  </Box>
                )}
                <Divider style={{ marginBottom: 20 }} />
                <Box pb={1} display="flex" justifyContent="space-between">
                  <NewTypography variation="subtitleDesktopMedium" value="Subtotal" />

                  <NewTypography
                    variation="subtitleDesktopMedium"
                    value={formatCentsToReal(subTotal)}
                  />
                </Box>
              </Fragment>
            )
          })}
        </Fragment>
      ))}
    </>
  )
}

export default NegotiationFinancialSummary
