import { Box } from '@material-ui/core'
import { Checkbox, FormControlLabel, Typography } from '@olaisaac/design-system'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'

import { HotjarEvents, useHotjar } from 'src/shared/hooks/useHotjar'
import { useSnackbar } from '@/shared/hooks'

import CampaignDialog from '../../CampaignDialog'
import { CampaignContext } from '../../context'
import { ActionType } from '../../types'

const StyledButton = styled.button`
  font-size: 14px;
  color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`

const DisclaimerTermsAndConditions = () => {
  const { state, dispatch } = useContext(CampaignContext)
  const [isDialogOpen, setDialogOpen] = useState(false)
  const { setIsOpen: setSnackbarIsOpen } = useSnackbar()
  const { sendHotjarEvent } = useHotjar()

  const onChange = () => {
    setSnackbarIsOpen(false)

    dispatch({
      type: ActionType.SET_PRE_CONTRACT_DISCLAIMER_CHECK,
      payload: {
        preContractDisclaimerCheck: !state.preContractDisclaimerCheck,
      },
    })
  }

  const toggleTermsAndConditions = () => {
    setDialogOpen(!isDialogOpen)
  }

  useEffect(() => {
    if (isDialogOpen) {
      sendHotjarEvent(HotjarEvents.CAMPAIGN_TERMS_AND_CONDITIONS_OPENED)
    }
  }, [isDialogOpen])

  return (
    <div>
      <Box>
        <FormControlLabel
          checked={state.preContractDisclaimerCheck}
          control={<Checkbox onChange={onChange} />}
          label={
            <Typography>
              Declaro que li e concordo com os{' '}
              <StyledButton onClick={toggleTermsAndConditions}>Termos e condições</StyledButton>.
            </Typography>
          }
        />
      </Box>
      <CampaignDialog
        open={isDialogOpen}
        title="Termos e condições"
        content={
          <>
            Declaro que li e concordo que o pagamento do valor da pré-matrícula não garante a vaga
            do aluno no ano letivo de 2023. A mesma será confirmada{' '}
            <strong>mediante adimplência ou renegociação do contrato de 2022</strong>, de acordo com
            as nossas políticas internas.
          </>
        }
        closeText="Fechar"
        onClose={toggleTermsAndConditions}
      />
    </div>
  )
}

export default DisclaimerTermsAndConditions
