import { useEffect, useState } from 'react'

import { useQuery } from 'src/escolas/hooks/useQuery'
import { ContractStatus, DebtStatus } from 'src/shared/interfaces'

import { ContractsFiltersType } from '../types'
import { GuardianContractsQueryParamsNameEnum } from '../constants'

const parseArrayToFilter = <T>(filter: string) => {
  return (!filter || filter === '' ? [] : filter.split(',')) as T[]
}

/**
 * @description Hook to handle query params for guardian contracts page
 */
export const useGuardianContractsQueryParams = () => {
  const { query, setMultipleQueryParams } = useQuery()

  const [filters, setFilters] = useState<ContractsFiltersType>({
    debt_statuses: parseArrayToFilter<DebtStatus>(
      query.get(GuardianContractsQueryParamsNameEnum.debtStatuses)
    ),
    status: query.get(GuardianContractsQueryParamsNameEnum.contractStatuses) as ContractStatus,
    reference_years: parseArrayToFilter<string>(
      query.get(GuardianContractsQueryParamsNameEnum.years)
    ),
    product_ids: parseArrayToFilter<string>(
      query.get(GuardianContractsQueryParamsNameEnum.productIds)
    ),
  })

  const updateFilters = (data: ContractsFiltersType) => {
    setFilters(data)
  }

  const filtersToQueryParams = (filters: ContractsFiltersType) =>
    Object.entries(filters).reduce((prev, [name, value]) => {
      prev.push({ name, value: value && value.length ? value : null })
      return prev
    }, [])

  useEffect(() => {
    setMultipleQueryParams([...filtersToQueryParams(filters)])
  }, [filters])

  return {
    filters,
    updateFilters,
  }
}
