import { useParams } from 'react-router-dom'
import { Box } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { SelectPrimitives } from '@gravity/select'
import { Button } from '@gravity/button'

import { ErrorDialog } from '@/modules/enrollment/components/ErrorDialog'
import { useNavigation } from '@/escolas/hooks'
import { useSetPageTitle } from '@/shared/hooks/useSetPageTitle'
import { PageWrapper } from '@/escolas/components/PageWrapper'

import { useSelectedYear } from './hooks/useSelectedYear'
import * as Styled from './styles'
import { useEnrollmentCampaignRedirect } from '@/modules/enrollment/hooks/useEnrollmentCampaignRedirect'

type URLParams = {
  year: string
}

export const CampaignsList = () => {
  useEnrollmentCampaignRedirect()

  useSetPageTitle(`Campanhas de matrícula: - isaac`)

  const { year: urlYearParam } = useParams<URLParams>()
  const {
    hasErrorOnFetchCycles,
    selectedEnrollmentCycle,
    selectedCycleYear,
    years,
    updateCycleYear,
    isFetchingCycles,
  } = useSelectedYear(urlYearParam)

  const { navigateTo } = useNavigation()

  const currentYear = new Date().getFullYear()
  const isAddNewCampaignBtnDisabled =
    selectedEnrollmentCycle?.enabled === false &&
    selectedEnrollmentCycle.reference_year < currentYear

  return (
    <PageWrapper>
      <Styled.PageContainer>
        <ErrorDialog isError={hasErrorOnFetchCycles || (!isFetchingCycles && years.length === 0)} />

        <Styled.ActionsContainer>
          <Box flexDirection="column" order="1">
            <Box display="flex" flexDirection="row">
              <Styled.SelectYearTitle>Campanhas de matrícula:</Styled.SelectYearTitle>

              <Styled.SelectYearWrapper>
                <SelectPrimitives.Root
                  onValueChange={updateCycleYear}
                  value={String(selectedCycleYear)}
                  aria-label="Campanhas de matrícula"
                >
                  <Styled.SelectLabelWrapper>
                    <SelectPrimitives.Trigger aria-label="Campanhas de matrícula" />
                  </Styled.SelectLabelWrapper>
                  <SelectPrimitives.Content>
                    {years.map(year => (
                      <SelectPrimitives.Item key={year} value={String(year)}>
                        {year}
                      </SelectPrimitives.Item>
                    ))}
                  </SelectPrimitives.Content>
                </SelectPrimitives.Root>
              </Styled.SelectYearWrapper>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" order="2">
            <Button
              iconStart={<AddIcon />}
              onClick={() => navigateTo({ path: `/campanha/novo` })}
              disabled={isAddNewCampaignBtnDisabled}
            >
              Nova campanha
            </Button>
          </Box>
        </Styled.ActionsContainer>
      </Styled.PageContainer>
    </PageWrapper>
  )
}
