import { Enrollment, Status } from '@/escolas/services/enrollment/types'
import { Skeleton } from '@material-ui/lab'
import { Typography } from '@olaisaac/design-system'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import relativeTime from 'dayjs/plugin/relativeTime'
import styled from 'styled-components'

const Header = styled.header`
  margin: ${({ theme }) => theme.spacing(8)}px 0 ${({ theme }) => theme.spacing(10)}px;
`

const Title = styled.h1`
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.125;
  margin-bottom: 8px;
`

const formatDate = (value: datestring) =>
  dayjs(value).format('DD [de] MMM. [de] YYYY').toLocaleLowerCase()

export const getLastEdit = (enrollment: Enrollment): [boolean, datestring] => {
  const editedPendingInstallments = enrollment.installments.filter(
    i => i.status === Status.PENDING && !dayjs(i.updated_at).isSame(dayjs(i.created_at))
  )

  if (editedPendingInstallments.length === 0) {
    return [false, '']
  }

  const sorted = editedPendingInstallments.sort((a, b) =>
    dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? 1 : -1
  )

  return [true, sorted[0].updated_at]
}

export const formatLastEdit = (now: datestring, updatedAt: datestring): string => {
  dayjs.locale('pt-br')
  dayjs.extend(relativeTime)

  const diffHours = dayjs(now).diff(dayjs(updatedAt), 'hours')
  const updatedYesterday = dayjs(now).subtract(1, 'day').isSame(dayjs(updatedAt), 'date')

  if (updatedYesterday && diffHours > 12) {
    return 'ontem'
  }

  if (diffHours <= 24) {
    return dayjs(updatedAt).from(dayjs(now))
  }

  if (diffHours < 7 * 24) {
    return dayjs(updatedAt).format('dddd')
  }

  return `em ${formatDate(updatedAt)}`
}

/**
 * @deprecated This component will be deprecated after the EEMAT-727-ENABLE_UNIFIED_CONTRACT_EDITING rollout
 */
export const PageHeader = ({
  isLoading,
  enrollment,
}: {
  enrollment: Enrollment
  isLoading: boolean
}) => {
  const [wasEdited, editedAt] = isLoading ? [false, ''] : getLastEdit(enrollment)

  return (
    <Header>
      <Title>Editar contrato</Title>
      {isLoading ? (
        <Skeleton width={288} height={32} />
      ) : (
        <Typography variation="bodyLarge">
          {`Contrato criado em ${formatDate(enrollment.created_at)}`}
          {wasEdited && (
            <span title={dayjs(editedAt).toISOString()}>
              , última edição feita <span>{formatLastEdit(dayjs().toISOString(), editedAt)}</span>
            </span>
          )}
        </Typography>
      )}
    </Header>
  )
}
