import { AxiosError } from 'axios'
import { API_NAMED_ERRORS } from '@/shared/interfaces'

export const isApiNamedError = (error: AxiosError, api_named_error: API_NAMED_ERRORS): boolean => {
  const responseErrors = error?.response?.data?.errors

  if (responseErrors === undefined) {
    const errorMessage = error?.response?.data?.message
    const responseErrorsTag = error?.response?.data?.tag
    return errorMessage === api_named_error || responseErrorsTag === api_named_error
  }

  for (const errorItem of responseErrors) {
    if (errorItem?.reference_name === api_named_error) {
      return true
    }
  }

  return false
}
