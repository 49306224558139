import styled from 'styled-components'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { TableControlBar } from '@olaisaac/design-system'

export const PageTableControlBar = styled(TableControlBar)<{
  $isActionDrawerOpen?: boolean
  sizeRight?: string
}>(({ $isActionDrawerOpen, sizeRight = '25%' }) => ({
  bottom: 0,
  left: 96,
  position: 'fixed',
  right: $isActionDrawerOpen ? sizeRight : 0,
  zIndex: 1,
  justifyContent: 'right',
}))

export const InfoIcon = styled(InfoOutlinedIcon)``

export const Divider = styled.hr`
  border: 1px solid ${({ theme }) => theme.primitiveTokens.colors.gray[6]};
`
