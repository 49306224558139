import { useEffect, useState } from 'react'
import { BtnSimulation, FormStyled } from './styles'
import { decimalAdjust, formatCurrencyValue } from '@/shared/utils'
import DefaultTemplate from '../../templates/DefaultTemplate'
import {
  useContextEligibility,
  useContextCreditSimulation,
  useContextCreditOrderId,
  useContextRequestReason,
} from '../../contexts/CreditDataContext'
import { useCreateCreditSimulation } from '../../hooks/queries/useCreateCreditSimulation'
import {
  CreateCreditSimulationRequest,
  CreditReason,
  CreditSimulationResponse,
} from '../../services/types'
import { useSelectedSchool } from '@/shared/hooks/useSelectedSchool'
import { useHistory } from 'react-router-dom'
import { LoadingSimulation } from './LoadingSimulation'
import CreditReasonDropdown from '../../components/CreditReasonDropdown'
import { Footer } from '../../components/Footer'
import { EventDispatcherEvents } from '@/shared/models/enums/EventDispatcherEvents.enum'
import { MixpanelEventActions } from '../../constants/MixpanelEventActions.enum'
import { CreditEventComponentNames } from '../../constants/CreditEventComponentNames.enum'
import useMixpanelEventSender from '../../utils/useMixpanelEventSender'
import { TextField, numberFormat } from '@gravity/text-field'

// Arbitrary maximum value for simulations, regardless of the user's pre-approved value. Represents R$ 10.000.000,00
const MAX_ABSOLUTE_SIMULATION_VALUE = 10_000_000_00

enum SimulationValueError {
  MAX_PA_EXCEEDED,
  MAX_ABSOLUTE_VALUE_EXCEEDED,
}

const valueAsNumber = (value: string) => Number(value.replace(/[^0-9]/g, ''))

export const Simulation = () => {
  const { push } = useHistory()

  const { sendCreditEventToMixpanel } = useMixpanelEventSender()

  const [requestValue, setRequestValue] = useState<string>('0,00')
  const [reason, setReason] = useState<CreditReason>(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(requestValue === '0,00')
  const [maxPaExceeded, setMaxPaExceeded] = useState<boolean>(false)
  const [loadingSummary, setLoadingSummary] = useState<boolean>(false)
  const [note, setNote] = useState<string>('')
  const [valueErrorCode, setValueErrorCode] = useState<SimulationValueError | null>(null)

  const isButtonDisabledReasons = reason === null || (reason?.requiredNote && note === '')
  const isButtonDisabledTotal = isButtonDisabled || isButtonDisabledReasons

  const { school, schoolSlug } = useSelectedSchool()

  const { eligibility } = useContextEligibility()
  const { setCreditSimulation } = useContextCreditSimulation()
  const { setCreditOrderId } = useContextCreditOrderId()
  const { setRequestReason } = useContextRequestReason()

  const { mutateAsync } = useCreateCreditSimulation()

  useEffect(() => {
    sendCreditEventToMixpanel(EventDispatcherEvents.PAGE_VIEWED, MixpanelEventActions.PAGE_VIEW, {
      $page_name: CreditEventComponentNames.SIMULATION_PAGE,
    })
  }, [])

  useEffect(() => {
    const valueNotSet = valueAsNumber(requestValue) === 0
    const surpassMaxPa = maxPaExceeded && isGreaterThanMaxPa
    const surpassAbsoluteMax = valueAsNumber(requestValue) > MAX_ABSOLUTE_SIMULATION_VALUE

    setIsButtonDisabled(valueNotSet || surpassMaxPa || surpassAbsoluteMax)

    if (!isGreaterThanMaxPa) setValueErrorCode(null)

    if (surpassMaxPa) setValueErrorCode(SimulationValueError.MAX_PA_EXCEEDED)

    if (valueAsNumber(requestValue) > MAX_ABSOLUTE_SIMULATION_VALUE) {
      setValueErrorCode(SimulationValueError.MAX_ABSOLUTE_VALUE_EXCEEDED)
    } else if (isGreaterThanMaxPa && !maxPaExceeded) {
      setValueErrorCode(null)
    }
  }, [requestValue])

  // TODO: this is a quick solution to redirect user if there is no eligibility data in context
  // we should persist data in browser storage or use useNavigate to avoid this
  useEffect(() => {
    if (!eligibility) {
      push(`/${schoolSlug}/credito`)
    }
  }, [eligibility])

  const maxPa = decimalAdjust('floor', eligibility?.pa, 2)
  const isGreaterThanMaxPa = valueAsNumber(requestValue) / 100 > maxPa
  const isEqualPa = valueAsNumber(requestValue) / 100 === maxPa

  const errorMessagesForValueInput: Record<SimulationValueError, string> = {
    [SimulationValueError.MAX_ABSOLUTE_VALUE_EXCEEDED]: 'Valor máximo atingindo',
    [SimulationValueError.MAX_PA_EXCEEDED]: `Valor máximo pré-aprovado: ${formatCurrencyValue(
      maxPa
    )}`,
  }

  const removeCentsAndRoundUp = (value: string): string => {
    const hasCents = Number(value) % 100 !== 0
    const formattedValue = value.length > 2 ? value.slice(0, -2) : value
    const response = hasCents ? Number(formattedValue) + 1 : Number(formattedValue)
    return `${response.toString()}00`
  }

  const handleSimulateButtonClick = () => {
    sendCreditEventToMixpanel(EventDispatcherEvents.BUTTON_CLICKED, MixpanelEventActions.CLICK, {
      $button_name: 'Simular agora',
      $selected: reason.label,
      $value: requestValue,
      $maximum: isGreaterThanMaxPa ? 'more' : isEqualPa ? 'equal' : 'less',
    })

    if (isGreaterThanMaxPa) {
      setValueErrorCode(SimulationValueError.MAX_PA_EXCEEDED)
      setMaxPaExceeded(true)
      return
    }

    const data: CreateCreditSimulationRequest = {
      note: note,
      reason: reason.label,
      requestedValue: Number(removeCentsAndRoundUp(requestValue.replace(/[^0-9]/g, ''))),
      schoolId: school.id,
    }

    setLoadingSummary(true)
    mutateAsync(data).then((response: CreditSimulationResponse) => {
      setRequestReason({ reason: data?.reason, note: data?.note ?? '' })
      setCreditSimulation(response.data)
      setLoadingSummary(false)
      setCreditOrderId(response.data.orderId)
      push(`/${schoolSlug}/credito/resumo`)
    })
  }

  const handleChangeSimulationValue = (value: string) => {
    setRequestValue(value)
  }

  if (loadingSummary) {
    return <LoadingSimulation />
  }

  return (
    <DefaultTemplate
      title="Simulação de crédito"
      handleBackPreviousRoute={() => {
        push(`/${schoolSlug}/credito`)
      }}
      child={
        <>
          <FormStyled>
            <CreditReasonDropdown
              title="Como o crédito vai apoiar sua escola?"
              reason={reason}
              setReason={setReason}
              note={note}
              setNote={setNote}
            />

            <TextField
              name="creditValue"
              size={3}
              placeholder="0,00"
              inputPrefix="R$"
              label="Valor desejado"
              error={valueErrorCode !== null}
              errorMessage={
                valueErrorCode !== null ? errorMessagesForValueInput[valueErrorCode] : ''
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeSimulationValue(event.target.value)
                numberFormat(event)
              }}
              onBlur={event => {
                const splitValue = event.target.value.split(',')[0]
                event.target.value = splitValue ? splitValue + ',00' : '0,00'
              }}
            />

            <BtnSimulation
              title="Simular agora"
              disabled={
                maxPaExceeded
                  ? isButtonDisabledReasons || isGreaterThanMaxPa
                  : isButtonDisabledTotal
              }
              fullWidth
              onClick={handleSimulateButtonClick}
            >
              Simular agora
            </BtnSimulation>
          </FormStyled>
          <Footer />
        </>
      }
    />
  )
}
